// import React, { Component } from "react";

// // MetisMenu
// import MetisMenu from "metismenujs";
// import { withRouter } from "react-router-dom";
// import { Link } from "react-router-dom";

// //i18n
// import { withNamespaces } from "react-i18next";

// import { connect } from "react-redux";
// import {
//   changeLayout,
//   changeLayoutWidth,
//   changeSidebarTheme,
//   changeSidebarType,
//   changePreloader,
// } from "../../store/actions";
// import UserPermissionFetcher from "../../helpers/getUserPerimissions";
// const authUser = localStorage.getItem("authUser");

// console.log(authUser, "authUser");

// class SidebarContent extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }

//   componentDidMount() {
//     this.initMenu();
//   }

//   componentDidUpdate(prevProps) {
//     if (prevProps !== this.props) {
//       if (this.props.type !== prevProps.type) {
//         this.initMenu();
//       }
//     }
//   }

//   initMenu() {
//     new MetisMenu("#side-menu");

//     var matchingMenuItem = null;
//     var ul = document.getElementById("side-menu");
//     var items = ul.getElementsByTagName("a");
//     for (var i = 0; i < items.length; ++i) {
//       if (this.props.location.pathname === items[i].pathname) {
//         matchingMenuItem = items[i];
//         break;
//       }
//     }
//     if (matchingMenuItem) {
//       this.activateParentDropdown(matchingMenuItem);
//     }
//   }

//   activateParentDropdown = (item) => {
//     item.classList.add("active");
//     const parent = item.parentElement;

//     if (parent) {
//       parent.classList.add("mm-active");
//       const parent2 = parent.parentElement;

//       if (parent2) {
//         parent2.classList.add("mm-show");

//         const parent3 = parent2.parentElement;

//         if (parent3) {
//           parent3.classList.add("mm-active"); // li
//           parent3.childNodes[0].classList.add("mm-active"); //a
//           const parent4 = parent3.parentElement;
//           if (parent4) {
//             parent4.classList.add("mm-active");
//           }
//         }
//       }
//       return false;
//     }
//     return false;
//   };

//   render() {
//     return (
//       <React.Fragment>
//         <div id="sidebar-menu">
//           <ul className="metismenu list-unstyled" id="side-menu">
//             <li className="menu-title">{this.props.t("Menu")}</li>
//             <li>
//               <Link to="/dashboard" className=" waves-effect">
//                 <i className="ri-dashboard-line"></i>
//                 <span className="ml-1">{this.props.t("Dashboard")}</span>
//               </Link>
//             </li>
//             <li>
//               <Link to="/user_roles" className=" waves-effect">
//                 <i className="  dripicons-user"></i>
//                 <span className="ml-1">{this.props.t("User Roles")}</span>
//               </Link>
//             </li>
//             <li>
//               <Link to="/user_profile" className=" waves-effect">
//                 <i className=" ri-group-fill"></i>
//                 <span className="ml-1">{this.props.t("User Profile")}</span>
//               </Link>
//             </li>

//             <li>
//               <Link to="/teams" className=" waves-effect">
//                 <i className=" ri-group-fill"></i>
//                 <span className="ml-1">{this.props.t("Teams")}</span>
//               </Link>
//             </li>

//             <li>
//               <Link to="/#" className="waves-effect has-arrow">
//                 <i className="mdi mdi-clipboard-check-outline"></i>

//                 <span className="ml-1">{this.props.t("Inventory")}</span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link to="/inventories" className="waves-effect">
//                     <span className="ml-1">
//                       {this.props.t("All Inventories")}
//                     </span>
//                   </Link>
//                 </li>

//                 <li>
//                   <Link to="/categories" className="waves-effect">
//                     <span className="ml-1">{this.props.t("Categories")}</span>
//                   </Link>
//                 </li>

//                 <li>
//                   <Link to="/resourcetypes" className="waves-effect">
//                     <span className="ml-1">
//                       {this.props.t("Resource Type")}
//                     </span>
//                   </Link>
//                 </li>
//                 {/* <li>
//                   <Link to="/floorplan" className="waves-effect">
//                     <span className="ml-1">{this.props.t("Floor Plan")}</span>
//                   </Link>
//                 </li> */}
//               </ul>
//             </li>

//             <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="mdi mdi-city"></i>

//                 <span className="ml-1">{this.props.t("Location ")}</span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link to="/locations" className="waves-effect">
//                     <span className="ml-1">{this.props.t("Location ")}</span>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="/location_brands" className="waves-effect">
//                     <span className="ml-1">
//                       {this.props.t("Location Brands")}
//                     </span>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="/floorplans" className="waves-effect">
//                     <span className="ml-1">{this.props.t("Floor Plan")}</span>
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="/operational_hours" className="waves-effect">
//                     <span className="ml-1">
//                       {this.props.t("Oprational Hours")}
//                     </span>
//                   </Link>
//                 </li>
//               </ul>
//             </li>

//             <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 {/* <i className="mdi mdi-city"></i> */}
//                 <i className="mdi mdi-currency-rial"></i>
//                 {/* mdiCurrencyRial */}

//                 <span className="ml-1">{this.props.t("Pricing ")}</span>
//               </Link>
//               {/* Sub-Menu-Start */}
//               <ul className="sub-menu">
//                 <li>
//                   <Link to="/discount" className="waves-effect">
//                     <span className="ml-1">{this.props.t("Discount")}</span>
//                   </Link>
//                 </li>
//                 {/* <li>
//                   <Link to="/packages" className="waves-effect">
//                     <span className="ml-1">{this.props.t("Packages")}</span>
//                   </Link>
//                 </li> */}

//                 <li>
//                   <Link to="/packages" className="waves-effect">
//                     <span className="ml-1">{this.props.t("Packages")}</span>
//                   </Link>
//                 </li>

//                 <li>
//                   <Link to="/instant_checkin" className="waves-effect">
//                     <span className="ml-1">
//                       {this.props.t("Instant CheckIn ")}
//                     </span>
//                   </Link>
//                 </li>
//               </ul>
//             </li>
//             {/* <li>
//               <Link to="/addapackage" className="waves-effect">
//                 <i className="ri-dashboard-line"></i>
//                 <span className="badge badge-pill badge-success float-right">
//                   3
//                 </span>
//                 <span className="ml-1">{this.props.t("AddAPackage")}</span>
//               </Link>
//             </li> */}

//             <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="fas fa-file-alt"></i>
//                 <span className="ml-1">{this.props.t("Reports ")}</span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link to="/reports" className=" waves-effect">
//                     {/* <i className="fas fa-file-alt"></i> */}
//                     <span className="ml-1">
//                       {this.props.t("Create a Report")}
//                     </span>
//                   </Link>
//                 </li>

//                 <li>
//                   <Link to="/report_request" className=" waves-effect">
//                     {/* <i className="fas fa-file-alt"></i> */}
//                     <span className="ml-1">{this.props.t("All Reports")}</span>
//                   </Link>
//                 </li>
//               </ul>
//             </li>

//             <li>
//               <Link to="/finance" className=" waves-effect">
//                 <i className="dripicons-wallet"></i>
//                 <span className="ml-1">{this.props.t("Finance")}</span>
//               </Link>
//             </li>
//             {/* <li>
//             <li>
//                   <Link to="/fullcalender" className="waves-effect">
//                     <span className="ml-1">{this.props.t("Calender")}</span>
//                   </Link>
//                 </li>
//             </li> */}

//             {/* <li className="menu-title">{this.props.t("Other Pages")}</li> */}

//             {/* <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="ri-account-circle-line"></i>
//                 <span className="ms-1">{this.props.t("Authentication")}</span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link to="/login">{this.props.t("Login")}</Link>
//                 </li>
//                 <li>
//                   <Link to="/register">{this.props.t("Register")}</Link>
//                 </li>
//                 <li>
//                   <Link to="/forgot-password">
//                     {this.props.t("Recover Password")}
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="/lock-screen">{this.props.t("Lock Screen")}</Link>
//                 </li>
//               </ul>
//             </li> */}

//             {/* <li>
//               <Link to="/#" className="has-arrow waves-effect">
//                 <i className="ri-profile-line"></i>
//                 <span className="ms-1">{this.props.t("Utility")}</span>
//               </Link>
//               <ul className="sub-menu">
//                 <li>
//                   <Link to="/starter">{this.props.t("Starter Page")}</Link>
//                 </li>
//                 <li>
//                   <Link to="/maintenance">{this.props.t("Maintenance")}</Link>
//                 </li>
//                 <li>
//                   <Link to="/comingsoon">{this.props.t("Coming Soon")}</Link>
//                 </li>
//                 <li>
//                   <Link to="/timeline">{this.props.t("Timeline")}</Link>
//                 </li>
//                 <li>
//                   <Link to="/faqs">{this.props.t("FAQs")}</Link>
//                 </li>
//                 <li>
//                   <Link to="/pricing">{this.props.t("Pricing")}</Link>
//                 </li>
//                 <li>
//                   <Link to="/404">{this.props.t("Error 404")}</Link>
//                 </li>
//                 <li>
//                   <Link to="/500">{this.props.t("Error 500")}</Link>
//                 </li>
//               </ul>
//             </li> */}
//             {/* <li className="menu-title">{this.props.t('')}</li> */}
//             <br />

//             <br />
//             <br />
//             <br />
//             <br />
//             <br />
//             <br />
//             <br />

//             <li>
//               <Link to="/data_logs" className=" waves-effect">
//                 <i className="mdi mdi-view-list"></i>
//                 <span className="ml-1">{this.props.t("Logs")}</span>
//               </Link>
//             </li>
//           </ul>
//         </div>
//       </React.Fragment>
//     );
//   }
// }

// const mapStatetoProps = (state) => {
//   return { ...state.Layout };
// };

// export default withRouter(
//   connect(mapStatetoProps, {
//     changeLayout,
//     changeSidebarTheme,
//     changeSidebarType,
//     changeLayoutWidth,
//     changePreloader,
//   })(withNamespaces()(SidebarContent))
// );
//----------------------- Functonal Component-----------------------------
import React, { useEffect, useState } from "react";
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from "../../store/actions";
import UserPermissionFetcher from "../../helpers/getUserPerimissions";
import Loader from "react-spinners/BounceLoader";

const SidebarContent = (props) => {
  // const [userPermissions, setUserPromissions] = useState(null);
  const userPermissions = UserPermissionFetcher();
  const assignedRole = localStorage.getItem("assignedRole");

  useEffect(() => {
    // setUserPromissions(userPermissions);
    // console.log(userPermissions, "userPermissions>>>From Side Bar>>");
    if (userPermissions) {
      initMenu();

      const matchingMenuItem = findMatchingMenuItem();
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    }
  }, [props.type, props.location.pathname, userPermissions]);
  // console.log(userPermissions, "userPermissions From Side BAr");

  // if (!userPermissions) {
  //   <Loader loaded={false} className="spinner" />;
  // }
  const initMenu = () => {
    new MetisMenu("#side-menu");
  };

  const findMatchingMenuItem = () => {
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        return items[i];
      }
    }
    return null;
  };

  const activateParentDropdown = (item) => {
    item.classList.add("active");
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show");

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add("mm-active");
          parent3.childNodes[0].classList.add("mm-active");
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add("mm-active");
          }
        }
      }
    }
  };

  return (
    <React.Fragment>
      {userPermissions ? (
        <div
          id="sidebar-menu"
          style={{ maxHeight: "calc(100vh - 60px)", overflowY: "auto" }}
        >
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Menu")}</li>
              <li>
                <Link to="/dashboard" className=" waves-effect">
                  <i className="ri-dashboard-line"></i>
                  <span className="ml-1">{props.t("Dashboard")}</span>
                </Link>
              </li>
              {/* User Role Permissions */}
              {assignedRole === "Admin" ? (
                <li>
                  <Link to="/user_roles" className=" waves-effect">
                    <i className="  dripicons-user"></i>
                    <span className="ml-1">{props.t("User Roles")}</span>
                  </Link>
                </li>
              ) : (
                ""
              )}

              {userPermissions[10].actions.read && (
                <li>
                  <Link to="/user_profile" className=" waves-effect">
                    <i className=" ri-group-fill"></i>
                    <span className="ml-1">{props.t("User Profile")}</span>
                  </Link>
                </li>
              )}
              {/* Team Read Actions  */}
              {userPermissions[0].actions.read && (
                <li>
                  <Link to="/teams" className=" waves-effect">
                    <i className=" ri-group-fill"></i>
                    <span className="ml-1">{props.t("Teams")}</span>
                  </Link>
                </li>
              )}
              {userPermissions[4].actions.read ||
              userPermissions[6].actions.read ||
              userPermissions[3].actions.read ? (
                <>
                  <li>
                    <Link to="/#" className="waves-effect has-arrow">
                      <i className="mdi mdi-clipboard-check-outline"></i>
                      <span className="ml-1">{props.t("Inventory")}</span>
                    </Link>
                    <ul className="sub-menu">
                      {/* Resource */}
                      {userPermissions[4].actions.read && (
                        <li>
                          <Link to="/inventories" className="waves-effect">
                            <span className="ml-1">
                              {props.t("All Inventories")}
                            </span>
                          </Link>
                        </li>
                      )}
                      {/* Resource Type Category */}
                      {userPermissions[6].actions.read && (
                        <li>
                          <Link to="/categories" className="waves-effect">
                            <span className="ml-1">
                              {props.t("Categories")}
                            </span>
                          </Link>
                        </li>
                      )}
                      {userPermissions[3].actions.read && (
                        <li>
                          <Link to="/resourcetypes" className="waves-effect">
                            <span className="ml-1">
                              {props.t("Resource Type")}
                            </span>
                          </Link>
                        </li>
                      )}
                      {/* <li>
                  <Link to="/floorplan" className="waves-effect">
                    <span className="ml-1">{props.t("Floor Plan")}</span>
                  </Link>
                </li> */}
                    </ul>
                  </li>
                </>
              ) : (
                ""
              )}
              {userPermissions[5].actions.read ||
              userPermissions[7].actions.read ||
              userPermissions[12].actions.read ? (
                <>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="mdi mdi-city"></i>
                      <span className="ml-1">{props.t("Location ")}</span>
                    </Link>
                    <ul className="sub-menu">
                      {/* Location  */}
                      {userPermissions[5].actions.read && (
                        <li>
                          <Link to="/locations" className="waves-effect">
                            <span className="ml-1">{props.t("Location ")}</span>
                          </Link>
                        </li>
                      )}
                      {userPermissions[7].actions.read && (
                        <li>
                          <Link to="/location_brands" className="waves-effect">
                            <span className="ml-1">
                              {props.t("Location Brands")}
                            </span>
                          </Link>
                        </li>
                      )}
                      {userPermissions[12].actions.read && (
                        <li>
                          <Link to="/floorplans" className="waves-effect">
                            <span className="ml-1">
                              {props.t("Floor Plan")}
                            </span>
                          </Link>
                        </li>
                      )}
                      {userPermissions[13].actions.read && (
                        <li>
                          <Link
                            to="/operational_hours"
                            className="waves-effect"
                          >
                            <span className="ml-1">
                              {props.t("Oprational Hours")}
                            </span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                </>
              ) : (
                ""
              )}
              {userPermissions[11].actions.read 
               ? (
                <>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="mdi mdi-currency-rial"></i>
                      <span>
                        {/* <img
                          src={
                            "../../components/VerticalLayout/pics/price-tag-9701.svg"
                          }
                          alt="Icon"
                        ></img> */}
                      </span>
                      <span className="ml-1">{props.t("Discounts ")}</span>
                    </Link>
                    <ul className="sub-menu">
                      {userPermissions[11].actions.read && (
                        <li>
                          <Link to="/discount" className="waves-effect">
                            <span className="ml-1">{props.t("Discount")}</span>
                          </Link>
                        </li>
                      )}
                      {userPermissions[11].actions.read && (
                        <li>
                          <Link to="/plan-discounts" className="waves-effect">
                            <span className="ml-1">
                              {props.t("Plan Discount")}
                            </span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                </>
              ) : (
                ""
              )}
              {userPermissions[11].actions.read ||
              userPermissions[1].actions.read ||
              userPermissions[8].actions.read ? (
                <>
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <i className="mdi mdi-currency-rial"></i>
                      <span className="ml-1">{props.t("Pricing ")}</span>
                    </Link>
                    <ul className="sub-menu">
                      {/* {userPermissions[11].actions.read && (
                      <li>
                        <Link to="/discount" className="waves-effect">
                          <span className="ml-1">{props.t("Discount")}</span>
                        </Link>
                      </li>
                    )} */}
                      {userPermissions[1].actions.read && (
                        <li>
                          <Link to="/packages" className="waves-effect">
                            <span className="ml-1">{props.t("Packages")}</span>
                          </Link>
                        </li>
                      )}
                      {/* This resource Type not handled from backend */}
                      {userPermissions[8].actions.read && (
                        <li>
                          <Link to="/instant_checkin" className="waves-effect">
                            <span className="ml-1">
                              {props.t("Instant CheckIn ")}
                            </span>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </li>
                </>
              ) : (
                ""
              )}

              {/* Reports Section */}
              {userPermissions[14].actions.read && (
                <li>
                  <Link to="/#" className="has-arrow waves-effect">
                    <i className="fas fa-file-alt"></i>
                    <span className="ml-1">{props.t("Reports ")}</span>
                  </Link>
                  <ul className="sub-menu">
                    {userPermissions[14].actions.create && (
                      <li>
                        <Link to="/reports" className=" waves-effect">
                          {/* <i className="fas fa-file-alt"></i> */}
                          <span className="ml-1">
                            {props.t("Create a Report")}
                          </span>
                        </Link>
                      </li>
                    )}
                    {userPermissions[14].actions.read && (
                      <li>
                        <Link to="/report_request" className=" waves-effect">
                          {/* <i className="fas fa-file-alt"></i> */}
                          <span className="ml-1">{props.t("All Reports")}</span>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              )}
              {/* Finance Section */}
              {userPermissions[15].actions.read && (
                <li>
                  <Link to="/finance" className=" waves-effect">
                    <i className="dripicons-wallet"></i>
                    <span className="ml-1">{props.t("Finance")}</span>
                  </Link>
                </li>
              )}
              {/* <br /> */}

              
              {/* <li>
                <Link to="/data_logs" className=" waves-effect">
                  <i className="mdi mdi-view-list"></i>
                  <span className="ml-1">{props.t("Logss")}</span>
                </Link>
              </li> */}


<li>
                  <Link to="/#" className="has-arrow waves-effect">
                  <i className="mdi mdi-view-list"></i>
                    <span className="ml-1">{props.t("Logs")}</span>
                  </Link>
                  <ul className="sub-menu">
                    
                      <li>
                        <Link to="/data_logs" className=" waves-effect">
                          
                          <span className="ml-1">
                            {props.t("HyperPay Logs")}
                          </span>
                        </Link>
                      </li>
                   
                    
                      <li>
                        <Link to="/audit-trail-logs" className=" waves-effect">
                          
                          <span className="ml-1">{props.t("Audit Trail Logs")}</span>
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/audit-trail-logs/operational-hours" className=" waves-effect">
                          
                          <span className="ml-1">{props.t("Operational Hours Logs")}</span>
                        </Link>
                      </li> */}
                      {/* <li>
                        <Link to="/audit-trail-logs/locations" className=" waves-effect">
                          
                          <span className="ml-1">{props.t("Locations Logs")}</span>
                        </Link>
                      </li> */}
                   
                  </ul>
                </li>

            </ul>
          </div>
        </div>
      ) : (
        <Loader loaded={false} className="spinner" />
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader,
  })(withNamespaces()(SidebarContent))
);
