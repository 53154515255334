import React, { useState, useEffect } from "react";
import Container from "reactstrap/lib/Container";
import Chart from "./Chart";
import moment from "moment";
import { chart_Data_Urls } from "../../config/chartsData";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Nav,
  NavItem,
  TabContent,
  NavLink,
  TabPane,
  ButtonToolbar,
  ButtonGroup,
  Button,
  FormGroup,
  Input,
} from "reactstrap";
import classnames from "classnames";
import "./css/mydashboard.css";
import { Link } from "react-router-dom";
import UseDashboard from "./useDashboard";
import UserPermissionFetcher from "../../helpers/getUserPerimissions";
import NoAccess from "../../components/Common/NoAccess";
// const ChartsView = React.lazy(() =>import('./chartComponent'));
// import ChartComponent from "./chartComponent";

// Destructuring methods from useDashboardFile🛠️
const MyDashboard = () => {
  const userPermissions = UserPermissionFetcher("bookings");
  const userAllPermissions = UserPermissionFetcher();
  // console.log(userAllPermissions, "Dashboeard All permissions");

  const {
    t_ID,

    setMaxDataAge,
    loaded,
    activeTabJustify,
    toggleCustomJustified,
    Loader,
    financeCardddata,
    generalCardddata,
    locationCardddata,
    brandCardddata,
    usersCardddata,
    packageCardddata,
    teamsCardddata,
    activeButton,
    chartsView,
    setMaxDataAgeForButton,
    filterActiveBtnStyle,
    filterInActiveBtnStyle,
    activeBtnHeading,
    CustomStartDate,
    CustomEndDate,
    customFilterHandler,
    startDate,
    endDate
  } = UseDashboard();

  return (
    <>
      {loaded ? (
        <Loader loaded={false} className="spinner" />
      ) : userPermissions === null ? (
        <Loader loaded={false} className="spinner" />
      ) : userPermissions ? (
        <div className="page-content">
          <Card
            className=" generalcardborders"
            style={{ backgroundColor: "#FEFEFE " }}
          >
            {/*------------------------------------ Bookings-Card-Start--------------------------------- */}
            <Container fluid>
              <Row className="mt-4">
                {userPermissions.read && (
                  <Col md={4} lg={4} sm={12}>
                    <Link to={`/request_bookings/${t_ID}`} className="link">
                      <Card className="cardcss">
                        <CardBody>
                          <Row>
                            <Col md={10} lg={10} sm={10}>
                              <CardTitle className="headings">
                                Bookings
                              </CardTitle>
                            </Col>
                            <Col md={2} lg={2} sm={2}>
                              <i className="fas fa-bell icons"></i>
                            </Col>
                          </Row>
                          <Row className="mt-3">
                            <Col md={10} lg={10} sm={10}></Col>
                            <Col md={2} lg={2} sm={2}>
                              <i className="fas fa-arrow-right"></i>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>
                )}
                {/*-------------------------------Bookings-Card-End--------------------------------- */}
                {/*-------------------------------Check-In-Card-Start--------------------------------- */}

                <Col md={4} lg={4} sm={12}>
                  <Link to="/checkedin_customer" className="link">
                    <Card className="cardcss">
                      <CardBody>
                        <Row>
                          <Col md={10} lg={10} sm={10}>
                            <CardTitle className="headings">
                              Checked-in customers
                            </CardTitle>
                          </Col>
                          <Col md={2} lg={2} sm={2}>
                            <i className="fas fa-users icons"></i>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col md={10} lg={10} sm={10}>
                            <CardText className="amount"></CardText>
                          </Col>
                          <Col md={2} lg={2} sm={2}>
                            <i className="fas fa-arrow-right "></i>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Link>
                </Col>
                <Col md={4} lg={4} sm={12}></Col>
              </Row>
              {/*-------------------------------Check-In-Card-End--------------------------------- */}
              {/*----------------------------- Tab-menu-Start------------------------------------------ */}
              <div>
                <div>
                  <Row className="mt-5 mb-2">
                    <Col md={10}>
                      {/* <Card className="bg-transparent generalcardborders"> */}
                      <Nav tabs className="nav-tabs-custom nav-justified">
                        <NavItem>
                          <NavLink
                            style={{
                              cursor: "pointer",
                              backgroundColor: "transparent",
                            }}
                            className={classnames({
                              active: activeTabJustify === "1",
                            })}
                            onClick={() => {
                              toggleCustomJustified("");
                              toggleCustomJustified("1");
                            }}
                          >
                            <span className="d-none d-sm-block dashboardtab1 ">
                              General
                            </span>
                          </NavLink>
                        </NavItem>
                        {userAllPermissions[1].actions.read && (
                          <NavItem>
                            <NavLink
                              style={{
                                cursor: "pointer",
                                backgroundColor: "transparent",
                              }}
                              className={
                                ("navbuttonborder",
                                classnames({
                                  active: activeTabJustify === "6",
                                }))
                              }
                              onClick={() => {
                                toggleCustomJustified("");
                                toggleCustomJustified("6");
                              }}
                            >
                              <span className="d-none d-sm-block dashboardtab1 ">
                                Packages
                              </span>
                            </NavLink>
                          </NavItem>
                        )}
                        {userAllPermissions[15].actions.read && (
                          <NavItem>
                            <NavLink
                              style={{
                                cursor: "pointer",
                                backgroundColor: "transparent",
                              }}
                              className={
                                ("navbuttonborder",
                                classnames({
                                  active: activeTabJustify === "2",
                                }))
                              }
                              onClick={() => {
                                toggleCustomJustified("");
                                toggleCustomJustified("2");
                              }}
                            >
                              <span className="d-none d-sm-block dashboardtab1 ">
                                Finance
                              </span>
                            </NavLink>
                          </NavItem>
                        )}
                        {userAllPermissions[5].actions.read && (
                          <NavItem>
                            <NavLink
                              style={{
                                cursor: "pointer",
                                backgroundColor: "transparent",
                              }}
                              className={classnames({
                                active: activeTabJustify === "3",
                              })}
                              onClick={() => {
                                toggleCustomJustified("");
                                toggleCustomJustified("3");
                              }}
                            >
                              <span className="d-none d-sm-block dashboardtab1">
                                Locations
                              </span>
                            </NavLink>
                          </NavItem>
                        )}
                        {userAllPermissions[7].actions.read && (
                          <NavItem>
                            <NavLink
                              style={{
                                cursor: "pointer",
                                backgroundColor: "transparent",
                              }}
                              className={classnames({
                                active: activeTabJustify === "4",
                              })}
                              onClick={() => {
                                toggleCustomJustified("");
                                toggleCustomJustified("4");
                              }}
                            >
                              <span className="d-none d-sm-block dashboardtab1">
                                Brands
                              </span>
                            </NavLink>
                          </NavItem>
                        )}
                        {userAllPermissions[10].actions.read && (
                          <NavItem>
                            <NavLink
                              style={{
                                cursor: "pointer",
                                backgroundColor: "transparent",
                              }}
                              className={classnames({
                                active: activeTabJustify === "5",
                              })}
                              onClick={() => {
                                toggleCustomJustified("");
                                toggleCustomJustified("5");
                              }}
                            >
                              <span className="d-none d-sm-block dashboardtab1">
                                Users
                              </span>
                            </NavLink>
                          </NavItem>
                        )}
                        {userAllPermissions[0].actions.read && (
                          <NavItem>
                            <NavLink
                              style={{
                                cursor: "pointer",
                                backgroundColor: "transparent",
                              }}
                              className={classnames({
                                active: activeTabJustify === "7",
                              })}
                              onClick={() => {
                                toggleCustomJustified("");
                                toggleCustomJustified("7");
                              }}
                            >
                              <span className="d-none d-sm-block dashboardtab1">
                                Teams
                              </span>
                            </NavLink>
                          </NavItem>
                        )}
                        <hr />
                        <hr />
                      </Nav>
                      {/* </Card> */}

                      <TabContent activeTab={activeTabJustify}>
                        <TabPane tabId="1" className="p-3"></TabPane>
                        <TabPane tabId="2" className="p-3"></TabPane>
                        <TabPane tabId="3" className="p-3"></TabPane>
                        <TabPane tabId="4" className="p-3"></TabPane>
                        <TabPane tabId="5" className="p-3"></TabPane>
                        <TabPane tabId="6" className="p-3"></TabPane>
                        <TabPane tabId="7" className="p-3"></TabPane>
                      </TabContent>
                    </Col>
                    <Col md={4}></Col>
                  </Row>
                </div>
                {activeTabJustify === "1" ? (
                  <Row>
                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Bookings Today
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {generalCardddata
                                  ? generalCardddata.todayBookings
                                  : "0"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Total Sales
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {generalCardddata
                                  ? generalCardddata.totalSales
                                  : "0"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Check-ins Today
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {" "}
                                {generalCardddata
                                  ? generalCardddata.todayCheckIns
                                  : "0"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : activeTabJustify === "2" ? (
                  <Row>
                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Total Sales
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {financeCardddata
                                  ? financeCardddata.totalSales
                                  : "0"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Best Selling Products
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {financeCardddata
                                  ? financeCardddata.bestSellingProduct
                                  : "0"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Top Brand By Revenue
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {financeCardddata
                                  ? financeCardddata?.topBrand?.name
                                      ?.charAt(0)
                                      .toUpperCase() +
                                    financeCardddata?.topBrand?.name?.slice(1)
                                  : "0"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : activeTabJustify === "3" ? (
                  <Row>
                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Top Location
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {locationCardddata
                                  ? locationCardddata.topLocationByBookings.name
                                  : "0"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Top Resource Type
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {locationCardddata
                                  ? locationCardddata.topResourceTtype
                                  : "0"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : activeTabJustify === "4" ? (
                  <Row>
                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Top Brand By Bookings
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {brandCardddata
                                  ? brandCardddata?.topBrandsByBookings?.name
                                      ?.charAt(0)
                                      .toUpperCase() +
                                    brandCardddata?.topBrandsByBookings?.name?.slice(
                                      1
                                    )
                                  : "0"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : activeTabJustify === "5" ? (
                  <Row>
                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Total Users
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {usersCardddata
                                  ? usersCardddata.totalUsers
                                  : "0"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Top User Type(Industry)
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {usersCardddata
                                  ? usersCardddata.topUsersByIndustry.name
                                  : "0"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Top User Type(Position)
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {usersCardddata
                                  ? usersCardddata.topBrandsByBookings.name
                                  : "0"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : activeTabJustify === "6" ? (
                  <Row>
                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Package Sales Today
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {packageCardddata
                                  ? packageCardddata?.todayPackgesSell
                                  : "0"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Total Packages Sales
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {packageCardddata
                                  ? packageCardddata?.totalSales
                                  : "0"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Best Selling Package
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {packageCardddata
                                  ? packageCardddata?.bestSellingPackage
                                  : "N/A"}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : activeTabJustify === "7" ? (
                  <Row>
                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                Total teams
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {teamsCardddata?.teams}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                All Team’s Bookings
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                {teamsCardddata?.teamsBookings}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>

                    <Col md={3} lg={3} sm={12}>
                      <Card className="cardcss dashboardsecondcard">
                        <CardBody>
                          <Row>
                            <Col md={12} lg={12} sm={12}>
                              <CardTitle className="headings1">
                                All Teams Revenue
                              </CardTitle>
                            </Col>
                          </Row>
                          <Row className="">
                            <Col md={12} lg={12} sm={12}>
                              <CardText className="amount1">
                                SAR {teamsCardddata?.teamsRevenue}
                              </CardText>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                ) : null}
              </div>
              {/*------------------------------------- Filter-Tabs-Start --------------------------------*/}
              <div>
                {/* <Row>
                  <Col md={3} ><span>From:</span></Col>
                  <Col md={3}><span>To:</span></Col>
                  <Col md={6}></Col>
                </Row> */}

{/* <div style={{display:"flex", }}><p style={{margin:"8px"}}>From:</p> <p style={{margin:"8px"}}>From:</p></div> */}
                <Row className="mt-4 ">
                  <Col md={3}><FormGroup>
                    <div style={{display:"flex", }}>
                      <p style={{margin:"8px"}}>From:</p>
                      <Input
                      type="date"
                      
                      value={
                        startDate
                      
                      }
                      style={{width:"14vw"}}
                      // defaultValue="2022-5-12"
                      // min={moment().subtract(90, "days").format("YYYY-MM-DD")}
                      max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                      id="example-date-input"
                      onChange={(e) => {
                        CustomStartDate(e.target.value)
                        
                      }}
                    />
                    </div> 
                  </FormGroup></Col>
                  <Col md={4}><FormGroup>
                  <div style={{display:"flex"}}>
                      <p style={{margin:"8px"}}>To:</p>
                    <Input
                      type="date"
                      value={
                        
                        endDate
                      }

                      style={{width:"14vw"}}
                      // defaultValue="2022-5-12"
                      // min={moment().subtract(90, "days").format("YYYY-MM-DD")}
                      max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                      id="example-date-input"
                      onChange={(e) => {
                        CustomEndDate(e.target.value)
                        
                      }}
                    />
                    <Button style={{ marginLeft: "5px",backgroundColor: " #03b2a5" }} disabled={!startDate || !endDate}  onClick={customFilterHandler}>
              
             Apply
            </Button>
                    </div> 
                  </FormGroup></Col>
                  <Col md={5}>
                    <ButtonToolbar>
                      <ButtonGroup>
                        <Button
                          // style={{ backgroundColor: "green", color: "white" }}
                          style={
                            activeButton === "today"
                              ? filterActiveBtnStyle
                              : filterInActiveBtnStyle
                          }
                          onClick={() => setMaxDataAgeForButton("today")}
                        >
                          <span
                            className={
                              activeButton === "today"
                                ? "activeBtnHeading"
                                : "dashboardfilter"
                            }
                          >
                            Today
                          </span>
                        </Button>

                        {/* <Button
                          // bsStyle="light"
                          style={
                            activeButton === "yesterday"
                              ? filterActiveBtnStyle
                              : filterInActiveBtnStyle
                          }
                          onClick={() => setMaxDataAgeForButton("yesterday")}
                        >
                          <span
                            className={
                              activeButton === "yesterday"
                                ? "activeBtnHeading"
                                : "dashboardfilter"
                            }
                          >
                            Yesterday
                          </span>
                        </Button> */}
                        <Button
                          bsStyle="light"
                          style={
                            activeButton === "lastWeek"
                              ? filterActiveBtnStyle
                              : filterInActiveBtnStyle
                          }
                          onClick={() => setMaxDataAgeForButton("lastWeek")}
                        >
                          <span
                            className={
                              activeButton === "lastWeek"
                                ? "activeBtnHeading"
                                : "dashboardfilter"
                            }
                          >
                            Last week
                          </span>
                        </Button>

                        <Button
                          bsStyle="light"
                          style={
                            activeButton === "Thismonth"
                              ? filterActiveBtnStyle
                              : filterInActiveBtnStyle
                          }
                          onClick={() => setMaxDataAgeForButton("Thismonth")}
                        >
                          <span
                            className={
                              activeButton === "Thismonth"
                                ? "activeBtnHeading"
                                : "dashboardfilter"
                            }
                          >
                            This month
                          </span>
                        </Button>
                        <Button
                          bsStyle="light"
                          style={
                            activeButton === "Lastmonth"
                              ? filterActiveBtnStyle
                              : filterInActiveBtnStyle
                          }
                          onClick={() => setMaxDataAgeForButton("Lastmonth")}
                        >
                          <span
                            className={
                              activeButton === "Lastmonth"
                                ? "activeBtnHeading"
                                : "dashboardfilter"
                            }
                          >
                            Last month
                          </span>
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </Col>
                </Row>
              </div>
              {/* <Suspense fallback={<div>Loading...</div>} > */}
              {chartsView()}
              {/* </Suspense> */}
              {/* <LoadableComponent /> */}
              {/* <ChartComponent /> */}
            </Container>
          </Card>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default MyDashboard;
