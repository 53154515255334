import React, { useEffect, useState } from "react";
import { getAllTeamApi } from "../../../APIS/allTeams";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { DIOM_BASED_URLS } from "../../../config/url";

const UseCreateTeam = () => {
  const [createLoading, setCreateLoading] = useState(false);
  const history = useHistory();
  const [teamExist, setTeamExist] = useState(false);
  const token = localStorage.getItem("Token");
  const [discountNumber, setDiscountNumber] = useState(null);
  const [modal_static, setModal_static] = useState(false);
  const [selectedDiscountRadio, setSelectedDiscountRadio] =
    useState("noDiscount");
  const [teamName, setTeamName] = useState("");
  const [teamAdminName, setTeamAdminName] = useState("");
  const [teamAdminEmail, setTeamAdminEmail] = useState("");
  const [teamArr, setTeamArr] = useState([]);
  const [modal_static1, setModal_static1] = useState(false);

  const removeMmeberFunc = (ele) => {
    const newTeamArr = [...teamArr];

    const index = newTeamArr.findIndex(
      (member) => member?.email === ele?.email
    );

    if (index !== -1) {
      newTeamArr.splice(index, 1);
    }

    setTeamArr(newTeamArr);
  };

  // unique email function

  function checkUniqueEmails(array) {
    const emailSet = new Set();

    for (let i = 0; i < array.length; i++) {
      const emailObj = array[i];
      const { email } = emailObj;

      if (emailSet.has(email)) {
        // Email is repeated
        return false;
      }

      emailSet.add(email);
    }

    // All emails are unique
    return true;
  }

  const addMemFunc = () => {
    if (!checkUniqueEmails([...teamArr, { email: teamAdminEmail }])) {
      return toast.error("This email is already exist", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setTeamArr([
        ...teamArr,
        {
          // name: teamAdminName,
          email: teamAdminEmail,
          role: "teamMember",
        },
      ]);

      setTeamAdminEmail("");
      setTeamAdminName("");
    }
  };

  const handleOptionChange = (event) => {
    setSelectedDiscountRadio(event.target.value);
  };

  const tog_static = () => {
    setModal_static(!modal_static);

    // removeBodyCss();
  };
  const tog_static1 = () => {
    setModal_static1(!modal_static1);

    // removeBodyCss();
  };

  function Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  function OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  const changeAdmin = (e, item, index) => {
    const newTeam = teamArr.map((team) => {
      return { ...team, role: "teamMember" };
    });

    const object = newTeam.find((e) => e.email === item?.email);
    const newArr = [...newTeam];
    const newObject = {
      ...object,
      role: e === true ? "teamAdmin" : "teamMember",
    };
    newArr[index] = newObject;
    setTeamArr(newArr);
  };

  const createTeamFunc = () => {
    var isAdmin = false;

    for (let i = 0; i < teamArr.length; i++) {
      if (teamArr[i].role === "teamAdmin") {
        isAdmin = true;
        break;
      }
    }

    if (teamName === "") {
      toast.error("Team Name is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (isAdmin === false) {
      return toast.error("please make Administrator of team", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (teamArr?.length === 0) {
      toast.error("Please add team", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (selectedDiscountRadio === "") {
      toast.error("Discount field is required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setCreateLoading(true);
      fetch(`${DIOM_BASED_URLS}/admin-teams`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          name: teamName,
          discountOnBookings:
            selectedDiscountRadio === "discount"
              ? Number(discountNumber)
              : null,
          teamMembers: teamArr,
        }),
      })
        .then((result3) => {
          setCreateLoading(false);
          if (result3.status === 200) {
            history.push("/teams");
            toast.success("Created Successfully");
          } else if (result3.status === 204) {
            history.push("/teams");
            toast.success("Created Successfully");
          } else {
            toast.error(" Something went wrong");
          }
          setModal_static1(false);
        })
        .catch((error) => {
          setCreateLoading(false);
          toast.error(" Something went wrong");
        });
    }
  };

  const handleSearch = () => {
    fetch(`${DIOM_BASED_URLS}/teams/run-commands`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        command: "CHECK_TEAM_EXISTANCE",
        key: "",
        value: [teamName],
      }),
    })
      .then((result3) => result3?.json())
      .then((result3) => {
        if (result3?.message === "No Team With this name exists") {
          setTeamExist(false);
        } else if (
          result3?.error?.message ===
          "Team name already exists. Please try another"
        ) {
          setTeamExist(true);
          toast.error(result3?.error?.message);
        }
        setModal_static1(false);
      })
      .catch((error) => toast.error(" Something went wrong"));
  };

  return {
    createLoading,
    teamExist,
    handleSearch,
    discountNumber,
    setDiscountNumber,
    createTeamFunc,
    tog_static1,
    modal_static1,
    setModal_static1,
    Offsymbol,
    OnSymbol,
    tog_static,
    modal_static,
    setModal_static,
    handleOptionChange,
    selectedDiscountRadio,
    setSelectedDiscountRadio,
    setTeamName,
    setTeamExist,
    teamName,
    teamAdminName,
    setTeamAdminName,
    teamAdminEmail,
    setTeamAdminEmail,
    teamArr,
    setTeamArr,
    removeMmeberFunc,
    addMemFunc,
    changeAdmin,
  };
};

export default UseCreateTeam;
