import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  CardBody,
  Input,
  Form,
  Card,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "../css/MyCategoriesDetailed.css";
import { UseCreateCategory } from "./useCreateCategory";
import { DIOM_BASED_URLS } from "../../../../config/url";
import UserPermissionFetcher from "../../../../helpers/getUserPerimissions";
import NoAccess from "../../../../components/Common/NoAccess";
const Loader = require("react-loader");

const CreateCategory = () => {
  const userPromissions = UserPermissionFetcher("resourceTypeCategories");

  const {
    cateLoading,
    passCheck,
    setPassCheck,
    setMembershipCheck,
    membershipCheck,
    selectedFilesUrl,
    setSelectedFilesUrl,
    selectedFilesLoading,
    setSelectedFilesLoading,
    selectedFiles,
    setSelectedFiles,
    error,
    modal_static,
    setModal_static,
    modal_static1,
    setModal_static1,
    categoryDetailname,
    updateNameAndDescription,
    categoryNamechangefunc,
    FetchbrandLocations,
    tog_static,
    tog_static1,
    categoryDetail,
    selectedMulti,
    handleAcceptedFiles,
  } = UseCreateCategory();

  if (error) {
    return (
      <>
        {toast.error(error)}
        <ToastContainer autoClose={8000} />
      </>
    );
  } else if (userPromissions === null) {
    return (
      <div className="page-content">
        <Loader loaded={false} className="spinner" />
      </div>
    );
  } else if (!userPromissions.create) {
    return <NoAccess />;
  } else {
    return (
      <div className="page-content">
        <Row className="mb-3">
          <Col md={4}>
            <Link to="/categories" className="link">
              <span className="fas fa-angle-left arrowheightwidth"></span>
            </Link>
            <span className="locationbrandtitle  ">
              {categoryDetailname ? categoryDetailname : "Add new Category"}
            </span>
          </Col>
          <Col md={5}></Col>
          {selectedMulti && categoryDetail.name && cateLoading === false ? (
            <Col md={3}>
              <Button
                color="success"
                className="waves-effect waves-light mr-1  w-100"
                block
                onClick={tog_static}
              >
                <span className="printbutton">Save</span>
              </Button>
            </Col>
          ) : (
            <Col md={3}>
              <Button
                color="success"
                className="waves-effect waves-light mr-1  w-100"
                block
                disabled
                onClick={tog_static}
              >
                <span className="printbutton">Save</span>
              </Button>
            </Col>
          )}
        </Row>

        <Row>
          <Col md={12}>
            <CardBody style={{ background: "white" }}>
              <Row style={{ marginLeft: "2px" }}>
                <Col md={12}>
                  <p className="LBDheadings mt-2">Enter category details</p>
                </Col>
              </Row>
              <hr />
              <Row style={{ marginLeft: "2px" }}>
                <Col md={5}>
                  <p className="LBDLabels">Category Name</p>
                  <Input
                    className="detailsinput"
                    placeholder="Enter Name"
                    type="text"
                    name="defaultconfig"
                    onChange={categoryNamechangefunc}
                    id="defaultconfig"
                    value={categoryDetail.name ? categoryDetail.name : ""}
                  />

                  <Row className="mt-4">
                    <Col md={6}>
                      <span className="d-flex">
                        <Input
                          className=""
                          type="checkbox"
                          checked={membershipCheck}
                          onChange={(e) => {
                            setMembershipCheck(!membershipCheck);
                          }}
                        />
                        <p className="LBDLabels mx-2">Membership</p>
                      </span>
                    </Col>
                    <Col md={6}>
                      <span className="d-flex">
                        <Input
                          className=""
                          type="checkbox"
                          checked={passCheck}
                          onChange={(e) => {
                            setPassCheck(!passCheck);
                          }}
                        />
                        <p className="LBDLabels mx-2">Pass</p>
                      </span>
                    </Col>
                  </Row>
                </Col>

                <Col md={1}></Col>
                {/* <Col md={6}>
                <p className="LBDLabels">Resource Type for this Category</p>
                <Select
                  value={selectedMulti}
                  isMulti={true}
                  onChange={handleMulti}
                  options={categoryDropDown}
                  classNamePrefix="select2-selection"
                />
              </Col> */}

                <Col md={6}>
                  {selectedFilesLoading && selectedFilesUrl === "" ? (
                    <Loader />
                  ) : (
                    <span>
                      <Row style={{ marginRight: "2px" }}>
                        <Col md={12}>
                          <Form className="dropzone">
                            <Dropzone
                              accept={".jpg,.png,.jpeg"}
                              onDrop={(acceptedFiles) => {
                                handleAcceptedFiles(acceptedFiles);
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div style={{ cursor: "pointer" }}>
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />

                                    <div className="mb-3">
                                      <i className="display-4 text-muted ri-upload-cloud-2-line" />
                                    </div>
                                    <h5>Drop files here or click to upload.</h5>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          </Form>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {selectedFiles.map((f, i) => {
                              return (
                                <Card
                                  className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                  key={i + "-file"}
                                >
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          alt={f.name}
                                          src={f.preview}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {f.name}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>{f.formattedSize}</strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                    </span>
                  )}
                </Col>
              </Row>
            </CardBody>
          </Col>
        </Row>

        <Modal isOpen={modal_static} toggle={tog_static} centered={true}>
          <Row>
            <Col md={4}></Col>
            <Col md={7}>
              <ModalHeader toggle={() => setModal_static(false)}>
                Resource Addition
              </ModalHeader>
            </Col>
            <Col md={1}></Col>
          </Row>

          <ModalBody>
            <Row>
              <Col md={2}></Col>
              <Col md={8}>
                <p>Are you sure you want to save your changes?</p>
              </Col>
              <Col md={2}></Col>
            </Row>
            <hr />
            <Row>
              <Col md={2}></Col>

              <Col md={4}>
                <Button
                  color="success"
                  outline
                  className="waves-effect waves-light w-100"
                  onClick={() => setModal_static(false)}
                >
                  No
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  color="success"
                  className="waves-effect waves-light  w-100"
                  onClick={updateNameAndDescription}
                >
                  Yes
                </Button>
              </Col>

              <Col md={2}></Col>
            </Row>
          </ModalBody>
        </Modal>
        <ToastContainer autoClose={8000} />

        <Modal isOpen={modal_static1} toggle={tog_static1} centered={true}>
          <Row>
            <Col md={4}></Col>
            <Col md={7}>
              <ModalHeader toggle={() => setModal_static1(false)}>
                Category
              </ModalHeader>
            </Col>
            <Col md={1}></Col>
          </Row>

          <ModalBody>
            <Row>
              <Col md={2}></Col>
              <Col md={8}>
                <p>
                  Are you sure you want to Move the Location from another Brand?
                </p>
              </Col>
              <Col md={2}></Col>
            </Row>
            <hr />
            <Row>
              <Col md={2}></Col>

              <Col md={4}>
                <Button
                  color="success"
                  outline
                  className="waves-effect waves-light w-100"
                  onClick={() => setModal_static1(false)}
                >
                  No
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  color="success"
                  className="waves-effect waves-light  w-100"
                  onClick={FetchbrandLocations}
                >
                  Yes
                </Button>
              </Col>

              <Col md={2}></Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
};

export default CreateCategory;
