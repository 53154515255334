
import moment from "moment";
import { DIOM_BASED_URLS } from "../config/url";

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns DataLogs
 */


 export const getDataLogs = async (token,currentPage,pageSize,history) => {
    // return await (
      
      const res= await fetch(
        `${DIOM_BASED_URLS}/hyper-pay-logs?size=${pageSize}&page=${currentPage}`,
        {
          method: "GET",
          redirect: "follow",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      if (!res.ok) {
        if(res.status === 401){
          history.push("/login");
          throw new Error(resJson.error.message);
       
      }
        const resJson = await res.json();
        throw new Error(resJson.error.message);
      }
      const data = await res.json();
      
      return {
        data
      };
    // ).json();
  };
 export const getAuditTrailsLogs = async (token,currentPage,pageSize ,selectedEntity ,selectedUser ,startDate , endDate ,selectedSort) => {
const baseUrl = `${DIOM_BASED_URLS}/audit-trails-logs`;

  // Create a filter object
  const filter = {
    where: {
      
    }
   
  }


  if(selectedSort){
filter.order=[`createdAt ${selectedSort}`]
  }
  // Add userId conditionally
   // Add filters conditionally
   if (selectedUser) {
    filter.where.userEmail = selectedUser;
  }

  if (selectedEntity) {
    filter.where.logType = selectedEntity;
  }

  if (startDate && endDate) {
    filter.where.createdAt = {
      between: [startDate, endDate]
    };
  }

  // Build the URL with query parameters
  const url = new URL(baseUrl);
  url.searchParams.append('size', pageSize);
  url.searchParams.append('page', currentPage);
  url.searchParams.append('filter', JSON.stringify(filter));
  
      const res= await fetch(
        url,
        {
          method: "GET",
          redirect: "follow",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      if (!res.ok) {
        if(res.status === 401){
          
          throw new Error(resJson.error.message);
       
      }
        const resJson = await res.json();
        throw new Error(resJson.error.message);
      }
      const data = await res.json();
      
      return {
        data
      };
    
  };
 export const getAuditTrailsLog = async (token , id) => {
    
      const res= await fetch(
        `${DIOM_BASED_URLS}/audit-trails-logs/${id}`,
        {
          method: "GET",
          redirect: "follow",
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      )
      if (!res.ok) {
        if(res.status === 401){
          
          throw new Error(resJson.error.message);
       
      }
        const resJson = await res.json();
        throw new Error(resJson.error.message);
      }
      const data = await res.json();
      
      return {
        data
      };
    // ).json();
  };


  // export const getDataLogsDetails = async (token,logsId) => {
  //   return await (
  //     await await fetch(
  //       `https://diom-prod-api.devbeans.io/api//hyper-pay-logs/${logsId}`,
  //       {
  //         method: "GET",
  //         redirect: "follow",
  //         headers: {
  //           Authorization: "Bearer " + token,
  //         },
  //       }
  //     )
  //   ).json();
  // };