import React, { useEffect, useState } from "react";
import UseCreateTeam from "./useCreateTeam";
import {
  Row,
  Col,
  Button,
  Card,
  Input,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputGroupAddon,
  Modal,
  Spinner as BtnLoader,
} from "reactstrap";

import Switch from "react-switch";
import { Link } from "react-router-dom";
import ".././css/styles.css";
import UserPermissionFetcher from "../../../helpers/getUserPerimissions";
import Loader from "react-spinners/BounceLoader";
import NoAccess from "../../../components/Common/NoAccess";
import Spinner from "../../../components/Common/Loader";

const CreateTeam = () => {
  const userPermissions = UserPermissionFetcher("teams");
 
  const [focused, setFocused] = useState(false);
  const [emailFormate, setEmailFormate] = useState(false);

  const handelFocus = (e) => {
    setFocused(true);
  };


  const {
    createLoading,
    teamExist,
    handleSearch,
    discountNumber,
    setDiscountNumber,
    createTeamFunc,
    setModal_static1,
    tog_static1,
    modal_static1,
    changeAdmin,
    Offsymbol,
    OnSymbol,
    tog_static,
    modal_static,
    setModal_static,
    selectedDiscountRadio,
    setTeamName,
    setTeamExist,
    teamName,
    setTeamAdminName,
    teamAdminEmail,
    setTeamAdminEmail,
    teamArr,
    setTeamArr,
    removeMmeberFunc,
    addMemFunc,
    handleOptionChange,
  } = UseCreateTeam();
  

  let regEmail =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  useEffect(() => {
    if (!regEmail.test(teamAdminEmail)) {
      setEmailFormate(true);
    } else {
      setEmailFormate(false);
    }
  }, [teamAdminEmail]);

  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.create ? (
        <div className="page-content">
          <Row className="mb-3">
            <Col md={4}>
              <Link to="/teams" className="link">
                <span className="fas fa-angle-left arrowheightwidth"></span>
              </Link>
              <span className="locationbrandtitle  ">Create Team</span>
            </Col>

            <Col md={2}></Col>
            <Col md={2}></Col>

            <Col md={2}>
              <Link to="teams">
                <Button
                  color="success"
                  outline
                  className="waves-effect waves-light mr-1  w-100"
                  block
                >
                  <span className="printbutton">Cancel</span>
                </Button>
              </Link>
            </Col>

            <Col md={2}>
              <Button
                disabled={teamExist || teamArr?.length < 1}
                color="success"
                className="waves-effect waves-light mr-1  w-100"
                block
                onClick={() => {
                  setModal_static1(true);
                }}
              >
                <span className="printbutton">Create</span>
              </Button>
            </Col>
            
          </Row>
          <Row>
            <Col md={12}>
              <Card>
                <div className="d-flex justify-content-center p-5">
                  <div className="ct_c1 p-3">
                    <p className="ct_c2 mt-3 mx-3">
                      {teamName
                        ? teamName?.substring(0, 2)?.toUpperCase()
                        : "PD"}
                    </p>
                  </div>
                </div>
                <Row className="mb-4">
                  <Col md={4}></Col>
                  <Col md={4}>
                    <div>
                      <Input
                        type="search"
                        className={
                          !teamExist ? `searchTeamcss` : `searchTeamcssfalse`
                        }
                        value={teamName}
                        onChange={(ele) => {
                          const inputValue = ele.target.value;
                          if (inputValue.length <= 29) {
                            // Check if input length is within limit.
                            setTeamName(inputValue);
                            setTeamExist(false);
                          }
                        }}
                        onBlur={() => {
                          handleSearch(); // Call the API when the input loses focus
                        }}
                      />
                      {!teamExist ? (
                        ""
                      ) : (
                        <p className="teamExistcss mt-1">
                          {" "}
                          This team is already exist
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col md={4}></Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {teamArr?.length === 0 ? (
            <Row>
              <Col md={12}>
                <Card className="p-4">
                  <Row>
                    <Col md={7}>
                      <p className="ct_c3">Add administrator</p>
                    </Col>

                    <Col md={3}></Col>

                    <Col md={2}>
                      <Button
                        color="success"
                        className="waves-effect waves-light mr-1  w-100"
                        block
                        disabled={emailFormate || emailFormate}
                        onClick={() => {
                          setTeamArr([
                            {
                              // name: teamAdminName,
                              email: teamAdminEmail,
                              role: "teamAdmin",
                            },
                          ]);
                          setTeamAdminEmail("");
                          setTeamAdminName("");
                        }}
                      >
                        <span className="printbutton">Add</span>
                      </Button>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    {/* <Col md={6}>
                  <p className='ct_c4'>Name </p>
                  <Input
                    type='text'
                    required
                    value={teamAdminName}
                    onChange={(e) => {
                      const cleanedValue = e.target.value.replace(
                        /[^a-zA-Z ]/g,
                        ""
                      );
                      setTeamAdminName(cleanedValue);
                    }}
                  />
                </Col> */}
                    <Col md={6}>
                      <p className="ct_c4">Email address </p>
                      <div className="formInput">
                        <Input
                          required
                          type="email"
                          className="inputs forminputsize"
                          value={teamAdminEmail}
                          onBlur={handelFocus}
                          focused={focused.toString()}
                          onChange={(e) => {
                            setTeamAdminEmail(e?.target?.value);
                          }}
                        />
                        <span className="inputerror">
                          Please Enter correct format
                        </span>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {teamArr?.length === 0 ? (
            ""
          ) : (
            <Row>
              <Col md={12}>
                <Card className="p-4">
                  <Row>
                    <Col md={7}>
                      <p className="ct_c3">Add members</p>
                    </Col>

                    <Col md={3}>
                      <Button
                        color="success"
                        outline
                        className="waves-effect waves-light mr-1  w-100"
                        block
                        onClick={() => {
                          setModal_static(true);
                        }}
                      >
                        <span className="printbutton">Change team lead</span>
                      </Button>
                    </Col>

                    <Col md={2}>
                      <Button
                        color="success"
                        className="waves-effect waves-light mr-1  w-100"
                        block
                        onClick={() => {
                          addMemFunc();
                        }}
                        disabled={emailFormate || emailFormate}
                      >
                        <span className="printbutton">Add</span>
                      </Button>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    {/* <Col md={6}>
                  <p className='ct_c4'>Name </p>
                  <Input
                    type='text'
                    required
                    value={teamAdminName}
                    onChange={(e) => {
                      const cleanedValue = e.target.value.replace(
                        /[^a-zA-Z ]/g,
                        ""
                      );
                      setTeamAdminName(cleanedValue);
                    }}
                  />
                </Col> */}
                    <Col md={6}>
                      <p className="ct_c4">Email address </p>
                      <div className="formInput">
                        <Input
                          type="email"
                          value={teamAdminEmail}
                          required
                          className={
                            teamAdminEmail === "" ? "" : `inputs forminputsize`
                          }
                          onBlur={handelFocus}
                          focused={focused.toString()}
                          onChange={(e) => {
                            setTeamAdminEmail(e?.target?.value);
                          }}
                          // onChange={(e) => {
                          //   if (!regEmail.test(e?.target?.value)) {
                          //     setEmailFormate(true);
                          //   } else {
                          //     setEmailFormate(false);
                          //     setTeamAdminEmail(e?.target?.value);
                          //   }
                          // }}
                        />
                        <span className="inputerror">
                          Please Enter correct format
                        </span>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3 mb-3">
                    {teamArr?.map((item) => {
                      return (
                        <Col className=" my-3" md={3}>
                          <Card className="   h-100 teamCardbg ">
                            <Row>
                              <Col md={12}>
                                <div className="d-flex justify-content-end">
                                  {" "}
                                  {item?.role === "teamAdmin" ? (
                                    <p className="teamLeadTag"> Team Lead </p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={12}>
                                <div className="">
                                  <div>
                                    <div className=" d-flex justify-content-center ">
                                      <p
                                        className={
                                          item.email
                                            .substring(0, 1)
                                            .toUpperCase() >= "A" &&
                                          item.email
                                            .substring(0, 1)
                                            .toUpperCase() <= "F"
                                            ? "teamNameC4"
                                            : item.email
                                                .substring(0, 1)
                                                .toUpperCase() >= "G" &&
                                              item.email
                                                .substring(0, 1)
                                                .toUpperCase() <= "P"
                                            ? "teamNameC3"
                                            : item.email
                                                .substring(0, 1)
                                                .toUpperCase() >= "Q" &&
                                              item.email
                                                .substring(0, 1)
                                                .toUpperCase() <= "V"
                                            ? "teamNameC2"
                                            : item.email
                                                .substring(0, 1)
                                                .toUpperCase() >= "W" &&
                                              item.email
                                                .substring(0, 1)
                                                .toUpperCase() <= "Z"
                                            ? "teamNameC5"
                                            : ""
                                        }
                                      >
                                        {item.email
                                          .substring(0, 2)
                                          .toUpperCase()}
                                      </p>
                                    </div>

                                    <div className=" d-flex justify-content-center">
                                      {/* <p className='teamNameC'>{item?.name}</p> */}
                                    </div>
                                  </div>

                                  <div className=" d-flex justify-content-center">
                                    <p className="teamEmailC">{item?.email}</p>
                                  </div>
                                  {item?.role === "teamAdmin" ? (
                                    ""
                                  ) : (
                                    <div className="p-3">
                                      <Button
                                        color="success"
                                        outline
                                        className="waves-effect waves-light mr-1  w-100"
                                        block
                                        onClick={() => removeMmeberFunc(item)}
                                      >
                                        <span className="printbutton">
                                          Remove
                                        </span>
                                      </Button>
                                    </div>
                                  )}
                                </div>
                              </Col>
                            </Row>

                            {/* </div> */}
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12}>
              <Card className="p-4">
                <Row>
                  <Col md={12}>
                    <p className="ct_c3">Discount settings </p>
                    <p className="ct_l1">
                      Select if members of this team are eligible to discounts
                      when making bookings, purchasing passes, plans or other
                      charges
                    </p>
                    <div className="ct_l2 p-3">
                      <p className="ct_l4">Discounts on booking</p>
                      <p className="ct_l3">
                        <Input
                          checked={selectedDiscountRadio === "noDiscount"}
                          onChange={handleOptionChange}
                          value="noDiscount"
                          type="radio"
                          className="ct_r1"
                        />
                        Members of this team do not get a discount in their
                        bookings
                      </p>
                      <p className="ct_l3 ">
                        <Input
                          checked={selectedDiscountRadio === "discount"}
                          onChange={handleOptionChange}
                          value="discount"
                          type="radio"
                          className="ct_r1"
                        />
                        Members of this team get a discount of their bookings.
                        {selectedDiscountRadio === "discount" ? (
                          <InputGroup className="ct_discountInput">
                            <Input
                              type="number"
                              value={discountNumber >= 0 ? discountNumber : ""}
                              max={100}
                              onChange={(e) => {
                                const value = parseInt(e.target.value);
                                if (!isNaN(value) && value >= 0) {
                                  const clampedValue = Math.min(value, 100);
                                  setDiscountNumber(clampedValue);
                                } else {
                                  setDiscountNumber(0);
                                }
                              }}
                            />
                            <InputGroupAddon addonType="append">
                              %
                            </InputGroupAddon>
                          </InputGroup>
                        ) : (
                          ""
                        )}
                      </p>
                    </div>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <div>
            <Modal isOpen={modal_static} toggle={tog_static} centered={true}>
              <Row>
                <Col md={4}></Col>
                <Col md={7}>
                  <ModalHeader toggle={() => setModal_static(false)}>
                    Change team admin
                  </ModalHeader>
                </Col>
                <Col md={1}></Col>
              </Row>

              <ModalBody>
                {teamArr?.map((item, index) => {
                  return (
                    <Row>
                      <Col md={6}>
                        <div className="d-flex">
                          <div className=" d-flex justify-content-center ">
                            <p
                              className={
                                item.email.substring(0, 1).toUpperCase() >=
                                  "A" &&
                                item.email.substring(0, 1).toUpperCase() <= "F"
                                  ? "teamNameC4"
                                  : item.email.substring(0, 1).toUpperCase() >=
                                      "G" &&
                                    item.email.substring(0, 1).toUpperCase() <=
                                      "P"
                                  ? "teamNameC3"
                                  : item.email.substring(0, 1).toUpperCase() >=
                                      "Q" &&
                                    item.email.substring(0, 1).toUpperCase() <=
                                      "V"
                                  ? "teamNameC2"
                                  : item.email.substring(0, 1).toUpperCase() >=
                                      "W" &&
                                    item.email.substring(0, 1).toUpperCase() <=
                                      "Z"
                                  ? "teamNameC5"
                                  : ""
                              }
                            >
                              {item.email.substring(0, 2).toUpperCase()}
                            </p>
                          </div>
                          <b className="ct_chng_lead_l1  ">{item?.email}</b>
                        </div>
                      </Col>
                      <Col md={4}>
                        {/* <div className='d-flex'> */}

                        {item?.role === "teamAdmin" ? (
                          <p className="ct_chng_lead_l2">
                            Administrator{" "}
                            <span style={{ color: "red" }}>*</span>
                          </p>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col md={2}>
                        <div className="d-flex justify-content-end">
                          <Switch
                            name="always"
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbol(<small>NO</small>)}
                            checkedIcon={OnSymbol(<small>YES</small>)}
                            onColor="#a2a2a2"
                            checked={item?.role === "teamAdmin" ? true : false}
                            onChange={(e) => changeAdmin(e, item, index)}
                            className="mr-1 mt-1 switchercss "
                          />
                        </div>
                        {/* </div> */}
                      </Col>
                    </Row>
                  );
                })}
              </ModalBody>
            </Modal>
          </div>

          <div>
            <Modal isOpen={modal_static1} toggle={tog_static1} centered={true}>
              <Row>
                <Col md={4}></Col>
                <Col md={7}>
                  <ModalHeader toggle={() => setModal_static1(false)}>
                    Create Team
                  </ModalHeader>
                </Col>
                <Col md={1}></Col>
              </Row>

              <ModalBody>
                <div className="d-flex justify-content-center">
                  <p className="createpopcss_l1">
                    Are you sure you want to create this team ?
                  </p>
                </div>
                <Row className="mt-2">
                  <Col md={2}></Col>
                  <Col md={4}>
                    <Link to="/teams">
                      {" "}
                      <Button
                        color="success"
                        outline
                        className="w-100"
                        onClick={() => {
                          setModal_static1(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Link>
                  </Col>
                  <Col md={4}>
                    <Button
                      color="success"
                      className="w-100"
                      onClick={() => createTeamFunc()}
                    >
                      {createLoading ? (
                        <>
                          <BtnLoader size="sm" className="me-2" />
                          <span>Loading...</span>
                        </>
                      ) : (
                        "Create"
                      )}
                    </Button>
                  </Col>
                  <Col md={2}></Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default CreateTeam;
