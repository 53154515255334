import React, { useState, useEffect } from "react";
// import "./css/MyLocation.css";
import {
  Row,
  Col,
  CardBody,
  Card,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalBody,
  ModalHeader,
  Modal,
  Dropdown,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import "../Tables/datatables.scss";

import { useUserProfile } from "./useUserProfile";
import "./css/userprofile.css";
import UserPromissionFetcher from "../../helpers/getUserPerimissions";
import NoAccess from "../../components/Common/NoAccess";
import Spinner from "../../components/Common/Loader";
import { useRoleUsers } from "../userRoles/roleUsers/useRoleUsers";
import { Select } from "@mui/material";

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: "id",
    order: "asc",
  },
];

const UserProfile = () => {
  const userPermissions = UserPromissionFetcher("users");
  const assignedRole = localStorage.getItem("assignedRole");

  const {
    currentPage,
    pageOptions,
    userProfileData,
    hasNextPage,
    hasPreviousPage,
    total,
    pagelengthnum,
    pageSize,
    toggle,
    isOpen,
    isLoading,
    userDelFunc,
    setSearchedData,
    showForGetModel,

    forGetConfirmationModel,

    userEmail,
    changeCurrentPage,
    updatedRoleUserData,
    setSearchUserTerm,
    isLoadingSearchUsers,
    userSearchedData,
    searchUserTerm,
    toggleDropdown,
    setUpdatedRoleUserData,
  } = useUserProfile();

  const {
    setShowForgetModel,
    setUserEmail,
    setshowModel,
    getUserData,
    passwordChangeHandler,
    showForgetModel,
    updateUserRole,
    activeSubmitBtn,
    setacticeSubmitBtn,
    setSelectedRole,
    dropDownData,
    role,
    showModel,
    selectedUserData,
  } = useRoleUsers();
  // initaill value of DropDown
  const initialValue = { label: role, value: role };

  // Table Column
  const columns = [
    {
      dataField: "id",
      text: "No.",
      sort: true,
    },
    {
      dataField: "_id",
      text: "User Id",
      width: 15,
      sort: true,
      formatter: (cell, row) => (
        <Link to={`/user_profile_detail/${row._id}`} className="link">
          {cell}
        </Link>
      ),
    },
    {
      dataField: "userName",
      text: "Name",
      sort: true,
      formatter: (cell, row) => (
        <Link to={`/user_profile_detail/${row._id}`} className="link">
          {cell}
        </Link>
      ),
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
    },
    {
      dataField: "MobilePhone",
      text: "Contact",
      sort: true,
    },
    {
      dataField: "checkInTime",
      text: "Recent Checkin",
      sort: true,
      formatter: (cell, row) => (
        <Link to={`/user_profile_detail/${row._id}`} className="link">
          <span className="ms-5 "> {cell}</span>
        </Link>
      ),
    },
    {
      text: <span> Actions</span>,
      sort: true,
      formatter: (cell, row) => (
        <div>
          <span
            className="ms-3 ri-settings-4-fill"
            style={{ cursor: "pointer", fontSize: "20px" }}
            onClick={() => {
              if (assignedRole === "Admin") {
                toggleDropdown(row._id);
              }
            }}
          ></span>
          <Dropdown
            isOpen={row.isSelected}
            toggle={() => {
              console.log();
            }}
          >
            <DropdownToggle tag="span" className="d-none"></DropdownToggle>
            <DropdownMenu className="dropdown-menu-end" right>
              <DropdownItem
                onClick={() => {
                  // 1) Take User Data
                  setUserEmail(row.email);
                  // 2) show Model
                  setShowForgetModel(true);
                }}
              >
                <span className="mdi mdi-key me-2"> </span> Reset Password
              </DropdownItem>
              {/* This is listing page of mobile user so we cannot update role */}
              {/* <DropdownItem
                onClick={() => {
                  setshowModel(true);
                  getUserData(row);
                }}
              >
                <span className="mdi mdi-account-edit me-2"> </span>
                Edit Role
              </DropdownItem> */}
              {/* Add more DropdownItems as needed */}
            </DropdownMenu>
          </Dropdown>
        </div>
      ),
    },
  ];

  return (
    <>
      {isLoading ? (
        <div className="page-content">
          <Spinner />
        </div>
      ) : userPermissions === null || userPermissions.read ? (
        <div className="page-content">
          <div>
            <Row>
              <Col md={2}>
                <h5 className="loctiontitle">User Profile</h5>
              </Col>
              <Col md={6}></Col>
              <Col md={2}>
                
              </Col>
              <Col md={2} className="">
                <Link to="/user_delete">
                  {userPermissions?.delete ? (
                    <Button
                      // disabled={!userPermissions.delete}
                      color="success"
                      className="w-100 "
                      onClick={userDelFunc}
                    >
                      Delete Requests
                    </Button>
                  ) : (
                    ""
                  )}
                </Link>
              </Col>
            </Row>
          </div>

          <div className="tablebgcolor mt-3">
            <Row>
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <Row>
                      <div className="search-box ">
                        <Col md={3}>
                          <Input
                            type="text"
                            className="search-input-team"
                            placeholder="Search User"
                            value={searchUserTerm}
                            onChange={(el) => {
                              // setCurrentPage(1);
                              setSearchUserTerm(el?.target?.value);
                            }}
                          />
                        </Col>
                      </div>
                    </Row>

                    {updatedRoleUserData.length === 0 ||
                    isLoadingSearchUsers ? (
                      <Spinner />
                    ) : (
                      <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField="id"
                        columns={columns}
                        data={userSearchedData ?? updatedRoleUserData}
                      >
                        {({ paginationProps, paginationTableProps }) => (
                          <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={userSearchedData ?? updatedRoleUserData}
                            search
                          >
                            {(toolkitProps) => (
                              <React.Fragment>
                                <Row className="mb-2">
                                  <Col md="4">
                                    <div className="search-box me-2 mb-2 d-inline-block">
                                      <div className="position-relative">
                                        {/* <SearchBar
                                        {...toolkitProps.searchProps}
                                      /> */}
                                        <i className="search-box chat-search-box" />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md="4"></Col>
                                  <Col md="4"></Col>
                                </Row>

                                <Row>
                                  <Col xl="12">
                                    <div className="table-responsive">
                                      <BootstrapTable
                                        hover
                                        keyField={"id"}
                                        responsive
                                        bordered={false}
                                        striped={false}
                                        defaultSorted={defaultSorted}
                                        classes={
                                          "table align-middle table-nowrap"
                                        }
                                        headerWrapperClasses={"thead-light"}
                                        {...toolkitProps.baseProps}
                                        {...paginationTableProps}
                                      />
                                    </div>
                                  </Col>
                                </Row>

                                <Row className="align-items-md-center mt-3">
                                  <Col className="inner-custom-pagination d-flex">
                                    <span className="paginationitmes ">
                                      Items
                                    </span>
                                    <div className="d-inline">
                                      {/* <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    /> */}
                                      <ButtonDropdown
                                        isOpen={isOpen}
                                        toggle={toggle}
                                        onClick={(e) => pagelengthnum(e)}
                                      >
                                        <DropdownToggle caret color="secondary">
                                          {pageSize}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem value="1">
                                            10
                                          </DropdownItem>
                                          <DropdownItem value="2">
                                            30
                                          </DropdownItem>
                                          <DropdownItem />
                                          <DropdownItem value="3">
                                            50
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </ButtonDropdown>
                                    </div>
                                    <span className="paginationitmes1 ">
                                      show
                                    </span>
                                    <div className="text-md-right ms-auto">
                                      {/* <PaginationListStandalone
                                      {...paginationProps}
                                    /> */}
                                      <Button
                                        color="secondary"
                                        className="waves-effect "
                                        style={{
                                          marginLeft: 7,
                                          marginRight: 7,
                                        }}
                                        disabled={currentPage <= 1}
                                        onClick={() =>
                                          currentPage === 1
                                            ? null
                                            : changeCurrentPage(
                                                (prev) => prev - 1
                                              )
                                        }
                                      >
                                        <i className="dripicons-chevron-left"></i>
                                      </Button>

                                      <Button
                                        style={{
                                          marginLeft: 7,
                                          marginRight: 7,
                                        }}
                                        color="success"
                                        className="btn-rounded waves-effect waves-light me-1 mr-2 ml-2"
                                      >
                                        {currentPage}
                                      </Button>
                                      <Button
                                        style={{
                                          marginLeft: 7,
                                          marginRight: 7,
                                        }}
                                        color="secondary"
                                        className="waves-effect"
                                        disabled={!hasNextPage}
                                        onClick={() =>
                                          changeCurrentPage((prev) => prev + 1)
                                        }
                                      >
                                        <i className="dripicons-chevron-right"></i>
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </React.Fragment>
                            )}
                          </ToolkitProvider>
                        )}
                      </PaginationProvider>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          {/* PasswordForGetModel */}
          <Modal isOpen={showForgetModel} centered={true}>
            <Row>
              <Col md={2}></Col>
              <Col md={8}>
                <ModalHeader>Forget Password Confirmation!</ModalHeader>
              </Col>
              <Col md={2}></Col>
            </Row>

            <ModalBody>
              <Row>
                <Col md={2}></Col>
                <Col md={9}>
                  <p>{`Are you sure want to change password for this user?`}</p>
                </Col>
                <Col md={1}></Col>
              </Row>
              <hr />
              <Row>
                <Col md={2}></Col>

                <Col md={4}>
                  <Button
                    color="success"
                    outline
                    className="waves-effect waves-light w-100"
                    onClick={() => setShowForgetModel(false)}
                  >
                    No
                  </Button>
                </Col>
                <Col md={4}>
                  <Button
                    color="success"
                    outline
                    className="waves-effect waves-light w-100"
                    onClick={() => {
                      // Hide Model
                      setShowForgetModel(false);
                      passwordChangeHandler();
                      // If we want to Hide to dropDown after ForgetPassword Done assign initially value
                      // setUpdatedRoleUserData(updatedRoleUserData);
                    }}
                  >
                    Yes
                  </Button>
                </Col>

                <Col md={2}></Col>
              </Row>
            </ModalBody>
          </Modal>
        </div>
      ) : (
        <div className="page-content">
          <NoAccess />
        </div>
      )}
    </>
  );
};

export default UserProfile;
