import { Row, Col, FormGroup } from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import UseRequestBookings from "../MyDashboard/RequestBookings/useRequestBookings";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./css/Calendar.css";
import Spinner from "../../components/Common/Loader";
const Loader = require("react-loader");
// import { useEffect } from "react";
function ReactFullCalendar() {
  // Getting fn from usefie
  const {
    bookingsToday,
    locationHandler,
    isLoading,
    selectInputRefResourceType,
    categoryDropDown,
    resourceTypeHandler,
    locationsData,
    bookingOptionData,
    bookingHandler,
  } = UseRequestBookings();
  console.log(bookingsToday, "Bookings from index file");
  // Modify the data accordings to react-calendar📆
  const myEventsList = bookingsToday.map((booking) => ({
    id: booking.id,
    title: booking.userName, // Use any relevant booking data as the title
    start: moment(booking.fromTime).toDate(), // Parse and convert start time to Date
    end: moment(booking.toTime).toDate(), // Parse and convert end time to Date
    // Add other properties as needed
  }));

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div
          style={{
            marginTop: "100px",
            marginLeft: "12px",
            marginRight: "12px",
          }}
        >
          <Row className="calendar-title-row">
            <Col md={5}>
              <Link to="/request_bookings" className="link">
                <span className="fas fa-angle-left arrowheightwidth "></span>
              </Link>
              <span className="locationbrandtitle ml-4 calendar-text-gray ">
                Calendar View
              </span>
            </Col>
            <Col md={7}></Col>
          </Row>
          {/* ---------------------------------------Row Drop Down ---------------------------------------*/}
          <Row style={{ marginBottom: "20px" }}>
            <Col md="4" className="dropdowncss">
              <div>
                <FormGroup className="select2-container">
                  <Select
                    onChange={(opt) => {
                      bookingHandler(opt);
                    }}
                    options={bookingOptionData}
                    placeholder="All Bookings"
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </div>
            </Col>

            <Col md="4" className="dropdowncss">
              <div>
                <FormGroup className="select2-container ">
                  <Select
                    onChange={(opt) => locationHandler(opt.value)}
                    // options={diomLocation}
                    options={locationsData}
                    placeholder="All Location"
                    classNamePrefix="select2-selection"
                  />
                </FormGroup>
              </div>
            </Col>
            <Col md="4 " className="dropdowncss">
              <Select
                ref={selectInputRefResourceType}
                placeholder="All Resources Types"
                isMulti={false}
                options={categoryDropDown}
                onChange={(opt) => resourceTypeHandler(opt)}
                isSearchable={true}
                hideSelectedOptions={true}
              />
            </Col>
          </Row>

          <MyCalendar myEventsList={myEventsList} />
        </div>
      )}
    </>
  );
}

const CustomEvent = ({ event }) => {
  const formattedStart = moment(event.start).format("DD MMM (HH:mm)");
  const formattedEnd = moment(event.end).format("DD MMM (HH:mm)");

  return (
    <div className={"event-title"}>
      {event.title}
      <div className="date-style ">
        {formattedStart} to {formattedEnd}
      </div>
    </div>
  );
};
const localizer = momentLocalizer(moment);
const formats = {
  eventTimeRangeFormat: () => {
    return "";
  },
};

const MyCalendar = (props) => (
  <div>
    <BigCalendar
      formats={formats}
      localizer={localizer}
      events={props.myEventsList}
      startAccessor="start"
      endAccessor="end"
      defaultView="month"
      views={["month", "week", "day", "agenda"]}
      style={{ height: 500 }}
      components={{
        event: CustomEvent, // Use your custom event component
      }}
    />
  </div>
);
export default ReactFullCalendar;
