
import {  Col, Row , Label } from 'reactstrap'
import Select from 'react-select';
import DatePicker from 'react-flatpickr';
const EventSpaceSelections = ({bookingDateHandler,startTimeHandler,endTimeHandler ,selectedRTC ,accessTypeOptions ,setAccessType ,bookingDate,bookingEndTime,bookingStartTime}) => {
  return (
   <Row>
      <Col md={3} > 
                    <Label
                        htmlFor="access_type_event_space"
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                       Access Type <span className="cp_starik">*</span>
                      </Label>  <Select placeholder="Select" id="access_type_event_space" options={selectedRTC?accessTypeOptions:[]}  defaultValue={[{ value: 'Pass', label: 'Pass' }]}   onChange={(accessType)=>{ setAccessType(accessType)}} ></Select>
                      </Col>
                   <Col  md={3}> 
                    
                     <Label
                       htmlFor='booking_date_event_space'
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                        Booking Date <span className="cp_starik">*</span>
                      </Label> 
                      <DatePicker
                          name="bookingDate"
                         onChange={(date)=>bookingDateHandler(date)}
                          className="datepickercss_exceptions"
                          
                          placeholder={
                            "Select date"
                          }
                          id="booking_date_event_space"
                          options={{
                            
                           
                              minDate: "today"
                          
                        }}
                          
                          
                        />
                      
                      </Col>

                      {bookingDate&& 
                 
                 <> 
                    <Col md={3} >
                      
                        <Label
                       htmlFor='start_time_event_space'
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                        Check-in Time <span className="cp_starik">*</span>
                      </Label> 
                       <DatePicker
                          onChange={(date)=> startTimeHandler(date)}
                          name="startTimeEventSpace"
                          value={bookingStartTime}
                         
                          className="datepickercss_exceptions"
                          placeholder={
                            "Select start time"
                          }
                          options={{
                            enableTime: true,
                            noCalendar:true,
                            
                            minuteIncrement: 15 
                             
                          
                        }}
                          id="start_time_event_space"/> 
                          </Col>

                          <Col md={3} >
                      
                       <Label
                        htmlFor="end_time_event_space"
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                        Check-out Time <span className="cp_starik">*</span>
                      </Label>  <DatePicker
                           onChange={(date)=> endTimeHandler(date)}
                          name="end_time_event_space"
                          value={bookingEndTime}
                          className="datepickercss_exceptions"
                          placeholder={
                            "Select end time"
                          }
                          options={{
                            enableTime: true,
                            noCalendar:true,
                            
                            minuteIncrement: 15 
                             
                          
                        }}
                          id="end_time_event_space"/> 
                          </Col> </>}
    </Row>
  )
}

export default EventSpaceSelections
