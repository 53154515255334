import React, { useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  Modal,
  ModalBody,
  DropdownItem,
} from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
import "../../../Tables/datatables.scss";
import UseExceptionListing from "./useExceptionListing.js";
import Switch from "react-switch";
import Select from "react-select";
import moment from "moment";
import "./css/exceptionListing.css";
import UserPromissionFetcher from "../../../../helpers/getUserPerimissions";
import Spinner from "../../../../components/Common/Loader";
import NoAccess from "../../../../components/Common/NoAccess";

const { SearchBar } = Search;

const defaultSorted = [
  {
    dataField: "id",
    order: "asc",
  },
];

const ExceptionListing = () => {
  const userPermissions = UserPromissionFetcher("operationalHours");
  const { products } = UseExceptionListing();

  const {
    dayTimeValue,
    Offsymbol,
    OnSymbol,
    Offsymbolhours,
    OnSymbolhours,
    DeleteModelFunc,
    setModal_static2,
    modal_static2,
    tog_static2,
    setModal_static1,
    modal_static1,
    tog_static1,
    currentPage,
    pageOptions,
    exceptoionsLisitngData,
    hasNextPage,
    pagelengthnum,
    pageSize,
    toggle,
    isOpen,
    filter,
    deleteExceptionFunc,
    changeCurrentPage,
    noData,
  } = UseExceptionListing();

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },
    {
      dataField: "dateRange",
      text: "Date Range",
      sort: true,
      formatter: (cell, row) => {
        const range = row?.fromTime
          ? moment(row?.fromTime).format("DD/MM/YYYY") +
            " - " +
            moment(row?.toTime).format("DD/MM/YYYY")
          : "";
        return range;
      },
    },
    {
      dataField: "type",
      text: "Type",
      sort: true,
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
    },

    {
      dataField: "createdAt",
      text: "Created on",
      sort: true,
      formatter: (cell, row) => {
        const created = row?.createdAt
          ? moment(row?.createdAt).format("DD/MM/YYYY")
          : "";
        return created;
      },
    },
    {
      dataField: "userName",
      text: "Created by",
      sort: true,
    },
    {
      dataField: "",
      text: "Time Slot",
      sort: true,
      formatter: (cell, row) =>
        row?.type === "TIME_SETTINGS" ? (
          <p
            onClick={() => {
              tog_static2(row);
            }}
            style={{ margin: "auto" }}
          >
            {" "}
            view
          </p>
        ) : (
          ""
        ),
    },
    {
      dataField: "actions",
      text: "Actions",
      sort: true,
      formatter: (cell, row) => (
        <div>
          {row.actions ? (
            ""
          ) : (
            <>
              <span>
                <Link
                  to={{
                    pathname: `/edit_exception/${row.id}`,
                    state: { exceptionRow: row },
                  }}
                  style={{ color: "black" }}
                >
                  {/* <Link to="/editException" style={{ color: "black" }} params={{ row : `${row.id}` }}> */}
                  <i className="editIcon mdi mdi-lead-pencil  deliconsize "></i>
                </Link>
                {cell}
              </span>
              <span>
                <i
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    tog_static1(row.id);
                  }}
                  className="delIcon mdi mdi-delete  deliconsize ml-5"
                ></i>
              </span>
            </>
          )}
        </div>
      ),
    },
  ];

  const [ttright, setTtright] = useState();
  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.read ? (
        <div className="page-content">
          <Row className="mb-4">
            <Col md={4}>
              <Link to="/operational_hours" style={{ color: "black" }}>
                {" "}
                <span className="fas fa-angle-left arrowheightwidth"></span>
              </Link>
              <span className="bookingtitle ml-4">Exceptions</span>
            </Col>
            <Col md={6}></Col>
            <Col md={2}>
              <Link to="/add_exception">
                {userPermissions.create && (
                  <Button
                    // onClick={handlePrint}
                    color="success"
                    className=" mr-1 w-100 "
                    block
                  >
                    <span className="e_a_btns ">Add</span>
                  </Button>
                )}
              </Link>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="tablebgcolor mt-3">
                <Row>
                  <Col className="col-12">
                    <Card>
                      <CardBody>
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="id"
                          columns={columns}
                          data={
                            exceptoionsLisitngData[0]
                              ? exceptoionsLisitngData
                              : noData
                          }
                          className=""
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              columns={columns}
                              data={
                                exceptoionsLisitngData[0]
                                  ? exceptoionsLisitngData
                                  : noData
                              }
                              search
                            >
                              {(toolkitProps) => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col md="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="search-box chat-search-box" />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col md="4"></Col>
                                    <Col md="4"></Col>
                                  </Row>

                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          hover
                                          keyField={"id"}
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          defaultSorted={defaultSorted}
                                          classes={
                                            "table align-middle table-nowrap"
                                          }
                                          headerWrapperClasses={"thead-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>

                                  <Row className="align-items-md-center mt-30">
                                    <Col className="inner-custom-pagination d-flex">
                                      <span className="paginationitmes ">
                                        Items
                                      </span>
                                      <div className="d-inline">
                                        {/* <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    /> */}

                                        <ButtonDropdown
                                          isOpen={isOpen}
                                          toggle={toggle}
                                          onClick={(e) => pagelengthnum(e)}
                                        >
                                          <DropdownToggle
                                            caret
                                            color="secondary"
                                          >
                                            {pageSize}
                                          </DropdownToggle>
                                          <DropdownMenu>
                                            <DropdownItem value="1">
                                              10
                                            </DropdownItem>
                                            <DropdownItem value="2">
                                              30
                                            </DropdownItem>
                                            <DropdownItem />
                                            <DropdownItem value="3">
                                              50
                                            </DropdownItem>
                                          </DropdownMenu>
                                        </ButtonDropdown>
                                      </div>
                                      <span className="paginationitmes1 ">
                                        show
                                      </span>
                                      <div className="text-md-right ms-auto">
                                        {/* <PaginationListStandalone
                                        {...paginationProps}
                                      /> */}
                                        <Button
                                          color="secondary"
                                          className="waves-effect "
                                          style={{
                                            marginLeft: 7,
                                            marginRight: 7,
                                          }}
                                          disabled={currentPage <= 1}
                                          onClick={() =>
                                            currentPage === 1
                                              ? null
                                              : changeCurrentPage(
                                                  (prev) => prev - 1
                                                )
                                          }
                                        >
                                          <i className="dripicons-chevron-left"></i>
                                        </Button>

                                        <Button
                                          style={{
                                            marginLeft: 7,
                                            marginRight: 7,
                                          }}
                                          color="success"
                                          className="btn-rounded waves-effect waves-light me-1 mr-2 ml-2"
                                        >
                                          {currentPage}
                                        </Button>
                                        <Button
                                          style={{
                                            marginLeft: 7,
                                            marginRight: 7,
                                          }}
                                          color="secondary"
                                          className="waves-effect"
                                          disabled={!hasNextPage}
                                          onClick={() =>
                                            changeCurrentPage(
                                              (prev) => prev + 1
                                            )
                                          }
                                        >
                                          <i className="dripicons-chevron-right"></i>
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <div>
            <Modal isOpen={modal_static1} toggle={tog_static1} centered={true}>
              <ModalBody>
                <Row>
                  <Col md={3}></Col>
                  <Col md={8}>
                    {" "}
                    <p className="modeltitlecss mt-3">Delete Exception</p>
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    {" "}
                    <p>
                      Are you sure you want to delete this exception? If this
                      exception is deleted, the resources will have operational
                      hours of their respective location, and any booking for
                      selected resources outside operational hours will be
                      revoked.
                    </p>
                  </Col>
                  <Col md={1}></Col>
                </Row>

                <hr />
                <Row>
                  <Col md={3}></Col>

                  <Col md={3}>
                    <Button
                      color="success"
                      outline
                      className="waves-effect waves-light w-100"
                      onClick={() => setModal_static1(false)}
                    >
                      No
                    </Button>
                  </Col>

                  <Col md={3}>
                    <Button
                      color="success"
                      className="waves-effect waves-light w-100"
                      onClick={DeleteModelFunc}
                    >
                      Delete
                    </Button>
                  </Col>
                  <Col md={3}></Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
          <div>
            <Modal
              isOpen={modal_static2}
              toggle={tog_static2}
              size={"lg"}
              centered={true}
            >
              <ModalBody>
                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    <Row className="mt-1">
                      <Col md={6}>
                        <p className="oh_title ">
                          Operational hours saved settings
                        </p>
                      </Col>
                    </Row>

                    <Row className="oh_days_title">
                      <Col md={2}>
                        <p>DAYS</p>
                      </Col>
                      <Col md={2}>
                        <p>OPEN</p>
                      </Col>
                      <Col md={2}>
                        <p>24 HOURS</p>
                      </Col>
                      <Col md={3}>
                        <p>FROM</p>
                      </Col>
                      <Col md={3}>
                        <p>UNTILL</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    <div>
                      {/* <Row className="mt-5">
                  <Col md={6}>
                    <p className="oh_title ">
                      Operational hours saved settings
                    </p>
                  </Col>
                </Row>

                <Row className="oh_days_title">
                  <Col md={2}>
                    <p>DAYS</p>
                  </Col>
                  <Col md={2}>
                    <p>OPEN</p>
                  </Col>
                  <Col md={2}>
                    <p>24 HOURS</p>
                  </Col>
                  <Col md={2}>
                    <p>FROM</p>
                  </Col>
                  <Col md={2}>
                    <p>UNTILL</p>
                  </Col>
                </Row> */}
                      <div>
                        <Row>
                          <Col md={2}>
                            <p className="oh_dayssubtitle">Sunday</p>
                          </Col>
                          <Col md={2}>
                            <Switch
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbol(<small>NO</small>)}
                              checkedIcon={OnSymbol(<small>YES</small>)}
                              onColor="#a2a2a2"
                              checked={!dayTimeValue.sundayClosed}
                              // checked={daysActiveStutus?.sunday}
                              className="mr-1 mt-1  "
                              disabled={true}
                            />
                          </Col>
                          <Col md={2}>
                            <Switch
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                              checkedIcon={OnSymbolhours(<small>YES</small>)}
                              // checked={activeOnWholeday?.sunday}
                              onColor="#a2a2a2"
                              className="mr-1 mt-1  "
                              disabled={true}
                            />
                          </Col>
                          <Col md={3}>
                            <Select
                              value={{
                                label: moment()
                                  .startOf("day")
                                  .add(dayTimeValue?.sundayOpenTime, "minutes")
                                  .format("hh:mm A"),
                                value: dayTimeValue?.sundayOpenTime,
                              }}
                              isDisabled={true}
                            ></Select>
                          </Col>
                          <Col md={3}>
                            <Select
                              value={{
                                label: moment()
                                  .startOf("day")
                                  .add(dayTimeValue?.sundayCloseTime, "minutes")
                                  .format("hh:mm A"),
                                value: dayTimeValue?.sundayCloseTime,
                              }}
                              isDisabled={true}
                            ></Select>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={2}>
                            <p className="oh_dayssubtitle">Monday</p>
                          </Col>
                          <Col md={2}>
                            <Switch
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbol(<small>NO</small>)}
                              checkedIcon={OnSymbol(<small>YES</small>)}
                              onColor="#a2a2a2"
                              checked={!dayTimeValue.mondayClosed}
                              // checked={daysActiveStutus?.monday}
                              className="mr-1 mt-1  "
                              disabled={true}
                            />
                          </Col>
                          <Col md={2}>
                            <Switch
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                              checkedIcon={OnSymbolhours(<small>YES</small>)}
                              // checked={activeOnWholeday?.monday}
                              onColor="#a2a2a2"
                              className="mr-1 mt-1  "
                              disabled={true}
                            />
                          </Col>
                          <Col md={3}>
                            <Select
                              isDisabled={true}
                              value={{
                                label: moment()
                                  .startOf("day")
                                  .add(dayTimeValue?.mondayOpenTime, "minutes")
                                  .format("hh:mm A"),
                                value: dayTimeValue?.mondayOpenTime,
                              }}
                            ></Select>
                          </Col>
                          <Col md={3}>
                            <Select
                              isDisabled={true}
                              value={{
                                label: moment()
                                  .startOf("day")
                                  .add(dayTimeValue?.mondayCloseTime, "minutes")
                                  .format("hh:mm A"),
                                value: dayTimeValue?.mondayCloseTime,
                              }}
                            ></Select>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={2}>
                            <p className="oh_dayssubtitle">Tuesday</p>
                          </Col>
                          <Col md={2}>
                            <Switch
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbol(<small>NO</small>)}
                              checkedIcon={OnSymbol(<small>YES</small>)}
                              checked={!dayTimeValue.tuesdayClosed}
                              // checked={daysActiveStutus?.tuesday}
                              onColor="#a2a2a2"
                              className="mr-1 mt-1  "
                              disabled={true}
                            />
                          </Col>
                          <Col md={2}>
                            <Switch
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                              checkedIcon={OnSymbolhours(<small>YES</small>)}
                              onColor="#a2a2a2"
                              // checked={activeOnWholeday?.tuesday}
                              className="mr-1 mt-1  "
                              disabled={true}
                            />
                          </Col>
                          <Col md={3}>
                            <Select
                              isDisabled={true}
                              value={{
                                label: moment()
                                  .startOf("day")
                                  .add(dayTimeValue?.tuesdayOpenTime, "minutes")
                                  .format("hh:mm A"),
                                value: dayTimeValue?.tuesdayOpenTime,
                              }}
                            ></Select>
                          </Col>
                          <Col md={3}>
                            <Select
                              isDisabled={true}
                              value={{
                                label: moment()
                                  .startOf("day")
                                  .add(
                                    dayTimeValue?.tuesdayCloseTime,
                                    "minutes"
                                  )
                                  .format("hh:mm A"),
                                value: dayTimeValue?.tuesdayCloseTime,
                              }}
                            ></Select>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={2}>
                            <p className="oh_dayssubtitle">Wednessday</p>
                          </Col>
                          <Col md={2}>
                            <Switch
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbol(<small>NO</small>)}
                              checkedIcon={OnSymbol(<small>YES</small>)}
                              checked={!dayTimeValue.wednesdayClosed}
                              // checked={daysActiveStutus?.wednessday}
                              onColor="#a2a2a2"
                              className="mr-1 mt-1  "
                              disabled={true}
                            />
                          </Col>
                          <Col md={2}>
                            <Switch
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                              checkedIcon={OnSymbolhours(<small>YES</small>)}
                              // checked={activeOnWholeday?.wednessday}
                              onColor="#a2a2a2"
                              className="mr-1 mt-1  "
                              disabled={true}
                            />
                          </Col>
                          <Col md={3}>
                            <Select
                              isDisabled={true}
                              value={{
                                label: moment()
                                  .startOf("day")
                                  .add(
                                    dayTimeValue?.wednesdayOpenTime,
                                    "minutes"
                                  )
                                  .format("hh:mm A"),
                                value: dayTimeValue?.wednesdayOpenTime,
                              }}
                            ></Select>
                          </Col>
                          <Col md={3}>
                            <Select
                              isDisabled={true}
                              value={{
                                label: moment()
                                  .startOf("day")
                                  .add(
                                    dayTimeValue?.wednesdayCloseTime,
                                    "minutes"
                                  )
                                  .format("hh:mm A"),
                                value: dayTimeValue?.wednesdayCloseTime,
                              }}
                            ></Select>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={2}>
                            <p className="oh_dayssubtitle">Thursday</p>
                          </Col>
                          <Col md={2}>
                            <Switch
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbol(<small>NO</small>)}
                              checkedIcon={OnSymbol(<small>YES</small>)}
                              checked={!dayTimeValue.thursdayClosed}
                              // checked={daysActiveStutus?.thursday}
                              onColor="#a2a2a2"
                              className="mr-1 mt-1  "
                              disabled={true}
                            />
                          </Col>
                          <Col md={2}>
                            <Switch
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                              checkedIcon={OnSymbolhours(<small>YES</small>)}
                              // checked={activeOnWholeday?.thursday}
                              onColor="#a2a2a2"
                              className="mr-1 mt-1  "
                              disabled={true}
                            />
                          </Col>
                          <Col md={3}>
                            <Select
                              isDisabled={true}
                              value={{
                                label: moment()
                                  .startOf("day")
                                  .add(
                                    dayTimeValue?.thursdayOpenTime,
                                    "minutes"
                                  )
                                  .format("hh:mm A"),
                                value: dayTimeValue?.thursdayOpenTime,
                              }}
                            ></Select>
                          </Col>
                          <Col md={3}>
                            <Select
                              isDisabled={true}
                              value={{
                                label: moment()
                                  .startOf("day")
                                  .add(
                                    dayTimeValue?.thursdayCloseTime,
                                    "minutes"
                                  )
                                  .format("hh:mm A"),
                                value: dayTimeValue?.thursdayCloseTime,
                              }}
                            ></Select>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={2}>
                            <p className="oh_dayssubtitle">Friday</p>
                          </Col>
                          <Col md={2}>
                            <Switch
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbol(<small>NO</small>)}
                              checkedIcon={OnSymbol(<small>YES</small>)}
                              checked={!dayTimeValue.fridayClosed}
                              // checked={daysActiveStutus?.friday}
                              onColor="#a2a2a2"
                              className="mr-1 mt-1  "
                              disabled={true}
                            />
                          </Col>
                          <Col md={2}>
                            <Switch
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                              checkedIcon={OnSymbolhours(<small>YES</small>)}
                              // checked={true}
                              onColor="#a2a2a2"
                              className="mr-1 mt-1  "
                              disabled={true}
                            />
                          </Col>
                          <Col md={3}>
                            <Select
                              isDisabled={true}
                              value={{
                                label: moment()
                                  .startOf("day")
                                  .add(dayTimeValue?.fridayOpenTime, "minutes")
                                  .format("hh:mm A"),
                                value: dayTimeValue?.fridayOpenTime,
                              }}
                            ></Select>
                          </Col>
                          <Col md={3}>
                            <Select
                              isDisabled={true}
                              value={{
                                label: moment()
                                  .startOf("day")
                                  .add(dayTimeValue?.fridayCloseTime, "minutes")
                                  .format("hh:mm A"),
                                value: dayTimeValue?.fridayCloseTime,
                              }}
                            ></Select>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={2}>
                            <p className="oh_dayssubtitle">Saturday</p>
                          </Col>
                          <Col md={2}>
                            <Switch
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbol(<small>NO</small>)}
                              checkedIcon={OnSymbol(<small>YES</small>)}
                              checked={!dayTimeValue.saturdayClosed}
                              // checked={daysActiveStutus?.saturday}
                              onColor="#a2a2a2"
                              className="mr-1 mt-1  "
                              disabled={true}
                            />
                          </Col>
                          <Col md={2}>
                            <Switch
                              onHandleColor="#16b185"
                              width={70}
                              uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                              checkedIcon={OnSymbolhours(<small>YES</small>)}
                              // checked={true}
                              onColor="#a2a2a2"
                              className="mr-1 mt-1  "
                              disabled={true}
                            />
                          </Col>
                          <Col md={3}>
                            <Select
                              isDisabled={true}
                              value={{
                                label: moment()
                                  .startOf("day")
                                  .add(
                                    dayTimeValue?.saturdayOpenTime,
                                    "minutes"
                                  )
                                  .format("hh:mm A"),
                                value: dayTimeValue?.saturdayOpenTime,
                              }}
                            ></Select>
                          </Col>
                          <Col md={3}>
                            <Select
                              isDisabled={true}
                              value={{
                                label: moment()
                                  .startOf("day")
                                  .add(
                                    dayTimeValue?.saturdayCloseTime,
                                    "minutes"
                                  )
                                  .format("hh:mm A"),
                                value: dayTimeValue?.saturdayCloseTime,
                              }}
                            ></Select>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                  <Col md={1}></Col>
                </Row>

                <hr />
                <Row>
                  <Col md={4}></Col>
                  <Col md={4}>
                    <Button
                      color="success"
                      outline
                      className="w-100"
                      onClick={() => setModal_static2(false)}
                    >
                      <p className="e_a_btns" style={{ margin: "auto" }}>
                        Ok
                      </p>
                    </Button>
                  </Col>

                  <Col md={4}></Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default ExceptionListing;
