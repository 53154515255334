import React, { useMemo, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { getSingleTeamApi } from "../../../APIS/allTeams";
import { useQuery, useQueryClient } from "react-query";
import { DIOM_BASED_URLS } from "../../../config/url";
import { toast } from "react-toastify";

const UseViewTeam = () => {
  const [errMsg, setErrMsg] = useState("");
  const [canDeleteTeam, setCanDeleteTeam] = useState(false);
  const [discountNumber, setDiscountNumber] = useState(null);
  const [selectedDiscountRadio, setSelectedDiscountRadio] = useState("");
  const [delLoading, setDelLoading] = useState(false);
  const QueryClient = useQueryClient();
  const [modal_static2, setModal_static2] = useState(false);
  const [delCheck1, setDelCheck1] = useState(false);
  const [delCheck2, setDelCheck2] = useState(false);
  const [delCheck3, setDelCheck3] = useState(false);
  const [modal_static1, setModal_static1] = useState(false);
  const token = localStorage.getItem("Token");
  let history = useHistory();
  const { id } = useParams();

  // all team without pagination

  const { data: singleTeamsDataa } = useQuery(["singleTeamsData"], () =>
    getSingleTeamApi(token, history, id)
  );

  const singleTeamsData = singleTeamsDataa?.data;

  const tog_static1 = () => {
    setModal_static1(!modal_static1);

    // removeBodyCss();
  };
  const tog_static2 = () => {
    setModal_static2(!modal_static2);

    // removeBodyCss();
  };

  const deleteTeamFunc = () => {
    setDelLoading(true);
    fetch(`${DIOM_BASED_URLS}/admin-teams/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((result3) => result3?.json())
      .then((result3) => {
        setDelLoading(false);
        if (result3.message === "Team Deleted Successfully") {
          QueryClient.invalidateQueries("AllTeamsDataFilters");
          QueryClient.invalidateQueries("AllTeamsData");
          history.push("/teams");
          toast.success(result3.message);
        } else {
          setDelLoading(false);
          toast.error(result3?.error?.message);
        }
      })
      .catch((error) => toast.error(" Something went wrong"));
  };

  const handleOptionChange = (event) => {
    setSelectedDiscountRadio(event.target.value);
  };

  useMemo(() => {
    setDiscountNumber(singleTeamsData?.discountOnBookings);
    setSelectedDiscountRadio(
      singleTeamsData?.discountOnBookings !== null ? "discount" : "noDiscount"
    );
  }, [singleTeamsData]);

  const saveDiscountFunc = () => {
    fetch(`${DIOM_BASED_URLS}/admin-teams/${id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        name: singleTeamsData?.name,
        discountOnBookings:
          selectedDiscountRadio === "discount" ? Number(discountNumber) : null,
      }),
    })
      .then((result3) => {
        if (result3.status === 200) {
          QueryClient.invalidateQueries("singleTeamsData");
          toast.success("Successfully Updated");
        } else if (result3.status === 204) {
          QueryClient.invalidateQueries("singleTeamsData");
          toast.success("Successfully Updated");
        } else {
          toast.error(" Something went wrong");
        }
        setModal_static1(false);
      })
      .catch((error) => {
        toast.error(" Something went wrong");
      });
  };

  const delButtonClicked = () => {
    setModal_static1(true);

    fetch(`${DIOM_BASED_URLS}/teams/${id}/run-commands`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        command: "CAN_TEAM_BE_DELETED",

        key: "",

        value: [id],
      }),
    })
      .then((result) => result?.json())
      .then((result3) => {
        if (result3?.data?.canDelete === true) {
          setCanDeleteTeam(true);

          // toast.success(result3.message);
        } else {
          setCanDeleteTeam(false);
          setErrMsg(result3.message);
          // toast.error(result3?.error?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(" Something went wrong");
      });
  };

  return {
    errMsg,
    canDeleteTeam,
    delButtonClicked,
    saveDiscountFunc,
    setDiscountNumber,
    discountNumber,
    handleOptionChange,
    selectedDiscountRadio,
    id,
    delLoading,
    deleteTeamFunc,
    singleTeamsData,
    modal_static1,
    setModal_static1,
    tog_static1,
    delCheck1,
    setDelCheck1,
    delCheck2,
    setDelCheck2,
    delCheck3,
    setDelCheck3,
    modal_static2,
    setModal_static2,
    tog_static2,
  };
};

export default UseViewTeam;
