import React from "react";
import {
  Card,
  Row,
  Col,
  Input,
  Button,
  FormGroup,
  Form,
  Label,
  Modal,
  ModalBody,
  Container,
} from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import Dropzone from "react-dropzone";
import Cropper from "react-easy-crop";
import { Spinner } from "reactstrap";
import UsePackageCategory from "../packageCategories/usePackageCategory";
import UseEditPackage from "./useEditPackage";
import "./css/editPackage.css";
import "react-image-crop/dist/ReactCrop.css";
import "../css/createPackage.css";
import UserPromissionFetcher from "../../../../helpers/getUserPerimissions";
import NoAccess from "../../../../components/Common/NoAccess";

const EditPackage = () => {
  const userPermissions = UserPromissionFetcher("packages");
  const { allCategoriesData } = UsePackageCategory();
  const CategoryOptions = allCategoriesData?.map((category) => {
    return {
      value: category?.id,
      label: category.name,
    };
  });

  const {
    setCreatePackageObj,
    CustomerSearchLoading,
    packagesCustomerSearchData,
    handleCustomerInputChange,
    detailedViewLoading,
    detailedViewBase64Func,
    detailImageButton,
    cardViewLoading,
    cardViewBase64Func,
    cardImageButton,
    testImg,
    refundableMinutes,
    refundableHours,
    cropCover,
    zoomCover,
    setCropCover,
    onCropCompleteCover,
    setZoomCover,
    setCoverMedia,
    imgCC,
    imageTwoArea,
    imageOneArea,
    setModal_static3,
    modal_static3,
    tog_static3,
    setImageOneArea,
    setImageTwoArea,
    loadedImgCover,
    pkgValidityFunc,
    pkgLocationFunc,
    pkgInventoryFunc,
    pkgPriorityFunc,
    pkgCustomersFunc,
    pkgConditionsFunc,
    pkgNameFunc,
    pkgPriceFunc,
    pkgDescriptionFunc,
    pkgCategory1Func,
    pkgDisplayHours1Func,
    pkgDisplayMinutes1Func,
    pkgActualHours1Func,
    pkgActualMinutes1Func,
    pkgActiveateToggleFunc,
    pkgTagInputFunc,
    pkgTagToggleFunc,
    createPackageObj,
    loadingLocations,
    CustomerLoading,
    PackageValidityOption,
    packagesCustomerData,
    locationsData,
    selectedFiles,
    setSelectedFiles,
    Offsymbol,
    OnSymbol,
    timeOptions,
    secondRowDataFunc,
    rowSecondDellFunc,
    handleAcceptedFiles,
    updatePackagesFunc,
    packageDataa,
    categoryTimeCreditArray,
    onCropCompleteProfile,
    setCrop,
    setZoom,
    zoom,
    crop,
    setProfileMedia,
    isLoading,
  } = UseEditPackage();
  const Loader = require("react-loader");

  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.update ? (
        <div className="page-content">
          {isLoading ? (
            <Loader loaded={false} className="spinner" />
          ) : (
            <>
              <Row className="mb-3">
                <Col md={4}>
                  <Link to="/packages" className="link">
                    <span className="fas fa-angle-left arrowheightwidth"></span>
                  </Link>
                  <span className="locationbrandtitle  ">Edit package</span>
                </Col>

                <Col md={4}></Col>
                <Col md={2}>
                  <Button
                    color="success"
                    outline
                    className="waves-effect waves-light mr-1  w-100"
                    block
                    onClick={tog_static3}
                  >
                    <span className="printbutton">Cancel</span>
                  </Button>
                </Col>
                <Col md={2}>
                  <Button
                    color="success"
                    className="waves-effect waves-light mr-1  w-100"
                    block
                    onClick={updatePackagesFunc}
                  >
                    <span className="printbutton">Update</span>
                  </Button>
                </Col>
              </Row>
              {createPackageObj ? (
                <Card className="p-4 ">
                  <Row className="mt-2">
                    <Col md={12}>
                      <p className="c_p_title">Activate Package</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={1}>
                      <Switch
                        name="always"
                        onHandleColor="#16b185"
                        width={70}
                        uncheckedIcon={Offsymbol(<small>NO</small>)}
                        checkedIcon={OnSymbol(<small>YES</small>)}
                        onColor="#a2a2a2"
                        checked={
                          createPackageObj?.ActivateToggle === false
                            ? createPackageObj?.ActivateToggle
                            : packageDataa?.visibility
                        }
                        onChange={(e) => pkgActiveateToggleFunc(e)}
                        className="mr-1 mt-1  "
                      />
                    </Col>
                    <Col md={10}>
                      <p className="c_p_light_text">
                        Activate this package as soon as it is created
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col md={6}>
                      <p className="c_p_title">General Details</p>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={6}>
                      <p className="c_p_title2">
                        {" "}
                        Package Name <span className="cp_starik">*</span>{" "}
                      </p>
                      <Input
                        defaultValue={packageDataa?.name}
                        placeholder="Enter Name"
                        onChange={(e) => pkgNameFunc(e)}
                      />
                    </Col>
                    <Col md={6}>
                      <p className="c_p_title2">
                        {" "}
                        Price <span className="cp_starik">*</span>{" "}
                      </p>
                      <Input
                        defaultValue={packageDataa?.price}
                        type="number"
                        placeholder="Enter Price"
                        onChange={(e) => pkgPriceFunc(e)}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={12}>
                      <p className="c_p_title2">
                        Description <span className="cp_starik">*</span>
                      </p>
                      <Input
                        defaultValue={packageDataa?.description}
                        type="textarea"
                        placeholder="Enter Description Here..."
                        onChange={(e) => pkgDescriptionFunc(e)}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col md={12}>
                      <p className="c_p_title">
                        Define category and time credit
                      </p>
                    </Col>
                  </Row>

                  {/* **********First Row******** */}
                  {categoryTimeCreditArray?.map((e, i) => {
                    return (
                      <>
                        <Row className="mt-3">
                          <Col md={4}>
                            <p className="c_p_title2">
                              Select Category{" "}
                              <span className="cp_starik">*</span>
                            </p>
                            <FormGroup className="select2-container  ">
                              <Select
                                defaultValue={{ label: e?.name, value: e?.id }}
                                placeholder="Select Category"
                                classNamePrefix="select2-selection "
                                onChange={(ele) => pkgCategory1Func(ele, e.uid)}
                                options={CategoryOptions}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={2}>
                            <p className="c_p_title2">
                              Display (Hours){" "}
                              <span className="cp_starik">*</span>
                            </p>
                            <Input
                              defaultValue={e?.DisplayHours}
                              type="number"
                              placeholder="Enter Display Hours"
                              onChange={(ele) =>
                                pkgDisplayHours1Func(ele, e.uid)
                              }
                            />
                          </Col>
                          <Col md={2}>
                            <p className="c_p_title2">
                              Minutes <span className="cp_starik">*</span>
                            </p>
                            <FormGroup className="select2-container  ">
                              <Select
                                defaultValue={{
                                  value: e?.DisplayMinutes,
                                  label: e?.DisplayMinutes,
                                }}
                                options={timeOptions}
                                onChange={(ele) =>
                                  pkgDisplayMinutes1Func(ele, e.uid)
                                }
                                placeholder="0"
                                classNamePrefix="select2-selection "
                              />
                            </FormGroup>
                          </Col>
                          <Col md={2}>
                            <p className="c_p_title2">
                              Actual (Hours){" "}
                              <span className="cp_starik">*</span>
                            </p>
                            <Input
                              type="number"
                              defaultValue={e?.ActualHours}
                              placeholder="Enter Actual Hours"
                              onChange={(ele) =>
                                pkgActualHours1Func(ele, e.uid)
                              }
                            />
                          </Col>
                          <Col md={2}>
                            <p className="c_p_title2">
                              Minutes <span className="cp_starik">*</span>
                            </p>
                            <span className="deleteIconeEdit">
                              <FormGroup
                                className="select2-container  "
                                style={{ width: "80%" }}
                              >
                                <Select
                                  defaultValue={{
                                    value: e?.ActualMinutes,
                                    label: e?.ActualMinutes,
                                  }}
                                  options={timeOptions}
                                  onChange={(ele) =>
                                    pkgActualMinutes1Func(ele, e.uid)
                                  }
                                  placeholder="0"
                                  classNamePrefix="select2-selection "
                                />
                              </FormGroup>
                              {i === 0 ? (
                                ""
                              ) : (
                                <span>
                                  <i
                                    className="delIcon mdi mdi-delete c_p_delbtn   ml-5"
                                    onClick={() => rowSecondDellFunc(e.uid)}
                                  ></i>
                                </span>
                              )}
                            </span>
                          </Col>
                        </Row>
                      </>
                    );
                  })}

                  <Row className="mt-3">
                    {categoryTimeCreditArray?.length === 3 ? (
                      ""
                    ) : (
                      <Col md={2}>
                        <Button
                          color="info"
                          outline
                          onClick={() => {
                            secondRowDataFunc();
                          }}
                        >
                          Add more
                        </Button>
                      </Col>
                    )}
                  </Row>

                  <Row className="mt-3">
                    <Col md="6">
                      <span className="c_p_title2">
                        Validity <span className="cp_starik">*</span>
                      </span>
                      <FormGroup className="select2-container  ">
                        <Select
                          className="mt-3"
                          value={{
                            label: createPackageObj?.Validity,
                            value: createPackageObj?.Validity,
                          }}
                          options={PackageValidityOption}
                          onChange={(opt) => pkgValidityFunc(opt)}
                          placeholder="Select Validity"
                          classNamePrefix="select2-selection "
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <span className="c_p_title2">
                        Location <span className="cp_starik">*</span>
                      </span>
                      {loadingLocations ? (
                        <Spinner
                          className="cat_loading"
                          animation="border"
                          role="status"
                        ></Spinner>
                      ) : (
                        ""
                      )}

                      <FormGroup className="select2-container  ">
                        <Select
                          isMulti={true}
                          className="mt-3"
                          value={createPackageObj?.Location}
                          options={locationsData}
                          onChange={(opt) => pkgLocationFunc(opt)}
                          placeholder="Select Location"
                          classNamePrefix="select2-selection "
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md={6}>
                      <span className="c_p_title2">
                        Inventory <span className="cp_starik">*</span>
                      </span>
                      <Input
                        className="mt-3"
                        value={createPackageObj?.Inventory}
                        type="number"
                        placeholder="Enter Inventory"
                        onChange={(opt) => pkgInventoryFunc(opt)}
                      />
                    </Col>
                    <Col md={6}>
                      <span className="c_p_title2">
                        Priority <span className="cp_starik">*</span>
                      </span>
                      <Input
                        className="mt-3"
                        value={createPackageObj?.Priority}
                        type="number"
                        placeholder="Enter Priority"
                        onChange={(opt) => pkgPriorityFunc(opt)}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col md={1}>
                      <span className="c_p_title2 mt-2">Tag</span>
                    </Col>
                    <Col md={2}>
                      <Switch
                        name="always"
                        onHandleColor="#16b185"
                        width={70}
                        uncheckedIcon={Offsymbol(<small>NO</small>)}
                        checkedIcon={OnSymbol(<small>YES</small>)}
                        onColor="#a2a2a2"
                        checked={
                          createPackageObj?.TagToggle === false
                            ? createPackageObj?.TagToggle
                            : packageDataa?.showTag
                        }
                        onChange={(e) => pkgTagToggleFunc(e)}
                        className="mr-1 mt-1  "
                      />
                    </Col>
                    <Col md={3}></Col>
                    <Col md={6}>
                      <span className="c_p_title2  mt-2">Customers</span>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col md={6}>
                      <Input
                        defaultValue={
                          packageDataa?.tags === undefined
                            ? ""
                            : packageDataa.tags
                        }
                        placeholder="Enter Tag"
                        onChange={(e) => pkgTagInputFunc(e)}
                      />
                    </Col>

                    <Col md={6}>
                      <FormGroup className="select2-container  ">
                        <Select
                          cacheOptions={false}
                          isLoading={CustomerSearchLoading}
                          options={packagesCustomerSearchData?.data}
                          isMulti={true}
                          value={createPackageObj?.CustomersData}
                          onChange={(opt) => pkgCustomersFunc(opt)}
                          onInputChange={handleCustomerInputChange}
                          placeholder="All Customers"
                          classNamePrefix="select2-selection "
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col md={3}>
                      <p className="c_p_title2">
                        Refundable Hours <span className="cp_starik">*</span>
                      </p>
                    </Col>
                    <Col md={3}>
                      <p className="c_p_title2">
                        Refundable Minutes <span className="cp_starik">*</span>
                      </p>
                    </Col>
                    <Col md={6}>
                      <p className="c_p_title2">Category</p>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col md={3}>
                      <Input
                        placeholder="i.e 10 hours"
                        onChange={refundableHours}
                        defaultValue={Number(
                          String(packageDataa?.refundAbleMinutes / 60).split(
                            "."
                          )[0]
                        )}
                        type="number"
                      />
                    </Col>
                    <Col md={3}>
                      <Input
                        placeholder="i.e 30 minutes"
                        onChange={refundableMinutes}
                        defaultValue={Number(
                          Number(packageDataa?.refundAbleMinutes) -
                            Number(
                              String(
                                packageDataa?.refundAbleMinutes / 60
                              ).split(".")[0] * 60
                            )
                        )}
                      />
                    </Col>
                    <Col md={6}>
                      <Input
                        className="d_pkg_all_inp"
                        defaultValue={
                          packageDataa?.type ? packageDataa?.type : ""
                        }
                        onChange={(e) => {
                          setCreatePackageObj({
                            ...createPackageObj,
                            type: e?.target?.value,
                          });
                        }}
                      />
                    </Col>
                  </Row>

                  <Row className="">
                    <Col md={6}>
                      <p className="c_p_light_text">
                        If user’s usage is less than or equal to the refundable
                        hours, User will be refunded accordingly
                      </p>
                    </Col>
                    <Col md={6}></Col>
                  </Row>

                  <Row className="mt-5">
                    <Col md={11}>
                      <p className="c_p_title">
                        Set Package Image <span className="cp_starik">*</span>
                      </p>
                    </Col>
                    <Col md={1}>
                      {selectedFiles === "" ? (
                        ""
                      ) : (
                        <Label
                          className="c_p_title2"
                          onClick={() => {
                            setSelectedFiles("");
                            setImageOneArea("");
                            setImageTwoArea("");
                          }}
                        >
                          Remove
                        </Label>
                      )}
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col md={12}>
                      {/* *********start******* */}
                      <div>
                        {selectedFiles !== "" ? (
                          <Form
                            className="dropzone"
                            style={{ overflowX: "auto", maxHeight: "30px" }}
                          >
                            <Dropzone>
                              {() => (
                                <div>
                                  <img
                                    key="dwejf9"
                                    // height="80"
                                    // className="avatar-sm rounded bg-light"
                                    alt={selectedFiles}
                                    src={selectedFiles}
                                  />
                                </div>
                              )}
                            </Dropzone>
                          </Form>
                        ) : (
                          <Form
                            className="dropzone"
                            style={{ overflowX: "auto", maxHeight: "30px" }}
                          >
                            <Dropzone
                              // accept={".pdf"}
                              onDrop={(acceptedFiles) => {
                                handleAcceptedFiles(acceptedFiles);
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div>
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />

                                    <div className="mb-3">
                                      <i className="display-4 text-muted ri-upload-cloud-2-line" />
                                    </div>
                                    <h4>Drop Image here or click to upload.</h4>
                                    <img
                                      key="dwejf9"
                                      alt={selectedFiles}
                                      src={selectedFiles}
                                    />
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                          </Form>
                        )}
                      </div>

                      {/* *********End*********** */}
                    </Col>
                  </Row>
                  <Row className="mt-4  ">
                    <Col md={5}>
                      {selectedFiles ? (
                        <>
                          {imgCC ? (
                            <>
                              <Row>
                                <p className="c_p_title">
                                  {" "}
                                  Package Card View{" "}
                                  <span className="cp_starik">*</span>
                                </p>
                                <Col md={8}>
                                  <p className="c_p_light_text">
                                    Use click and drag to adjust image in view
                                  </p>
                                </Col>
                                <Col md={4}>
                                  {cardImageButton ? (
                                    <Button
                                      color="success"
                                      disabled
                                      className="w-100"
                                    >
                                      Saved
                                    </Button>
                                  ) : (
                                    <Button
                                      outline
                                      color="secondary"
                                      className="w-100"
                                    >
                                      <span
                                        className=""
                                        onClick={cardViewBase64Func}
                                      >
                                        Save
                                      </span>
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col md={1}></Col>
                    <Col md={6}>
                      {selectedFiles ? (
                        <>
                          {imgCC ? (
                            <>
                              <Row>
                                <p className="c_p_title">
                                  Package Detail View{" "}
                                  <span className="cp_starik">*</span>
                                </p>
                                <Col md={8}>
                                  <p className="c_p_light_text">
                                    Use click and drag to adjust image in view
                                  </p>
                                </Col>
                                <Col md={4}>
                                  {detailImageButton ? (
                                    <Button
                                      color="success"
                                      disabled
                                      className="w-100"
                                    >
                                      Saved
                                    </Button>
                                  ) : (
                                    <Button
                                      outline
                                      color="secondary"
                                      className="w-100"
                                    >
                                      <span
                                        className=""
                                        onClick={detailedViewBase64Func}
                                      >
                                        Save
                                      </span>
                                    </Button>
                                  )}
                                </Col>
                              </Row>
                            </>
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                  {/* ************View Crop*********** */}
                  {imgCC ? (
                    ""
                  ) : (
                    <Row className=" mt-4 ">
                      <Col md={3}>
                        {selectedFiles ? (
                          <>
                            <p className="c_p_title">
                              {" "}
                              Package Card View{" "}
                              <span className="cp_starik">*</span>
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col md={6}>
                        {selectedFiles ? (
                          <>
                            <p className="c_p_title">
                              Package Detail View{" "}
                              <span className="cp_starik">*</span>
                            </p>
                          </>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  )}
                  <Row className=" mt-1 mb-3">
                    {selectedFiles !== "" ? (
                      <Col md={3}>
                        <Dropzone>
                          {() =>
                            imgCC ? (
                              ""
                            ) : (
                              <img
                                height={173}
                                width={173}
                                src={imageOneArea}
                              />
                            )
                          }
                        </Dropzone>
                      </Col>
                    ) : (
                      ""
                    )}
                    {selectedFiles !== "" ? (
                      <Col md={6}>
                        <Dropzone>
                          {() =>
                            imgCC ? (
                              <Container>{imgCC ? "" : ""}</Container>
                            ) : (
                              <img
                                width={490}
                                height={173}
                                src={imageTwoArea}
                              />
                            )
                          }
                        </Dropzone>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>
                  {/* ********Crop start******* */}
                  <Container>
                    <Row className=" mt-1 mb-5">
                      {selectedFiles !== "" ? (
                        <Col md={5}>
                          <Dropzone>
                            {() =>
                              imgCC ? (
                                <Container
                                  style={{
                                    maxHeight: "100px",
                                    maxWidth: "100px",
                                  }}
                                >
                                  <Cropper
                                    image={imgCC[0]?.preview}
                                    crop={crop}
                                    zoom={zoom}
                                    crossorigin="anonymous"
                                    objectFit="contain"
                                    onCropChange={(e) => setCrop(e)}
                                    onCropComplete={onCropCompleteProfile}
                                    onZoomChange={(e) => setZoom(e)}
                                    onMediaLoaded={(mediaSize) => {
                                      setProfileMedia(mediaSize);
                                    }}
                                  />
                                </Container>
                              ) : (
                                ""
                              )
                            }
                          </Dropzone>
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col md={1}></Col>

                      {selectedFiles !== "" ? (
                        <Col md={6}>
                          <Dropzone>
                            {() =>
                              imgCC ? (
                                <Container>
                                  {imgCC ? (
                                    <>
                                      <Form
                                        className="dropzone"
                                        style={{
                                          overflowX: "auto",
                                          maxHeight: "158px",
                                          maxWidth: "349px",
                                          paddingLeft: "100px",
                                        }}
                                      >
                                        <>
                                          <Cropper
                                            image={imgCC[0]?.preview}
                                            crop={cropCover}
                                            zoom={zoomCover}
                                            aspect={6 / 2}
                                            crossorigin="anonymous"
                                            objectFit="contain"
                                            onCropChange={(e) =>
                                              setCropCover(e)
                                            }
                                            onCropComplete={onCropCompleteCover}
                                            onZoomChange={(e) =>
                                              setZoomCover(e)
                                            }
                                            onMediaLoaded={(mediaSize) => {
                                              setCoverMedia(mediaSize);
                                            }}
                                          />
                                        </>
                                      </Form>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </Container>
                              ) : (
                                ""
                              )
                            }
                          </Dropzone>
                        </Col>
                      ) : (
                        ""
                      )}
                    </Row>
                  </Container>
                  {testImg ? (
                    <Row hidden={true}>
                      <img src={testImg[0]?.preview} onLoad={loadedImgCover} />
                    </Row>
                  ) : (
                    ""
                  )}
                  {imgCC ? (
                    <Row>
                      <Col md={5}>
                        <div className="controls">
                          <span className="c_p_title2">
                            Set Card View Image{" "}
                          </span>
                          {cardViewLoading ? (
                            <Spinner
                              className="cat_loading"
                              animation="border"
                              role="status"
                            ></Spinner>
                          ) : (
                            ""
                          )}
                          <input
                            style={{ float: "right" }}
                            type="range"
                            value={zoom}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => {
                              setZoom(e.target.value);
                            }}
                            className="zoom-range"
                          />
                        </div>
                      </Col>
                      <Col md={1}></Col>
                      <Col md={6}>
                        <div className="controls">
                          <span className="c_p_title2">
                            Set Detail View Image
                          </span>
                          {detailedViewLoading ? (
                            <Spinner
                              className="cat_loading"
                              animation="border"
                              role="status"
                            ></Spinner>
                          ) : (
                            ""
                          )}
                          <input
                            style={{ float: "right" }}
                            type="range"
                            value={zoomCover}
                            min={1}
                            max={3}
                            step={0.1}
                            aria-labelledby="Zoom"
                            onChange={(e) => {
                              setZoomCover(e.target.value);
                            }}
                            className="zoom-range"
                          />
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    ""
                  )}
                  {/* **********crop end********** */}
                  {imgCC ? <hr /> : ""}
                  <Row className="mt-3">
                    <Col md={12}>
                      <p className="c_p_title">
                        Terms & Conditions <span className="cp_starik">*</span>
                      </p>
                      <Input
                        defaultValue={packageDataa?.termsAndCondidtions}
                        type="textarea"
                        onChange={(opt) => pkgConditionsFunc(opt)}
                        placeholder="Terms & Conditions"
                      />
                    </Col>
                  </Row>
                </Card>
              ) : (
                ""
              )}
              <div>
                <Modal
                  isOpen={modal_static3}
                  toggle={tog_static3}
                  centered={true}
                >
                  <ModalBody>
                    <Row style={{ textAlign: "center" }}>
                      <p className="modeltitlecss mt-2 mb-2">Cancel Editing</p>
                    </Row>

                    <hr />
                    <Row style={{ textAlign: "center" }}>
                      <p className="mt-3">
                        Are you sure you want to cancel Editing in this package?
                      </p>
                    </Row>

                    <hr />
                    <Row>
                      <Col md={2}></Col>

                      <Col md={4}>
                        <Button
                          color="success"
                          outline
                          className="waves-effect waves-light w-100"
                          onClick={() => setModal_static3(false)}
                        >
                          No
                        </Button>
                      </Col>

                      <Col md={4}>
                        <Link to="/packages">
                          <Button
                            color="success"
                            className="waves-effect waves-light w-100"
                          >
                            Yes
                          </Button>
                        </Link>
                      </Col>
                      <Col md={2}></Col>
                    </Row>
                  </ModalBody>
                </Modal>
              </div>
            </>
          )}
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default EditPackage;
