import { DIOM_BASED_URLS } from "../config/url";

// **************brands*****************
export const getoperationHoursBrand = async (token) => {
  const res = (
    await (
      await fetch(`${DIOM_BASED_URLS}/admin-location-categories`, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      })
    ).json()
  ).map((element) => ({
    value: element.id,
    label: element.name,
  }));
  return [
    {
      // label: "Please select a brand",
      options: [
        // {
        //   value: res.map((e) => e?.value),
        //   label: "All",
        // },
        ...res,
      ],
    },
  ];
};

// ************Locations of Operational Hours**************

export const getLocationsOperationalHours = async (
  token,
  locationApplyFilter,
  brandValues
) => {
  if (locationApplyFilter) {
    var brandValuesArray = brandValues.flat(2);
    const res = (
      await (
        await fetch(
          `${DIOM_BASED_URLS}/admin-business-locations?filter={"where":{"locationCategoriesId":{"inq":${JSON.stringify(
            brandValuesArray
          )}}}}`,

          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
      ).json()
    )?.map((element) => ({
      value: element.id,
      label: element.Name,
    }));
    return [
      {
        // label: "All",

        options: [
          {
            value: res.map((e) => e?.value),
            label: "All",
          },
          ...res,
        ],
      },
    ];
  }
};

// **********resourceTypes of OperationalHours*********

export const getResourcetypeOperationalHours = async (
  token = "",
  resourcetypeApplyFilter,
  locationValues
) => {
  var locationValuesArray = locationValues.flat(2);

  // console.log(locationValuesArray, "location value fromOHfile");
  if (resourcetypeApplyFilter || locationValues) {
    const res = await fetch(
      `${DIOM_BASED_URLS}/admin-resource-types-inventories?filter={"where":{"BusinessId":{"inq":${JSON.stringify(
        locationValuesArray
      )}},"visibility":true}}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    // ).json();
    if (!res.ok) {
      if (res.status === 401) {
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      throw new Error(resJson);
    }

    const resJson = await res.json();

    return {
      data: resJson?.data,
      total: resJson.total,
      hasNextPage: resJson.hasNextPage,
      hasPreviousPage: resJson.hasPreviousPage,
    };
  }
};

// *************resources************
// export const getExceptionsResource = async (token = "", id, history) => {
//   if (id) {
//     const res = await fetch(
//       `${DIOM_BASED_URLS}/admin-resources-inventories?filter={ "where" :{ "ResourceTypeId" : {"inq" :[${id}]}}}`,
//       {
//         method: "GET",
//         redirect: "follow",
//         headers: {
//           Authorization: "Bearer " + token,
//         },
//       }
//     );
//     if (!res.ok) {
//       if (res.status === 401) {
//         history.push("/login");
//         throw new Error(resJson.error.message);
//       }
//       const resJson = await res.json();
//       throw new Error(resJson.error.message);
//     }
//     const data = await res.json();

//     return {
//       data: data?.data,
//     };
//   }
//   // ).json();
// };

export const getExceptionsResource = async (token = "", id, history) => {
  if (id) {
    const res = (
      await (
        await fetch(
          `${DIOM_BASED_URLS}/admin-resources-inventories?filter={ "where" :{ "ResourceTypeId" : {"inq" :[${id}]}}}`,
          {
            method: "GET",
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        )
      ).json()
    )?.data?.map((element) => ({
      value: element.id,
      label: element.Name,
    }));
    return [
      {
        // label: "Please select locations",

        options: [
          {
            value: res.map((e) => e?.value),
            label: "All",
          },
          ...res,
        ],
      },
    ];
  }
};
