// <<<<<<<<<<<<<<<<<<<<<   old  Production        >>>>>>>>>>>>>>>>>>>>>>>>>>>>
// export const DIOM_BASED_URLS = "https://diom-prod-api.devbeans.io/api";
// export const DIOM_BASED_URLS = "https://diom-api.devbeans.io";

// <<<<<<<<<<<<<<<<<<<<<     Staging        >>>>>>>>>>>>>>>>>>>>>>>>>>>>
export const DIOM_BASED_URLS = "https://diom-api.devbeans.io/api";
export const DIOM_BASED_URLS_FRONT_END = "https://diom.devbeans.io";
export const DIOM_PAYMENT_BASE_URL="https://diom-api.devbeans.io"
export const DIOM_HAYPER_PAY_URL="https://eu-test.oppwa.com"


// <<<<<<<<<<<<<<<<<<<<<r local>>>>>>>>>>>>>>>>>>>>>>>>>>>
// export const DIOM_BASED_URLS = "http://localhost:8000/api";

// <<<<<<<<<<<<<<<<<<<<<     Production        >>>>>>>>>>>>>>>>>>>>>>>>>>>>
// export const DIOM_BASED_URLS = "https://api.diom.net/api";
// export const DIOM_BASED_URLS_FRONT_END = "https://diom.net";
// export const DIOM_PAYMENT_BASE_URL="https://api.diom.net"
// export const DIOM_HAYPER_PAY_URL="https://eu-prod.oppwa.com"



