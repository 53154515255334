import React from "react";
import {
  Card,
  Row,
  Col,
  Input,
  Button,
  FormGroup,
  Form,
  Modal,
  ModalBody,
} from "reactstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import Switch from "react-switch";
import Dropzone from "react-dropzone";
import UsePackageCategory from "../packageCategories/usePackageCategory";
import UseEditPackage from "../editPackage/useEditPackage";
import UseDetailedPackage from "./useDetailedPackage";
import "./css/detailedPkg.css";
import "react-image-crop/dist/ReactCrop.css";
import "../css/createPackage.css";
import UserPromissionFetcher from "../../../../helpers/getUserPerimissions";
import NoAccess from "../../../../components/Common/NoAccess";
import Spinner from "../../../../components/Common/Loader";

const DetailedPackage = () => {
  const userPermissions = UserPromissionFetcher("packages");

  const { allCategoriesData } = UsePackageCategory();
  const CategoryOptions = allCategoriesData?.map((category) => {
    return {
      value: category?.id,
      label: category.name,
    };
  });

  const {
    imageTwoArea,
    imageOneArea,
    PackageValidityOption,
    packagesCustomerData,
    locationsData,
    selectedFiles,
    timeOptions,
    packageDataa,
    isLoading,
    Offsymbol,
    OnSymbol,
  } = UseEditPackage();

  const {
    modal_static5,
    delPkgFunc,
    tog_static5,
    setModal_static5,
    packageInactiveFunc,
    packageActiveFunc,
  } = UseDetailedPackage();
  const customersDataa = packageDataa?.customers[0]
    ? packageDataa?.customers?.map((e) => ({
        label: e?.name,
        value: e?.id,
      }))
    : "";
  const Loader = require("react-loader");

  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.read ? (
        <div className="page-content">
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <Row className="mb-3">
                <Col md={4}>
                  <Link to="/packages" className="link">
                    <span className="fas fa-angle-left arrowheightwidth"></span>
                  </Link>
                  <span className="locationbrandtitle  ">Details</span>
                </Col>

                <Col
                  md={
                    userPermissions.delete ? 2 : userPermissions.update ? 4 : 8
                  }
                ></Col>
                {userPermissions.delete && (
                  <Col md={2}>
                    <Button
                      color="danger"
                      outline
                      className="waves-effect waves-light mr-1  w-100"
                      block
                      onClick={tog_static5}
                    >
                      <span className="printbutton">Delete</span>
                    </Button>
                  </Col>
                )}
                {userPermissions.update && (
                  <>
                    <Col md={2}>
                      {packageDataa?.visibility === false ? (
                        <Button
                          color="success"
                          outline
                          className="waves-effect waves-light mr-1  w-100"
                          block
                          onClick={packageActiveFunc}
                        >
                          <span className="printbutton">Activate</span>
                        </Button>
                      ) : (
                        <Button
                          color="success"
                          outline
                          className="waves-effect waves-light mr-1  w-100"
                          block
                          onClick={packageInactiveFunc}
                        >
                          <span className="printbutton">Deactivate</span>
                        </Button>
                      )}
                    </Col>

                    <Col md={2}>
                      <Link to={`/edit_Package/${packageDataa?.id}`}>
                        <Button
                          color="success"
                          className="waves-effect waves-light mr-1  w-100"
                          block
                        >
                          <span className="printbutton">Edit</span>
                        </Button>
                      </Link>
                    </Col>
                  </>
                )}
              </Row>
              {packageDataa ? (
                <Card className="p-4 ">
                  <Row className="mt-2">
                    <Col md={12}>
                      <p className="c_p_title">Activate Package</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={1}>
                      <Switch
                        checked={packageDataa?.visibility}
                        onChange={() => {
                          "";
                        }}
                        disabled={true}
                        name="always"
                        onHandleColor="#16b185"
                        width={70}
                        uncheckedIcon={Offsymbol(<small>NO</small>)}
                        checkedIcon={OnSymbol(<small>YES</small>)}
                        onColor="#a2a2a2"
                        className="mr-1 mt-1  "
                      />
                    </Col>
                    <Col md={10}>
                      <p className="c_p_light_text">
                        Activate this package as soon as it is created
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col md={6}>
                      <p className="c_p_title">General Details</p>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={6}>
                      <p className="c_p_title2"> Package Name </p>
                      <Input
                        disabled
                        className="d_pkg_all_inp"
                        defaultValue={packageDataa?.name}
                        placeholder="Enter Name"
                      />
                    </Col>
                    <Col md={6}>
                      <p className="c_p_title2"> Price </p>
                      <Input
                        className="d_pkg_all_inp"
                        disabled
                        defaultValue={packageDataa?.price}
                        type="number"
                        placeholder="Enter Price"
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={12}>
                      <p className="c_p_title2">Description </p>
                      <Input
                        className="d_pkg_all_inp"
                        disabled
                        defaultValue={packageDataa?.description}
                        type="textarea"
                        placeholder="Enter Description Here..."
                      />
                    </Col>
                  </Row>

                  <Row className="mt-5">
                    <Col md={12}>
                      <p className="c_p_title">Summary</p>
                    </Col>
                  </Row>

                  <Row className="d_pkg py-3">
                    <Col md={2}></Col>

                    <Col md={2}>
                      <p className="d_pkg_enitity c_p_title2">Quanity Sold</p>
                      <Input
                        className="d_pkg_inp"
                        disabled
                        value={packageDataa?.quantitySold}
                      />
                    </Col>

                    <Col md={2}>
                      <p className="d_pkg_enitity c_p_title2">Priority</p>
                      <Input
                        className="d_pkg_inp"
                        disabled
                        value={packageDataa?.priority}
                      />
                    </Col>

                    <Col md={2}>
                      <p className="d_pkg_enitity c_p_title2">Inventory</p>
                      <Input
                        className="d_pkg_inp"
                        disabled
                        value={packageDataa?.inventory}
                      />
                    </Col>

                    <Col md={2}>
                      <p className="d_pkg_enitity c_p_title2">Last Purchased</p>
                      <Input
                        className="d_pkg_inp"
                        disabled
                        value={packageDataa?.recentlyPurchasedAt}
                      />
                    </Col>
                  </Row>

                  <Row className="mt-5">
                    <Col md={12}>
                      <p className="c_p_title">
                        Define category and time credit
                      </p>
                    </Col>
                  </Row>

                  {/* **********First Row******** */}
                  {packageDataa?.categories?.map((e) => {
                    return (
                      <>
                        <Row className="mt-3">
                          <Col md={4}>
                            <p className="c_p_title2">Select Category</p>
                            <FormGroup className="select2-container  ">
                              <Select
                                className="d_pkg_all_inp"
                                isDisabled
                                value={{ label: e?.name, value: e?.id }}
                                placeholder="Select Category"
                                classNamePrefix="select2-selection "
                                options={CategoryOptions}
                              />
                            </FormGroup>
                          </Col>
                          <Col md={2}>
                            <p className="c_p_title2">Display (Hours)</p>
                            <Input
                              className="d_pkg_all_inp"
                              disabled
                              defaultValue={
                                String(e?.displayTime / 60).split(".")[0]
                              }
                              type="number"
                              placeholder="Enter Display Hours"
                            />
                          </Col>
                          <Col md={2}>
                            <p className="c_p_title2">Minutes</p>
                            <FormGroup className="select2-container  ">
                              <Select
                                className="d_pkg_all_inp"
                                value={{
                                  value:
                                    e?.displayTime -
                                    String(e?.displayTime / 60).split(".")[0] *
                                      60,
                                  label:
                                    e?.displayTime -
                                    String(e?.displayTime / 60).split(".")[0] *
                                      60,
                                }}
                                options={timeOptions}
                                isDisabled
                                placeholder="0"
                                classNamePrefix="select2-selection "
                              />
                            </FormGroup>
                          </Col>
                          <Col md={2}>
                            <p className="c_p_title2">Actual (Hours)</p>
                            <Input
                              type="number"
                              className="d_pkg_all_inp"
                              disabled
                              value={String(e?.actualTime / 60).split(".")[0]}
                              placeholder="Enter Actual Hours"
                            />
                          </Col>
                          <Col md={2}>
                            <p className="c_p_title2">Minutes</p>
                            <FormGroup
                              className="select2-container  "
                              style={{ width: "80%" }}
                            >
                              <Select
                                className="d_pkg_all_inp"
                                isDisabled
                                value={{
                                  value:
                                    e?.actualTime -
                                    String(e?.actualTime / 60).split(".")[0] *
                                      60,
                                  label:
                                    e?.actualTime -
                                    String(e?.actualTime / 60).split(".")[0] *
                                      60,
                                }}
                                options={timeOptions}
                                placeholder="0"
                                classNamePrefix="select2-selection "
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                    );
                  })}

                  <Row className="mt-3">
                    <Col md="6">
                      <p className="c_p_title2">Validity</p>
                      <FormGroup className="select2-container  ">
                        <Select
                          className="d_pkg_all_inp"
                          value={{
                            label: packageDataa?.validity,
                            value: packageDataa?.validity,
                          }}
                          isDisabled
                          options={PackageValidityOption}
                          placeholder="Select Validity"
                          classNamePrefix="select2-selection "
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <p className="c_p_title2">Location </p>
                      <FormGroup className="select2-container  ">
                        <Select
                          isMulti={true}
                          className="d_pkg_all_inp"
                          isDisabled
                          value={
                            packageDataa?.businesses
                              ? packageDataa?.businesses?.map((e) => ({
                                  label: e?.Name,
                                  value: e?.id,
                                }))
                              : ""
                          }
                          options={locationsData}
                          placeholder="Select Location"
                          classNamePrefix="select2-selection "
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md={6}>
                      <p className="c_p_title2">Inventory</p>
                      <Input
                        className="d_pkg_all_inp"
                        disabled
                        defaultValue={packageDataa?.inventory}
                        type="number"
                        placeholder="Enter Inventory"
                      />
                    </Col>
                    <Col md={6}>
                      <p className="c_p_title2">Priority</p>
                      <Input
                        disabled
                        className="d_pkg_all_inp"
                        defaultValue={packageDataa?.priority}
                        type="number"
                        placeholder="Enter Priority"
                      />
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col md={1}>
                      <p className="c_p_title2 mt-2">Tag</p>
                    </Col>
                    <Col md={2}>
                      <Switch
                        checked={packageDataa?.showTag}
                        onChange={() => {
                          "";
                        }}
                        disabled
                        name="always"
                        onHandleColor="#16b185"
                        width={70}
                        uncheckedIcon={Offsymbol(<small>NO</small>)}
                        checkedIcon={OnSymbol(<small>YES</small>)}
                        onColor="#a2a2a2"
                        className="mr-1 mt-1  "
                      />
                    </Col>
                    <Col md={3}></Col>
                    <Col md={6}>
                      <p className="c_p_title2  mt-2">Customers</p>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col md={6}>
                      <Input
                        className="d_pkg_all_inp"
                        disabled
                        defaultValue={
                          packageDataa?.tags === undefined
                            ? ""
                            : packageDataa?.tags
                        }
                        placeholder="Enter Tag"
                      />
                    </Col>
                    <Col md={6}>
                      <FormGroup className="select2-container  ">
                        <Select
                          isMulti={true}
                          className="d_pkg_all_inp"
                          isDisabled
                          value={customersDataa}
                          options={packagesCustomerData}
                          placeholder="All Customers"
                          classNamePrefix="select2-selection "
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row className="mt-4">
                    <Col md={3}>
                      <p className="c_p_title2">Refundable Hours</p>
                    </Col>
                    <Col md={3}>
                      <p className="c_p_title2">Refundable Minutes</p>
                    </Col>
                    <Col md={6}>
                      <p className="c_p_title2">Category</p>
                    </Col>
                  </Row>
                  <Row className="">
                    <Col md={3}>
                      <Input
                        className="d_pkg_all_inp"
                        disabled
                        placeholder="i.e 10 hours"
                        defaultValue={Number(
                          String(packageDataa?.refundAbleMinutes / 60).split(
                            "."
                          )[0]
                        )}
                        type="number"
                      />
                    </Col>
                    <Col md={3}>
                      <Input
                        className="d_pkg_all_inp"
                        disabled
                        placeholder="i.e 30 minutes"
                        defaultValue={Number(
                          Number(packageDataa?.refundAbleMinutes) -
                            Number(
                              String(
                                packageDataa?.refundAbleMinutes / 60
                              ).split(".")[0] * 60
                            )
                        )}
                      />
                    </Col>

                    <Col md={6}>
                      <Input
                        className="d_pkg_all_inp"
                        disabled
                        value={packageDataa?.type ? packageDataa?.type : ""}
                      />
                      {console.log("packageDataa?.type", packageDataa?.type)}
                    </Col>
                  </Row>

                  <Row className="">
                    <Col md={6}>
                      <p className="c_p_light_text">
                        If user’s usage is less than or equal to the refundable
                        hours, User will be refunded accordingly
                      </p>
                    </Col>
                    <Col md={6}></Col>
                  </Row>

                  <Row className="mt-5">
                    <Col md={11}>
                      <p className="c_p_title">Package Image</p>
                    </Col>
                    <Col md={1}></Col>
                  </Row>

                  <Row className="mt-2">
                    <Col md={12}>
                      {/* *********start******* */}
                      <div>
                        {selectedFiles === "" ||
                        packageDataa?.profileImage !== "" ? (
                          <Form
                            className="dropzone"
                            style={{ overflowX: "auto", maxHeight: "30px" }}
                          >
                            <Dropzone>
                              {({ getRootProps, getInputProps }) => (
                                <img
                                  key="dwejf9"
                                  alt={packageDataa?.actualImage}
                                  src={packageDataa?.actualImage}
                                />
                              )}
                            </Dropzone>
                          </Form>
                        ) : (
                          <Form
                            className="dropzone"
                            style={{ overflowX: "auto", maxHeight: "30px" }}
                          >
                            <Dropzone>
                              {() => (
                                <img
                                  key="dwejf9"
                                  alt={selectedFiles}
                                  // src={f.preview}  f is not defined
                                />
                              )}
                            </Dropzone>
                          </Form>
                        )}
                      </div>

                      {/* *********End*********** */}
                    </Col>
                  </Row>

                  {/* ********Crop start******* */}
                  <Row className="mt-4">
                    <Col md={3}>
                      <p className="c_p_title"> Package Card View</p>
                    </Col>

                    <Col md={6}>
                      <p className="c_p_title">Package Detail View</p>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row className=" mt-1 mb-5">
                    <Col md={3}>
                      <Dropzone>
                        {() => (
                          <img
                            src={imageOneArea}
                            height={173}
                            width={173}
                            style={{ float: "center" }}
                          />
                        )}
                      </Dropzone>
                    </Col>
                    <Col md={6}>
                      <Form style={{ overflowX: "auto", maxHeight: "173px" }}>
                        <img src={imageTwoArea} width={490} height={173} />
                      </Form>
                    </Col>
                  </Row>

                  {/* **********crop end********** */}
                </Card>
              ) : (
                ""
              )}
              <div>
                <Modal
                  isOpen={modal_static5}
                  toggle={tog_static5}
                  centered={true}
                >
                  <ModalBody>
                    <div style={{ textAlign: "center" }}>
                      <p className="modeltitlecss mt-3">Delete Package</p>
                    </div>

                    <hr />
                    <div style={{ textAlign: "center" }}>
                      <p className="mt-4 mb-4">
                        Are you sure you want to delete this package??
                      </p>
                    </div>

                    <hr />
                    <Row>
                      <Col md={2}></Col>

                      <Col md={4}>
                        <Button
                          color="success"
                          outline
                          className="waves-effect waves-light w-100"
                          onClick={() => setModal_static5(false)}
                        >
                          No
                        </Button>
                      </Col>

                      <Col md={4}>
                        <Button
                          color="success"
                          className="waves-effect waves-light w-100"
                          onClick={delPkgFunc}
                        >
                          Delete
                        </Button>
                      </Col>
                      <Col md={2}></Col>
                    </Row>
                  </ModalBody>
                </Modal>
              </div>
            </>
          )}
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default DetailedPackage;
