// ***********Get All DiscountAmounts  ************

import { flatMap } from "lodash";
import moment from "moment";
import { DIOM_BASED_URLS } from "../config/url";

export const getDiscountAmounts = async (
  size,
  page,
  token = "",
  history,
  activeTabState
) => {
  const res = await fetch(
    //  `${DIOM_BASED_URLS}/discount-codes?size=${size}&page=${page}`,
    `${DIOM_BASED_URLS}/discount-codes?size=${size}&page=${page}&filter={"where":{"Active":${activeTabState}}}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();

  const DiscountAmounts = resJson.data.map((element, index) => ({
    id: element.id,
    code: element?.Code,
    amount:
      element?.DiscountAmount === null
        ? element?.DiscountPercentage
        : element?.DiscountPercentage === null
        ? element?.DiscountAmount
        : "",
    type:
      element?.DiscountAmount === null
        ? "Percentage"
        : element?.DiscountPercentage === null
        ? "Amount"
        : "",
    timesUsed: element?.timesUsed,
    lastUsed: element?.lastTimeUsed
      ? moment(element?.lastTimeUsed).format("DD-MM-YYYY")
      : "",
    DiscountPercentage: element?.DiscountPercentage,
    expires:
      moment(element?.ValidTo).isAfter(new Date()) || element?.ValidTo === null
        ? "No"
        : "Yes",

    cumulativeSpentTimeInMinutes: element?.cumulativeSpentTimeInMinutes
      ? element?.cumulativeSpentTimeInMinutes !== 0
        ? `${Math.floor(element?.cumulativeSpentTimeInMinutes / 60)} hours`
        : 0
      : 0,
  }));

  return {
    data: DiscountAmounts.length > 0 ? DiscountAmounts : [],
    total: resJson ? resJson.total : 0,
    hasNextPage: resJson.hasNextPage ?? false,
    hasPreviousPage: resJson.hasPreviousPage ?? false,
  };
};

// **************get one Discount **************

export const getDiscountAmount = async (token = "", history, discountId) => {
  const res = await fetch(
    //  `${DIOM_BASED_URLS}/discount-codes?size=${size}&page=${page}`,
    `${DIOM_BASED_URLS}/discount-codes/${discountId}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const DiscountAamount = await res.json();
  //   const DiscountAamount = resJson.data.map((element, index) => ({
  //     id:element.id,
  // code: element?.Code,
  // amount:element?.DiscountAmount,
  // type:element?.DiscountAmount === null?"Percentage":element?.DiscountPercentage === null?"Amount":"",
  // timesUsed:element?.timesUsed,
  // lastUsed:element?.lastTimeUsed,
  //   })

  // );

  return {
    data: DiscountAamount,
  };
};

// ***********Get All DiscountLogs  ************

export const getDiscountLogs = async (
  size,
  page,
  token = "",
  history,
  filter,
  discountLogFilter
) => {
  const logname = [discountLogFilter?.logName].flat(2);
  const logcode = [discountLogFilter?.logValue].flat(2);
  const res = await fetch(
    filter === false
      ? `${DIOM_BASED_URLS}//discount-code-logs?size=${size}&page=${page}`
      : `${DIOM_BASED_URLS}//discount-code-logs?size=${size}&page=${page}&filter={"where":{"userId":{"inq":${JSON.stringify(
          logname
        )}},"discountCode":{"inq":${JSON.stringify(logcode)}}}}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();
  const DiscountLogs = resJson.data.map((element, index) => ({
    timesUsed: element?.timesUsed,
    bookingId: element?.bookingId,
    discountAmount: element?.discountAmount,
    userName: element?.userName,
    discountCode: element?.discountCode,
  }));

  return {
    data: DiscountLogs.length > 0 ? DiscountLogs : [],
    total: resJson ? resJson.total : 0,
    hasNextPage: resJson.hasNextPage ?? false,
    hasPreviousPage: resJson.hasPreviousPage ?? false,
  };
};

export const getDiscountLogsDropsCode = async (
  size,
  page,
  token = "",
  history
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/discount-code-logs?size=${size}&page=${page}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();
  const DiscountLogsDropsCode = resJson.data.map((element, index) => ({
    label: element?.discountCode,
    value: element?.discountCode,
  }));

  return [
    {
      options: [
        {
          value: DiscountLogsDropsCode.map((e) => e?.value),
          label: "All",
        },
        ...DiscountLogsDropsCode,
      ],
      // data: DiscountLogsDropsName.length > 0 ? DiscountLogsDropsName : [],
    },
  ];
};

export const getDiscountLogsDropsName = async (
  size,
  page,
  token = "",
  history
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}//discount-code-logs?size=${size}&page=${page}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();
  const DiscountLogsDropsName = resJson.data.map((element, index) => ({
    label: element?.userName,
    value: element?.userId,
  }));

  return [
    {
      options: [
        {
          value: DiscountLogsDropsName.map((e) => e?.value),
          label: "All",
        },
        ...DiscountLogsDropsName,
      ],
      // data: DiscountLogsDropsName.length > 0 ? DiscountLogsDropsName : [],
    },
  ];
};
