import React,{useState} from 'react'
import {
    Row,
    Col,
    CardBody,
    Card,
    Button,
    ButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import { Link } from "react-router-dom";
// import UseUserDel from './useUserDel';
import { UseUserDel } from './useUserDel';
import "../../Tables/datatables.scss"

const { SearchBar } = Search;
const tempdata=[{
    "id" : 1,
    "name" : "dawood",
    "requestTime":"28 August,2021",
    "recentCheckedin":"3038093",
    "contact":"23:23",
    "timeRemaining":"33:33"

}]

const UserDel = () => {
   
    const {
      deletionData,
      pageOptions,
  isLoading,
  hasNextPage,
  hasPreviousPage,
  total,
  pagelengthnum,
  pageSize,
  toggle,
  isOpen,
  changeCurrentPage,
  currentPage 
    }=UseUserDel();
    const Loader = require("react-loader");



const columns = [
//   {
//     dataField: "id",
//     text: "No.",
//     sort: true,
//   },
 
  {
    dataField: "createdAt",
    text: "Request Time",
    sort: true,
  },
  {
    dataField: "userName",
    text: "Name",
    sort: true,
  },
  {
    dataField: "recentCheckin",
    text: "Recent Checkin",
    sort: true,
  },
  
  {
    dataField: "contact",
    text: "Contact",
    sort: true,
  },
  {
    dataField: "timeRemaining",
    text: "Time remaining",
    sort: true,
  },
];

const defaultSorted = [
  {
    dataField: "id",
    order: "asc",
  },
];



  return (
    <>
      {isLoading ? (
        <Loader loaded={false} className="spinner" />
      ) : (
 
    <div className='page-content'>

            <Row>
              <Col md={4}>
              <Link to="/user_profile" className="link">
                  {" "}
                  <span className="fas fa-angle-left arrowheightwidth"></span>
                </Link>
                <span className="profiletitle">Delete Requests</span>
              </Col>
              <Col md={6}></Col>
              <Col md={2} className="">
            </Col>
            </Row>
         
          
            <Row  className="tablebgcolor mt-3">
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={deletionData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={deletionData}
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="4"></Col>
                                <Col md="4">
                                  
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      hover
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-3">
                                <Col className="inner-custom-pagination d-flex">
                                  <span className="paginationitmes ">
                                    Items
                                  </span>
                                  <div className="d-inline">
                                    {/* <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    /> */}
                                    <ButtonDropdown
                                      isOpen={isOpen}
                                      toggle={toggle}
                                    //   onClick={(e) => pagelengthnum(e)}
                                    >
                                      <DropdownToggle caret color="secondary">
                                        {pageSize}
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem value="1">
                                          10
                                        </DropdownItem>
                                        <DropdownItem value="2">
                                          30
                                        </DropdownItem>
                                        <DropdownItem />
                                        <DropdownItem value="3">
                                          50
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </ButtonDropdown>
                                  </div>
                                  <span className="paginationitmes1 ">
                                    show
                                  </span>
                                  {/* <div className="text-md-right ms-auto">
                                  
                                    <Button
                                      color="secondary"
                                      className="waves-effect "
                                      style={{
                                        marginLeft: 7,
                                        marginRight: 7,
                                      }}
                                      disabled={currentPage <= 1}
                                      onClick={() =>
                                        currentPage === 1
                                          ? null
                                          : changeCurrentPage(
                                              (prev) => prev - 1
                                            )
                                      }
                                    >
                                      <i className="dripicons-chevron-left"></i>
                                    </Button>

                                    <Button
                                      style={{
                                        marginLeft: 7,
                                        marginRight: 7,
                                      }}
                                      color="success"
                                      className="btn-rounded waves-effect waves-light me-1 mr-2 ml-2"
                                    >
                                      {currentPage}
                                    </Button>
                                    <Button
                                      style={{
                                        marginLeft: 7,
                                        marginRight: 7,
                                      }}
                                      color="secondary"
                                      className="waves-effect"
                                      disabled={!hasNextPage}
                                      onClick={() =>
                                        changeCurrentPage((prev) => prev + 1)
                                      }
                                    >
                                      <i className="dripicons-chevron-right"></i>
                                    </Button>
                                  </div> */}
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
        


    </div>
       
  )
  }
  </>
  )
}

export default UserDel

