import { DIOM_BASED_URLS } from "../config/url";
import moment from "moment";

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of Booking Invoices in DIOM
 */

export const getInvoices = async (
  size = 30,
  page = 1,
  token = "",
  applyFilter = false,
  history
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/invoices?size=${size}&page=${page}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();

  const Invoiceslistingdata = resJson.data.map((element, index) => ({
    ...element,
    id: index + 1,
    invoiceId: element.id,
    bookingId: element.bookingId,
    totalPaid: element.total,
    paymentMethod: element.paymentMethod,
    createdAt: moment(element?.createdAt).format("YYYY-MM-DD "),
    customerData: element.customerName,
  }));

  return {
    data: Invoiceslistingdata,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};

export const getInvoice = async (
  size = 30,
  page = 1,
  token = "",
  id,
  history
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/invoices/${id}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();

  const InvoiceData = resJson.data;

  return {
    data: InvoiceData,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of Package Invoices in DIOM
 */

export const getPackageInvoices = async (
  size = 30,
  page = 1,
  token = "",
  applyFilter = false,
  history
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/user-package-invoices?size=${size}&page=${page}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();

  const InvoicesPackageData = resJson.data.map((element, index) => ({
    ...element,
    id: index + 1,
    name: element.packageName,
    invoiceId: element.id,
    status: element.invoiceNexudusId,
    totalPaid: element.total,
    paymentMethod: element.paymentBrand,
    createdAt: moment(element?.createdAt).format("YYYY-MM-DD "),
  }));
  return {
    data: InvoicesPackageData,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};

export const getPackageInvoice = async (
  size = 30,
  page = 1,
  token = "",
  id,
  history
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/user-package-invoices/${id}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();

  const InvoiceData = resJson.data;

  return {
    data: InvoiceData,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};

// ********Erp Api ********

export const getErpApi = async (token = "", id, history) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/erp-invoice/${id}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();

  const ErpData = resJson.data;

  return {
    data: ErpData,
  };
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of Team Invoices in DIOM
 */

export const getTeamInvoices = async (
  size = 30,
  page = 1,
  token = "",
  applyFilter = false,
  history
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/user-invoices?filter={"where":{"for":{"inq":["TEAM_BOOKING","TEAM_PACKAGE"]}}}&size=${size}&page=${page}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();

  const Invoiceslistingdata = resJson.data.map((element, index) => ({
    ...element,
    id: index + 1,
    invoiceId: element.id,
    totalPaid: element.total,
    subtotal: element.subtotal,
    userId: element.userId,
    userName: element.userName,
    createdAt: moment(element?.createdAt).format("YYYY-MM-DD "),
  }));

  return {
    data: Invoiceslistingdata,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of Membership
 *  Invoices in DIOM
 */

export const getMembershipInvoices = async (
  size = 30,
  page = 1,
  token = "",
  applyFilter = false,
  history
) => {
  const res = await fetch(
    `${DIOM_BASED_URLS}/user-invoices?filter={"where":{"for":{"inq":["MEMBERSHIP","TEAM_MEMBERSHIP"]}}}&size=${size}&page=${page}`,

    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();

  const Invoiceslistingdata = resJson.data.map((element, index) => ({
    ...element,
    id: index + 1,
    invoiceId: element.id,
    totalPaid: element.total,
    subtotal: element.subtotal,
    userId: element.userId,
    userName: element.userName,
    createdAt: moment(element?.createdAt).format("YYYY-MM-DD "),
  }));

  return {
    data: Invoiceslistingdata,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a single Team Invoices in DIOM
 */

export const getTeamInvoice = async (id, token = "", history) => {
  if (id) {
    const res = await fetch(
      `${DIOM_BASED_URLS}/user-invoice/${id}`,

      {
        method: "GET",
        redirect: "follow",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    if (!res.ok) {
      if (res.status === 401) {
        history.push("/login");
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();

    const InvoiceData = resJson.data;

    return {
      data: InvoiceData,
    };
  }
};
