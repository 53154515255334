import React, { useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { getLocationsEsxceptions } from "../../../../../APIS/exceptions";
import {
  getExceptionsResource,
  getLocationsOperationalHours,
  getoperationHoursBrand,
  getResourcetypeOperationalHours,
} from "../../../../../APIS/operationalHours";
import { getreportLocationBrand } from "../../../../../APIS/reports";
import { getResourceResourceType } from "../../../../../APIS/resourceType";
import { DIOM_BASED_URLS } from "../../../../../config/url";
import { useAllDataQuery } from "../../../../../hooks/query";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import moment from "moment";

export const UseAddException = () => {
  const timeOptions = [
    { value: 0, label: "12:00 A.M" },
    { value: 30, label: "12:30 A.M" },
    { value: 60, label: "01:00 A.M" },
    { value: 90, label: "01:30 A.M" },
    { value: 120, label: "02:00 A.M" },
    { value: 150, label: "02:30 A.M" },
    { value: 180, label: "03:00 A.M" },
    { value: 210, label: "03:30 A.M" },
    { value: 240, label: "04:00 A.M" },
    { value: 270, label: "04:30 A.M" },
    { value: 300, label: "05:00 A.M" },
    { value: 330, label: "05:30 A.M" },
    { value: 360, label: "06:00 A.M" },
    { value: 390, label: "06:30 A.M" },
    { value: 420, label: "07:00 A.M" },
    { value: 450, label: "07:30 A.M" },
    { value: 480, label: "08:00 A.M" },
    { value: 510, label: "08:30 A.M" },
    { value: 540, label: "09:00 A.M" },
    { value: 570, label: "09:30 A.M" },
    { value: 600, label: "10:00 A.M" },
    { value: 630, label: "10:30 A.M" },
    { value: 660, label: "11:00 A.M" },
    { value: 690, label: "11:30 A.M" },
    { value: 720, label: "12:00 P.M" },
    { value: 750, label: "12:30 P.M" },
    { value: 780, label: "01:00 P.M" },
    { value: 810, label: "01:30 P.M" },
    { value: 840, label: "02:00 P.M" },
    { value: 870, label: "02:30 P.M" },
    { value: 900, label: "03:00 P.M" },
    { value: 930, label: "03:30 P.M" },
    { value: 960, label: "04:00 P.M" },
    { value: 990, label: "04:30 P.M" },
    { value: 1020, label: "05:00 P.M" },
    { value: 1050, label: "05:30 P.M" },
    { value: 1080, label: "06:00 P.M" },
    { value: 1110, label: "06:30 P.M" },
    { value: 1140, label: "07:00 P.M" },
    { value: 1170, label: "07:30 P.M" },
    { value: 1200, label: "08:00 P.M" },
    { value: 1230, label: "08:30 P.M" },
    { value: 1260, label: "09:00 P.M" },
    { value: 1290, label: "09:30 P.M" },
    { value: 1320, label: "10:00 P.M" },
    { value: 1350, label: "10:30 P.M" },
    { value: 1380, label: "11:00 P.M" },
    { value: 1410, label: "11:30 P.M" },
    { value: 1425, label: "11:45 P.M" },
  ];

  const queryClient = useQueryClient();
  const exceptionSelectInputRefLocations = useRef("");
  const exceptionSelectInputRefResourceType = useRef("");
  const exceptionSelectInputRefResources = useRef("");
  // const {exceptionSelectInputRefResources,exceptionSelectInputRefResourceType,exceptionSelectInputRefLocations} = useRef("");
  const [locationApplyFilter, setLocationApplyFilter] = useState(false);
  const [brandValues, setBrandValues] = useState("");
  const [categoryDropDown, setCategoryDropDown] = useState([]);
  const [locationValues, setLocationValues] = useState([]);
  const [resourcetypeApplyFilter, setResourcetypeApplyFilter] = useState(false);
  const [activeOnWholeday, setActiveOnWholeday] = useState(true);
  const [daysActiveStutus, setDaysActiveStutus] = useState([{}]);
  const [resourceTypesValues, setResourceTypesValues] = useState([]);
  const [resourceValues, setResourceValues] = useState("");
  const [modal_static1, setModal_static1] = useState(false);
  const [operatioHourShowORHide, setOperatioHourShowORHide] = useState(true);
  const [brandObj, setBrandObj] = useState([]);
  const [locationsObj, setlocationsObj] = useState([]);
  const [resourceTypesObj, setResourceTypesObj] = useState([]);
  const [resourceObj, setResourceObj] = useState([]);

  const [activInactivestate, setActivInactivestate] = useState({
    sunday: true,
    monday: true,
    tuesday: true,
    wednessday: true,
    thursday: true,
    friday: true,
    saturday: true,
  });

  const token = localStorage.getItem("Token");
  const [dayTimeValue, setDayTimeValue] = useState({});
  const [name, setName] = useState("");

  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);

  const [always, setAlways] = useState(false);
  const [type, setType] = useState("");
  const [isAlwaysDisable, setIsAlwaysDisable] = useState(false);

  function Offsymbolhours(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  function OnSymbolhours(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }
  function Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  function OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  const exceptionNameFun = (e) => {
    setName(e.target.value);
  };

  const fromTimeFun = (e) => {
    const from = moment(e[0]).format("YYYY-MM-DD");
    setFromTime(from);
  };

  const toTimeFun = (e) => {
    const to = moment(e[0]).format("YYYY-MM-DD");
    if (to > fromTime) {
      setToTime(to);
    } else {
      toast.error("'To' time should be greater than 'from' time");
    }
  };

  const alwaysFun = (e) => {
    if (e === true) {
      setFromTime("");
      setToTime("");
    }
    setAlways(e);
  };

  const typeFunc = (e) => {
    if (e?.value === "CLOSURE") {
      setIsAlwaysDisable(true);
      setAlways(false);
      setOperatioHourShowORHide(true);
    } else if (e?.value === "TIME_SETTINGS") {
      setIsAlwaysDisable(false);
      setAlways(true);
      setOperatioHourShowORHide(false);
    }
    setType(e.value);
  };

  const typeDropDown = [
    { value: "CLOSURE", label: "CLOSURE" },
    { value: "TIME_SETTINGS", label: "Time Setting" },
  ];

  const diomBrandHandler = (e) => {
    const result =
      exceptionSelectInputRefLocations?.current?.state?.value !== null
        ? exceptionSelectInputRefLocations?.current?.select?.clearValue()
        : "";
    setBrandValues([`${e.value}`]);
    setBrandObj(e);
    setLocationApplyFilter(true);
    getSelectedBrand(token, e.value);
    queryClient.invalidateQueries("locationsOperationalHours1");
  };

  const getSelectedBrand = async (token, e) => {
    const response = await fetch(
      `${DIOM_BASED_URLS}/admin-location-categories/${e}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );

    const data = await response.json();

    setTimeout(() => {
      setDayTimeValue(data);
      setDaysActiveStutus({
        sunday: !data?.sundayClosed,
        monday: !data?.mondayClosed,
        tuesday: !data?.tuesdayClosed,
        wednessday: !data?.wednesdayClosed,
        thursday: !data?.thursdayClosed,
        friday: !data?.fridayClosed,
        saturday: !data?.saturdayClosed,
      });

      setActivInactivestate({
        sunday: data?.sundayClosed,
        monday: data?.mondayClosed,
        tuesday: data?.tuesdayClosed,
        wednessday: data?.wednesdayClosed,
        thursday: data?.thursdayClosed,
        friday: data?.fridayClosed,
        saturday: data?.saturdayClosed,
      });
      // }
    }, 1000);
  };

  const diomLocationHandler = async (e) => {
    const result =
      exceptionSelectInputRefResourceType?.current?.state?.value !== null
        ? // exceptionSelectInputRefResourceType?.current?.select?.getValue?.length > 0 ??
          exceptionSelectInputRefResourceType?.current?.select?.clearValue()
        : "";
    setLocationValues([e?.value]);
    setlocationsObj(e);
    setResourcetypeApplyFilter(true);
    getSelectedLocations1(e?.value);
    queryClient.invalidateQueries("resourceTypedropdownoperationalHpours");
  };
  const getSelectedLocations1 = async (e) => {
    const lValues = [e]?.flat(2);
    // const lValues = e
    const response = await fetch(
      `${DIOM_BASED_URLS}/admin-business-locations?filter={"where":{"id":{"inq":${JSON.stringify(
        lValues
      )}}}}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const data = await response.json();
    setTimeout(() => {
      setDayTimeValue(data[0]);
      setDaysActiveStutus({
        sunday: !data[0]?.sundayClosed,
        monday: !data[0]?.mondayClosed,
        tuesday: !data[0]?.tuesdayClosed,
        wednessday: !data[0]?.wednesdayClosed,
        thursday: !data[0]?.thursdayClosed,
        friday: !data[0]?.fridayClosed,
        saturday: !data[0]?.saturdayClosed,
      });

      setActivInactivestate({
        sunday: data[0]?.sundayClosed,
        monday: data[0]?.mondayClosed,
        tuesday: data[0]?.tuesdayClosed,
        wednessday: data[0]?.wednesdayClosed,
        thursday: data[0]?.thursdayClosed,
        friday: data[0]?.fridayClosed,
        saturday: data[0]?.saturdayClosed,
      });
      // }
    }, 1000);
  };
  //
  const diomResourceTypesHandler = async (e) => {
    const result =
      exceptionSelectInputRefResourceType?.current?.state?.value !== null
        ? // exceptionSelectInputRefResources?.current?.select?.getValue?.length > 0 ??
          exceptionSelectInputRefResources?.current?.select?.clearValue()
        : "";
    setResourceTypesValues(e?.value);
    setResourceTypesObj(e);
    setResourcetypeApplyFilter(true);
    getSelectedResourceType(token, e?.value);
    queryClient.invalidateQueries("resourceResourcetypdata");
  };
  const getSelectedResourceType = async (token, e) => {
    const resourceType = [e].flat(2);
    const response = await fetch(
      `${DIOM_BASED_URLS}/admin-resource-types-inventories?filter={"where":{"id":{"inq":${JSON.stringify(
        resourceType
      )}}}}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const res1 = await response.json();
    const data1 = res1?.data;
    setTimeout(() => {
      setDayTimeValue(data1[0]);
      setDaysActiveStutus({
        sunday: !data1[0]?.sundayClosed,
        monday: !data1[0]?.mondayClosed,
        tuesday: !data1[0]?.tuesdayClosed,
        wednessday: !data1[0]?.wednesdayClosed,
        thursday: !data1[0]?.thursdayClosed,
        friday: !data1[0]?.fridayClosed,
        saturday: !data1[0]?.saturdayClosed,
      });
      // active inactave state ***
      setActivInactivestate({
        sunday: data1[0]?.sundayClosed,
        monday: data1[0]?.mondayClosed,
        tuesday: data1[0]?.tuesdayClosed,
        wednessday: data1[0]?.wednesdayClosed,
        thursday: data1[0]?.thursdayClosed,
        friday: data1[0]?.fridayClosed,
        saturday: data1[0]?.saturdayClosed,
      });
    }, 1000);
  };

  const diomResourceHandler = async (e) => {
    setResourceValues(e?.value);
    setResourceObj(e);
    getSelectedResources(e?.value);
  };

  const getSelectedResources = async (e) => {
    // const resources = [{e}].flat(2);
    const resources = Array.from([e]).flat(2);

    const response = await fetch(
      `${DIOM_BASED_URLS}/admin-resources-inventories?filter={ "where" :{ "id" : {"inq" :${JSON.stringify(
        resources
      )}}}}`,
      {
        method: "GET",
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    const res1 = await response.json();

    const data1 = res1?.data;

    setTimeout(() => {
      setDayTimeValue(data1[0]);
      setDaysActiveStutus({
        sunday: !data1[0]?.sundayClosed,
        monday: !data1[0]?.mondayClosed,
        tuesday: !data1[0]?.tuesdayClosed,
        wednessday: !data1[0]?.wednesdayClosed,
        thursday: !data1[0]?.thursdayClosed,
        friday: !data1[0]?.fridayClosed,
        saturday: !data1[0]?.saturdayClosed,
      });
      // active inactave state ***
      setActivInactivestate({
        sunday: data1[0]?.sundayClosed,
        monday: data1[0]?.mondayClosed,
        tuesday: data1[0]?.tuesdayClosed,
        wednessday: data1[0]?.wednesdayClosed,
        thursday: data1[0]?.thursdayClosed,
        friday: data1[0]?.fridayClosed,
        saturday: data1[0]?.saturdayClosed,
      });
    }, 1000);
  };

  const datePickerUntillSunday = (e) => {
    if (e.value > dayTimeValue?.sundayOpenTime) {
      setDayTimeValue({ ...dayTimeValue, sundayCloseTime: e.value });
    } else {
      toast.error("Untill time should be greater than from start time");
    }
  };
  const datePickerFromSunday = (e) => {
    setDayTimeValue({ ...dayTimeValue, sundayOpenTime: e.value });
    if (e.value > dayTimeValue?.sundayCloseTime) {
      toast.error("Start time should be less than from Untill time");
    }
  };

  const datePickerFromMonday = (e) => {
    setDayTimeValue({ ...dayTimeValue, mondayOpenTime: e.value });
    if (e.value > dayTimeValue?.mondayCloseTime) {
      toast.error("Start time should be less than from Untill time");
    }
  };
  const datePickerUntillMonday = (e) => {
    if (e.value > dayTimeValue?.mondayOpenTime) {
      setDayTimeValue({ ...dayTimeValue, mondayCloseTime: e.value });
    } else {
      toast.error("Untill time should be greater than from start time");
    }
  };

  const datePickerFromTuesday = (e) => {
    if (e.value > dayTimeValue?.tuesdayCloseTime) {
      toast.error("Start time should be less than from Untill time");
    }
    setDayTimeValue({ ...dayTimeValue, tuesdayOpenTime: e.value });
  };

  const datePickerUntillTuesday = (e) => {
    if (e.value > dayTimeValue?.tuesdayOpenTime) {
      setDayTimeValue({ ...dayTimeValue, tuesdayCloseTime: e.value });
    } else {
      toast.error("Untill time should be greater than from start time");
    }
  };
  const datePickerFromWednessday = (e) => {
    if (e.value > dayTimeValue?.wednesdayCloseTime) {
      toast.error("Start time should be less than from Untill time");
    }
    setDayTimeValue({ ...dayTimeValue, wednesdayOpenTime: e.value });
  };
  const datePickerUntillWednessday = (e) => {
    if (e.value > dayTimeValue?.wednesdayOpenTime) {
      setDayTimeValue({ ...dayTimeValue, wednesdayCloseTime: e.value });
    } else {
      toast.error("Untill time should be greater than from start time");
    }
  };

  const datePickerFromThursday = (e) => {
    if (e.value > dayTimeValue?.thursdayCloseTime) {
      toast.error("Start time should be less than from Untill time");
    }
    setDayTimeValue({ ...dayTimeValue, thursdayOpenTime: e.value });
  };
  const datePickerUntillThursday = (e) => {
    if (e.value > dayTimeValue?.thursdayOpenTime) {
      setDayTimeValue({ ...dayTimeValue, thursdayCloseTime: e.value });
    } else {
      toast.error("Untill time should be greater than from start time");
    }
  };
  const datePickerFromFriday = (e) => {
    if (e.value > dayTimeValue?.fridayCloseTime) {
      toast.error("Start time should be less than from Untill time");
    }
    setDayTimeValue({ ...dayTimeValue, fridayOpenTime: e.value });
  };

  const datePickerUntillFriday = (e) => {
    if (e.value > dayTimeValue?.fridayOpenTime) {
      setDayTimeValue({ ...dayTimeValue, fridayCloseTime: e.value });
    } else {
      toast.error("Untill time should be greater than from start time");
    }
  };

  const datePickerFromSaturday = (e) => {
    if (e.value > dayTimeValue?.saturdayCloseTime) {
      toast.error("Start time should be less than from Untill time");
    }
    setDayTimeValue({ ...dayTimeValue, saturdayOpenTime: e.value });
  };

  const datePickerUntillSaturday = (e) => {
    if (e.value > dayTimeValue?.saturdayOpenTime) {
      setDayTimeValue({ ...dayTimeValue, saturdayCloseTime: e.value });
    } else {
      toast.error("Untill time should be greater than from start time");
    }
  };

  const mondayActiveFunc = (e) => {
    if (e === true) {
      setActivInactivestate({ ...activInactivestate, monday: false });
      setDaysActiveStutus({ ...daysActiveStutus, monday: e });
    } else {
      setActivInactivestate({ ...activInactivestate, monday: true });
      setDaysActiveStutus({ ...daysActiveStutus, monday: e });
    }
  };
  const tuesdayActiveFunc = (e) => {
    if (e === true) {
      setActivInactivestate({ ...activInactivestate, tuesday: false });
      setDaysActiveStutus({ ...daysActiveStutus, tuesday: e });
    } else {
      setActivInactivestate({ ...activInactivestate, tuesday: true });
      setDaysActiveStutus({ ...daysActiveStutus, tuesday: e });
    }
  };
  const wednesdayActiveFunc = (e) => {
    if (e === true) {
      setActivInactivestate({ ...activInactivestate, wednessday: false });
      setDaysActiveStutus({ ...daysActiveStutus, wednessday: e });
    } else {
      setActivInactivestate({ ...activInactivestate, wednessday: true });
      setDaysActiveStutus({ ...daysActiveStutus, wednessday: e });
    }
  };
  const thursdayActiveFunc = (e) => {
    if (e === true) {
      setActivInactivestate({ ...activInactivestate, thursday: false });
      setDaysActiveStutus({ ...daysActiveStutus, thursday: e });
    } else {
      setActivInactivestate({ ...activInactivestate, thursday: true });
      setDaysActiveStutus({ ...daysActiveStutus, thursday: e });
    }
  };
  const fridayActiveFunc = (e) => {
    if (e === true) {
      setActivInactivestate({ ...activInactivestate, friday: false });
      setDaysActiveStutus({ ...daysActiveStutus, friday: e });
    } else {
      setActivInactivestate({ ...activInactivestate, friday: true });
      setDaysActiveStutus({ ...daysActiveStutus, friday: e });
    }
  };
  const saturdayActiveFunc = (e) => {
    if (e === true) {
      setActivInactivestate({ ...activInactivestate, saturday: false });
      setDaysActiveStutus({ ...daysActiveStutus, saturday: e });
    } else {
      setActivInactivestate({ ...activInactivestate, saturday: true });
      setDaysActiveStutus({ ...daysActiveStutus, saturday: e });
    }
  };
  const sundayActiveFunc = (e) => {
    if (e === true) {
      setActivInactivestate({ ...activInactivestate, sunday: false });
      setDaysActiveStutus({ ...daysActiveStutus, sunday: e });
    } else {
      setActivInactivestate({ ...activInactivestate, sunday: true });
      setDaysActiveStutus({ ...daysActiveStutus, sunday: e });
    }
  };

  const saturdayWholeDayFunc = (e) => {
    if (e === true) {
      setActiveOnWholeday({ ...activeOnWholeday, saturday: true });
      setDayTimeValue({ ...dayTimeValue, saturdayOpenTime: 0 });
      setDayTimeValue({ ...dayTimeValue, saturdayCloseTime: 1440 });
    } else {
      setActiveOnWholeday({ ...activeOnWholeday, saturday: false });
    }
  };

  const sundayWholeDayFunc = (e) => {
    if (e === true) {
      setActiveOnWholeday({ ...activeOnWholeday, sunday: true });
      setDayTimeValue({ ...dayTimeValue, sundayOpenTime: 0 });
      setDayTimeValue({ ...dayTimeValue, sundayCloseTime: 1440 });
    } else {
      setActiveOnWholeday({ ...activeOnWholeday, sunday: false });
    }
  };

  const mondayWholeDayFunc = (e) => {
    if (e === true) {
      setActiveOnWholeday({ ...activeOnWholeday, monday: true });
      setDayTimeValue({ ...dayTimeValue, mondayOpenTime: 0 });
      setDayTimeValue({ ...dayTimeValue, mondayCloseTime: 1440 });
    } else {
      setActiveOnWholeday({ ...activeOnWholeday, monday: false });
    }
  };
  const tuesdayWholeDayFunc = (e) => {
    if (e === true) {
      setActiveOnWholeday({ ...activeOnWholeday, tuesday: true });
      setDayTimeValue({ ...dayTimeValue, tuesdayOpenTime: 0 });
      setDayTimeValue({ ...dayTimeValue, tuesdayCloseTime: 1440 });
    } else {
      setActiveOnWholeday({ ...activeOnWholeday, tuesday: false });
    }
  };
  const wednesdayWholeDayFunc = (e) => {
    if (e === true) {
      setActiveOnWholeday({ ...activeOnWholeday, wednessday: true });
      setDayTimeValue({ ...dayTimeValue, wednessdayOpenTime: 0 });
      setDayTimeValue({ ...dayTimeValue, wednessdayCloseTime: 1440 });
    } else {
      setActiveOnWholeday({ ...activeOnWholeday, wednessday: false });
    }
  };
  const thursdayWholeDayFunc = (e) => {
    if (e === true) {
      setActiveOnWholeday({ ...activeOnWholeday, thursday: true });
      setDayTimeValue({ ...dayTimeValue, thursdayOpenTime: 0 });
      setDayTimeValue({ ...dayTimeValue, thursdayCloseTime: 1440 });
    } else {
      setActiveOnWholeday({ ...activeOnWholeday, thursday: false });
    }
  };
  const fridayWholeDayFunc = (e) => {
    if (e === true) {
      setActiveOnWholeday({ ...activeOnWholeday, friday: true });
      setDayTimeValue({ ...dayTimeValue, fridayOpenTime: 0 });
      setDayTimeValue({ ...dayTimeValue, fridayCloseTime: 1440 });
    } else {
      setActiveOnWholeday({ ...activeOnWholeday, friday: false });
    }
  };

  // **************Brands Get****************
  // *******************
  const { data: brandData } = useAllDataQuery(
    ["reportlocationbrandData", "visible"],
    () => getoperationHoursBrand(token)
  );

  // **************Locaitons Get****************
  const { data: locationsData, isLoading: loadingLocations } = useQuery(
    ["locationsOperationalHours1", brandValues],
    () => getLocationsEsxceptions(token, locationApplyFilter, brandValues)
  );
  const locationData = locationsData;

  // **************Resource Get****************
  // *******************

  const resourceTypedropdownData = useQuery(
    ["resourceTypedropdownoperationalHpours", locationValues],
    () =>
      getResourcetypeOperationalHours(
        token,
        resourcetypeApplyFilter,
        locationValues
      )
  );

  const resourceTypedropdownDailedData = resourceTypedropdownData.data;

  // *************
  const Resourcetypesdropdown = async () => {
    // Adding the option as ALL  in first Index
    const result = resourceTypedropdownDailedData.data.length > 0;
    {
      resourceTypedropdownDailedData.data.unshift({
        id: resourceTypedropdownDailedData.data.map((e) => e?.id),
        Name: "All",
        isAvailableInWnpl: true,
        startTime: "0",
        endTime: "0",
      });
    }

    const matadata = resourceTypedropdownDailedData.data.map((element) => {
      return {
        label: element.Name,
        value: element.id,
        UniqueId: element.UniqueId,
        resourceTypeCategoryId: element.resourceTypeCategoryId,
        // visibility: element.visibility,
      };
    });

    setCategoryDropDown(matadata);
  };

  // *************

  // ******resources*********
  const resourceResourcessdata = useQuery(
    ["resourceResourcetypdata", resourceTypesValues],
    () => getExceptionsResource(token, resourceTypesValues)
  );
  const resourceResourcedata = resourceResourcessdata?.data;

  const apiObject = {
    name,
    fromTime: fromTime
      ? new Date(fromTime).toISOString()
      : "2022-09-24T11:45:00.000Z",
    toTime: toTime
      ? new Date(toTime).toISOString()
      : "2022-09-24T11:45:00.000Z",
    always,
    type,
    // locationBrands: brandValues,
    // locations: [locationValues[0]]?.flat(2),
    // resourceTypes: [resourceTypesValues]?.flat(2),
    // resources: [resourceValues]?.flat(2),

    locationBrands: [{ id: brandObj?.value, name: brandObj?.label }],
    locations:
      typeof locationsObj?.value === "number"
        ? [{ id: locationsObj?.value, name: locationsObj?.label }]
        : [],
    resourceTypes:
      typeof resourceTypesObj?.value === "number"
        ? [{ id: resourceTypesObj?.value, name: resourceTypesObj?.label }]
        : [],
    resources:
      typeof resourceObj?.value === "number"
        ? [{ id: resourceObj?.value, name: resourceObj?.label }]
        : [],
    mondayOpenTime: dayTimeValue?.mondayOpenTime,
    mondayCloseTime: dayTimeValue?.mondayCloseTime,
    tuesdayOpenTime: dayTimeValue?.tuesdayOpenTime,
    tuesdayCloseTime: dayTimeValue?.tuesdayCloseTime,
    wednesdayOpenTime: dayTimeValue?.wednesdayOpenTime,
    wednesdayCloseTime: dayTimeValue?.wednesdayCloseTime,
    thursdayOpenTime: dayTimeValue?.thursdayOpenTime,
    thursdayCloseTime: dayTimeValue?.thursdayCloseTime,
    fridayOpenTime: dayTimeValue?.fridayOpenTime,
    fridayCloseTime: dayTimeValue?.fridayCloseTime,
    saturdayOpenTime: dayTimeValue?.saturdayOpenTime,
    saturdayCloseTime: dayTimeValue?.saturdayCloseTime,
    sundayOpenTime: dayTimeValue?.sundayCloseTime,
    sundayCloseTime: dayTimeValue?.sundayOpenTime,
    mondayClosed: !daysActiveStutus?.monday,
    tuesdayClosed: !daysActiveStutus?.tuesday,
    wednesdayClosed: !daysActiveStutus?.wednessday,
    thursdayClosed: !daysActiveStutus?.thursday,
    fridayClosed: !daysActiveStutus?.friday,
    saturdayClosed: !daysActiveStutus?.saturday,
    sundayClosed: !daysActiveStutus?.sunday,
  };

  // *************
  const saveExceptionData = () => {
    fetch(`${DIOM_BASED_URLS}/exceptions`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(apiObject),
    })
      .then((response) => response.json())
      .then((result3) => {
        setModal_static1(false);
        if (result3?.id) {
          toast.success("Successfully Added");

          //Todo: to make it currect
          window.location.href = "/exception_listing";
          // <Redirect to="/exceptionListing" />
        } else {
          toast.error(result3?.error?.message);
        }
      })
      .catch((error) => {
        setModal_static1(false);
        toast.error("Error: ", error);
        console.log(error);
      });
  };

  useEffect(
    // FOR PRE-FETCHING NEXT PAGE
    async () => {
      resourceTypedropdownDailedData && (await Resourcetypesdropdown());
    },
    [resourceTypedropdownDailedData]
  );

  const data2 = {
    name,
    fromTime,
    toTime,
    always,
    type,
    locationValues,
    brandValues,
    dayTimeValue,
    daysActiveStutus: daysActiveStutus,
    resourceTypesValues,
  };

  // *************
  return {
    data2,
    Offsymbol,
    OnSymbol,
    Offsymbolhours,
    OnSymbolhours,
    datePickerFromSunday,
    datePickerUntillSunday,
    datePickerFromMonday,
    datePickerUntillMonday,
    datePickerUntillSaturday,
    datePickerFromSaturday,
    datePickerUntillFriday,
    timeOptions,
    datePickerFromFriday,
    datePickerUntillThursday,
    datePickerFromThursday,
    datePickerUntillWednessday,
    datePickerFromWednessday,
    datePickerUntillTuesday,
    datePickerFromTuesday,
    diomBrandHandler,
    diomLocationHandler,
    categoryDropDown,
    dayTimeValue,
    activInactivestate,
    name,
    exceptionNameFun,
    fromTimeFun,
    fromTime,
    toTimeFun,
    toTime,
    alwaysFun,
    always,
    typeFunc,
    type,
    typeDropDown,
    saveExceptionData,
    brandData,
    locationData,
    daysActiveStutus,
    activeOnWholeday,
    saturdayWholeDayFunc,
    sundayWholeDayFunc,
    mondayWholeDayFunc,
    tuesdayWholeDayFunc,
    wednesdayWholeDayFunc,
    thursdayWholeDayFunc,
    fridayWholeDayFunc,
    diomResourceTypesHandler,
    sundayActiveFunc,
    saturdayActiveFunc,
    fridayActiveFunc,
    thursdayActiveFunc,
    wednesdayActiveFunc,
    tuesdayActiveFunc,
    mondayActiveFunc,
    resourceResourcedata,
    diomResourceHandler,
    isAlwaysDisable,
    modal_static1,
    setModal_static1,
    operatioHourShowORHide,
    exceptionSelectInputRefResources,
    exceptionSelectInputRefResourceType,
    exceptionSelectInputRefLocations,
  };
};
