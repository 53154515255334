import React, { useRef, useState } from 'react';
import '../css/styles.css';

const CopyLink = ({baseUrl , bookingId }) => {
  const [inputValue, setInputValue] = useState(`${baseUrl}/user-booking-details/${bookingId}`);
  const inputRef = useRef(null);

  const copyToClipboard = () => {
    const originalValue = inputValue;
    inputRef.current.select();
    document.execCommand('copy');
    setInputValue('Copied!');
    setTimeout(() => setInputValue(originalValue), 2000);
  };

  return (
    <div className="copy-link">
      <input
        type="text"
        className="copy-link-input"
        value={inputValue}
        readOnly
        ref={inputRef}
        onFocus={() => inputRef.current.select()}
      />
      <button type="button" className="copy-link-button" onClick={copyToClipboard}>
        <span className=" ri ri-file-copy-2-line"/>
      </button>
    </div>
  );
};

export default CopyLink;
