import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  FormGroup,
  Input,
  Row,
  Modal,
  ModalBody,
} from "reactstrap";
import Switch from "react-switch";
import { UseOperationalHours } from "./useOperationalHours";
import "./css/operationalHours.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import UserPromissionFetcher from "../../../helpers/getUserPerimissions";
import Spinner from "../../../components/Common/Loader";
import NoAccess from "../../../components/Common/NoAccess";

const OperationalHours = () => {
  const userPermissions = UserPromissionFetcher("operationalHours");
  const {
    operationalHourHidden,
    setResetBtn,
    resetBtn,
    revokeReasonText,
    setModal_static3,
    modal_static3,
    tog_static3,
    setModal_static4,
    modal_static4,
    tog_static4,
    modal_static1,
    setModal_static1,
    tog_static1,
    activeOnWholeday,
    saturdayWholeDayFunc,
    sundayWholeDayFunc,
    mondayWholeDayFunc,
    tuesdayWholeDayFunc,
    wednessdayWholeDayFunc,
    thursdayWholeDayFunc,
    fridayWholeDayFunc,
    saturdayActiveFunc,
    fridayActiveFunc,
    thursdayActiveFunc,
    wednessdayActiveFunc,
    tuesdayActiveFunc,
    mondayActiveFunc,
    sundayActiveFunc,
    daysActiveStutus,
    Offsymbol,
    OnSymbol,
    Offsymbolhours,
    OnSymbolhours,
    datePickerFromMonday,
    datePickerUntillMonday,
    datePickerUntillSaturday,
    datePickerFromSaturday,
    datePickerUntillFriday,
    resourceTypeHandler,
    datePickerUntillSunday,
    datePickerFromSunday,
    datePickerFromFriday,
    datePickerUntillThursday,
    datePickerFromThursday,
    datePickerUntillWednessday,
    datePickerFromWednessday,
    datePickerUntillTuesday,
    datePickerFromTuesday,
    brandData,
    timeOptions,
    locationData,
    diomBrandHandler,
    categoryDropDown,
    diomLocationHandler,
    operationHoursSaveFunc,
    activInactivestate,
    dayTimeValue,
    editDisable,
    selectInputRefResourceType,
    selectInputRefLocations,
    operationalHours,
    setOperationalHours,
    setOperationalHourHidden,
    brandValues,
    locationValues,
    resourceTypesValues,
    saveBtnDisable, 
    
  } = UseOperationalHours();


 
    const shouldShowButton =
    userPermissions?.update &&
    brandValues &&
    locationValues.length > 0 &&
    locationValues[0] &&
    resourceTypesValues?.resourceTypeId;

  const [editButtonEnabled, setEditButtonEnabled] = useState(true);
  const [cencelBtn, setCancelBtn] = useState(true);

  const EditFunc = () => {
    setOperationalHourHidden(false);
    setEditButtonEnabled(false);
    setResetBtn(true);
    setCancelBtn(false);
  };
  const saveFunc = () => {
    setResetBtn(false);
    setEditButtonEnabled(true);
    setCancelBtn(true);
    operationHoursSaveFunc();
  };
  const revokeReasonFunc = () => {
    setModal_static1(true);
  };

  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.read ? (
        <div className="page-content">
          <div>
            <Row className="mb-4">
              <Col md={4}>
                <span className="bookingtitle ml-4">Operation Hours</span>
              </Col>
              <Col md={6}></Col>
              <Col md={2}>
                <Link to="/exception_listing">
                  <Button
                    // onClick={handlePrint}
                    color="success"
                    className=" mr-1 w-100 "
                    block
                  >
                    <span className="e_a_btns ">Exceptions</span>
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
          <div>
            <Row className="mb-4">
              <Col md={12}>
                <Card className="p-4">
                  <Row>
                    <Col md={6}>
                      <p className="oh_title">
                        Select Brand, Location and Resource Type
                      </p>
                    </Col>
                    <Col md={2}></Col>
                    <Col md={2}>
                      {cencelBtn ? (
                        <div hidden={resetBtn}>
                          {shouldShowButton?.length>0 && (
                            <Button
                              color="warning"
                              outline
                              className=" mr-1 w-100 "
                              onClick={() => {
                                window.location.reload(true);
                              }}
                              block
                            >
                              <span className="e_a_btns ">Clear</span>
                            </Button>
                          )}
                        </div>
                      ) : (
                        ""
                      )}

{shouldShowButton?.length>0 &&  (    <div hidden={cencelBtn}>
                        <Button
                          color="success"
                          className=" mr-1 w-100 "
                          // onClick={()=>{window.location.reload(true)}}
                          onClick={tog_static4}
                          block
                        >
                          <span className="e_a_btns ">Cancel</span>
                        </Button>
                      </div>)}
                    </Col>
                    <Col md={2}>
                      {editButtonEnabled ? (
                        <div hidden={resetBtn}>
                          {shouldShowButton?.length>0 &&  (
                            <Button
                              // onClick={handlePrint}
                              color="success"
                              outline
                              className="mr-1 w-100 "
                              block
                              onClick={EditFunc}
                              disabled={editDisable}
                            >
                              <span className="e_a_btns ">Edit</span>
                            </Button>
                          )}
                        </div>
                      ) : shouldShowButton?.length>0? (
                        
                        <Button
                          // onClick={handlePrint}
                          color="success"
                         
                          className=" mr-1 w-100 "
                          block
                          disabled={editDisable||saveBtnDisable}
                          onClick={revokeReasonFunc}
                        >
                          <span className="e_a_btns ">Save</span>
                        </Button>
                      ):""}
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <p className="oh_subtitle">Select Brand</p>
                      <FormGroup className="select2-container  ">
                        <Select
                          options={brandData}
                          onChange={(opt) => diomBrandHandler(opt)}
                          placeholder="Brands"
                          classNamePrefix="select2-selection "
                        />
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <p className="oh_subtitle">Select Location</p>
                      <FormGroup className="select2-container  ">
                        <Select
                          ref={selectInputRefLocations}
                          options={locationData}
                          onChange={(opt) => diomLocationHandler(opt)}
                          placeholder="All Locations"
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="mt-3">
                      <p className="oh_subtitle">Select Resource Type</p>
                      <Select
                        ref={selectInputRefResourceType}
                        placeholder="All Resources Types"
                        isMulti={true}
                        options={categoryDropDown}
                        onChange={(opt) => resourceTypeHandler(opt)}
                        isSearchable={true}
                        hideSelectedOptions={true}
                      />
                    </Col>
                  </Row>

                  <div hidden={operationalHourHidden || operationalHours}>
                    <Row className="mt-5">
                      <Col md={6}>
                        <p className="oh_title ">
                          Operational hours saved settings
                        </p>
                      </Col>
                    </Row>

                    <Row className="oh_days_title">
                      <Col md={2}>
                        <p>DAYS</p>
                      </Col>
                      <Col md={2}>
                        <p>OPEN</p>
                      </Col>
                      <Col md={2}>
                        <p>24 HOURS</p>
                      </Col>
                      <Col md={2}>
                        <p>FROM</p>
                      </Col>
                      <Col md={2}>
                        <p>UNTILL</p>
                      </Col>
                    </Row>
                    <div>
                      <Row>
                        <Col md={2}>
                          <p className="oh_dayssubtitle">Sunday</p>
                        </Col>
                        <Col md={2}>
                          <Switch
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbol(<small>NO</small>)}
                            checkedIcon={OnSymbol(<small>YES</small>)}
                            onColor="#a2a2a2"
                            onChange={(e) => {
                              sundayActiveFunc(e);
                            }}
                            checked={daysActiveStutus?.sunday}
                            className="mr-1 mt-1  "
                            disabled={editButtonEnabled}
                          />
                        </Col>
                        <Col md={2}>
                          <Switch
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                            checkedIcon={OnSymbolhours(<small>YES</small>)}
                            onChange={(e) => {
                              sundayWholeDayFunc(e);
                            }}
                            checked={activeOnWholeday?.sunday}
                            onColor="#a2a2a2"
                            className="mr-1 mt-1  "
                            disabled={
                              activInactivestate?.sunday || editButtonEnabled
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Select
                            value={{
                              label: moment()
                                .startOf("day")
                                .add(dayTimeValue?.sundayOpenTime, "minutes")
                                .format("hh:mm A"),
                              value: dayTimeValue?.sundayOpenTime,
                            }}
                            options={timeOptions}
                            onChange={datePickerFromSunday}
                            isDisabled={
                              activInactivestate?.sunday ||
                              activeOnWholeday?.sunday ||
                              editButtonEnabled
                            }
                          ></Select>
                        </Col>
                        <Col md={2}>
                          <Select
                            options={timeOptions}
                            value={{
                              label: moment()
                                .startOf("day")
                                .add(dayTimeValue?.sundayCloseTime, "minutes")
                                .format("hh:mm A"),
                              value: dayTimeValue?.sundayCloseTime,
                            }}
                            onChange={datePickerUntillSunday}
                            isDisabled={
                              activInactivestate?.sunday ||
                              activeOnWholeday?.sunday ||
                              editButtonEnabled
                            }
                          ></Select>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md={2}>
                          <p className="oh_dayssubtitle">Monday</p>
                        </Col>
                        <Col md={2}>
                          <Switch
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbol(<small>NO</small>)}
                            checkedIcon={OnSymbol(<small>YES</small>)}
                            onColor="#a2a2a2"
                            onChange={(e) => {
                              mondayActiveFunc(e);
                            }}
                            checked={daysActiveStutus?.monday}
                            className="mr-1 mt-1  "
                            disabled={editButtonEnabled}
                          />
                        </Col>
                        <Col md={2}>
                          <Switch
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                            checkedIcon={OnSymbolhours(<small>YES</small>)}
                            onChange={(e) => {
                              
                              mondayWholeDayFunc(e);
                            }}
                            checked={activeOnWholeday?.monday}
                            onColor="#a2a2a2"
                            className="mr-1 mt-1  "
                            disabled={
                              activInactivestate?.monday || editButtonEnabled
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Select
                            options={timeOptions}
                            onChange={datePickerFromMonday}
                            isDisabled={
                              activInactivestate?.monday ||
                              activeOnWholeday?.monday ||
                              editButtonEnabled
                            }
                            value={{
                              label: moment()
                                .startOf("day")
                                .add(dayTimeValue?.mondayOpenTime, "minutes")
                                .format("hh:mm A"),
                              value: dayTimeValue?.mondayOpenTime,
                            }}
                          ></Select>
                        </Col>
                        <Col md={2}>
                          <Select
                            options={timeOptions}
                            onChange={datePickerUntillMonday}
                            isDisabled={
                              activInactivestate?.monday ||
                              activeOnWholeday?.monday ||
                              editButtonEnabled
                            }
                            value={{
                              label: moment()
                                .startOf("day")
                                .add(dayTimeValue?.mondayCloseTime, "minutes")
                                .format("hh:mm A"),
                              value: dayTimeValue?.mondayCloseTime,
                            }}
                          ></Select>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md={2}>
                          <p className="oh_dayssubtitle">Tuesday</p>
                        </Col>
                        <Col md={2}>
                          <Switch
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbol(<small>NO</small>)}
                            checkedIcon={OnSymbol(<small>YES</small>)}
                            onChange={(e) => {
                              tuesdayActiveFunc(e);
                            }}
                            checked={daysActiveStutus?.tuesday}
                            onColor="#a2a2a2"
                            className="mr-1 mt-1  "
                            disabled={editButtonEnabled}
                          />
                        </Col>
                        <Col md={2}>
                          <Switch
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                            checkedIcon={OnSymbolhours(<small>YES</small>)}
                            onColor="#a2a2a2"
                            onChange={(e) => {
                              tuesdayWholeDayFunc(e);
                            }}
                            checked={activeOnWholeday?.tuesday}
                            className="mr-1 mt-1  "
                            disabled={
                              activInactivestate?.tuesday || editButtonEnabled
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Select
                            options={timeOptions}
                            onChange={datePickerFromTuesday}
                            isDisabled={
                              activInactivestate?.tuesday ||
                              activeOnWholeday?.tuesday ||
                              editButtonEnabled
                            }
                            value={{
                              label: moment()
                                .startOf("day")
                                .add(dayTimeValue?.tuesdayOpenTime, "minutes")
                                .format("hh:mm A"),
                              value: dayTimeValue?.tuesdayOpenTime,
                            }}
                          ></Select>
                        </Col>
                        <Col md={2}>
                          <Select
                            options={timeOptions}
                            onChange={datePickerUntillTuesday}
                            isDisabled={
                              activInactivestate?.tuesday ||
                              activeOnWholeday?.tuesday ||
                              editButtonEnabled
                            }
                            value={{
                              label: moment()
                                .startOf("day")
                                .add(dayTimeValue?.tuesdayCloseTime, "minutes")
                                .format("hh:mm A"),
                              value: dayTimeValue?.tuesdayCloseTime,
                            }}
                          ></Select>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md={2}>
                          <p className="oh_dayssubtitle">Wednessday</p>
                        </Col>
                        <Col md={2}>
                          <Switch
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbol(<small>NO</small>)}
                            checkedIcon={OnSymbol(<small>YES</small>)}
                            onChange={(e) => {
                              wednessdayActiveFunc(e);
                            }}
                            checked={daysActiveStutus?.wednessday}
                            onColor="#a2a2a2"
                            className="mr-1 mt-1  "
                            disabled={editButtonEnabled}
                          />
                        </Col>
                        <Col md={2}>
                          <Switch
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                            checkedIcon={OnSymbolhours(<small>YES</small>)}
                            onChange={(e) => {
                              wednessdayWholeDayFunc(e);
                            }}
                            checked={activeOnWholeday?.wednessday}
                            onColor="#a2a2a2"
                            className="mr-1 mt-1  "
                            disabled={
                              activInactivestate?.wednessday ||
                              editButtonEnabled
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Select
                            options={timeOptions}
                            onChange={datePickerFromWednessday}
                            isDisabled={
                              activInactivestate?.wednessday ||
                              activeOnWholeday?.wednessday ||
                              editButtonEnabled
                            }
                            value={{
                              label: moment()
                                .startOf("day")
                                .add(dayTimeValue?.wednesdayOpenTime, "minutes")
                                .format("hh:mm A"),
                              value: dayTimeValue?.wednesdayOpenTime,
                            }}
                          ></Select>
                        </Col>
                        <Col md={2}>
                          <Select
                            options={timeOptions}
                            onChange={datePickerUntillWednessday}
                            isDisabled={
                              activInactivestate?.wednessday ||
                              activeOnWholeday?.wednessday ||
                              editButtonEnabled
                            }
                            value={{
                              label: moment()
                                .startOf("day")
                                .add(
                                  dayTimeValue?.wednesdayCloseTime,
                                  "minutes"
                                )
                                .format("hh:mm A"),
                              value: dayTimeValue?.wednesdayCloseTime,
                            }}
                          ></Select>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md={2}>
                          <p className="oh_dayssubtitle">Thursday</p>
                        </Col>
                        <Col md={2}>
                          <Switch
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbol(<small>NO</small>)}
                            checkedIcon={OnSymbol(<small>YES</small>)}
                            onChange={(e) => {
                              thursdayActiveFunc(e);
                            }}
                            checked={daysActiveStutus?.thursday}
                            onColor="#a2a2a2"
                            className="mr-1 mt-1  "
                            disabled={editButtonEnabled}
                          />
                        </Col>
                        <Col md={2}>
                          <Switch
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                            checkedIcon={OnSymbolhours(<small>YES</small>)}
                            onChange={(e) => {
                              thursdayWholeDayFunc(e);
                            }}
                            checked={activeOnWholeday?.thursday}
                            onColor="#a2a2a2"
                            className="mr-1 mt-1  "
                            disabled={
                              activInactivestate?.thursday || editButtonEnabled
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Select
                            options={timeOptions}
                            onChange={datePickerFromThursday}
                            isDisabled={
                              activInactivestate?.thursday ||
                              activeOnWholeday?.thursday ||
                              editButtonEnabled
                            }
                            value={{
                              label: moment()
                                .startOf("day")
                                .add(dayTimeValue?.thursdayOpenTime, "minutes")
                                .format("hh:mm A"),
                              value: dayTimeValue?.thursdayOpenTime,
                            }}
                          ></Select>
                        </Col>
                        <Col md={2}>
                          <Select
                            options={timeOptions}
                            onChange={datePickerUntillThursday}
                            isDisabled={
                              activInactivestate?.thursday ||
                              activeOnWholeday?.thursday ||
                              editButtonEnabled
                            }
                            value={{
                              label: moment()
                                .startOf("day")
                                .add(dayTimeValue?.thursdayCloseTime, "minutes")
                                .format("hh:mm A"),
                              value: dayTimeValue?.thursdayCloseTime,
                            }}
                          ></Select>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md={2}>
                          <p className="oh_dayssubtitle">Friday</p>
                        </Col>
                        <Col md={2}>
                          <Switch
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbol(<small>NO</small>)}
                            checkedIcon={OnSymbol(<small>YES</small>)}
                            onChange={(e) => {
                              fridayActiveFunc(e);
                            }}
                            checked={daysActiveStutus?.friday}
                            onColor="#a2a2a2"
                            className="mr-1 mt-1  "
                            disabled={editButtonEnabled}
                          />
                        </Col>
                        <Col md={2}>
                          <Switch
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                            checkedIcon={OnSymbolhours(<small>YES</small>)}
                            onChange={(e) => {
                              fridayWholeDayFunc(e);
                            }}
                            checked={activeOnWholeday?.friday}
                            onColor="#a2a2a2"
                            className="mr-1 mt-1  "
                            disabled={
                              activInactivestate?.friday || editButtonEnabled
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Select
                            options={timeOptions}
                            onChange={datePickerFromFriday}
                            isDisabled={
                              activInactivestate?.friday ||
                              activeOnWholeday?.friday ||
                              editButtonEnabled
                            }
                            value={{
                              label: moment()
                                .startOf("day")
                                .add(dayTimeValue?.fridayOpenTime, "minutes")
                                .format("hh:mm A"),
                              value: dayTimeValue?.fridayOpenTime,
                            }}
                          ></Select>
                        </Col>
                        <Col md={2}>
                          <Select
                            options={timeOptions}
                            onChange={datePickerUntillFriday}
                            isDisabled={
                              activInactivestate?.friday ||
                              activeOnWholeday?.friday ||
                              editButtonEnabled
                            }
                            value={{
                              label: moment()
                                .startOf("day")
                                .add(dayTimeValue?.fridayCloseTime, "minutes")
                                .format("hh:mm A"),
                              value: dayTimeValue?.fridayCloseTime,
                            }}
                          ></Select>
                        </Col>
                      </Row>
                      <hr />
                      <Row>
                        <Col md={2}>
                          <p className="oh_dayssubtitle">Saturday</p>
                        </Col>
                        <Col md={2}>
                          <Switch
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbol(<small>NO</small>)}
                            checkedIcon={OnSymbol(<small>YES</small>)}
                            onChange={(e) => {
                              saturdayActiveFunc(e);
                            }}
                            checked={daysActiveStutus?.saturday}
                            onColor="#a2a2a2"
                            className="mr-1 mt-1  "
                            disabled={editButtonEnabled}
                          />
                        </Col>
                        <Col md={2}>
                          <Switch
                            onHandleColor="#16b185"
                            width={70}
                            uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                            checkedIcon={OnSymbolhours(<small>YES</small>)}
                            onChange={(e) => {
                              saturdayWholeDayFunc(e);
                            }}
                            checked={activeOnWholeday?.saturday}
                            onColor="#a2a2a2"
                            className="mr-1 mt-1  "
                            disabled={
                              activInactivestate?.saturday || editButtonEnabled
                            }
                          />
                        </Col>
                        <Col md={2}>
                          <Select
                            options={timeOptions}
                            onChange={datePickerFromSaturday}
                            isDisabled={
                              activInactivestate?.saturday ||
                              activeOnWholeday?.saturday ||
                              editButtonEnabled
                            }
                            value={{
                              label: moment()
                                .startOf("day")
                                .add(dayTimeValue?.saturdayOpenTime, "minutes")
                                .format("hh:mm A"),
                              value: dayTimeValue?.saturdayOpenTime,
                            }}
                          ></Select>
                        </Col>
                        <Col md={2}>
                          <Select
                            options={timeOptions}
                            onChange={datePickerUntillSaturday}
                            isDisabled={
                              activInactivestate?.saturday ||
                              activeOnWholeday?.saturday ||
                              editButtonEnabled
                            }
                            value={{
                              label: moment()
                                .startOf("day")
                                .add(dayTimeValue?.saturdayCloseTime, "minutes")
                                .format("hh:mm A"),
                              value: dayTimeValue?.saturdayCloseTime,
                            }}
                          ></Select>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>
          </div>

          <div>
            <Modal isOpen={modal_static1} toggle={tog_static1} centered={true}>
              <ModalBody>
                <Row>
                  <Col md={4}></Col>
                  <Col md={8}>
                    {" "}
                    <p className="modeltitlecss">Revoke Reason</p>
                  </Col>
                  <Col md={2}></Col>
                </Row>

                <hr />
                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    <p>
                      If there are any bookings that start and end outside of
                      new operational hours, these bookings will be revoked.
                      Please enter a message that should be sent to these
                      customers
                    </p>
                  </Col>
                  <Col md={1}></Col>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    <div>
                      <Input
                        type="textarea"
                        onChange={(e) => revokeReasonText(e)}
                      />
                    </div>
                  </Col>
                  <Col md={1}></Col>
                </Row>
                <hr />
                <Row>
                  <Col md={2}></Col>

                  <Col md={4}>
                    <Button
                      color="success"
                      outline
                      className=" w-100"
                      onClick={() => setModal_static1(false)}
                    >
                      <p className="e_a_btns" style={{ margin: "auto" }}>
                        {" "}
                        No{" "}
                      </p>
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      color="success"
                      className="waves-effect waves-light  w-100"
                      onClick={saveFunc}
                      
                    >
                      <p className="e_a_btns" style={{ margin: "auto" }}>
                        {" "}
                        Save{" "}
                      </p>
                    </Button>
                  </Col>

                  <Col md={2}></Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
          <div>
            <Modal isOpen={modal_static3} toggle={tog_static3} centered={true}>
              <ModalBody>
                <Row>
                  <Col md={2}></Col>
                  <Col md={8}>
                    {" "}
                    <p className="modeltitlecss mt-3">
                      Different Operating Hours
                    </p>
                  </Col>
                  <Col md={2}></Col>
                </Row>

                <hr />
                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    {" "}
                    <p>
                      {" "}
                      Your Selections are set to Have different operational
                      hours. if you want to have the same times for the
                      selections, please do so in the edit option.
                    </p>
                  </Col>
                  <Col md={1}></Col>
                </Row>

                <hr />
                <Row>
                  <Col md={4}></Col>

                  <Col md={4}>
                    <Button
                      color="success"
                      className=" w-100"
                      onClick={() => setModal_static3(false)}
                    >
                      <p className="e_a_btns" style={{ margin: "auto" }}>
                        Ok
                      </p>
                    </Button>
                  </Col>
                  <Col md={4}></Col>

                  <Col md={2}></Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>

          <div>
            <Modal isOpen={modal_static4} toggle={tog_static4} centered={true}>
              <ModalBody>
                <Row>
                  <Col md={3}></Col>
                  <Col md={8}>
                    {" "}
                    <p
                      className="modeltitlecss mt-3 "
                      style={{ margin: "auto" }}
                    >
                      Cancel Changes
                    </p>
                  </Col>
                  {/* <Col md={4}></Col> */}
                </Row>

                <hr />
                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    {" "}
                    <p>
                      Are you sure you want to proceed without saving changes to
                      operational hours?
                    </p>
                  </Col>
                  <Col md={1}></Col>
                </Row>

                <hr />
                <Row>
                  <Col md={3}></Col>

                  <Col md={3}>
                    <Button
                      color="success"
                      outline
                      className="w-100"
                      onClick={() => setModal_static4(false)}
                    >
                      <p className="e_a_btns" style={{ margin: "auto" }}>
                        No
                      </p>
                    </Button>
                  </Col>

                  <Col md={3}>
                    {/* <Link to="/operationalHours">     */}
                    <Button
                      color="success"
                      className="w-100"
                      onClick={() => {
                        window.location.reload(true);
                      }}
                    >
                      <p className="e_a_btns" style={{ margin: "auto" }}>
                        Yes
                      </p>
                    </Button>
                    {/* </Link> */}
                  </Col>
                  <Col md={3}></Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default OperationalHours;
