import React, { useState } from "react";
import {
  Button,
  Form,
  ModalHeader,
  Col,
  ModalBody,
  Input,
  Row,
  FormGroup,
  Card,
  Modal,
} from "reactstrap";
import Switch from "react-switch";

import { TimePicker } from "react-ios-time-picker";

import { UseAddException } from "./useException";
import moment from "moment";
import Select from "react-select";
import "./css/exceptionAdd.css";
import { Link } from "react-router-dom";
// import DatePicker from "reactstrap-date-picker";
import DatePicker from "react-flatpickr";
import UserPromissionFetcher from "../../../../../helpers/getUserPerimissions";
import NoAccess from "../../../../../components/Common/NoAccess";
import Spinner from "../../../../../components/Common/Loader";

const AddException = () => {
  const userPermissions = UserPromissionFetcher("operationalHours");
  // var {DatePicker} = require("reactstrap-date-picker")
  // var DatePicker = require("reactstrap-date-picker");
  const [editButtonEnabled, setEditButtonEnabled] = useState(false);

  const {
    modal_static1,
    setModal_static1,
    Offsymbol,
    OnSymbol,
    Offsymbolhours,
    OnSymbolhours,
    datePickerFromMonday,
    datePickerUntillMonday,
    datePickerUntillSaturday,
    datePickerFromSaturday,
    datePickerUntillFriday,
    datePickerFromFriday,
    datePickerUntillThursday,
    datePickerFromThursday,
    datePickerFromSunday,
    datePickerUntillSunday,
    datePickerUntillWednessday,
    datePickerFromWednessday,
    datePickerUntillTuesday,
    timeOptions,
    datePickerFromTuesday,
    dayTimeValue,
    name,
    exceptionNameFun,
    fromTimeFun,
    fromTime,
    toTimeFun,
    toTime,
    alwaysFun,
    always,
    typeFunc,
    type,
    typeDropDown,
    brandData,
    locationData,
    diomBrandHandler,
    diomLocationHandler,
    categoryDropDown,
    activInactivestate,
    saturdayWholeDayFunc,
    sundayWholeDayFunc,
    mondayWholeDayFunc,
    tuesdayWholeDayFunc,
    wednesdayWholeDayFunc,
    thursdayWholeDayFunc,
    fridayWholeDayFunc,
    sundayActiveFunc,
    saturdayActiveFunc,
    fridayActiveFunc,
    thursdayActiveFunc,
    wednesdayActiveFunc,
    tuesdayActiveFunc,
    mondayActiveFunc,
    daysActiveStutus,
    activeOnWholeday,
    resourceResourcetypedata,
    data2,
    diomResourceTypesHandler,
    resourceResourcedata,
    saveExceptionData,
    diomResourceHandler,
    isAlwaysDisable,
    operatioHourShowORHide,
    exceptionSelectInputRefResources,
    exceptionSelectInputRefResourceType,
    exceptionSelectInputRefLocations,
  } = UseAddException();
  let newDate = new Date();

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static1 = () => {
    setModal_static1(!modal_static1);

    removeBodyCss();
  };
  console.log(name, "<<<<<InputName>>>>>");

  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.create ? (
        <div className="page-content">
          <Row className="mb-4">
            <Col md={4}>
              <Link to="/exception_listing" style={{ color: "black" }}>
                <span className="fas fa-angle-left arrowheightwidth"></span>
              </Link>
              <span className="bookingtitle ml-4">Add Exceptions</span>
            </Col>
            <Col md={4}></Col>
            <Col md={2}>
              <Link to="/exception_listing">
                <Button
                  // onClick={handlePrint}
                  outline
                  color="success"
                  className=" w-100 "
                  block
                >
                  <span className="e_a_btns ">Cancel</span>
                </Button>
              </Link>
            </Col>
            <Col md={2}>
              <Button
                // onClick={handlePrint}
                color="success"
                className=" mr-1 w-100 "
                block
                disabled={!name}
                onClick={() => {
                  setModal_static1(false);
                  tog_static1();
                }}
              >
                <span className="e_a_btns ">Save</span>
              </Button>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card className="p-4 pb-5">
                <Row md={12}>
                  <p className="e_a_title"> Exception Name</p>
                </Row>
                <Row>
                  <Col md={12}>
                    <Input
                      name="name"
                      className=" "
                      type="text"
                      value={name}
                      onChange={(e) => exceptionNameFun(e)}
                    />
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md={3}>
                    <p className="e_a_title">Date Range</p>
                  </Col>
                  <Col md={7}></Col>
                  <Col md={2}>
                    <p className="e_a_title">Type</p>
                  </Col>
                </Row>

                <Row>
                  <Col md={2}>
                    <p className="e_a_subtitle">From</p>
                  </Col>
                  <Col md={2}>
                    <p className="e_a_subtitle">To</p>
                  </Col>
                  <Col md={2}></Col>
                  <Col md={4}></Col>
                  <Col md={2}></Col>
                </Row>
                <Row>
                  <Col md={2}>
                    <FormGroup>
                      {/* <Input */}

                      <DatePicker
                        className="datepickercss_exceptions"
                        defaultValue={`${moment(newDate).format("YYYY-MM-DD")}`}
                        // min={moment().subtract(30, "days")}
                        // max={moment().subtract(0, "days")}
                        disabled={always ? true : false}
                        id="example-date-input"
                        onChange={(e) => {
                          fromTimeFun(e);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2}>
                    <FormGroup>
                      <DatePicker
                        className="datepickercss_exceptions"
                        value={`${fromTime ? fromTime : newDate}`}
                        disabled={always ? true : false}
                        id="example-date-input"
                        onChange={(e) => {
                          toTimeFun(e);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <Switch
                      name="always"
                      onHandleColor="#16b185"
                      width={70}
                      uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                      checkedIcon={OnSymbolhours(<small>YES</small>)}
                      onColor="#a2a2a2"
                      checked={always ? true : false}
                      onChange={(e) => alwaysFun(e)}
                      disabled={isAlwaysDisable ? true : false}
                      className="mr-1 mt-1  "
                    />
                    <span className="e_a_subtitle e_a_alaways">Always</span>
                  </Col>
                  <Col md={3}></Col>
                  <Col md={2}>
                    <Select
                      name="typeInput"
                      placeholder="Type"
                      isMulti={false}
                      options={typeDropDown}
                      onChange={(opt) => typeFunc(opt)}
                    />
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col md={12}>
                    <p className="e_a_title">Applicable on</p>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <p className="e_a_subtitle">Select Brand</p>
                    <FormGroup className="select2-container  ">
                      <Select
                        isMulti={false}
                        options={brandData}
                        onChange={(opt) => diomBrandHandler(opt)}
                        placeholder="Brands"
                        classNamePrefix="select2-selection "
                      />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <p className="e_a_subtitle">Select Location</p>
                    <FormGroup className="select2-container  ">
                      <Select
                        ref={exceptionSelectInputRefLocations}
                        isMulti={false}
                        options={locationData}
                        onChange={(opt) => diomLocationHandler(opt)}
                        placeholder="Locations"
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mt-3 mb-5">
                  <Col md={6}>
                    <p className="e_a_subtitle">Select Resource Type </p>
                    <Select
                      ref={exceptionSelectInputRefResourceType}
                      // value={selectedMulti}
                      placeholder="Resources Types"
                      isMulti={false}
                      options={categoryDropDown}
                      onChange={(opt) => diomResourceTypesHandler(opt)}
                      // classNamePrefix="select2-selection"
                    />
                  </Col>

                  <Col md={6}>
                    <p className="e_a_subtitle">Select Resource</p>
                    <Select
                      ref={exceptionSelectInputRefResources}
                      // value={selectedMulti}
                      placeholder="Resources"
                      // isMulti={true}
                      options={resourceResourcedata}
                      onChange={(opt) => diomResourceHandler(opt)}
                      // classNamePrefix="select2-selection"
                    />
                  </Col>
                </Row>
                <div
                  name="operationalHoursDiv "
                  hidden={operatioHourShowORHide}
                >
                  <Row className="mt-5">
                    <Col md={6}>
                      <p className="oh_title ">
                        Operational hours saved settings
                      </p>
                    </Col>
                  </Row>

                  <Row className="oh_days_title">
                    <Col md={2}>
                      <p>DAYS</p>
                    </Col>
                    <Col md={2}>
                      <p>OPEN</p>
                    </Col>
                    <Col md={2}>
                      <p>24 HOURS</p>
                    </Col>
                    <Col md={2}>
                      <p>FROM</p>
                    </Col>
                    <Col md={2}>
                      <p>UNTILL</p>
                    </Col>
                  </Row>
                  <div>
                    <Row>
                      <Col md={2}>
                        <p className="oh_dayssubtitle">Sunday</p>
                      </Col>
                      <Col md={2}>
                        <Switch
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbol(<small>NO</small>)}
                          checkedIcon={OnSymbol(<small>YES</small>)}
                          onColor="#a2a2a2"
                          onChange={(e) => {
                            sundayActiveFunc(e);
                          }}
                          checked={daysActiveStutus?.sunday}
                          className="mr-1 mt-1  "
                          disabled={editButtonEnabled}
                        />
                      </Col>
                      <Col md={2}>
                        <Switch
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                          checkedIcon={OnSymbolhours(<small>YES</small>)}
                          onChange={(e) => {
                            sundayWholeDayFunc(e);
                          }}
                          checked={activeOnWholeday?.sunday}
                          onColor="#a2a2a2"
                          className="mr-1 mt-1  "
                          disabled={
                            activInactivestate?.sunday || editButtonEnabled
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <Select
                          value={{
                            label: moment()
                              .startOf("day")
                              .add(dayTimeValue?.sundayOpenTime, "minutes")
                              .format("hh:mm A"),
                            value: dayTimeValue?.sundayOpenTime,
                          }}
                          options={timeOptions}
                          onChange={datePickerFromSunday}
                          isDisabled={
                            activInactivestate?.sunday ||
                            activeOnWholeday?.sunday ||
                            editButtonEnabled
                          }
                        ></Select>
                      </Col>
                      <Col md={2}>
                        <Select
                          options={timeOptions}
                          value={{
                            label: moment()
                              .startOf("day")
                              .add(dayTimeValue?.sundayCloseTime, "minutes")
                              .format("hh:mm A"),
                            value: dayTimeValue?.sundayCloseTime,
                          }}
                          onChange={datePickerUntillSunday}
                          isDisabled={
                            activInactivestate?.sunday ||
                            activeOnWholeday?.sunday ||
                            editButtonEnabled
                          }
                        ></Select>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md={2}>
                        <p className="oh_dayssubtitle">Monday</p>
                      </Col>
                      <Col md={2}>
                        <Switch
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbol(<small>NO</small>)}
                          checkedIcon={OnSymbol(<small>YES</small>)}
                          onColor="#a2a2a2"
                          onChange={(e) => {
                            mondayActiveFunc(e);
                          }}
                          checked={daysActiveStutus?.monday}
                          className="mr-1 mt-1  "
                          disabled={editButtonEnabled}
                        />
                      </Col>
                      <Col md={2}>
                        <Switch
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                          checkedIcon={OnSymbolhours(<small>YES</small>)}
                          onChange={(e) => {
                            mondayWholeDayFunc(e);
                          }}
                          checked={activeOnWholeday?.monday}
                          onColor="#a2a2a2"
                          className="mr-1 mt-1  "
                          disabled={
                            activInactivestate?.monday || editButtonEnabled
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <Select
                          options={timeOptions}
                          onChange={datePickerFromMonday}
                          isDisabled={
                            activInactivestate?.monday ||
                            activeOnWholeday?.monday ||
                            editButtonEnabled
                          }
                          value={{
                            label: moment()
                              .startOf("day")
                              .add(dayTimeValue?.mondayOpenTime, "minutes")
                              .format("hh:mm A"),
                            value: dayTimeValue?.mondayOpenTime,
                          }}
                        ></Select>
                      </Col>
                      <Col md={2}>
                        <Select
                          options={timeOptions}
                          onChange={datePickerUntillMonday}
                          isDisabled={
                            activInactivestate?.monday ||
                            activeOnWholeday?.monday ||
                            editButtonEnabled
                          }
                          value={{
                            label: moment()
                              .startOf("day")
                              .add(dayTimeValue?.mondayCloseTime, "minutes")
                              .format("hh:mm A"),
                            value: dayTimeValue?.mondayCloseTime,
                          }}
                        ></Select>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md={2}>
                        <p className="oh_dayssubtitle">Tuesday</p>
                      </Col>
                      <Col md={2}>
                        <Switch
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbol(<small>NO</small>)}
                          checkedIcon={OnSymbol(<small>YES</small>)}
                          onChange={(e) => {
                            tuesdayActiveFunc(e);
                          }}
                          checked={daysActiveStutus?.tuesday}
                          onColor="#a2a2a2"
                          className="mr-1 mt-1  "
                          disabled={editButtonEnabled}
                        />
                      </Col>
                      <Col md={2}>
                        <Switch
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                          checkedIcon={OnSymbolhours(<small>YES</small>)}
                          onColor="#a2a2a2"
                          onChange={(e) => {
                            tuesdayWholeDayFunc(e);
                          }}
                          checked={activeOnWholeday?.tuesday}
                          className="mr-1 mt-1  "
                          disabled={
                            activInactivestate?.tuesday || editButtonEnabled
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <Select
                          options={timeOptions}
                          onChange={datePickerFromTuesday}
                          isDisabled={
                            activInactivestate?.tuesday ||
                            activeOnWholeday?.tuesday ||
                            editButtonEnabled
                          }
                          value={{
                            label: moment()
                              .startOf("day")
                              .add(dayTimeValue?.tuesdayOpenTime, "minutes")
                              .format("hh:mm A"),
                            value: dayTimeValue?.tuesdayOpenTime,
                          }}
                        ></Select>
                      </Col>
                      <Col md={2}>
                        <Select
                          options={timeOptions}
                          onChange={datePickerUntillTuesday}
                          isDisabled={
                            activInactivestate?.tuesday ||
                            activeOnWholeday?.tuesday ||
                            editButtonEnabled
                          }
                          value={{
                            label: moment()
                              .startOf("day")
                              .add(dayTimeValue?.tuesdayCloseTime, "minutes")
                              .format("hh:mm A"),
                            value: dayTimeValue?.tuesdayCloseTime,
                          }}
                        ></Select>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md={2}>
                        <p className="oh_dayssubtitle">Wednessday</p>
                      </Col>
                      <Col md={2}>
                        <Switch
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbol(<small>NO</small>)}
                          checkedIcon={OnSymbol(<small>YES</small>)}
                          onChange={(e) => {
                            wednesdayActiveFunc(e);
                          }}
                          checked={daysActiveStutus?.wednessday}
                          onColor="#a2a2a2"
                          className="mr-1 mt-1  "
                          disabled={editButtonEnabled}
                        />
                      </Col>
                      <Col md={2}>
                        <Switch
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                          checkedIcon={OnSymbolhours(<small>YES</small>)}
                          onChange={(e) => {
                            wednesdayWholeDayFunc(e);
                          }}
                          checked={activeOnWholeday?.wednessday}
                          onColor="#a2a2a2"
                          className="mr-1 mt-1  "
                          disabled={
                            activInactivestate?.wednessday || editButtonEnabled
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <Select
                          options={timeOptions}
                          onChange={datePickerFromWednessday}
                          isDisabled={
                            activInactivestate?.wednessday ||
                            activeOnWholeday?.wednessday ||
                            editButtonEnabled
                          }
                          value={{
                            label: moment()
                              .startOf("day")
                              .add(dayTimeValue?.wednesdayOpenTime, "minutes")
                              .format("hh:mm A"),
                            value: dayTimeValue?.wednesdayOpenTime,
                          }}
                        ></Select>
                      </Col>
                      <Col md={2}>
                        <Select
                          options={timeOptions}
                          onChange={datePickerUntillWednessday}
                          isDisabled={
                            activInactivestate?.wednessday ||
                            activeOnWholeday?.wednessday ||
                            editButtonEnabled
                          }
                          value={{
                            label: moment()
                              .startOf("day")
                              .add(dayTimeValue?.wednesdayCloseTime, "minutes")
                              .format("hh:mm A"),
                            value: dayTimeValue?.wednesdayCloseTime,
                          }}
                        ></Select>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md={2}>
                        <p className="oh_dayssubtitle">Thursday</p>
                      </Col>
                      <Col md={2}>
                        <Switch
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbol(<small>NO</small>)}
                          checkedIcon={OnSymbol(<small>YES</small>)}
                          onChange={(e) => {
                            thursdayActiveFunc(e);
                          }}
                          checked={daysActiveStutus?.thursday}
                          onColor="#a2a2a2"
                          className="mr-1 mt-1  "
                          disabled={editButtonEnabled}
                        />
                      </Col>
                      <Col md={2}>
                        <Switch
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                          checkedIcon={OnSymbolhours(<small>YES</small>)}
                          onChange={(e) => {
                            thursdayWholeDayFunc(e);
                          }}
                          checked={activeOnWholeday?.thursday}
                          onColor="#a2a2a2"
                          className="mr-1 mt-1  "
                          disabled={
                            activInactivestate?.thursday || editButtonEnabled
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <Select
                          options={timeOptions}
                          onChange={datePickerFromThursday}
                          isDisabled={
                            activInactivestate?.thursday ||
                            activeOnWholeday?.thursday ||
                            editButtonEnabled
                          }
                          value={{
                            label: moment()
                              .startOf("day")
                              .add(dayTimeValue?.thursdayOpenTime, "minutes")
                              .format("hh:mm A"),
                            value: dayTimeValue?.thursdayOpenTime,
                          }}
                        ></Select>
                      </Col>
                      <Col md={2}>
                        <Select
                          options={timeOptions}
                          onChange={datePickerUntillThursday}
                          isDisabled={
                            activInactivestate?.thursday ||
                            activeOnWholeday?.thursday ||
                            editButtonEnabled
                          }
                          value={{
                            label: moment()
                              .startOf("day")
                              .add(dayTimeValue?.thursdayCloseTime, "minutes")
                              .format("hh:mm A"),
                            value: dayTimeValue?.thursdayCloseTime,
                          }}
                        ></Select>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col md={2}>
                        <p className="oh_dayssubtitle">Friday</p>
                      </Col>
                      <Col md={2}>
                        <Switch
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbol(<small>NO</small>)}
                          checkedIcon={OnSymbol(<small>YES</small>)}
                          onChange={(e) => {
                            fridayActiveFunc(e);
                          }}
                          checked={daysActiveStutus?.friday}
                          onColor="#a2a2a2"
                          className="mr-1 mt-1  "
                          disabled={editButtonEnabled}
                        />
                      </Col>
                      <Col md={2}>
                        <Switch
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                          checkedIcon={OnSymbolhours(<small>YES</small>)}
                          onChange={(e) => {
                            fridayWholeDayFunc(e);
                          }}
                          checked={activeOnWholeday?.friday}
                          onColor="#a2a2a2"
                          className="mr-1 mt-1  "
                          disabled={
                            activInactivestate?.friday || editButtonEnabled
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <Select
                          options={timeOptions}
                          onChange={datePickerFromFriday}
                          isDisabled={
                            activInactivestate?.friday ||
                            activeOnWholeday?.friday ||
                            editButtonEnabled
                          }
                          value={{
                            label: moment()
                              .startOf("day")
                              .add(dayTimeValue?.fridayOpenTime, "minutes")
                              .format("hh:mm A"),
                            value: dayTimeValue?.fridayOpenTime,
                          }}
                        ></Select>
                      </Col>
                      <Col md={2}>
                        <Select
                          options={timeOptions}
                          onChange={datePickerUntillFriday}
                          isDisabled={
                            activInactivestate?.friday ||
                            activeOnWholeday?.friday ||
                            editButtonEnabled
                          }
                          value={{
                            label: moment()
                              .startOf("day")
                              .add(dayTimeValue?.fridayCloseTime, "minutes")
                              .format("hh:mm A"),
                            value: dayTimeValue?.fridayCloseTime,
                          }}
                        ></Select>
                      </Col>
                    </Row>
                    <hr />
                    <Row className="mb-5">
                      <Col md={2}>
                        <p className="oh_dayssubtitle">Saturday</p>
                      </Col>
                      <Col md={2}>
                        <Switch
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbol(<small>NO</small>)}
                          checkedIcon={OnSymbol(<small>YES</small>)}
                          onChange={(e) => {
                            saturdayActiveFunc(e);
                          }}
                          checked={daysActiveStutus?.saturday}
                          onColor="#a2a2a2"
                          className="mr-1 mt-1  "
                          disabled={editButtonEnabled}
                        />
                      </Col>
                      <Col md={2}>
                        <Switch
                          onHandleColor="#16b185"
                          width={70}
                          uncheckedIcon={Offsymbolhours(<small>NO</small>)}
                          checkedIcon={OnSymbolhours(<small>YES</small>)}
                          onChange={(e) => {
                            saturdayWholeDayFunc(e);
                          }}
                          checked={activeOnWholeday?.saturday}
                          onColor="#a2a2a2"
                          className="mr-1 mt-1  "
                          disabled={
                            activInactivestate?.saturday || editButtonEnabled
                          }
                        />
                      </Col>
                      <Col md={2}>
                        <Select
                          options={timeOptions}
                          onChange={datePickerFromSaturday}
                          isDisabled={
                            activInactivestate?.saturday ||
                            activeOnWholeday?.saturday ||
                            editButtonEnabled
                          }
                          value={{
                            label: moment()
                              .startOf("day")
                              .add(dayTimeValue?.saturdayOpenTime, "minutes")
                              .format("hh:mm A"),
                            value: dayTimeValue?.saturdayOpenTime,
                          }}
                        ></Select>
                      </Col>
                      <Col md={2}>
                        <Select
                          options={timeOptions}
                          onChange={datePickerUntillSaturday}
                          isDisabled={
                            activInactivestate?.saturday ||
                            activeOnWholeday?.saturday ||
                            editButtonEnabled
                          }
                          value={{
                            label: moment()
                              .startOf("day")
                              .add(dayTimeValue?.saturdayCloseTime, "minutes")
                              .format("hh:mm A"),
                            value: dayTimeValue?.saturdayCloseTime,
                          }}
                        ></Select>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Col>
          </Row>

          <div>
            <Modal isOpen={modal_static1} toggle={tog_static1} centered={true}>
              <ModalBody>
                <Row>
                  <Col md={4}></Col>
                  <Col md={8}>
                    {" "}
                    <p className="modeltitlecss">Add Exception</p>
                  </Col>
                  <Col md={2}></Col>
                </Row>
                <hr />
                <Row>
                  <Col md={2}></Col>
                  <Col md={8}>
                    <div>
                      <p>
                        Are you sure you want to create this exception? If this
                        exception is created, any booking for selected resources
                        outside operational hours will be revoked.
                      </p>
                    </div>
                  </Col>
                  <Col md={2}></Col>
                </Row>
                <hr />
                <Row>
                  <Col md={2}></Col>

                  <Col md={4}>
                    <Button
                      color="success"
                      outline
                      className="waves-effect waves-light w-100"
                      onClick={() => setModal_static1(false)}
                    >
                      No
                    </Button>
                  </Col>
                  <Col md={4}>
                    <Button
                      color="success"
                      className="waves-effect waves-light  w-100"
                      // onClick={updateNameAndDescription}
                      //   onClick={delRevokeBookings}
                      onClick={saveExceptionData}
                    >
                      Save
                    </Button>
                  </Col>

                  <Col md={2}></Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default AddException;
