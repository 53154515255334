import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Row, Col, Button, Alert, Container, Label, Spinner } from "reactstrap";
import sign_up_image from "./pic/sign_up_image.png";
import "./css/Login.css";
import { AvForm } from "availity-reactstrap-validation";
import { ToastContainer, toast } from "react-toastify";
import { checkLogin } from "../../store/actions";
import { DIOM_BASED_URLS } from "../../config/url";

// import { getMessaging, onMessage, getToken } from "firebase/messaging";
const Login = () => {
  const [username, setUsername] = useState("");
  const [userpassword, setUserpassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  // const { loginError } = useSelector((state) => state.Login);
  const [currentTokenOfUser, setCurrentTokenOfUser] = useState("");

  const [isValidEmail, setIsValidEmail] = useState(false);

  const handleSubmit = (event, values) => {
    if (username && userpassword) {
      // set isLoading True
      setIsLoading(true);
      // *************API START***************
      fetch(`${DIOM_BASED_URLS}/diom/spaces/admin/login`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          email: username,
          password: userpassword,
        }),
      })
        .then( async(response) => {
          const resJson = await response.json();

          if (!response.ok) {
            if (response.status === 401) {
              setIsLoading(false);
              return toast.error(resJson.error.message);
            }
            if (response.status === 404) {
              setIsLoading(false);
             
             return toast.error(resJson.error.message);
            }
            // throw new Error(resJson.error.message);
          }

          return resJson;
        })
        .then((data) => {
          //  2)Remove First saved assignedRole
          localStorage.removeItem("assignedRole");
          // 3)Set user assigned Role
          localStorage.setItem("assignedRole", data.data.assignedRole);
          // 4) Redirect to dashboard
          dispatch(
            checkLogin(
              { username, password: userpassword, currentTokenOfUser },
              history
            )
          );
          // 5)Set the token to local storage.
          localStorage.setItem("Token", data.token);
          // 6) Show toaster message
          toast.success("Successfully login");
        });
    } else {
      // toast.warning("Please fill all fields");
      setUsername("test");
      setIsValidEmail(false);
    }
  };

  // Setter fns
  const UserPasswordFunc = (e) => {
    setUserpassword(e.target.value);
  };

  // User Email Formate check fn
  const UserEmailFunc = (e) => {
    setUsername(e.target.value);
    const emailInput = e.target.value;
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    setIsValidEmail(emailRegex.test(emailInput));
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={5} md={5} className="">
              {/* <div className="bg-overlay "> */}
              <div className="image-wrap ">
                <img
                  className=" img-responsive  "
                  src={sign_up_image}
                  alt="img"
                />
              </div>
              {/* </div> */}
            </Col>
            <Col lg={7} md={7}>
              <div className="authentication-page-content p-4 d-flex align-items-center min-vh-100">
                <div className="w-100">
                  <Row className="justify-content-center">
                    <Col lg={9} md={9}>
                      <div>
                        <div className="text-center">
                          <h4 className="wellcomepanel">
                            Welcome to Diom Admin Panel
                          </h4>
                        </div>
                        {/* {loginError && loginError ? (
                          <Alert color="danger">{loginError}</Alert>
                        ) : null} */}
                        <div className="p-2 mt-5">
                          <Label
                            htmlFor="username"
                            className="logininboxpanels"
                          >
                            Username
                          </Label>
                          <AvForm
                            className="form-horizontal "
                            onValidSubmit={handleSubmit}
                          >
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text form-control ">
                                  <i
                                    class="ri-user-2-line auti-custom-input-icon"
                                    style={{
                                      lineHeight: "50px",
                                      fontSize: "20px",
                                    }}
                                  ></i>
                                </span>
                              </div>
                              <input
                                type="email"
                                className={`form-control  ${
                                  !!username &&
                                  (isValidEmail ? "is-valid" : "is-invalid")
                                }`}
                                style={{ lineHeight: "50px" }} // Adjust the line-height as needed
                                placeholder="Enter username"
                                id="email"
                                onChange={UserEmailFunc}
                              />
                              <div class="invalid-feedback">
                                Please enter a valid email address.
                              </div>
                            </div>

                            <Label
                              htmlFor="userpassword"
                              className="logininboxpanels"
                            >
                              Password
                            </Label>
                            <div class="input-group mb-3">
                              <div class="input-group-prepend">
                                <span class="input-group-text form-control ">
                                  <i
                                    className="ri-lock-2-line auti-custom-input-icon"
                                    style={{
                                      lineHeight: "50px",
                                      fontSize: "20px",
                                    }}
                                  ></i>
                                </span>
                              </div>
                              <input
                                type="password"
                                className={`form-control  ${
                                  userpassword ? "is-valid" : "is-invalid"
                                }`}
                                style={{ lineHeight: "50px" }} // Adjust the line-height as needed
                                placeholder="Enter Password"
                                id="password"
                                onChange={UserPasswordFunc}
                              />
                              <div class="invalid-feedback">
                                Please enter the password.
                              </div>
                            </div>
                            {/* <div className="auth-form-group-custom  mb-4">
                                <AvField
                                  name="password"
                                  value={state.userpassword}
                                  type="password"
                                  className="form-control"
                                  id="userpassword"
                                  placeholder="Enter password"
                                  onChange={UserPasswordFunc}
                                />
                              </div> */}
                            <div className="mt-5 text-center">
                              <Button
                                disabled={isLoading}
                                color="success"
                                className="waves-effect waves-light mr-1 w-100 "
                                block
                                // onClick={LoginFunc}
                              >
                                <span className="signinbutton">
                                  {isLoading ? (
                                    <>
                                      <Spinner size="sm">Loading...</Spinner>
                                      <span className="ms-1">Loading...</span>
                                    </>
                                  ) : (
                                    "SIGN IN"
                                  )}
                                </span>
                              </Button>
                            </div>
                          </AvForm>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer autoClose={8000} />
    </React.Fragment>
  );
};
export default Login;
