import { useMutation, useQueryClient } from "react-query"
import { getAvailableResourceTypes } from "../../../../../APIS/resourceType"
import { cancelGuestBookingByAdmin, cancelGuestBookingByUser, confirmGuestBooking, createGuestBooking } from "../../../../../APIS/bookings";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const useCheckAvailableResourceMutation = ( RTCId , businessId , history ,setAvailableResourceType) => {
 const token = localStorage.getItem("Token");
   const {
     mutate: checkAvailableResourceTypeMutate,
     mutateAsync: checkAvailableResourceTypeMutateAsync,
     data: newPrice,
     status: checkAvailableResourceTypeStatus,
     error: checkAvailableResourceTypeError,
     isLoading: isCheckAvailableResourceType,
   } = useMutation({
     mutationFn: (data) =>
      getAvailableResourceTypes(token ,data ,RTCId , businessId , history),
     onSuccess: (data) => {
       const availableRT=data.data.data.filter(RT => RT.hasAvailableResource)
       
      setAvailableResourceType(availableRT)
       
       
     },
   })
 
   return {
     checkAvailableResourceTypeMutate,
     newPrice,
     checkAvailableResourceTypeMutateAsync,
     isCheckAvailableResourceType,
     checkAvailableResourceTypeStatus,
     checkAvailableResourceTypeError,
   }
 }


//  Create Guest Booking
export const useCreateAdminGuestBookingMutation = () => {
  const history = useHistory();

 const token = localStorage.getItem("Token");
   const {
     mutate: adminGuestBookingMutate,
     mutateAsync: createAdminGuestBookingMutateAsync,
     data: newPrice,
     status: adminGuestBookingStatus,
     error: adminGuestBookingError,
     isLoading: isAdminGuestBookingTypeLoading,
   } = useMutation({
     mutationFn: (data) =>
      createGuestBooking(token ,data ,history),
     onSuccess: () => {
       
      
     
       
     },
   })
 
   return {
    adminGuestBookingMutate,
     newPrice,
     createAdminGuestBookingMutateAsync,
     isAdminGuestBookingTypeLoading,
     adminGuestBookingStatus,
     adminGuestBookingError,
   }
 }

//  Cancel Booking
export const useCancelAdminGuestBookingByUserMutation = (setIsOpen, queryString) => {
  const history = useHistory();
  const QueryClient = useQueryClient();
   const {
     mutate: cancelAdminGuestBookingMutate,
     mutateAsync: cancelAdminGuestBookingMutateAsync,
     data: newPrice,
     status: cancelAdminGuestBookingStatus,
     error: cancelAdminGuestBookingError,
     isLoading: isAdminGuestBookingCancelLoading,
   } = useMutation({
     mutationFn: (id) =>
      cancelGuestBookingByUser(id ,setIsOpen),
     onSuccess: () => {
       
      QueryClient.invalidateQueries(queryString)
     
       
     },
   })
 
   return {
    cancelAdminGuestBookingMutate,
    cancelAdminGuestBookingMutateAsync,
    isAdminGuestBookingCancelLoading,
    newPrice,
     cancelAdminGuestBookingStatus,
     cancelAdminGuestBookingError,
   }
 }
export const useCancelAdminGuestBookingByAdminMutation = ( token ,setIsOpen, queryString) => {
  const history = useHistory();
  const QueryClient = useQueryClient();

   const {
     mutate: cancelAdminGuestBookingMutate,
     mutateAsync: cancelAdminGuestBookingMutateAsync,
     data: newPrice,
     status: cancelAdminGuestBookingStatus,
     error: cancelAdminGuestBookingError,
     isLoading: isAdminGuestBookingCancelLoading,
   } = useMutation({
     mutationFn: (id) =>
      cancelGuestBookingByAdmin(token ,id ,setIsOpen),
     onSuccess: () => {
       
      QueryClient.invalidateQueries(queryString)
     
       
     },
   })
 
   return {
    cancelAdminGuestBookingMutate,
    cancelAdminGuestBookingMutateAsync,
    isAdminGuestBookingCancelLoading,
    newPrice,
     cancelAdminGuestBookingStatus,
     cancelAdminGuestBookingError,
   }
 }
export const useConfirmAdminGuestBookingMutation = ( token ,setIsOpen) => {
  const history = useHistory();
  const QueryClient = useQueryClient();

   const {
     mutate: confirmAdminGuestBookingMutate,
     mutateAsync: confirmAdminGuestBookingMutateAsync,
     data: newPrice,
     status: confirmAdminGuestBookingStatus,
     error: confirmAdminGuestBookingError,
     isLoading: isAdminGuestBookingConfirmedLoading,
   } = useMutation({
     mutationFn: (data) =>
      confirmGuestBooking(token,setIsOpen , data ,history),
     onSuccess: () => {
       
      QueryClient.invalidateQueries('AdminGuestBooking')
     
       
     },
   })
 
   return {
    confirmAdminGuestBookingMutate,
    confirmAdminGuestBookingMutateAsync,
    isAdminGuestBookingConfirmedLoading,
    newPrice,
     confirmAdminGuestBookingStatus,
     confirmAdminGuestBookingError,
   }
 }