import React, { useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  getcustomersPackages,
  getcustomersSearchPackages,
  getLocationPackges,
} from "../../../../APIS/packages";
import { DIOM_BASED_URLS } from "../../../../config/url";
import { usePaginatedQuery } from "../../../../hooks/query";
import { useHistory } from "react-router-dom";
import uuid from "react-uuid";

const UseCreatePackage = () => {
  const uniqueId = uuid();
  const [teamArr, setTeamArr] = useState([]);
  let history = useHistory();
  const token = localStorage.getItem("Token");
  const [imageRefCover, setImageRefCover] = useState();
  const [secondRowData, setSecondRowData] = useState(true);
  const [secondState, setSecondState] = useState(false);
  const [thirdRowData, setThirdRowData] = useState(true);
  const [thirdState, setThirdState] = useState(true);
  const [fourthRowData, setFourthRowData] = useState(true);
  const [fourthState, setFourthState] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState("");
  const [croppedImage, setCroppedImage] = useState(null);
  const [coverImgProcess, setcoverImgProcess] = useState("");
  const [createPackageObj, setCreatePackageObj] = useState({});
  const [categoryTimeCredit, setCategoryTimeCredit] = useState({});
  const [categoryTimeCreditArray, setCategoryTimeCreditArray] = useState([]);
  const [confirmHidden, setConfirmHidden] = useState(false);
  const [cardViewLoading, setCardViewLoading] = useState(false);
  const [detailedViewLoading, setDetailedViewLoading] = useState(false);
  const [detailImageButton, setDetailImageButton] = useState(false);
  const [cardImageButton, setCardImageButton] = useState(false);
  const [createButtonLoad, setCreateButtonLoad] = useState(false);
  const [cateReAgain, setCateReAgain] = useState(true);
  const [customerfinalOptions, setCustomerfinalOptions] = useState([]);
  const [customerSearchVer, setCustomerSearchVer] = useState();
  // const [customerDate,setCustomerData]=useState()

  const [cropCover, setCropCover] = useState({
    x: 0,
    y: 0,
    width: 173,
    height: 173,
  });
  const [zoomCover, setZoomCover] = useState(1);
  const [coverMedia, setCoverMedia] = useState();
  const [coverImgUrl, setCoverImgUrl] = useState();
  const [crop, setCrop] = useState({ x: 0, y: 0, width: 173, height: 173 });
  const [zoom, setZoom] = useState(1);
  const [profileMedia, setProfileMedia] = useState();
  const [zoom1, setZoom1] = useState(1);
  const [zoom2, setZoom2] = useState(1);
  const [count, setCount] = useState(1);
  const [crop11, setCrop11] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 349,
    height: 158,
  });
  const [crop22, setCrop22] = useState({
    unit: "px", // Can be 'px' or '%'
    x: 0,
    y: 0,
    width: 173,
    height: 173,
  });
  const [imgCC, setImgCC] = useState();
  const [testImg, setTestImg] = useState();
  const [imageOneArea, setImageOneArea] = useState("");
  const [imageTwoArea, setImageTwoArea] = useState("");

  const PackageValidityOption = [
    {
      label: "One Day",
      value: "OneDay",
    },
    {
      label: "One Week",
      value: "OneWeek",
    },
    {
      label: "Two Weeks",
      value: "TwoWeeks",
    },
    {
      label: "One Month",
      value: "OneMonth",
    },
    {
      label: "Three Months",
      value: "ThreeMonths",
    },
    {
      label: "One Year",
      value: "OneYear",
    },
  ];

  // useEffect(() => {
  //   setTeamArr([...teamArr, { id: uniqueId }]);
  // }, []);

  // const addmorepracticefunc = () => {
  //   teamArr.push({ id: uniqueId });
  // };

  const pkgValidityFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Validity: e?.value });
  };
  const pkgLocationFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Location: e });
  };
  const pkgInventoryFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Inventory: e?.target?.value });
  };
  const pkgPriorityFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Priority: e?.target?.value });
  };
  const pkgCustomersFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, CustomersData: e });
  };
  const pkgConditionsFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Conditions: e?.target?.value });
  };
  const pkgCategory3Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      id: e?.value,
      name: e?.label,
    });
    setCateReAgain(true);
  };
  const pkgDisplayHours3Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      DisplayHours: e?.target?.value,
    });
    setCateReAgain(true);
  };
  const pkgDisplayMinutes3Func = (e) => {
    setCategoryTimeCredit({ ...categoryTimeCredit, DisplayMinutes: e?.value });
    setCateReAgain(true);
  };
  const pkgActualHours3Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      ActualHours: e?.target?.value,
    });
    setCateReAgain(true);
  };
  const pkgActualMinutes3Func = (e) => {
    setCategoryTimeCredit({ ...categoryTimeCredit, ActualMinutes: e?.value });
    setCateReAgain(true);
  };
  const pkgCategory2Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      id: e?.value,
      name: e?.label,
    });
    setCateReAgain(true);
  };
  const pkgDisplayHours2Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      DisplayHours: e?.target?.value,
    });
    setCateReAgain(true);
  };
  const pkgDisplayMinutes2Func = (e) => {
    setCategoryTimeCredit({ ...categoryTimeCredit, DisplayMinutes: e?.value });
    setCateReAgain(true);
  };
  const pkgActualHours2Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      ActualHours: e?.target?.value,
    });
    setCateReAgain(true);
  };

  const refundableMinutes = (e) => {
    setCreatePackageObj({
      ...createPackageObj,
      refundableMinutes: e?.target?.value,
    });
  };
  const refundableHours = (e) => {
    setCreatePackageObj({
      ...createPackageObj,
      refundableHours: e?.target?.value,
    });
  };
  const pkgActualMinutes2Func = (e) => {
    setCategoryTimeCredit({ ...categoryTimeCredit, ActualMinutes: e?.value });
    setCateReAgain(true);
  };

  const pkgCategory1Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      id: e?.value,
      name: e?.label,
    });
    setCateReAgain(true);
  };

  const pkgDisplayHours1Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      DisplayHours: e?.target?.value,
    });
    setCateReAgain(true);
  };
  const pkgDisplayMinutes1Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      DisplayMinutes: e?.value,
    });
    setCateReAgain(true);
  };
  const pkgActualHours1Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      ActualHours: e?.target?.value,
    });
    setCateReAgain(true);
  };
  const pkgActualMinutes1Func = (e) => {
    setCategoryTimeCredit({
      ...categoryTimeCredit,
      ActualMinutes: e?.value,
    });
    setCateReAgain(true);
  };
  const pkgNameFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Name: e?.target?.value });
  };
  const pkgPriceFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Price: e?.target?.value });
  };
  const pkgDescriptionFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, Description: e?.target?.value });
  };

  const pkgActiveateToggleFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, ActivateToggle: e });
  };
  const pkgTagInputFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, TagInput: e?.target?.value });
  };
  const pkgTagToggleFunc = (e) => {
    setCreatePackageObj({ ...createPackageObj, TagToggle: e });
  };

  const loadedImgCover = (img) => {
    setImageRefCover(img?.currentTarget);
  };
  // ****************profile img data*******
  const onCropCompleteProfile = (croppedArea, croppedAreaPixels) => {
    setCardImageButton(false);
    drawImage(croppedArea, croppedAreaPixels);
  };
  const drawImage = (croppedArea, croppedAreaPixels) => {
    const canvas = document.createElement("canvas");
    if (imageRefCover) {
      const ctx = canvas.getContext("2d");
      canvas.setAttribute("width", croppedAreaPixels.width);
      canvas.setAttribute("height", croppedAreaPixels.height);
      ctx.drawImage(
        imageRefCover,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );
      var image = canvas.toDataURL("image/jpeg", 1.0);
      setImageOneArea(image);
    }
  };
  // ****************************Cover img data**********
  const onCropCompleteCover = (croppedArea, croppedAreaPixels) => {
    setDetailImageButton(false);
    drawImageCover(croppedArea, croppedAreaPixels);
  };
  const drawImageCover = (croppedArea, croppedAreaPixels) => {
    const canvas = document.createElement("canvas");
    if (imageRefCover) {
      const ctx = canvas.getContext("2d");
      canvas.setAttribute("width", croppedAreaPixels.width);
      canvas.setAttribute("height", croppedAreaPixels.height);
      ctx.drawImage(
        imageRefCover,
        croppedAreaPixels.x,
        croppedAreaPixels.y,
        croppedAreaPixels.width,
        croppedAreaPixels.height,
        0,
        0,
        croppedAreaPixels.width,
        croppedAreaPixels.height
      );
      var image = canvas.toDataURL("image/jpeg", 1.0);
      setImageTwoArea(image);
    }
  };

  // *************locationsData**********
  const { data: locationsData, isLoading: loadingLocations } = useQuery(
    ["locationsData"],
    () => getLocationPackges(token)
  );

  // *******************packagesCustomerSearchData********
  const { data: packagesCustomerSearchData, isLoading: CustomerSearchLoading } =
    usePaginatedQuery(
      ["packagesCustomerSearchData", customerSearchVer],
      () => getcustomersSearchPackages(token, history, customerSearchVer),
      {
        retry: 0,
      }
    );

  //   ***********************

  const handleCustomerInputChange = (e) => {
    setCustomerSearchVer(e);
  };
  const cropedImg1 = (e) => {};

  function Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  function OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  const timeOptions = [
    { value: 0, label: "0" },
    { value: 30, label: "30" },
  ];

  const secondRowDataFunc = () => {
    setSecondRowData(false);
    setSecondState(true);
    setThirdState(false);
    // categoryTimeCreditArray.push(categoryTimeCredit);
    if (Object.keys(categoryTimeCredit).length === 0) {
      toast.error("Categories should not be empty");
    } else if (
      categoryTimeCredit?.ActualHours === undefined ||
      categoryTimeCredit?.ActualMinutes === undefined ||
      categoryTimeCredit?.DisplayHours === undefined ||
      categoryTimeCredit?.DisplayMinutes === undefined ||
      categoryTimeCredit?.id === undefined ||
      categoryTimeCredit?.name === undefined
    ) {
      toast.error("Categories all fields are required");
    } else {
      categoryTimeCreditArray.push(categoryTimeCredit);
    }
  };

  const rowSecondDellFunc = () => {
    setSecondRowData(true);
    setSecondState(false);
    setThirdState(true);
    categoryTimeCreditArray.pop(categoryTimeCredit);
  };

  const thirdRowDataFunc = () => {
    setThirdRowData(false);
    setThirdState(true);
    // categoryTimeCreditArray.push(categoryTimeCredit);
    if (Object.keys(categoryTimeCredit).length === 0) {
      toast.error("Categories should not be empty");
    } else if (
      categoryTimeCredit?.ActualHours === undefined ||
      categoryTimeCredit?.ActualMinutes === undefined ||
      categoryTimeCredit?.DisplayHours === undefined ||
      categoryTimeCredit?.DisplayMinutes === undefined ||
      categoryTimeCredit?.id === undefined ||
      categoryTimeCredit?.name === undefined
    ) {
      toast.error("Categories all fields are required");
    } else {
      categoryTimeCreditArray.push(categoryTimeCredit);
    }
  };

  const rowThreeDellFunc = () => {
    setThirdRowData(true);
    setThirdState(false);
    categoryTimeCreditArray.pop(categoryTimeCredit);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const confirmActualImg = async () => {
    setConfirmHidden(true);
    setImgCC(testImg);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("Token")}`
    );

    var formdata = new FormData();
    formdata.append("file", testImg[0], testImg[0].preview);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    await fetch(`${DIOM_BASED_URLS}/file-uploads`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setSelectedFiles(result[0]?.Location);
        // setImgCC(result)
      })
      .catch((error) =>
        toast.error("Actual Image is not set Due to size of Image")
      );
  };

  const handleAcceptedFiles = async (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        htmlFormattedSize: formatBytes(file.size),
      })
    );
    setTestImg(files);
  };

  // *************POST Packages**********

  const PostPackagesFunc = () => {
    setCreateButtonLoad(true);

    if (Object.keys(categoryTimeCredit).length === 0) {
      toast.error("Categories should not be empty");
      setCreateButtonLoad(false);
    } else if (
      categoryTimeCredit?.ActualHours === undefined ||
      categoryTimeCredit?.ActualMinutes === undefined ||
      categoryTimeCredit?.DisplayHours === undefined ||
      categoryTimeCredit?.DisplayMinutes === undefined ||
      categoryTimeCredit?.id === undefined ||
      categoryTimeCredit?.name === undefined
    ) {
      toast.error("Categories all fields are required");
      setCreateButtonLoad(false);
    } else {
      if (cateReAgain === true) {
        categoryTimeCreditArray.push(categoryTimeCredit);
        setCateReAgain(false);
      }
    }

    setTimeout(() => {
      if (createPackageObj?.Name === undefined) {
        toast.error("Name is required");
        setCreateButtonLoad(false);
      } else if (categoryTimeCreditArray.length === 0) {
        toast.error("Category should not be empty");
        setCreateButtonLoad(false);
      } else if (!categoryTimeCreditArray?.map((e) => e?.name)) {
        toast.error("Category name");
        setCreateButtonLoad(false);
      } else if (!categoryTimeCreditArray?.map((e) => e?.DisplayHours)) {
        toast.error("Category Display Hours  should be 1 or  greater then 1");
        setCreateButtonLoad(false);
      } else if (!categoryTimeCreditArray?.map((e) => e?.DisplayMinutes)) {
        toast.error("Category Display minutes should be 1 or  greater then 1");
        setCreateButtonLoad(false);
      } else if (!categoryTimeCreditArray?.map((e) => e?.ActualHours)) {
        toast.error("Category Actual Hours should be 1 or  greater then 1");
        setCreateButtonLoad(false);
      } else if (!categoryTimeCreditArray?.map((e) => e?.ActualMinutes)) {
        toast.error("Category Actual Minutes should be 1 or  greater then 1");
        setCreateButtonLoad(false);
      } else if (
        createPackageObj?.refundableHours === undefined ||
        createPackageObj?.refundableMinutes === undefined
      ) {
        toast.error("Refund Limits is required");
        setCreateButtonLoad(false);
      } else if (createPackageObj?.Price === undefined) {
        toast.error("Price is required");
        setCreateButtonLoad(false);
      } else if (createPackageObj?.Description === undefined) {
        toast.error("Description is required");
        setCreateButtonLoad(false);
      } else if (createPackageObj?.Validity === undefined) {
        toast.error("Validity is required");
        setCreateButtonLoad(false);
      } else if (createPackageObj?.Location === undefined) {
        toast.error("Location is required");
        setCreateButtonLoad(false);
      } else if (createPackageObj?.Inventory === undefined) {
        toast.error("Inventory is required");
        setCreateButtonLoad(false);
      } else if (createPackageObj?.Priority === undefined) {
        toast.error("Priority is required");
        setCreateButtonLoad(false);
      } else if (selectedFiles === "") {
        toast.error("Actual image is required");
        setCreateButtonLoad(false);
      } else if (imageTwoArea === "") {
        toast.error("Cover Image is required");
        setCreateButtonLoad(false);
      } else if (imageOneArea === "") {
        toast.error("Profile Image is required");
        setCreateButtonLoad(false);
      } else if (createPackageObj?.Conditions === undefined) {
        toast.error("Conditions  are required");
        setCreateButtonLoad(false);
      } else if (detailImageButton === false) {
        toast.error("Please Save Detailed Image");
        setCreateButtonLoad(false);
      } else if (createPackageObj?.Priority < 1) {
        toast.error("Priority should not be less than 1");
        setCreateButtonLoad(false);
      } else if (createPackageObj?.Inventory < 1) {
        toast.error("Inventory should not be less than 1");
        setCreateButtonLoad(false);
      } else if (createPackageObj?.Price < 1) {
        toast.error("Price should not be less than 1");
        setCreateButtonLoad(false);
      } else if (cardImageButton === false) {
        toast.error("Please Save Cover Image");
        setCreateButtonLoad(false);
      } else if (categoryTimeCreditArray?.map((e) => e?.name) === undefined) {
        toast.error("category name should not be empty");
        setCreateButtonLoad(false);
      } else {
        const apiObjectPakcage = {
          type: createPackageObj?.type,
          visibility: createPackageObj?.ActivateToggle
            ? createPackageObj?.ActivateToggle
            : false,
          name: `${createPackageObj?.Name}`,
          price: Number(createPackageObj?.Price),
          description: `${createPackageObj?.Description}`,
          categories: categoryTimeCreditArray?.map((e) => {
            return {
              id: e?.id,
              name: e?.name,

              displayTime:
                Number(e?.DisplayHours == null ? 0 : e?.DisplayHours * 60) +
                Number(e?.DisplayMinutes == null ? 0 : e?.DisplayMinutes),

              actualTime:
                Number(e?.ActualHours == null ? 0 : e?.ActualHours * 60) +
                Number(e?.ActualMinutes == null ? 0 : e?.ActualMinutes),
            };
          }),
          validity: `${createPackageObj?.Validity}`,
          businesses: createPackageObj?.Location?.map((e) => ({
            id: Number(e.value),
            name: `${e.label}`,
          })),
          inventory: Number(createPackageObj?.Inventory),
          priority: Number(createPackageObj?.Priority),
          showTag: createPackageObj?.TagToggle
            ? createPackageObj?.TagToggle
            : false,
          tags: `${
            createPackageObj?.TagInput === undefined
              ? ""
              : createPackageObj?.TagInput
          }`,
          customers:
            createPackageObj?.CustomersData === undefined
              ? []
              : createPackageObj?.CustomersData?.map((e) => ({
                  id: e.value,
                  name: e.label,
                })),
          profileImage: `${imageOneArea}`,
          coverImage: `${imageTwoArea}`,
          actualImage: selectedFiles,
          termsAndCondidtions: `${createPackageObj?.Conditions}`,
          refundAbleMinutes: Number(
            Number(createPackageObj?.refundableHours * 60) +
              Number(createPackageObj?.refundableMinutes)
          ),
        };

        fetch(`${DIOM_BASED_URLS}/packages`, {
          method: "POST",
          headers: {
            Accept: "application/json, text/plain",
            "Content-Type": "application/json;charset=UTF-8",
            "Access-Control-Allow-Origin": "*",
            Authorization: "Bearer " + token,
          },

          body: JSON.stringify(apiObjectPakcage),
        })
          .then((response) => {
            if (response.ok) {
              history.push("/packages");
              toast.success("Successfully Created");
              setCreateButtonLoad(false);
            } else if (response?.Status == 413) {
              toast.error("the request was larger than the server can handle");
              setCreateButtonLoad(false);
            } else {
              return response.json();
            }
          })
          .then((result3) => {
            if (result3?.error) {
              toast.error(result3?.error?.message);
              setCreateButtonLoad(false);
            }
          })
          .catch((error) => {
            toast.error("Error: ", error);
            console.log(error);
            setCreateButtonLoad(false);
          });
      }
    }, 1000);
  };

  // ********************** Card View Base64*********************

  const cardViewBase64Func = () => {
    setCardViewLoading(true);
    const apiObjectPakcage = {
      file: `${imageOneArea}`,
    };
    fetch(`${DIOM_BASED_URLS}/base64-upload`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(apiObjectPakcage),
    })
      .then((response) => {
        return response.json();
      })
      .then((result3) => {
        setCardViewLoading(false);
        if (result3?.data?.Location) {
          toast.success("Image saved Successfully");
          setCardImageButton(true);
        }
        setImageOneArea(result3?.data?.Location);

        if (result3?.error) {
          setCardViewLoading(false);
          // toast.error(result3?.error?.message);
          toast.error("Card Image is not set Due to size of Image");
        }
      })
      .catch((error) => {
        setCardViewLoading(false);
        toast.error("Error: ", error);
        toast.error("Card Image  is not set Due to size of Image");
      });
  };

  // ********************** Detailed View Based64*************
  const detailedViewBase64Func = () => {
    setDetailedViewLoading(true);
    const apiObjectPakcage = {
      file: `${imageTwoArea}`,
    };
    fetch(`${DIOM_BASED_URLS}/base64-upload`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(apiObjectPakcage),
    })
      .then((response) => {
        return response.json();
      })
      .then((result3) => {
        setDetailedViewLoading(false);
        if (result3?.data?.Location) {
          toast.success("Image saved Successfully");
          setDetailImageButton(true);
        }
        setImageTwoArea(result3?.data?.Location);

        if (result3?.error) {
          setDetailedViewLoading(false);
          toast.error("Detailed Image is not set Due to size of Image");
          // toast.error(result3?.error?.message);
        }
      })
      .catch((error) => {
        setDetailedViewLoading(false);
        toast.error("Detailed Image is not set Due to size of Image");
        console.log(error);
      });
  };

  return {
    setCreatePackageObj,
    CustomerSearchLoading,
    customerfinalOptions,
    handleCustomerInputChange,
    cardViewLoading,
    detailedViewLoading,
    confirmHidden,
    zoom,
    setZoom,
    profileMedia,
    setProfileMedia,
    setCoverMedia,
    coverMedia,
    zoomCover,
    setZoomCover,
    cropCover,
    setCropCover,
    onCropCompleteCover,
    imageRefCover,
    setImageRefCover,
    loadedImgCover,
    PostPackagesFunc,
    pkgActiveateToggleFunc,
    pkgTagInputFunc,
    pkgTagToggleFunc,
    pkgValidityFunc,
    pkgLocationFunc,
    pkgInventoryFunc,
    pkgPriorityFunc,
    pkgCustomersFunc,
    pkgConditionsFunc,
    pkgCategory3Func,
    pkgDisplayHours3Func,
    pkgDisplayMinutes3Func,
    pkgActualHours3Func,
    pkgActualMinutes3Func,
    pkgCategory2Func,
    pkgDisplayHours2Func,
    pkgDisplayMinutes2Func,
    pkgActualHours2Func,
    pkgActualMinutes2Func,
    pkgNameFunc,
    pkgPriceFunc,
    pkgDescriptionFunc,
    pkgCategory1Func,
    pkgDisplayHours1Func,
    pkgDisplayMinutes1Func,
    pkgActualHours1Func,
    pkgActualMinutes1Func,
    refundableMinutes,
    refundableHours,
    createPackageObj,
    loadingLocations,
    PackageValidityOption,
    locationsData,
    secondRowData,
    setSecondRowData,
    secondState,
    setSecondState,
    thirdRowData,
    setThirdRowData,
    thirdState,
    setThirdState,
    fourthRowData,
    setFourthRowData,
    fourthState,
    setFourthState,
    selectedFiles,
    setSelectedFiles,
    crop11,
    setCrop11,
    crop22,
    setCrop22,
    crop,
    setCrop,
    zoom1,
    setZoom1,
    zoom2,
    setZoom2,
    count,
    setCount,
    croppedImage,
    setCroppedImage,
    cropedImg1,
    Offsymbol,
    OnSymbol,
    timeOptions,
    cardViewBase64Func,
    imgCC,
    setImgCC,
    testImg,
    setTestImg,
    secondRowDataFunc,
    rowSecondDellFunc,
    thirdRowDataFunc,
    rowThreeDellFunc,
    formatBytes,
    handleAcceptedFiles,
    coverImgProcess,
    setCoverImgUrl,
    setImgCC,
    confirmActualImg,
    imageRefCover,
    onCropCompleteProfile,
    setConfirmHidden,
    detailedViewBase64Func,
    detailImageButton,
    cardImageButton,
    createButtonLoad,
    teamArr,
    packagesCustomerSearchData,

    // addmorepracticefunc,
  };
};

export default UseCreatePackage;
