import { useEffect, useState } from "react";
import { useUserRole } from "../pages/userRoles/useUserRoles";

function UserPromissionFetcher(ResourceName) {
  //0) Use a state variable to store userRoleData
  const [userResourcePermissions, setUserResourcePermissions] = useState(null);
  // 1)  getting data Role Access from API
  const { userRoleData, isLoading } = useUserRole();
  // 2)Assign userRoleData to userPermissions when it changes
  useEffect(() => {
    if (!isLoading) {
      const assignedRole = localStorage.getItem("assignedRole");
      // console.log(assignedRole, "assignedRole");
      // 3) If resourceName have then send permissions according that Resource
      if (ResourceName) {
        setUserResourcePermissions(
          ...userRoleData
            .filter((userRole) => userRole.name === assignedRole)
            .map((userRole) => userRole.permissions)[0]
            .filter((permissions) => permissions.resourceName === ResourceName)
            .map((permissions) => permissions.actions)
        );
      } else {
        // 4) Otherwise send permissions according to assignRole
        setUserResourcePermissions(
          userRoleData
            .filter((userRole) => userRole.name === assignedRole)
            .map((userRole) => userRole.permissions)[0]
          // .map((permissions) => permissions.actions)
        );
      }
    }
  }, [userRoleData, isLoading, ResourceName]);

  return userResourcePermissions;
}

export default UserPromissionFetcher;
