import { useEffect, useState } from "react";
import { usePaginatedQuery } from "../../../hooks/query";
import { getroleusers, getuserroles } from "../../../APIS/userProfle";
import { useParams } from "react-router-dom";
import { DIOM_BASED_URLS } from "../../../config/url";
import { useQueryClient } from "react-query";
import { toast } from "react-toastify";
const token = localStorage.getItem("Token");

export const useRoleUsers = () => {
  const [pageSize, setPageSize] = useState(30);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showModel, setshowModel] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [activeSubmitBtn, setacticeSubmitBtn] = useState(false);
  const [showForgetModel, setShowForgetModel] = useState(false);
  const [userEmail, setUserEmail] = useState(false);
  const [updatedRoleUserData, setUpdatedRoleUserData] = useState(null);

  const queryClient = useQueryClient();

  const { role } = useParams();
  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);

  //*1) Get role users
  const {
    data: { data: roleUserData, hasNextPage, hasPreviousPage, total },
    isLoading,
  } = usePaginatedQuery(["roleusers", token, role], () =>
    getroleusers(token, role)
  );

  //*2) Manipulate roleUsers data
  useEffect(() => {
    if (!isLoading) {
      const upDatededData = roleUserData?.map((roleUser) => ({
        ...roleUser,
        isSelected: false,
        dummyField: true,
      }));

      //1) Set upDatedUserData
      setUpdatedRoleUserData(upDatededData);
    }
  }, [isLoading, roleUserData]);

  //*3) Update isSelectedproperty and show the dropDown
  function toggleDropdown(rowId) {
    const updatedData = updatedRoleUserData.map((userData) =>
      userData._id === rowId
        ? { ...userData, isSelected: !userData.isSelected }
        : { ...userData, isSelected: false }
    );

    setUpdatedRoleUserData(updatedData);
  }

  //*4) Update user Role
  const updateUserRole = async () => {
    const res = await fetch(
      `${DIOM_BASED_URLS}/user/${selectedUserData._id}/role`,
      {
        method: "PATCH",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          assignedRole: selectedRole,
        }),
      }
    );

    if (!res.ok) {
      toast.error(`Somthing Went Wrong`);
    } else {
      const data = await res.json();
      toast.success(data.message);
      queryClient.invalidateQueries("roleusers");
    }
  };
  //*5) Get data User Role
  const {
    data: { data: userRoleData },
  } = usePaginatedQuery(["userrole"], () =>
    getuserroles(pageSize, currentPage, token)
  );

  //*6) MakeData for dropDown
  const dropDownData = userRoleData
    .map((role) => {
      return {
        value: role.name,
        label: role.name,
      };
    })
    .filter((role) => role.label !== "Admin");

  // Toggle fn for page size drop down..
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  //  Page Size set Drop Down
  const pagelengthnum = (e) => {
    if (e.target.value === "1") {
      setPageSize(10);
    } else if (e.target.value === "2") {
      setPageSize(30);
    } else if (e.target.value === "3") {
      setPageSize(50);
    }
  };
  const pageOptions = {
    sizePerPage: pageSize,
    // totalSize: total ?? 0,
    totalSize: 0,
    custom: true,
  };
  // *7)When user click on edit btn get row data
  const getUserData = (row) => {
    setSelectedUserData(row);
  };

  async function passwordChangeHandler() {
    const body = {
      email: userEmail,
    };
    const response = await fetch(`${DIOM_BASED_URLS}/users/forgot`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    });

    const data = await response.json();
    if (data.statusCode === 200) {
      toast.success("This user password change check email");
    }
    console.log(data, ">>>>response>>>");
  }
  return {
    getUserData,
    pageOptions,
    roleUserData,
    isLoading,
    toggle,
    pagelengthnum,
    changeCurrentPage,
    currentPage,
    hasNextPage,
    pageSize,
    role,
    showModel,
    setshowModel,
    selectedUserData,
    dropDownData,
    setSelectedRole,
    activeSubmitBtn,
    setacticeSubmitBtn,
    updateUserRole,
    passwordChangeHandler,
    setShowForgetModel,
    showForgetModel,
    userEmail,
    setUserEmail,
    updatedRoleUserData,
    toggleDropdown,
    role
  };
};
