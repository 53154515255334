import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DIOM_BASED_URLS } from "../../../../config/url";
import { useHistory } from "react-router-dom";

const UseDetailedPackage = () => {
  let history = useHistory();
  const QueryClient = useQueryClient();
  const token = localStorage.getItem("Token");
  const { id } = useParams();
  const [modal_static5, setModal_static5] = useState(false);

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static5 = () => {
    setModal_static5(!modal_static5);
    removeBodyCss();
  };
  const delPkgFunc = async () => {
    const apiObjectPakcage = {
      command: "ARCHIVE_PACKAGE",
      value: `${id}`,
      key: `${id}`,
    };
    const res = await fetch(`${DIOM_BASED_URLS}/packages/run-commands`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token,
      },

      body: JSON.stringify(apiObjectPakcage),
    });

    if (!res.ok) {
      if (res.status === 401) {
        toast.error(resJson?.error?.message);
        setModal_static5(false);
      }
      const resJson = await res.json();
      toast.error(resJson?.error?.message);
      setModal_static5(false);
    } else {
      setModal_static5(false);
      history.push("/packages");
      toast.success(" Successfully Deleted");
      QueryClient.invalidateQueries("Packages");
    }
  };

  const packageActiveFunc = (e) => {
    if (id) {
      fetch(`${DIOM_BASED_URLS}/packages/${id}`, {
        method: "PATCH",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          visibility: true,
        }),
      })
        .then((result3) => {
          if (result3.status === 200) {
            toast.success("Package Activated");

            QueryClient.invalidateQueries("Package");
          } else if (result3.status === 204) {
            QueryClient.invalidateQueries("Package");
            toast.success("Package Activated");
          } else {
            toast.error(" Something went wrong");
          }
        })
        .catch((error) => toast.error(" Something went wrong"));
    }
  };

  const packageInactiveFunc = (e) => {
    fetch(`${DIOM_BASED_URLS}/packages/${id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        "Access-Control-Allow-Origin": "*",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        visibility: false,
      }),
    })
      .then((result3) => {
        if (result3.status === 200) {
          toast.success("Package Deactivated");

          QueryClient.invalidateQueries("Package");
        } else if (result3.status === 204) {
          QueryClient.invalidateQueries("Package");
          toast.success("Package Deactivated");
        } else {
          toast.error(" Something went wrong");
        }
      })
      .catch((error) => toast.error(" Something went wrong"));
  };

  return {
    modal_static5,
    delPkgFunc,
    tog_static5,
    setModal_static5,
    packageInactiveFunc,
    packageActiveFunc,
  };
};

export default UseDetailedPackage;
