import { useState } from "react";
import {
  getInvoices,
  getMembershipInvoices,
  getPackageInvoices,
  getTeamInvoices,
} from "../../../APIS/invoices";
// import { useAllDataQuery, usePaginatedQuery } from "../../hooks/query";
import { usePaginatedQuery } from "../../../hooks/query";
import LoaderHook from "../../../hooks/loaderHook";
import { useHistory } from "react-router-dom";
export const useInvoices = () => {
  let history = useHistory();
  const { setLoading } = LoaderHook();
  const [isOpen, setIsOpen] = useState(false);
  const token = localStorage.getItem("Token");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);
  const [activeTabJustify, setActiveTabJustify] = useState("1");

  const InvoicesPackagedata = [
    {
      invoiceId: "INV-2264",
      name: "ARI Satau",
      status: "paid",
      createdAt: "27 July,2021",
      totalPaid: "SAR 134",
      paymentMethod: "DC VISA",
    },
  ];

  // *******booking Invoices*****
  const {
    data: { data: Invoiceslistingdata, hasNextPage, hasPreviousPage, total },
    isLoading,
  } = usePaginatedQuery(["Invoices", `${pageSize}`, `${currentPage}`], () =>
    getInvoices(pageSize, currentPage, token, history)
  );

  // *******package Invoices*****
  const {
    data: {
      data: packageListingData,
      hasNextPage: packageHasNextPage,
      hasPreviousPage: packageHasPreviousPage,
      total: packageTotal,
    },
    isLoading: packageIsLoading,
  } = usePaginatedQuery(
    ["PackageInvoices", `${pageSize}`, `${currentPage}`],
    () => getPackageInvoices(pageSize, currentPage, token, history)
  );

  // *******Team Invoices*****
  const {
    data: { data: Teamlistingdata },
    teamIsLoading,
  } = usePaginatedQuery(
    ["Teamlistingdata", `${pageSize}`, `${currentPage}`],
    () => getTeamInvoices(pageSize, currentPage, token, history)
  );

  // ********** MemberShip Invoices *********
  const {
    data: { data: membershipListingData },
    membershipListingLoading,
  } = usePaginatedQuery(
    ["membershipListingData", `${pageSize}`, `${currentPage}`],
    () => getMembershipInvoices(pageSize, currentPage, token, history)
  );

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const pagelengthnum = (e) => {
    if (e.target.value === "1") {
      setPageSize(10);
    } else if (e.target.value === "2") {
      setPageSize(30);
    } else if (e.target.value === "3") {
      setPageSize(50);
    }
  };

  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      setActiveTabJustify(tab);
    }
  };

  const pageOptions = {
    sizePerPage: pageSize,
    totalSize: activeTabJustify === "1" ? total : packageTotal ?? 0,
    custom: true,
  };
  const loadingFunc = () => {
    setLoading(isLoading);
  };

  // useEffect(() => {
  //   loadingFunc();
  //   // FOR PRE-FETCHING NEXT PAGE
  //   // console.log(hasNextPage);
  //   if (hasNextPage) {
  //     const nextPage = currentPage + 1;
  //     queryClient.prefetchQuery(["Invoices", `${pageSize}`,`${currentPage}`], () =>
  //       getInvoices(pageSize, nextPage, token,history)
  //     );
  //   }
  // }, [currentPage, queryClient, isLoading]);

  return {
    membershipListingData,
    Teamlistingdata,
    packageListingData,
    InvoicesPackagedata,
    currentPage,
    pageOptions,
    Invoiceslistingdata,
    hasNextPage,
    hasPreviousPage,
    total,
    isLoading,
    activeTabJustify,
    setActiveTabJustify,
    pagelengthnum,
    pageSize,
    toggle,
    isOpen,
    changeCurrentPage,
    toggleCustomJustified,
    packageHasNextPage,
    packageHasPreviousPage,
    packageTotal,
    packageIsLoading,
  };
};
