import React from "react";
import {
  Row,
  Col,
  Button,
  Card,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";
import Switch from "react-switch";
import Instant_checkin from "../../../.././assets/images/instant_checkin.svg";
import UseEditInstantCheckin from "./useEditInstantCheckin";
import Select from "react-select";
import ".././css/editInstantChechin.css";
import { Link } from "react-router-dom";
import UserPromissionFetcher from "../../../../helpers/getUserPerimissions";
import Spinner from "../../../../components/Common/Loader";
import NoAccess from "../../../../components/Common/NoAccess";

const EditInstantCheckin = () => {
  const userPermissions = UserPromissionFetcher("bookings");
  const {
    Offsymbol,
    OnSymbol,
    resourceTypesDropDown,
    instantData,
    setInstantData,
    saveInstantCheckinData,
  } = UseEditInstantCheckin();

  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.read ? (
        <div className="page-content ci_bg">
          <Row>
            <Col md={12}>
              <Row>
                <Col md={8} className="ci_Title">
                  Instant Check-In Settings
                </Col>
                {userPermissions.update && (
                  <>
                    <Col md={2}>
                      <Link to="/instant_checkin">
                        <Button color="success" outline className="w-100">
                          Cancel
                        </Button>
                      </Link>
                    </Col>
                    <Col md={2}>
                      <Button
                        color="success"
                        className="w-100"
                        onClick={saveInstantCheckinData}
                      >
                        Save
                      </Button>
                    </Col>
                  </>
                )}
              </Row>
              <div
                style={{
                  pointerEvents: userPermissions.update ? "auto" : "none",
                }}
              >
                <Row>
                  <Col md={12}>
                    <Card className="ci_card  p-4 mt-4">
                      <Row>
                        <Col md={12}>
                          <p className="ci_card_heading1">
                            Instant Check-in Feature
                          </p>
                          <span>
                            <img src={Instant_checkin} />
                          </span>
                          <span className="ci_card_guide">Guide</span>
                          <p className="ci_card_hesading2">
                            This setting controls whether instant checkin is
                            enable or disabled for the users.
                          </p>
                          <Row>
                            <Col md={5}>
                              <p className="ci_card_guide">
                                Instant Check-In enabled
                              </p>
                            </Col>
                            <Col md={1}>
                              <p className="ci_instant_no">No</p>
                            </Col>
                            <Col md={1}>
                              <Switch
                                name="always"
                                onHandleColor="#16b185"
                                width={70}
                                uncheckedIcon={Offsymbol(<small>NO</small>)}
                                checkedIcon={OnSymbol(<small>YES</small>)}
                                onColor="#a2a2a2"
                                checked={instantData?.enabled}
                                onChange={(e) => {
                                  setInstantData({
                                    ...instantData,
                                    enabled: e,
                                  });
                                }}
                                className="mr-1 mt-2  "
                              />
                            </Col>
                            <Col md={1}>
                              <p className="ci_instant_yes">Yes</p>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Card className="ci_card  p-4">
                      <Row>
                        <Col md={12}>
                          <p className="ci_card_heading1">Resource type</p>
                          <span>
                            <img src={Instant_checkin} />
                          </span>
                          <span className="ci_card_guide">Guide</span>
                          <p className="ci_card_hesading2">
                            The following list of resources will be available in
                            the instant check-in menu for the customers
                          </p>
                          <Row>
                            <Col md={4} className="">
                              <Select
                                value={instantData?.resourceTypes}
                                placeholder="Select Resources Types"
                                isMulti={true}
                                options={resourceTypesDropDown}
                                onChange={(e) => {
                                  setInstantData({
                                    ...instantData,
                                    resourceTypes: e,
                                  });
                                }}
                                isSearchable={true}
                                hideSelectedOptions={true}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Card className="ci_card  p-4">
                      <Row>
                        <Col md={12}>
                          <p className="ci_card_heading1">Booking Duration</p>
                          <span>
                            <img src={Instant_checkin} />
                          </span>
                          <span className="ci_card_guide">Guide</span>
                          <p className="ci_card_hesading2">
                            Every Instant check-in booking is created for the
                            this duration if the DIOM space time allows it. If a
                            space closes earlier, then a booking will end at the
                            closing time.
                          </p>
                          <Row>
                            <Col md={2} className="">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                                <Input
                                  placeholder=""
                                  value={instantData?.bookingDuration}
                                  type="number"
                                  onChange={(e) => {
                                    setInstantData({
                                      ...instantData,
                                      bookingDuration: e?.target.value,
                                    });
                                  }}
                                />
                                <InputGroupText>Minutes</InputGroupText>
                              </InputGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Card className="ci_card  p-4">
                      <Row>
                        <Col md={12}>
                          <p className="ci_card_heading1">Geofence distance</p>
                          <span>
                            <img src={Instant_checkin} />
                          </span>
                          <span className="ci_card_guide">Guide</span>
                          <p className="ci_card_hesading2">
                            GeoFence Radius and distance verification interval.
                          </p>
                          <Row>
                            <Col md={2} className="">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                                <Input
                                  placeholder=""
                                  value={instantData?.geofenceDistance}
                                  type="number"
                                  onChange={(e) => {
                                    setInstantData({
                                      ...instantData,
                                      geofenceDistance: e?.target.value,
                                    });
                                  }}
                                />
                                <InputGroupText>Meters</InputGroupText>
                              </InputGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Card className="ci_card  p-4">
                      <Row>
                        <Col md={12}>
                          <p className="ci_card_heading1">
                            Minimum credits availability
                          </p>
                          <span>
                            <img src={Instant_checkin} />
                          </span>
                          <span className="ci_card_guide">Guide</span>
                          <p className="ci_card_hesading2">
                            The minimum amount of credits that should be
                            available in the user’s wallet for them to be
                            eligible for instant check-in.
                          </p>
                          <Row>
                            <Col md={2} className="">
                              <InputGroup>
                                <InputGroupAddon addonType="prepend"></InputGroupAddon>
                                <Input
                                  placeholder=""
                                  value={instantData?.minimumCredits}
                                  type="number"
                                  onChange={(e) => {
                                    setInstantData({
                                      ...instantData,
                                      minimumCredits: e?.target.value,
                                    });
                                  }}
                                />
                                <InputGroupText>Credits</InputGroupText>
                              </InputGroup>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Card className="ci_card p-4">
                      <Row>
                        <Col md={12}>
                          <p className="ci_card_heading1">
                            Low Balance Notification
                          </p>
                          <span>
                            <img src={Instant_checkin} />
                          </span>
                          <span className="ci_card_guide">Guide</span>
                          <p className="ci_card_hesading2">
                            Set a notification that is sent to the user
                            everytime their credits for any package category
                            falls below the set amount.
                          </p>
                          <Row>
                            <Col md={6} className="ci_low_balance">
                              <p>
                                Generate notification when credits fall below
                                the following amount
                              </p>
                            </Col>
                            <Col md={1}>
                              <p className="ci_instant_no">No</p>
                            </Col>
                            <Col md={1}>
                              <Switch
                                name="always"
                                onHandleColor="#16b185"
                                width={70}
                                uncheckedIcon={Offsymbol(<small>NO</small>)}
                                checkedIcon={OnSymbol(<small>YES</small>)}
                                onColor="#a2a2a2"
                                checked={
                                  instantData?.isLowBalanceNotificationEnabled
                                }
                                onChange={(e) => {
                                  setInstantData({
                                    ...instantData,
                                    isLowBalanceNotificationEnabled: e,
                                  });
                                }}
                                className="mr-1 mt-2  "
                              />
                            </Col>
                            <Col md={1}>
                              <p className="ci_instant_yes">Yes</p>
                            </Col>

                            <Row>
                              <Col md={2}>
                                <Input
                                  placeholder=""
                                  value={instantData?.lowBalanceNotification}
                                  type="number"
                                  onChange={(e) => {
                                    setInstantData({
                                      ...instantData,
                                      lowBalanceNotification: e?.target.value,
                                    });
                                  }}
                                />
                              </Col>
                            </Row>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default EditInstantCheckin;
