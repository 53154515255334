// *********** Get Al PAckage FAQS*************

import { DIOM_BASED_URLS } from "../config/url";

export const getAllPAckageFaqs = async (token = "", history) => {
  // return await (
  const res = await fetch(`${DIOM_BASED_URLS}/packages-faqs`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  // ).json();
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();

  return {
    data: resJson?.data,
  };
};

// *********** Get one PAckage FAQS*************

export const getPAckageFaq = async (token = "", history, id = "") => {
  if (id !== "") {
    const res = await fetch(`${DIOM_BASED_URLS}/packages-faqs/${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    // ).json();
    if (!res.ok) {
      if (res.status === 401) {
        history.push("/login");
        throw new Error(resJson.error.message);
      }
      const resJson = await res.json();
      throw new Error(resJson.error.message);
    }
    const resJson = await res?.json();

    return {
      data: resJson,
    };
  }
};
