import { useQuery, useQueryClient } from "react-query";
import { getuserroles } from "../../APIS/userProfle";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { usePaginatedQuery } from "../../hooks/query";
import { DIOM_BASED_URLS } from "../../config/url";
import { toast } from "react-toastify";

export const useUserRole = () => {
  let history = useHistory();
  const token = localStorage.getItem("Token");

  const [modleShow, setModelShow] = useState(false);
  const [modleShow1, setModelShow1] = useState(false);
  const [resetValue, setResetValue] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [InputValue, setInputValue] = useState("");
  const [inputActive, setInputActive] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [pageSize, setPageSize] = useState(50);
  // const [deleteBtnActive, setDeleteBtnACtive] = useState(false);
  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);
  const queryClient = useQueryClient();

  const {
    data: { data: userRoleData, hasNextPage, hasPreviousPage, total },
    isLoading,
  } = usePaginatedQuery(["userrole", `${currentPage}`, `${pageSize}`], () =>
    getuserroles(pageSize, currentPage, token, history)
  );

  //  Page Size set Drop Down
  const pagelengthnum = (e) => {
    if (e.target.value === "1") {
      setPageSize(10);
    } else if (e.target.value === "2") {
      setPageSize(30);
    } else if (e.target.value === "3") {
      setPageSize(50);
    }
  };

  const pageOptions = {
    sizePerPage: pageSize,
    totalSize: total ?? 0,
    custom: true,
  };
  // Toggle fn for page size drop down..
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  // useEffect(() => {
  //   // FOR PRE-FETCHING NEXT PAGE
  //   if (hasNextPage) {
  //     const nextPage = currentPage + 1;
  //     queryClient.prefetchQuery(
  //       ["user-role", `${currentPage}`, `${pageSize}`],
  //       () => getuserroles(pageSize, nextPage, token, history)
  //     );
  //   }
  // }, [currentPage, queryClient]);

  // Create Role API
  const createRoleFunc = () => {
    // setModelShow(true);
  };
  // Read Input Value and Active Handler
  const handleInputChange = (data) => {
    // console.log(data, "data from field>>>>>>");
    fetch(`${DIOM_BASED_URLS}/roles`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        name: data.roleName,
      }),
    })
      .then((res) => {
        console.log(res, "res");
        if (res.status === 500) {
          toast.error(`Internal Server Error`);
          return;
        }
        if (res.status === 200) {
          toast.success(`Role Create Successfully`);
          setResetValue(true)
          setModelShow(false);
          queryClient.invalidateQueries("userrole");

          return;
        }
        if (!res.ok) {
          toast.error(`Something went wrong`);
          return;
        }
      })
     
  };
  // Delete API
  const deleteRoleFnc = () => {
   
    setModelShow1(false);
    fetch(`${DIOM_BASED_URLS}/roles/${roleId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((result3) => {
        if (result3.status === 200) {
          toast.success(" Successfully Deleted");
          queryClient.invalidateQueries("userrole");
        } else if (result3.status === 204) {
          toast.success(" Successfully Deleted");
          queryClient.invalidateQueries("userrole");
        } else if (result3.status === 400) {
          toast.error(
            "Some users are assigned this role please change their role first"
          );
        } else {
          toast.error(" Something went wrong");
        }
      })
      .catch((error) => {
        toast.error(" Something went wrong");
      });
    //
  };
  return {
    handleInputChange,
    currentPage,
    pageOptions,
    userRoleData,
    hasNextPage,
    hasPreviousPage,
    toggle,
    isOpen,
    setIsOpen,
    total,
    pagelengthnum,
    pageSize,
    isLoading,
    changeCurrentPage,
    createRoleFunc,
    modleShow,
    setModelShow,
    setInputValue,
    inputActive,
    setInputActive,
    deleteRoleFnc,
    setRoleId,
    modleShow1,
    setModelShow1,
    resetValue,
    setResetValue
  };
};
