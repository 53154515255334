import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getDiscountAmount } from "../../../../APIS/discountAmount";
import { DIOM_BASED_URLS } from "../../../../config/url";

const UseEditDiscount = () => {
  let history = useHistory();
  const { id } = useParams();
  const token = localStorage.getItem("Token");
  // const [activeTabJustify, setActiveTabJustify] = useState("1");
  const [modal_static3, setModal_static3] = useState(false);
  const [modal_static4, setModal_static4] = useState(false);
  const [cancelBtnState, setCancelBtnState] = useState(false);
  const [currentPage, setCurrentPage] = useState(false);
  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);

  const [restrictions, setRestrictions] = useState();

  const QueryClient = useQueryClient();

  // const total=50;

  // const DiscountAmounts
  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };
  const tog_static3 = () => {
    setModal_static3(!modal_static3);
    removeBodyCss();
  };
  const tog_static4 = () => {
    setModal_static4(!modal_static4);
    removeBodyCss();
  };

  const { data: DiscountAamount, isLoading } = useQuery(
    ["DiscountAmount", id],
    () => getDiscountAmount(token, history, id)
  );
  const discountAmountData = DiscountAamount?.data;

  const minimumLimitationsMinutsFunc = (e) => {
    setCancelBtnState(true);
    setRestrictions({
      ...restrictions,
      minimumMinutes: e.target.value,
    });
  };
  const minimumLimitationsHoursFunc = (e) => {
    setCancelBtnState(true);
    setRestrictions({
      ...restrictions,
      minimumHours: e.target.value,
    });
  };

  const maximumLimitationsMinutsFunc = (e) => {
    setCancelBtnState(true);
    setRestrictions({
      ...restrictions,
      maximumMinutes: e.target.value,
    });
  };
  const maximumLimitationsHoursFunc = (e) => {
    setCancelBtnState(true);
    setRestrictions({
      ...restrictions,
      maximumHours: e.target.value,
    });
  };

  const cancelBtnHandler = () => {
    QueryClient.invalidateQueries("DiscountAmount");
    QueryClient.invalidateQueries("discountAmountData");
    setRestrictions({
      hasMininumHourlyLimit: discountAmountData?.hasMininumHourlyLimit,
      // mininumHourlyLimit:discountAmountData?.mininumHourlyLimit,
      minimumHours: discountAmountData?.minimumHours,
      minimumMinutes: discountAmountData?.minimumMinutes,
      hasMaximumHourlyLimit: discountAmountData?.hasMaximumHourlyLimit,
      // maximumHourlyLimit:discountAmountData?.maximumHourlyLimit,
      maximumHours: discountAmountData?.maximumHours,
      maximumMinutes: discountAmountData?.maximumMinutes,
    });
    setCancelBtnState(false);
    setModal_static3(false);
  };

  const discoutUpdateFunc = () => {
    if (restrictions?.minimumHours < 1) {
      toast.error("Hourly limit should be not less than 1");
      setModal_static4(false);
    } else if (
      restrictions?.hasMaximumHourlyLimit === "true" ||
      Number(restrictions?.maximumHours) * 60 +
        Number(restrictions?.maximumMinutes) <
        Number(restrictions?.minimumHours) * 60 +
          (Number(restrictions?.minimumMinutes) + 16)
    ) {
      toast.error(
        "Maximum limit should be 15 minutes greater than Minimum Limit."
      );
      setModal_static4(false);
    } else if (
      restrictions?.hasMininumHourlyLimit === "true" ||
      restrictions?.minimumMinutes > 59
    ) {
      toast.error("Minutes range is 0 - 59");
      setModal_static4(false);
    }
    // else if( restrictions?.hasMaximumHourlyLimit === true ){
    else if (
      restrictions?.hasMaximumHourlyLimit === "true" ||
      restrictions?.maximumMinutes > 59
    ) {
      toast.error("Minutes range is 0 - 59");
      setModal_static4(false);
    }
    // }
    else {
      fetch(`${DIOM_BASED_URLS}/discount-codes/${id}`, {
        method: "PATCH",
        // redirect: "follow",

        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          hasMininumHourlyLimit: restrictions?.hasMininumHourlyLimit,
          minimumHours: Number(restrictions?.minimumHours),
          minimumMinutes: Number(restrictions?.minimumMinutes),
          // "mininumHourlyLimit": Number(restrictions?.mininumHourlyLimit),
          hasMaximumHourlyLimit: restrictions?.hasMaximumHourlyLimit,
          // "maximumHourlyLimit": Number(restrictions?.maximumHourlyLimit),
          maximumHours: Number(restrictions?.maximumHours),
          maximumMinutes: Number(restrictions?.maximumMinutes),
        }),
      }).then((response) => {
        if (!response.ok) {
          toast.success(response?.error?.message);
          setModal_static4(false);
        } else {
          setModal_static4(false);
          toast.success("Successfully Updated");
          QueryClient.invalidateQueries("DiscountAmount");

          setCancelBtnState(false);
        }
      });
    }
  };
  // }

  useEffect(() => {
    setRestrictions({
      hasMininumHourlyLimit: discountAmountData?.hasMininumHourlyLimit,
      // mininumHourlyLimit:discountAmountData?.mininumHourlyLimit,
      minimumHours: discountAmountData?.minimumHours,
      minimumMinutes: discountAmountData?.minimumMinutes,
      hasMaximumHourlyLimit: discountAmountData?.hasMaximumHourlyLimit,
      // maximumHourlyLimit:discountAmountData?.maximumHourlyLimit,
      maximumHours: discountAmountData?.maximumHours,
      maximumMinutes: discountAmountData?.maximumMinutes,
    });
  }, [discountAmountData]);

  return {
    isLoading,
    setModal_static4,
    modal_static4,
    tog_static4,
    setModal_static3,
    modal_static3,
    tog_static3,
    discountAmountData,
    cancelBtnState,
    restrictions,
    cancelBtnHandler,
    changeCurrentPage,
    minimumLimitationsMinutsFunc,
    minimumLimitationsHoursFunc,
    maximumLimitationsMinutsFunc,
    maximumLimitationsHoursFunc,
    setCancelBtnState,
    discoutUpdateFunc,
    setRestrictions,
  };
};

export default UseEditDiscount;
