import React from "react";
import {
  Button,
  Card,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import "./css/generatareport.css";
import Select from "react-select";
import UseReports from "./useReports";
import moment from "moment";
import UserPromissionFetcher from "../../helpers/getUserPerimissions";
import Spinner from "../../components/Common/Loader";
import NoAccess from "../../components/Common/NoAccess";

const Reports = () => {
  const userPermissions = UserPromissionFetcher("reports");
  // const { categoryDropDown } = UseCategoryDetail();
  const {
    categoryDropDown,
    reportTypeOptions,
    userByProfessionData,
    userbyprofessionPosition,
    userbyprofessionindustry,
    timeSlotOptions,
    locationData,
    userDropDownVisibility,
    brandData,
    reportTypeHandeler,
    timeSlotHandler,
    diomBrandHandler,
    diomLocationHandler,
    resourceTypeHandler,
    userTypeIndustryHandler,
    userTypePositionHandler,
    startdateFunc,
    endDateFunc,
    reportExport,
    reportFinalLabels,
    setReportFinalLabels,
    timeSlotValue,
    setTimeSlotValue,
    brandValue,
    setBrandValue,
    setLocationValue,
    locationValue,
    setResourceTypeValue,
    resourceTypeValue,
    setUserPositionValue,
    userPositionValue,
    setUserIndustry,
    userIndustry,
  } = UseReports();

  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.create ? (
        <div className="page-content">
          <p className="reporttoplabel">Generate a report</p>
          <Card>
            <Container>
              <Row>
                <Col md={3}>
                  <p className="reportlabel1 mt-4 ">
                    Select your report type<span className="cp_starik">*</span>
                  </p>
                </Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
              </Row>

              <Row className="mt-4">
                <Col md={12}>
                  <Select
                    options={reportTypeOptions}
                    value={{ label: reportFinalLabels.reportLabel }}
                    onChange={reportTypeHandeler}
                    classNamePrefix="select2-selection"
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md={3}>
                  <p className="reportlabel1">Select a time period</p>
                </Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
              </Row>

              <Row className="mt-2">
                <Col md={3}>
                  <p className="reportlabel2">
                    From<span className="cp_starik">*</span>
                  </p>
                </Col>
                <Col md={3}>
                  <p className="reportlabel2">
                    To<span className="cp_starik">*</span>
                  </p>
                </Col>
                <Col md={3}>
                  <p className="reportlabel2">Time Slot</p>
                </Col>
                <Col md={3}></Col>
              </Row>

              <Row>
                <Col md={3}>
                  <FormGroup>
                    <Input
                      type="date"
                      value={
                        reportFinalLabels.startDate
                          ? moment(reportFinalLabels.startDate).format(
                              "YYYY-MM-DD"
                            )
                          : ""
                      }
                      // defaultValue="2022-5-12"
                      min={moment().subtract(90, "days").format("YYYY-MM-DD")}
                      max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                      id="example-date-input"
                      onChange={(e) => {
                        startdateFunc(e);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={3}>
                  <FormGroup>
                    <Input
                      type="date"
                      // defaultValue="2021-5-12"
                      value={
                        reportFinalLabels.endDate
                          ? moment(reportFinalLabels.endDate).format(
                              "YYYY-MM-DD"
                            )
                          : ""
                      }
                      id="example-date-input"
                      onChange={(e) => {
                        endDateFunc(e);
                      }}
                      min={moment().subtract(90, "days").format("YYYY-MM-DD")}
                      max={moment().subtract(1, "days").format("YYYY-MM-DD")}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <Select
                    options={timeSlotOptions}
                    value={timeSlotValue}
                    placeholder="All Slots"
                    isMulti={true}
                    onChange={timeSlotHandler}
                    classNamePrefix="select2-selection"
                  />
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md={3}>
                  <p className="reportlabel1">Make your selections</p>
                </Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
                <Col md={3}></Col>
              </Row>

              <Row className="mt-2">
                <Col md={6}>
                  <p className="reportlabel2">Diom Brand(s)</p>
                </Col>
                <Col md={6}>
                  <p className="reportlabel2">Diom Location(s)</p>
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <FormGroup className="select2-container  ">
                    <Select
                      isMulti={true}
                      options={brandData}
                      value={brandValue}
                      onChange={(opt) => diomBrandHandler(opt)}
                      placeholder="All Brands"
                      classNamePrefix="select2-selection "
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="select2-container  ">
                    <Select
                      isMulti={true}
                      options={locationData}
                      value={locationValue}
                      onChange={
                        (opt) => diomLocationHandler(opt)
                        // console.log("options : ", opt)
                      }
                      placeholder="All Locations"
                      classNamePrefix="select2-selection"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col md={12}>
                  <p className="reportlabel2">Resource Type(s)</p>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Select
                    // value={selectedMulti}
                    placeholder="All resources"
                    isMulti={true}
                    options={categoryDropDown}
                    value={resourceTypeValue}
                    onChange={(opt) => resourceTypeHandler(opt)}
                    // classNamePrefix="select2-selection"
                  />
                </Col>
              </Row>

              {userDropDownVisibility ? (
                ""
              ) : (
                <>
                  <Row className="mt-4">
                    <Col md={6}>
                      <p className="reportlabel2">User Type(by Position)</p>
                    </Col>

                    <Col md={6}>
                      <p className="reportlabel2">User Type(by Industry)</p>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Select
                        isDisabled={userDropDownVisibility}
                        options={userbyprofessionPosition}
                        value={userPositionValue}
                        isMulti={true}
                        onChange={userTypePositionHandler}
                        classNamePrefix="select2-selection"
                        key={userTypePositionHandler.value}
                      />
                    </Col>

                    <Col md={6}>
                      <Select
                        isDisabled={userDropDownVisibility}
                        options={userbyprofessionindustry}
                        value={userIndustry}
                        isMulti={true}
                        onChange={userTypeIndustryHandler}
                        classNamePrefix="select2-selection"
                        key={userTypeIndustryHandler.value}
                      />
                    </Col>
                  </Row>
                </>
              )}

              <Row className="mt-5">
                <Col md={3}></Col>
                <Col md={userPermissions.create ? 5 : 7}></Col>
                <Col md={2}>
                  <Button
                    disabled={!userPermissions.create}
                    color="success"
                    outline
                    className="invoicesdetailbuttons waves-effect waves-light  w-100  "
                    onClick={() => {
                      // window.location.reload();
                      setReportFinalLabels([]);
                      setTimeSlotValue([]);
                      setBrandValue([]);
                      setLocationValue([]);
                      setResourceTypeValue([]);
                      setUserPositionValue([]);
                      setUserIndustry([]);
                    }}
                  >
                    Clear All
                  </Button>
                </Col>
                <Col md={2}>
                  {userPermissions.create && (
                    <Button
                      disabled={
                        !(
                          reportFinalLabels.reportLabel &&
                          reportFinalLabels.startDate &&
                          reportFinalLabels.endDate
                        )
                      }
                      color="success"
                      className="invoicesdetailbuttons waves-effect waves-light  w-100  "
                      onClick={reportExport}
                    >
                      Export
                    </Button>
                  )}
                </Col>
              </Row>

              <p></p>
            </Container>
          </Card>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default Reports;
