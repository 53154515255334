import React, { useRef } from "react";
import { Row, Col, CardBody, Button, Table } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { useReactToPrint } from "react-to-print";
import UseTeamInvoiceDetailsPage from "./useTeamInvoiceDetailsPage";
import "react-toastify/dist/ReactToastify.css";
import "../../css/InvoicesDetailPage.css";
import InvoicesDetailPrint from "./components/InvoicesDetailPrint";

const TeamInvoiceDetailsPage = () => {
  const { id, TeamData } = UseTeamInvoiceDetailsPage();
  const isLoading = false;
  const Loader = require("react-loader");
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    copyStyles: true,
  });

  return (
    <>
      {isLoading ? (
        <Loader loaded={false} className="spinner" />
      ) : (
        <div className="page-content">
          <Row className="mb-3">
            <Col md={3}>
              <Link to={"/invoice"} className="link">
                <span className="fas fa-angle-left arrowheightwidth"></span>
              </Link>

              <span className=" ml-4 invoicedetailfirstID">
                {id ? id : "---/-"}
              </span>
            </Col>
            <Col md={9}></Col>
          </Row>

          <Row>
            <Col md={8}>
              <CardBody style={{ background: "white" }}>
                <Row>
                  <Col md={7}></Col>
                  <Col md={5} className="rightaligncss">
                    <p className="invoicedetailid">{id ? id : "---/-"}</p>
                    <Row>
                      <Col md={7}>
                        <p className="invoicedetailname">Invoice Status :</p>
                      </Col>
                      <Col md={5} className="rightaligncss">
                        {TeamData?.status === "UNPAID" ? (
                          <span className="invoiceunpaidstatuscss">
                            {" "}
                            {TeamData ? TeamData.status : "---/-"}
                          </span>
                        ) : (
                          <span className="invoicestatuscss">
                            {" "}
                            {TeamData ? TeamData.status : "---/-"}
                          </span>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={7}>
                        <p className="invoicedetailname">Invoice Team :</p>
                      </Col>
                      <Col md={5} className="rightaligncss">
                        <p className="invoicedetailname">
                          {TeamData?.userName ? TeamData?.userName : "---/-"}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={7}>
                        {" "}
                        <p className="invoicedetailname">Erp issue Date :</p>
                      </Col>
                      <Col md={5} className="rightaligncss">
                        <p className="invoicedetailname">
                          {TeamData
                            ? moment(TeamData?.issueDate).format("DD-MM-YYYY")
                            : "---/-"}
                        </p>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={7}>
                        {" "}
                        <p className="invoicedetailname">Invoice Erp id :</p>
                      </Col>
                      <Col md={5} className="rightaligncss">
                        {/* {TeamData ? TeamData?.erpInvoiceId : "---/-"} */}
                        {TeamData?.status === "UNPAID" ? (
                          TeamData ? (
                            TeamData?.erpInvoiceId
                          ) : (
                            "---/-"
                          )
                        ) : (
                          <a href={TeamData?.erpInvoiceUrl} target="_blank">
                            {TeamData ? TeamData?.erpInvoiceId : "---/-"}
                          </a>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <hr />

                {/* // table start  */}
                <div style={{ overflowX: "auto" }} className="mt-4">
                  <Table hover responsive size="lg" bordered>
                    <thead>
                      <tr>
                        <th className="thead-light">Business Name</th>
                        <th className="">Type</th>
                        <th>Name</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Unit Price</th>
                        <th>Quantity</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {TeamData?.items?.map((item, index) => (
                        <tr key={index}>
                          <td>{item?.otherDetails?.businessName}</td>
                          <td>{item?.type}</td>
                          <td>{item?.itemName}</td>
                          <td>
                            {moment(item?.otherDetails?.fromTime).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td>
                            {moment(item?.otherDetails?.toTime).format(
                              "DD-MM-YYYY"
                            )}
                          </td>
                          <td>{item?.unitPrice}</td>
                          <td>{item?.quantity}</td>
                          <td>{item?.balance}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>

                {/* table end  */}
                <hr />

                <Row>
                  <Col md={5}>
                    <p className="invoicesdetailtotalpay">Total Payable</p>
                  </Col>
                  <Col md={4}></Col>
                  <Col md={3} className="rightaligncss"></Col>
                </Row>
                <Row>
                  <Col md={6}></Col>
                  <Col md={6} className="pkg_invoice_SubTotal_bg">
                    <Row>
                      <Col md={8}>
                        <p> Sub Total </p>
                      </Col>
                      <Col md={4} className="rightaligncss">
                        {" "}
                        {TeamData?.subtotal}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <p> Vat </p>
                      </Col>
                      <Col md={4} className="rightaligncss">
                        {" "}
                        {TeamData?.vatTaxPercentage}%
                      </Col>
                    </Row>
                    <Row>
                      <Col md={8}>
                        <p> Discount </p>
                      </Col>
                      <Col md={4} className="rightaligncss">
                        {" "}
                        {TeamData?.discount}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={8}>
                        <p className="invoicedetailsBD">Total Payable </p>
                      </Col>
                      <Col
                        md={4}
                        className="rightaligncss"
                        style={{ overflowWrap: "break-word" }}
                      >
                        {" "}
                        <p className="invoicedetailsBD">{TeamData?.total}</p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Col>
            <Col md={4}>
              <Button
                onClick={handlePrint}
                color="success"
                outline
                className="waves-effect waves-light me-1 w-100 mt-3 invoicesdetailbuttons"
              >
                Print
              </Button>
            </Col>
          </Row>
          <div style={{ display: "none" }}>
            <div ref={componentRef}>
              <InvoicesDetailPrint TeamData={TeamData} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
// }
export default TeamInvoiceDetailsPage;
