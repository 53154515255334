import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  Input,
  ModalHeader,
} from "reactstrap";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../UserProfile/css/userprofile.css";
import { useUserRole } from "./useUserRoles";
import UserPermissionFetcher from "../../helpers/getUserPerimissions";
import Spinner from "../../components/Common/Loader";
import NoAccess from "../../components/Common/NoAccess";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
const { SearchBar } = Search;

function UserRoles() {
  const assignedRole = localStorage.getItem("assignedRole");

  // Defined Schema for validation
  // Validation Schema
  const schema = yup.object().shape({
    roleName: yup
      .string()
      .required("Role Name is required")
      .matches(/^[^\s]+$/, "Role Name cannot contain spaces"),
  });

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset, // Add this line to use the reset function
  } = useForm({
    resolver: yupResolver(schema),
  });
  const { roleName } = watch();
  


  const {
    currentPage,
    pageOptions,
    isOpen,
    toggle,
    hasNextPage,
    inputActive,
    pagelengthnum,
    pageSize,
    changeCurrentPage,
    isLoading,
    userRoleData,
    createRoleFunc,
    modleShow,
    setModelShow,
    handleInputChange,
    deleteRoleFnc,
    setRoleId,
    modleShow1,
    setModelShow1,
    resetValue,
    setResetValue
  } = useUserRole();
  function ResetInputValue(){
      setResetValue(false)
      reset()
    }

    if(resetValue){
      ResetInputValue()
      }
  // Column.....
  const columns = [
    {
      dataField: "id",
      text: "No.",
      sort: true,
      style: { width: "15%" },
    },
    {
      //
      dataField: "name",
      text: "Role Name",
      sort: true,
      style: { width: "20%" },
      className: "flex-grow-0",
      formatter: (cell, row) => (
        <Link to={`/user_role_detail/${row._id}`} className="link">
          {cell}
        </Link>
      ),
    },

    {
      // dataField: "bookingStatus",
      text: "Delete Role",
      sort: true,
      style: { width: "20%" },
      formatter: (cell, row) => (
        <Button
          disabled={!row.canBeDeleted}
          className="w-30"
          color="danger"
          style={{ marginLeft: "5%" }}
          size="sm"
          block
          onClick={() => {
            setModelShow1(true);
            setRoleId(row._id);
          }}
        >
          Delete
          {cell}
        </Button>
      ),
    },

    {
      dataField: "fd",
      style: { width: "40%" },
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : assignedRole === "Admin" ? (
        <div className="page-content">
          <div>
            <Row className="mb-3">
              <Col md={4}>
                <span className="locationbrandtitle ml-4 ">User Roles</span>
              </Col>
              <Col md={4}></Col>
              <Col md={2}>
                {assignedRole === "Admin" ? (
                  <Link to="/user_create">
                    <Button color="success" className="w-100 ">
                      Create User
                    </Button>
                  </Link>
                ) : (
                  ""
                )}
              </Col>
              <Col md={2}>
                {assignedRole === "Admin" ? (
                  <Button
                    // disabled={!userPromissions.create}
                    color="success"
                    className="w-100"
                    onClick={() => setModelShow(true)}
                  >
                    Create Role
                  </Button>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
          <div className="tablebgcolor mt-3">
            <Row
              style={assignedRole === "Admin" ? {} : { pointerEvents: "none" }}
            >
              <Col className="col-12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={userRoleData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={userRoleData}
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              {/* Search Bar Row */}
                              <Row className="mb-2">
                                <Col md="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="search-box chat-search-box" />
                                    </div>
                                  </div>
                                </Col>
                                <Col md="4"></Col>
                                <Col md="4"></Col>
                              </Row>
                              {/* Table Row */}
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      hover
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap"
                                      }
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              {/* Pagination btn row */}
                              <Row className="align-items-md-center mt-3">
                                <Col className="inner-custom-pagination d-flex">
                                  <span className="paginationitmes ">
                                    Items
                                  </span>
                                  <div className="d-inline">
                                    {/* <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    /> */}
                                    <ButtonDropdown
                                      isOpen={isOpen}
                                      toggle={toggle}
                                      onClick={(e) => pagelengthnum(e)}
                                    >
                                      <DropdownToggle caret color="secondary">
                                        {pageSize}
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem value="1">
                                          10
                                        </DropdownItem>
                                        <DropdownItem value="2">
                                          30
                                        </DropdownItem>
                                        <DropdownItem />
                                        <DropdownItem value="3">
                                          50
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </ButtonDropdown>
                                  </div>
                                  <span className="paginationitmes1 ">
                                    show
                                  </span>
                                  <div className="text-md-right ms-auto">
                                    {/* <PaginationListStandalone
                                      {...paginationProps}
                                    /> */}

                                    {/* Right Side Btn */}
                                    <Button
                                      color="secondary"
                                      className="waves-effect "
                                      style={{
                                        marginLeft: 7,
                                        marginRight: 7,
                                      }}
                                      disabled={currentPage <= 1}
                                      onClick={() =>
                                        currentPage === 1
                                          ? null
                                          : changeCurrentPage(
                                              (prev) => prev - 1
                                            )
                                      }
                                    >
                                      <i className="dripicons-chevron-left"></i>
                                    </Button>

                                    <Button
                                      style={{
                                        marginLeft: 7,
                                        marginRight: 7,
                                      }}
                                      color="success"
                                      className="btn-rounded waves-effect waves-light me-1 mr-2 ml-2"
                                    >
                                      {currentPage}
                                    </Button>
                                    <Button
                                      style={{
                                        marginLeft: 7,
                                        marginRight: 7,
                                      }}
                                      color="secondary"
                                      className="waves-effect"
                                      disabled={!hasNextPage}
                                      onClick={() =>
                                        changeCurrentPage((prev) => prev + 1)
                                      }
                                    >
                                      <i className="dripicons-chevron-right"></i>
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {/* <ToastContainer autoClose={8000} /> */}
        </div>
      ) : (
        <NoAccess />
      )}
      <div>
        {/* Create UserRole Model */}
        <Modal isOpen={modleShow} centered={true}>
          <ModalBody>
            <form onSubmit={handleSubmit(handleInputChange)}>
              <Row>
                <Col md={1}></Col>
                <Col md={10}>
                  <p className="ct_c4">Enter Role Name</p>
                  <div className="input-group">
                    <input
                      type="text"
                      {...register("roleName", {
                        required: true,
                        maxLength: 20,
                      })}
                      className={`form-control ${
                        errors.roleName ? "is-invalid" : ""
                      }`}
                      placeholder="Enter Role Name"
                      id="roleName"
                    />
                    {errors.roleName && (
                      <div className="invalid-feedback">
                        {errors.roleName.message}
                      </div>
                    )}
                  </div>
                </Col>
                <Col md={1}></Col>
              </Row>

              <hr />

              <Row>
                <Col md={3}></Col>

                <Col md={3}>
                  <Button
                    color="success"
                    outline
                    className="waves-effect waves-light w-100"
                    onClick={() => setModelShow(false)}
                  >
                    Cancel
                  </Button>
                </Col>

                <Col md={3}>
                  <Button
                    type="submit"
                    color="success"
                    className="waves-effect waves-light w-100"
                    // onClick={() => {
                    //   reset(
                    // }}
                  >
                    Submit
                  </Button>
                </Col>
                <Col md={3}></Col>
              </Row>
            </form>
          </ModalBody>
        </Modal>
        {/* UserRole Delete Model */}
        <Modal
          isOpen={modleShow1}
          // toggle={() => {
          //   tog_static();
          // }}
          centered={true}
        >
          <Row>
            <Col md={4}></Col>
            <Col md={7}>
              <ModalHeader
              // toggle={() => setModal_static(false)}
              >
                Delete Role
              </ModalHeader>
            </Col>
            <Col md={1}></Col>
          </Row>

          <ModalBody>
            <Row>
              <Col md={2}></Col>
              <Col md={9}>
                <p>{`Are you sure you want to delete this role?`}</p>
              </Col>
              <Col md={1}></Col>
            </Row>
            <hr />
            <Row>
              <Col md={2}></Col>

              <Col md={4}>
                <Button
                  color="success"
                  outline
                  className="waves-effect waves-light w-100"
                  onClick={() => setModelShow1(false)}
                >
                  No
                </Button>
              </Col>
              <Col md={4}>
                <Button
                  color="success"
                  className="waves-effect waves-light  w-100"
                  onClick={() => {
                    deleteRoleFnc();
                  }}
                >
                  Yes
                </Button>
              </Col>

              <Col md={2}></Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

// export default UserRoles;
export { UserRoles };