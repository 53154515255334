import React, { useState } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Modal,
  ModalBody,
  Row,
  Col,
  Button,
} from "reactstrap";

// i18n
import { withNamespaces } from "react-i18next";

// users
import avatar2 from "../../../assets/images/users/avatar-2.jpg";
import { ModalHeader } from "reactstrap/lib";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DIOM_BASED_URLS } from "../../../config/url";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
const token = localStorage.getItem("Token");
const ProfileMenu = ({ t }) => {
  const history = useHistory();
  const [menu, setMenu] = useState(false);
  const [modelClose, setModelClose] = useState(false);

  const toggle = () => {
    setMenu((prevMenu) => !prevMenu);
  };

  // Validation Schema
  const schema = yup.object().shape({
    oldPassword: yup.string().required("Old Password is required").min(8, "old Password must be at least 8 characters long"),
    newPassword: yup
      .string()
      .required("New Password is required")
      .min(8, "NewPassword must be at least 8 characters long")
      .max(16, "New Password must be at most 16 characters long")
      .matches(
        /^(?=.*[A-Z])(?=.*[!@#$%^&*]).*$/,
        "New Password must contain at least one uppercase letter and one special character"
      ),
    confirmPassword: yup
      .string()

      .oneOf(
        [yup.ref("newPassword"), null],
        "NewPassword and confirmPassword must match"
      ),
  });
  // React Form.....
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });



  async function onSubmit(data) {

   
    const body = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
      confirmPassword: data.confirmPassword,
    };

    const response = await fetch(`${DIOM_BASED_URLS}/users/reset`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      if (response.status === 401) {
        toast.error("Your old password is not correct");
      } else {
        toast.error("Something Went Wrong");
      }
    }

    const ResponseData = await response.json();
    if (ResponseData.statusCode === 200) {
      toast.success(ResponseData.message);

      // Remove token from localStorage
      localStorage.removeItem("token");
      // Navigate to
      history.push(`/logout`);
      setModelClose(false);
    }
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block user-dropdown"
      >
        <DropdownToggle
          tag="button"
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
        >
          {/* <img
            className="rounded-circle header-profile-user me-1"
            src={avatar2}
            alt="Header Avatar"
          /> */}
          <span className="d-none d-xl-inline-block ms-1 text-transform">
            {/* {username} */}
          </span>
          <i
            className="ri-settings-4-fill  d-none d-xl-inline-block"
            style={{ fontSize: "24px" }}
          ></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem header>Profile Actions:</DropdownItem>
          <DropdownItem divider />
          {/* <Label className="logininboxpanels ms-3"></Label> */}

          {/* <DropdownItem href="#"><i className="ri-user-line align-middle me-1"></i> {t('Profile')}</DropdownItem>
          <DropdownItem href="#"><i className="ri-wallet-2-line align-middle me-1"></i> {t('My Wallet')}</DropdownItem>
          <DropdownItem className="d-block" href="#"><span className="badge badge-success float-end mt-1">11</span><i className="ri-settings-2-line align-middle me-1"></i> {t('Settings')}</DropdownItem>
          <DropdownItem href="#"><i className="ri-lock-unlock-line align-middle me-1"></i> {t('Lock screen')}</DropdownItem> */}
          {/* <DropdownItem divider /> */}
          {/* {localStorage.setItem("Token", "")} */}
          <DropdownItem onClick={() => setModelClose(true)} className="mt-1 ">
            <i className=" ri-lock-2-fill align-middle me-2"></i>
            {t("Change Password")}
          </DropdownItem>

          <DropdownItem className="text-danger" href="/logout">
            <i className="ri-shut-down-line align-middle me-1 text-danger"></i>{" "}
            {t("Logout")}
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      {/* Change password Model */}
      <Modal isOpen={modelClose}>
        <ModalHeader>
          {" "}
          Change Password{" "}
          <span
            onClick={() => setModelClose(false)}
            style={{ marginLeft: "280px", cursor: "pointer" }}
            className="mdi mdi-close-thick"
          ></span>
        </ModalHeader>
        <ModalBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="mt-3">
              <Col md={12}>
                <Label
                  htmlFor="Old Password"
                  className="logininboxpanels"
                  style={{ pointerEvents: "none" }}
                >
                  Old Password <span className="cp_starik">*</span>
                </Label>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    {...register("oldPassword", {
                      required: true,
                      maxLength: 8,
                    })}
                    className={`form-control ${
                      errors.oldPassword ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Old Password"
                    id="Old Password"
                  />
                  {errors.oldPassword && (
                    <div className="invalid-feedback">
                      {errors.oldPassword.message}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <Label
                  htmlFor="Enter New Password"
                  className="logininboxpanels"
                  style={{ pointerEvents: "none" }}
                >
                  New Password <span className="cp_starik">*</span>
                </Label>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    {...register("newPassword", {
                      required: true,
                      maxLength: 20,
                    })}
                    className={`form-control ${
                      errors.newPassword ? "is-invalid" : ""
                    }`}
                    placeholder="Enter New Password "
                    id="Enter New Password"
                  />
                  {errors.newPassword && (
                    <div className="invalid-feedback">
                      {errors.newPassword.message}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col md={12}>
                <Label htmlFor="Confirm Password" className="logininboxpanels">
                  Confirm Password <span className="cp_starik">*</span>
                </Label>
                <div className="input-group mb-3">
                  <input
                    type="password"
                    {...register("confirmPassword")}
                    className={`form-control ${
                      errors.confirmPassword ? "is-invalid" : ""
                    }`}
                    placeholder="Enter Confirm Password"
                    id="Confirm Password"
                  />
                  {errors.confirmPassword && (
                    <div className="invalid-feedback">
                      {errors.confirmPassword.message}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={0}></Col>
              <Col md={10}>
                <div className="mt-3 mb-3 ">
                  <button
                    style={{ width: "100%" }}
                    type="submit"
                    className="btn btn-success "
                  >
                    Save Changes
                  </button>
                </div>
              </Col>
              <Col md={0}></Col>
            </Row>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default withNamespaces()(ProfileMenu);
