import { DIOM_BASED_URLS } from "../config/url";
import moment from "moment";

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of Announcment
 */
export const getAnouncements = async (
    size = 50,
    page = 1,
    token = "",
    history
    ) => {
  // return await (
    const res = await fetch(`${DIOM_BASED_URLS}/admin-announcements-histories`, {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  // ).json();
  if (!res.ok) {
    if(res.status === 401){
      history.push("/login");
      throw new Error(resJson.error.message);
   
  }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resData = await res.json();
  const Announcemrentlistingdata = resData.map((element, index) => ({
    ...element,
    id: element.id,
    body: element.body,
    title: element.title,
    userId: element.userId,
    createdAt: moment(element.createdAt).format("YYYY-MM-DD HH:MM"),
   
  }));
  
  return {
    data:Announcemrentlistingdata
  };
};



/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a single object of announctmet
 */
 export const getAnouncement = async (
   
    token = "",
    objectId,
    history
    ) => {
    const res = await fetch(`${DIOM_BASED_URLS}/admin-announcements-histories/${objectId}`, {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    })
  
  if (!res.ok) {
    if(res.status === 401){
      history.push("/login");
      throw new Error(resJson.error.message);
   
  }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resData = await res.json();
  return {
    data:resData
  };
};