import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { DIOM_BASED_URLS } from "../../../../config/url";
import { useAllDataQuery } from "../../../../hooks/query";
import { useHistory } from "react-router-dom";
import { getsearcuserprofile } from "../../../../APIS/userProfle";
import {
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { getplandiscount } from "../../../../APIS/planDiscounts";
const token = localStorage.getItem("Token");
export const useCreatePlanDiscount = () => {
  //*1) State Variables
  const selectInputRefResourceType = useRef(null);
  const [codePerecentage, setCodePerecentage] = useState(0);
  const [users, setUsers] = useState([]);
  const [locations, setLocations] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modelShow, setModelShow] = useState(false);
  const [isUserFetch, setIsUserFetch] = useState(false);
  const [discountData, setDiscountData] = useState({});
  // const [canceBtnClicked, setCanceBtnClicked] = useState(false);
  // const [editModelShow, setEditModelShow] = useState(false);
  const [activeSaveChangesBtn, setActiveSaveChangesBtn] = useState(true);
  //* 2)  Get Discount ID from Url
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const edit = queryParams.get("edit") === "true";
  //* 3) Get detail of already created code
  const { data: discountCodeDate } = useAllDataQuery(["planDiscount"], () =>
    getplandiscount(token, id)
  );
  const history = useHistory();
  //* 4) Search Users on every character
  useEffect(() => {
    // Define a function to fetch data based on the search term
    async function getUserData() {
      const userData = await getsearcuserprofile(
        token,
        searchTerm,
        setIsUserFetch
      );
      // console.log(userData, " userData from useEffect");
      setSearchResults(userData.dropDownData);
      // console.log(searchTerm, "searcTerm>>>");
    }
    if (searchTerm) {
      getUserData();
    }
  }, [searchTerm]);

  //*5) This React Form Hook Fn this show model and save data in sate variable.
  const onSubmit = (data) => {
    if (id) {
      // setEditModelShow(true);
      // setDiscountData(data);
      changeHandler(data);

      return;
    } else {
      setDiscountData(data);
      setModelShow(true);
    }
  };

  //*6) Create Discount
  const createDiscount = async () => {
    const body = {
      code: discountData.codeName,
      discountPercentage: Number(discountData.percentage),
      active: discountData.status ?? false,
      startDate: discountData.startDate,
      endDate: discountData.endDate,
      allowedUsers: discountData.selectedUsers ?? [],
      maxUsesPerUser: Number(discountData.maxUsePerUser) ?? null,
    };

    // set Loading True
    setIsLoading(true);
    const response = await fetch(`${DIOM_BASED_URLS}/plan-discounts`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      setIsLoading(false);

      const data = await response.json();
      if (data.error.statusCode === 400) {
        toast.error(`${data.error.message}`);
        return;
      }
      toast.error(`Somthing Went Wrong`);
    } else {
      const data = await response.json();
      // set empty value
      setIsLoading(false);
      toast.success("Plan discount created successfully");
      // Navigate to
      history.push(`/next_step/${data.id}`);
    }
  };

  function Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  function OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }
  // * 7) Change fn when user edit mode
  async function changeHandler(discountData) {
    // console.log(discountData, "change Handler");
    const body = {
      code: discountData.codeName ?? discountCodeDate.name,
      discountPercentage:
        Number(discountData.percentage) ?? Number(discountCodeDate.percentage),
      active: discountData.status ?? false,
      startDate: discountData.startDate ?? discountCodeDate.startDate,
      endDate: discountData.endDate ?? discountCodeDate.endDate,
      allowedUsers: discountData.selectedUsers,
      // .map((user) => ({
      //   id: user.value,
      //   name: user.label,
      // })) ?? discountCodeDate.selectedUsers,
      maxUsesPerUser:
        Number(discountData.maxUsePerUser) ??
        Number(discountCodeDate.maxUsePerUser),
    };

    const response = await fetch(`${DIOM_BASED_URLS}/plan-discounts/${id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      toast.error("Somthing when wrong");
    } else {
      toast.success("Plan discount code updated successfully");
      // Navigate to
      history.push(`/next_step/${id}`);
    }
  }

  return {
    OnSymbol,
    Offsymbol,
    users,
    setUsers,
    locations,
    setLocations,
    codePerecentage,
    setCodePerecentage,
    setSearchTerm,
    searchResults,
    selectInputRefResourceType,
    onSubmit,
    isLoading,
    modelShow,
    setModelShow,
    createDiscount,
    discountCodeDate,
    id,
    changeHandler,
    edit,
    setActiveSaveChangesBtn,
    activeSaveChangesBtn,
    isUserFetch,
  };
};
