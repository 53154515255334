import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQueryClient, useQuery } from "react-query";
import LoaderHook from "../../../hooks/loaderHook";
import { usePaginatedQuery } from "../../../hooks/query";
import { getAnouncements, getAnouncement } from "../../../APIS/announcment";
import { DIOM_BASED_URLS } from "../../../config/url";
import { toast } from "react-toastify";

export const UseAnnouncementListing = () => {
  const QueryClient = useQueryClient();
  let history = useHistory();
  const { setLoading } = LoaderHook();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("50");
  const [announcmet_modal_specific, setAnnouncmet_modal_specific] =
    useState(false);
  const [announcmet_modal_static, setAnnouncmet_modal_static] = useState(false);
  const [objectId, setObjectId] = useState("");
  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);
  const token = localStorage.getItem("Token");
  const queryClient = useQueryClient();

  const createAnnouncmentFunc = () => {
    setAnnouncmet_modal_static(true);
  };
  const specificAnnouncmentFunc = (e) => {
    setObjectId(e);
    setAnnouncmet_modal_specific(true);
  };

  const titleFunc = (e) => {
    setTitle(e.target.value);
  };
  const bodyFunc = (e) => {
    setBody(e.target.value);
  };

  const sendButtonFunc = () => {
    if(title === "" || body === "")
    {
        toast.error("title and description required");
    }
    else{
    fetch(`${DIOM_BASED_URLS}/admin-notifications/users`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        title: `${title}`,
        message: `${body}`,
      }),
    })
      .then((result3) => {
        if (result3.status === 200) {
          toast.success("Successfully Sent");
          QueryClient.invalidateQueries("Anouncements");
        } else if (result3.status === 204) {
          toast.success("Successfully Sent");
          QueryClient.invalidateQueries("Anouncements");
        } else {
          toast.error(" Something went wrong");
        }
        setAnnouncmet_modal_static(false);
      })
      .catch((error) => toast.error(" Something went wrong"));

    setAnnouncmet_modal_static(false);
    }
  };

  // ******************************
  const {
    data: {
      data: announcmentsData,
      hasPreviousPage,
      isLoading,
      total,
      hasNextPage,
    },
  } = usePaginatedQuery(["Anouncements", `${pageSize}`, `${currentPage}`], () =>
    getAnouncements(pageSize, currentPage, token, history)
  );
  // *******************************
  const {
    data: { data: announcmentData },
  } = usePaginatedQuery(["Anouncement", objectId], () =>
    getAnouncement(token, objectId, history)
  );

  //   ********************************

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const pagelengthnum = (e) => {
    if (e.target.value === "1") {
      setPageSize(10);
    } else if (e.target.value === "2") {
      setPageSize(30);
    } else if (e.target.value === "3") {
      setPageSize(50);
    }
  };

  const pageOptions = {
    sizePerPage: pageSize,
    totalSize: total ?? 0,
    custom: true,
  };
  const loadingFunc = () => {
    setLoading(isLoading);
  };
  useEffect(() => {
    isLoading && loadingFunc();
  }, [isLoading]);

  return {
    currentPage,
    announcmentData,
    announcmentsData,
    pageOptions,
    isLoading,
    hasNextPage,
    hasPreviousPage,
    total,
    pagelengthnum,
    pageSize,
    toggle,
    isOpen,
    changeCurrentPage,
    titleFunc,
    bodyFunc,
    sendButtonFunc,
    announcmet_modal_static,
    setAnnouncmet_modal_static,
    createAnnouncmentFunc,
    setAnnouncmet_modal_specific,
    announcmet_modal_specific,
    specificAnnouncmentFunc,
  };
};
