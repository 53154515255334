import { useParams } from "react-router-dom";
import { getTeamInvoice } from "../../../../APIS";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";

const UseTeamInvoiceDetailsPage = () => {
  let history = useHistory();
  const token = localStorage.getItem("Token");

  const { id } = useParams();

  // *******Team Invoice*****
  const { data: TeamDataa, teamIsLoading } = useQuery(
    ["singleTeamData", id],
    () => getTeamInvoice(id, token, history)
  );
  const TeamData = TeamDataa?.data;

  return { id, TeamData };
};

export default UseTeamInvoiceDetailsPage;
