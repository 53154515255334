import React from "react";
import { Row, Col, CardBody, Container, Table } from "reactstrap";
// import SimpleBar from "simplebar-react";
import "../../../css/InvoicesDetailPage.css";
import moment from "moment";

const InvoicesDetailPrint = ({ TeamData }) => {
  // const createdat = moment(bookings.otherDetails?.fromTime).format(
  //   "YYYY-MM-DD "
  // );
  // const bookingfrom = moment(bookings.otherDetails?.toTime).format(
  //   "YYYY-MM-DD "
  // );

  return (
    <div>
      <Container>
        <Row>
          <Col xs={12}>
            <CardBody style={{ background: "white" }}>
              <Row>
                <Col xs={7}></Col>
                <Col xs={5} className='rightaligncss'>
                  <Row>
                    <Col xs={7}>
                      <p className='invoicedetailname'>Invoice Status :</p>
                    </Col>
                    <Col xs={5} className='rightaligncss'>
                      {TeamData?.status === "UNPAID" ? (
                        <span className='invoiceunpaidstatuscss'>
                          {" "}
                          {TeamData ? TeamData.status : "---/-"}
                        </span>
                      ) : (
                        <span className='invoicestatuscss'>
                          {" "}
                          {TeamData ? TeamData.status : "---/-"}
                        </span>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={7}>
                      <p className='invoicedetailname'>Invoice Team :</p>
                    </Col>
                    <Col xs={5} className='rightaligncss'>
                      <p className='invoicedetailname'>
                        {TeamData?.userName ? TeamData?.userName : "---/-"}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={7}>
                      {" "}
                      <p className='invoicedetailname'>Erp issue Date :</p>
                    </Col>
                    <Col xs={5} className='rightaligncss'>
                      <p className='invoicedetailname'>
                        {TeamData
                          ? moment(TeamData?.issueDate).format("DD-MM-YYYY")
                          : "---/-"}
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={7}>
                      {" "}
                      <p className='invoicedetailname'>Invoice Erp id :</p>
                    </Col>
                    <Col xs={5} className='rightaligncss'>
                      {/* {TeamData ? TeamData?.erpInvoiceId : "---/-"} */}
                      {TeamData?.status === "UNPAID" ? (
                        TeamData ? (
                          TeamData?.erpInvoiceId
                        ) : (
                          "---/-"
                        )
                      ) : (
                        <a href={TeamData?.erpInvoiceUrl} target='_blank'>
                          {TeamData ? TeamData?.erpInvoiceId : "---/-"}
                        </a>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr />

              {/* // table start  */}
              <div style={{ overflowX: "auto" }} className='mt-4'>
                <Table hover responsive size='lg' bordered>
                  <thead>
                    <tr>
                      <th className='thead-light'>Business Name</th>
                      <th className=''>Type</th>
                      <th>Name</th>
                      <th>From</th>
                      <th>To</th>
                      <th>Unit Price</th>
                      <th>Quantity</th>
                      <th>Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {TeamData?.items?.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.otherDetails?.businessName}</td>
                        <td>{item?.type}</td>
                        <td>{item?.itemName}</td>
                        <td>
                          {moment(item?.otherDetails?.fromTime).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>
                          {moment(item?.otherDetails?.toTime).format(
                            "DD-MM-YYYY"
                          )}
                        </td>
                        <td>{item?.unitPrice}</td>
                        <td>{item?.quantity}</td>
                        <td>{item?.balance}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>

              {/* table end  */}
              <hr />

              <Row>
                <Col xs={5}>
                  <p className='invoicesdetailtotalpay'>Total Payable</p>
                </Col>
                <Col xs={4}></Col>
                <Col xs={3} className='rightaligncss'></Col>
              </Row>
              <Row>
                <Col xs={6}></Col>
                <Col xs={6} className='pkg_invoice_SubTotal_bg'>
                  <Row>
                    <Col xs={8}>
                      <p> Sub Total </p>
                    </Col>
                    <Col xs={4} className='rightaligncss'>
                      {" "}
                      {TeamData?.subtotal}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <p> Vat </p>
                    </Col>
                    <Col xs={4} className='rightaligncss'>
                      {" "}
                      {TeamData?.vatTaxPercentage}%
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={8}>
                      <p> Discount </p>
                    </Col>
                    <Col xs={4} className='rightaligncss'>
                      {" "}
                      {TeamData?.discount}
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={8}>
                      <p className='invoicedetailsBD'>Total Payable </p>
                    </Col>
                    <Col
                      xs={4}
                      className='rightaligncss'
                      style={{ overflowWrap: "break-word" }}
                    >
                      {" "}
                      <p className='invoicedetailsBD'>{TeamData?.total}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default InvoicesDetailPrint;
