import { toast } from "react-toastify";
import { DIOM_BASED_URLS } from "../config/url";
import moment from "moment";

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of BOOKING in DIOM
 */

//  Get individual booking
export const getBooking = async (token = "", id, history) => {
  // return await (
  const res = await fetch(`${DIOM_BASED_URLS}/admin-diom-bookings/${id}`, {
    method: "GET",
    redirect: "follow",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  // ).json();
  if (!res.ok) {
    const resJson = await res.json();
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    throw new Error(resJson.error.message);
  }
  const data = await res.json();
  return {
    data,
  };
};
export const getUserBooking = async ( id, history) => {
  // return await (
  const res = await fetch(`${DIOM_BASED_URLS}/admin-diom-bookings/${id}`, {
    method: "GET",
    redirect: "follow",
    // headers: {
    //   Authorization: "Bearer " + token,
    // },
  });
  // ).json();
  if (!res.ok) {
    const resJson = await res.json();
    if (res.status === 401) {
      // history.push("/login");
      throw new Error(resJson.error.message);
    }
    throw new Error(resJson.error.message);
  }
  const data = await res.json();
  return {
    data,
  };
};
export const createGuestBooking = async (token = "", bodyData ,history) => {
  // return await (
  const res = await fetch(`${DIOM_BASED_URLS}/v2/admin-guest-bookings`, {
    method: "POST",
    redirect: "follow",
    headers: {
      "Authorization": "Bearer " + token,
      "Content-Type": "application/json",
    },
     body: JSON.stringify(bodyData),
  });
  // ).json();
  if (!res.ok) {
    const resJson = await res.json();
    if (res.status === 401) {
      
      throw new Error(resJson.error.message);
    }
    if(res.status===403){
     return toast.error(resJson.error.message)
    }
    return toast.error(resJson.error.message)
    throw new Error(resJson.error.message);
  }else{
    toast.success("Guest booking created successfully")
    const data = await res.json();
    
    history.push(`/admin-booking-details/${data?.data.id}`)
  
    return {
      data,
    };
  }
};
export const cancelGuestBookingByUser = async (id ,setIsOpen) => {
  // return await (
  const res = await fetch(`${DIOM_BASED_URLS}/public/guest-bookings/${id}`, {
    method: "DELETE",
    redirect: "follow",
   
      });
 
  if (!res.ok) {  
    const resJson = await res.json();
    if (res.status === 401) {
      
      throw new Error(resJson.error.message);
    }
    if(res.status===400){
     return toast.error(resJson.error.message)
    }
    if(res.status===403){
     return toast.error(resJson.error.message)
    }
    
  }else{
    
    setIsOpen(false)
    toast.success("Guest booking cancelled successfully")
    const data = await res.json();
    
   
  
    return {
      data,
    };
  }
};
export const cancelGuestBookingByAdmin = async ( token="",id ,setIsOpen) => {
  // return await (
  const res = await fetch(`${DIOM_BASED_URLS}/guest-bookings/${id}`, {
    method: "DELETE",
    redirect: "follow",
    headers: {
      "Authorization": "Bearer " + token,
      "Content-Type": "application/json",
    },
   
      });
 
  if (!res.ok) {  
    const resJson = await res.json();
    if (res.status === 401) {
      
      throw new Error(resJson.error.message);
    }
    if(res.status===400){
     return toast.error(resJson.error.message)
    }
    if(res.status===403){
     return toast.error(resJson.error.message)
    }
    
  }else{
    
    setIsOpen(false)
    toast.success("Guest booking cancelled successfully")
    const data = await res.json();
    
   
  
    return {
      data,
    };
  }
};
export const confirmGuestBooking = async (token = "",setIsOpen ,bodyData ,history) => {
  // return await (
  const res = await fetch(`${DIOM_BASED_URLS}/bookings`, {
    method: "POST",
    redirect: "follow",
    headers: {
      "Authorization": "Bearer " + token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(bodyData),
      });
 
  if (!res.ok) {
    setIsOpen(false)

    const resJson = await res.json();
    if (res.status === 401) {
      
      throw new Error(resJson.error.message);
    }
    if(res.status===400){
     return toast.error(resJson.error.message)
    }
    
  }else{
    const data = await res.json();
    
    setIsOpen(false)
    history.push(`/booking_detail/${data.booking.id}`)
    toast.success("Guest booking confirmed successfully")
    
  
  
    return {
      data,
    };
  }
};
export const getBookingWithAdminTransactionReferenceId = async (token = "",setIsOpen ,trasactionId) => {
 // Create a filter object
 const filter = {
  where: {
    adminTransactionReferenceId:trasactionId
  }
 
}

    // Build the URL with query parameters
    const url = new URL(`${DIOM_BASED_URLS}/admin-diom-bookings`);
    url.searchParams.append('filter', JSON.stringify(filter));
  // return await (
  const res = await fetch(url, {
    method: "GET",
    redirect: "follow",
    headers: {
      "Authorization": "Bearer " + token,
      "Content-Type": "application/json",
    },
   
      });
 
  if (!res.ok) {
    

    const resJson = await res.json();
    if (res.status === 401) {
      
      throw new Error(resJson.error.message);
    }
    if(res.status===400){
     return toast.error(resJson.error.message)
    }
    
  }else{
    setIsOpen(false)
    const data = await res.json();
    
  
    return {
      data,
    };
  }
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of guests in DIOM
 */
// Get guestBooking....
export const getGuest = async (token = "", id, history) => {
  // return await (
  const res = await fetch(
    `${DIOM_BASED_URLS}/admin-booking-guest/booking/${id}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
  // ).json();
};
export const getAdminGuestBooking = async (token = "", id) => {
  
  const res = await fetch(
    `${DIOM_BASED_URLS}/guest-bookings/${id}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      // history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
  
};
export const getUserGuestBooking = async (id) => {
  
  const res = await fetch(
    `${DIOM_BASED_URLS}/public/guest-bookings/${id}`,
    {
      method: "GET",
      redirect: "follow",
      
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      // history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
  
};
export const getCheckOutForGuestBooking = async (id) => {
  
  const res = await fetch(
    `${DIOM_BASED_URLS}/public/guest-bookings/${id}`,
    {
      method: "GET",
      redirect: "follow",
      
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      // history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
  
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of bookinguserdata in DIOM
 */
// Get individula user
export const getbookinguser = async (token, id, history) => {
  // if (id) {
  // return await (
  const res = await fetch(`${DIOM_BASED_URLS}/users/${id}`, {
    method: "GET",
    redirect: "follow",
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
  // ).json();
  // }
};

/**
 *
 * @param size
 * @param page
 * @param {JWT} token
 * @returns a list of bookings locations in DIOM
 */

// Get all active locations
export const getbookinglocations = async (token = "", history) => {
  // return await (
  const res = await fetch(
    `${DIOM_BASED_URLS}/admin-business-locations?filter={"where":{"visibility":true}}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

  return {
    data,
  };
  // ).json();
};
// Get all active locations
export const searchTodayBooking = async (token = "", bookingQuery, history ,searchAPI) => {
  // return await (
  const res = await fetch(
    `${DIOM_BASED_URLS}/admin-diom-bookings-${searchAPI}/${bookingQuery}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const data = await res.json();

 

  const todayBookingsdata = data.data.map((booking, index) => ({
    ...booking,

    id: index + 1,
    _id: booking.id,
    updatedAt: moment(booking.updatedAt).format("YYYY-MM-DD HH:mm"),
    Bookingstartend:
      moment(booking.fromTime).format("DD MMM (HH:mm)") +
      " - " +
      moment(booking.toTime).format("DD MMM (HH:mm"),

    timeLogs:
      booking.timeLogs.length > 0
        ? moment(booking.timeLogs[booking.timeLogs.length - 1].fromTime).format(
            "HH:mm"
          )
        : null,

    
  }));
  return {
    data: todayBookingsdata,
    total: data.total,
    hasNextPage: data.hasNextPage,
    hasPreviousPage: data.hasPreviousPage,
  };

 
 
};

/**
 *
 * @param {*JWT} token
 * @returns get today bookings data for dropdown
 */
export const gettodaysbookings = async (
  size = 30,
  page = 1,
  token = "",
  applyFilter = false,
  filters = {},
  history,
  resourceTypeFilter
) => {
  const res = await fetch(
    !applyFilter
      ? `${DIOM_BASED_URLS}/admin-diom-bookings/today?size=${size}&page=${page}`
      : resourceTypeFilter === null
      ? `${DIOM_BASED_URLS}/admin-diom-bookings/today/?size=${size}&page=${page}&filter={"where":{"businessId":{"inq":[${filters} ]}}}`
      : `${DIOM_BASED_URLS}/admin-diom-bookings/today/?size=${size}&page=${page}&filter={"where":{"resourceTypeId":{"inq":[${resourceTypeFilter.value}]},"businessId":{"inq":[${filters} ]}}}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      // history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();
  const todayBookingsdata = resJson.data.map((booking, index) => ({
    ...booking,

    id: index + 1,
    _id: booking.id,
    updatedAt: moment(booking.updatedAt).format("YYYY-MM-DD HH:mm"),
    Bookingstartend:
      moment(booking.fromTime).format("DD MMM (HH:mm)") +
      " - " +
      moment(booking.toTime).format("DD MMM (HH:mm"),

    timeLogs:
      booking.timeLogs.length > 0
        ? moment(booking.timeLogs[booking.timeLogs.length - 1].fromTime).format(
            "HH:mm"
          )
        : null,

    // booking.timeLogs.map((e) => moment(e.fromTime).format("HH:mm"))
    // .join(", "),
  }));
  return {
    data: todayBookingsdata,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};
export const getGuestbookings = async (
  size = 30,
  page = 1,
  token = "",
  applyFilter = false,
  filters = {},
  history,
  resourceTypeFilter
) => {
  const res = await fetch(
     `${DIOM_BASED_URLS}/admin/guest-bookings?bookingPaymentStatus=PENDING&actionTaken=PENDING_ACTION`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      // history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }
  const resJson = await res.json();

  const guestBookingsdata = resJson.data.map((booking, index) => ({
    ...booking,

    id: index + 1,
    _id: booking.id,
    updatedAt: moment(booking.updatedAt).format("YYYY-MM-DD HH:mm"),
    Bookingstartend:
      moment(booking.fromTime).format("DD MMM (HH:mm)") +
      " - " +
      moment(booking.toTime).format("DD MMM (HH:mm"),
userName:booking.userDetails.userName
    // timeLogs:
    //   booking.timeLogs.length > 0
    //     ? moment(booking.timeLogs[booking.timeLogs.length - 1].fromTime).format(
    //         "HH:mm"
    //       )
    //     : null,

    // booking.timeLogs.map((e) => moment(e.fromTime).format("HH:mm"))
    // .join(", "),
  }));
  return {
    data: guestBookingsdata,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};

/**
 *
 * @param {*JWT} token
 * @returns get Fetched bookings data for dropdown
 */

export const getschduleddbookings = async (
  size = 30,
  page = 1,
  token = "",
  applyFilter = false,
  filters = {},
  history,
  resourceTypeFilter
) => {
  console.log(
    filters,
    resourceTypeFilter,
    applyFilter,
    "Schduledd Bookings API"
  );
  const res = await fetch(
    !applyFilter
      ? `${DIOM_BASED_URLS}/admin-diom-bookings/scheduled?size=${size}&page=${page}`
      : resourceTypeFilter === null
      ? `${DIOM_BASED_URLS}/admin-diom-bookings/scheduled/?size=${size}&page=${page}&filter={"where":{"businessId":{"inq":[${filters}]}}}`
      : `${DIOM_BASED_URLS}/admin-diom-bookings/scheduled/?size=${size}&page=${page}&filter={"where":{"resourceTypeId":{"inq":[${resourceTypeFilter.value}]},"businessId":{"inq":[${filters} ]}}}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }
    const resJson = await res.json();
    throw new Error(resJson.error.message);
  }

  const resJson = await res.json();

  // const result = applyFilter
  //   ? (result = resJson.data.bookings)
  //   : (result = resJson.data);

  const schduledBookingsdata = resJson.data?.map((booking, index) => ({
    ...booking,
    id: index + 1,
    updatedAt: moment(booking.updatedAt).format("YYYY-MM-DD HH:mm"),
    _id: booking.id,

    Bookingstartend:
      moment(booking.fromTime).format("DD MMM (HH:mm)") +
      " - " +
      moment(booking.toTime).format("DD MMM (HH:mm)"),
    timeLogs: booking.timeLogs?.map((e) => moment(e.fromTime).format("HH:mm")),
  }));

  return {
    data: schduledBookingsdata,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};

/**
 *
 * @param {*JWT} token
 * @returns get Fetched Past bookings data for dropdown
 */
export const getPasttbookings = async (
  size = 30,
  page = 1,
  token = "",
  applyFilter = false,
  filters = {},
  history,
  resourceTypeFilter
) => {
  console.log(
    "Get Past Bookings API",
    filters,
    resourceTypeFilter,
    applyFilter
  );
  const res = await fetch(
    !applyFilter
      ? `${DIOM_BASED_URLS}/admin-diom-bookings/past?size=${size}&page=${page}`
      : resourceTypeFilter === null
      ? `${DIOM_BASED_URLS}/admin-diom-bookings/past?size=${size}&page=${page}&filter={"where":{"businessId":{"inq":[${filters}]}}}`
      : `${DIOM_BASED_URLS}/admin-diom-bookings/past?size=${size}&page=${page}&filter={"where":{"resourceTypeId":{"inq":[${resourceTypeFilter.value}]},"businessId":{"inq":[${filters} ]}}}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );
  const resJson = await res.json();
  if (!res.ok) {
    if (res.status === 401) {
      history.push("/login");
      throw new Error(resJson.error.message);
    }

    throw new Error(resJson.error.message);
  }

  // const resJson = await res.json();

  // const result = applyFilter
  //   ? (result = resJson.data.bookings)
  //   : (result = resJson.data);

  const pastBookingsdata = resJson.data.map((booking, index) => ({
    ...booking,
    id: index + 1,
    updatedAt: moment(booking.updatedAt).format("YYYY-MM-DD HH:mm"),
    _id: booking.id,
    Bookingstartend:
      moment(booking.fromTime).format("DD MMM (HH:mm)") +
      " - " +
      moment(booking.toTime).format("DD MMM (HH:mm)"),
    // timeLogs: booking.timeLogs.map((e,i) =>(e.fromTime? moment(e.fromTime).format("HH:mm") :null)),
  }));
  return {
    data: pastBookingsdata,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};

// export const getrevoketbookings = async (
//   size = 30,
//   page = 1,
//   token = "",
//   applyFilter = false,
//   filters = {},
//   history
// ) => {
//   const res = await fetch(
//     !applyFilter
//       ? `${DIOM_BASED_URLS}/admin-diom-bookings/revoked?size=${size}&page=${page}`
//       : `${DIOM_BASED_URLS}/admin-diom-bookings/revoked${filters}?size=${size}&page=${page}`,
//     {
//       method: "GET",
//       redirect: "follow",
//       headers: {
//         Authorization: "Bearer " + token,
//       },
//     }
//   );

//   if (!res.ok) {
//     if(res.status === 401){
//       history.push("/login");
//       throw new Error(resJson.error.message);

//   }
//     const resJson = await res.json();
//     throw new Error(resJson.error.message);
//   }

//   const resJson = await res.json();

//   // const result = applyFilter
//   //   ? (result = resJson.data.bookings)
//   //   : (result = resJson.data);

//   const revokedBookingsdata = resJson.data.map((booking, index) => ({
//     ...booking,
//     id: index + 1,
//     updatedAt: moment(booking.updatedAt).format("YYYY-MM-DD HH:mm"),
//     _id: booking.id,
//     customerId:
//     Bookingstartend:
//       moment(booking.fromTime).format("DD MMM (HH:mm)") +
//       " - " +
//       moment(booking.toTime).format("DD MMM (HH:mm)"),
//     // timeLogs: booking.timeLogs.map((e,i) =>(e.fromTime? moment(e.fromTime).format("HH:mm") :null)),
//   }));
//   return {
//     data: revokedBookingsdata,
//     total: resJson.total,
//     hasNextPage: resJson.hasNextPage,
//     hasPreviousPage: resJson.hasPreviousPage,
//   };
// };

/**
 *
 * @param {*JWT} token
 * @returns get Fetched  revocked bookings data for dropdown
 */
export const getrevokeddbookings = async (
  size = 30,
  page = 1,
  token = "",
  applyFilter = false,
  filters = {},
  history,
  resourceTypeFilter
) => {
  console.log(
    "revoke Booking API",
    filters,
    resourceTypeFilter,
    applyFilter,
    token
  );
  const res = await fetch(
    !applyFilter
      ? `${DIOM_BASED_URLS}/admin-diom-bookings/revoked?size=${size}&page=${page}`
      : resourceTypeFilter === null
      ? `${DIOM_BASED_URLS}/admin-diom-bookings/revoked?size=${size}&page=${page}&filter={"where":{"businessId":{"inq":[${filters}]}}}`
      : `${DIOM_BASED_URLS}/admin-diom-bookings/revoked?size=${size}&page=${page}&filter={"where":{"resourceTypeId":{"inq":[${resourceTypeFilter.value}]},"businessId":{"inq":[${filters} ]}}}`,
    {
      method: "GET",
      redirect: "follow",
      headers: {
        Authorization: "Bearer " + token,
      },
    }
  );

  if (!res.ok) {
    const resJson = await res.json();

    if (res.status === 401) {
      history?.push("/login");
      throw new Error(resJson.error.message);
    }
    throw new Error(resJson.error.message);
  }

  const resJson = await res.json();

  // const result = applyFilter
  //   ? (result = resJson.data.bookings)
  //   : (result = resJson.data);

  const schduledBookingsdata = resJson.data?.map((booking, index) => ({
    ...booking,
    id: index + 1,
    updatedAt: moment(booking.updatedAt).format("YYYY-MM-DD HH:mm"),
    _id: booking.id,

    Bookingstartend:
      moment(booking.fromTime).format("DD MMM (HH:mm)") +
      " - " +
      moment(booking.toTime).format("DD MMM (HH:mm)"),
    timeLogs: booking.timeLogs?.map((e) => moment(e.fromTime).format("HH:mm")),
  }));

  // console.log(schduledBookingsdata)
  return {
    data: schduledBookingsdata,
    total: resJson.total,
    hasNextPage: resJson.hasNextPage,
    hasPreviousPage: resJson.hasPreviousPage,
  };
};
