
import moment from 'moment';
import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider ,{ Search } from 'react-bootstrap-table2-toolkit';
import Select from "react-select";
import ReactJson from 'react-json-view'
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  FormGroup,
  Row,
  DropdownToggle,
  ButtonDropdown,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Label,
  
} from "reactstrap";
import useAuditTrailLogs from './useAuditTrailLogs';
import Spinner from '../../components/Common/Loader';
import DatePicker from 'react-flatpickr';

const AuditTrailLogs = () => {
  const { SearchBar } = Search;

  const {
    auditTrailLogsData,
    auditTrailLogData,
    currentPage, 
    pageSize, 
    pageOptions ,
    auditTrailLogsLoading ,
    logdetialsfunc ,
     modal_static,
    setModal_static,
    pagelengthnum,
    changeCurrentPage,
    toggle,
    isOpen,
    hasNextPage,
    total,
    userDropDownData,
        isLoading,
        DateTimeDurationHandler,
        entityHandler,
        createdByHandler,
        filterHandler,
        selectedEntity , 
        selectedUser ,
         endTime,
         sortHandler,
         selectedSort,
         jsonObject,
         beforeOperationsHourValues,
         beforeResourceValues,
         auditTrailLogLoading
  }=useAuditTrailLogs()


    const defaultSorted = [
      {
        dataField: "id",
        order: "asc",
      },
    ];

  const columns = [
    {
      dataField: "createdAt",
      text: "Time Stamp",
      sort: true,
      formatter: (cell, row) => moment(cell).format('DD-MMMM-YYYY HH:mm'),
    },


    {
      dataField: "logType",
      text: "Entity",
      sort: true,
     

      
      
    
    },
    {
      dataField: "action",
      text: "Action",
      sort: true,
      formatter: (cell, row) => `${row.userName} ${row.action=== "PATCH"?"updated":row.action=== "DELETED"?"Deleted":row.action=== "CREATE"?"Created":row.action=== "GET"? "Get":row.action} ${row.logType} `,
      
      
    
    },

    {
      dataField: "userEmail",
      text: "Created By",
      sort: true,
      
    },
    // {
    //   dataField: "userIp",
    //   text: "User Ip",
    //   sort: true,
      
    // },
    // {
    //   dataField: "userAgent",
    //   text: "Request Agent",
    //   sort: true,
    //   formatter: (cell, row) => (
    //     <span
    //     style={{
    //       cursor: "pointer",
    //       display: "inline-block",
    //       maxWidth: "200px", 
    //       overflow: "hidden",
    //       textOverflow: "ellipsis",
    //       whiteSpace: "nowrap",
    //     }}
         
    //     >
    //       {cell}
    //     </span>
    //   ),
      
    // },
    {
      dataField: "",
      text:" Further Details",
      sort: true,
      formatter: (cell, row) => (
        <Button
              className="w-10"
              color="success"
              size="sm"
              block
              onClick={() => logdetialsfunc(row.id)}
            >
              Here
              {cell}
            </Button>
      ),
      
    },


  ];

  const beforeColumns=[
    {
      dataField: "operationsHours",
      text: "Days",
      sort: true,
      
    },
  ]

   // Check if all the necessary state variables are not null
   const isFilterEnabled = selectedEntity || selectedUser || endTime || selectedSort;
  return (
    <div className="page-content">
      <div>
            <Row>
              <Col md={5}>
                <Link to={`/dashboard`} className="link">
                  <span className="fas fa-angle-left arrowheightwidth"  style={{color:"#343434"}}></span>
                </Link>

                <span className="cehckincustomertitle"> Audit Trail Logs</span>
              </Col>
              <Col md={7}></Col>
            </Row>
          </div>

          {/* Card */}
          <Card className="mt-3 p-3">
          <Row className="mb-4 mt-2">
                            
                           
                            
                            <Col md={4}>
                            <Label
                        htmlFor="date-duration"
                        className="logininboxpanels"
                        >
                              Date Duration
                      </Label>
                            <DatePicker
                            // value={displayDateRange}
                          name="bookingDateRangeForShareSpace"
                         onChange={(date)=>{DateTimeDurationHandler(date)}}
                          className="datepickercss_exceptions"
                          placeholder={
                            "Select Duration"
                          }

                         
                          options={{
                            mode: "range",
                            // minDate: "today",
                            enableTime: true,
                            dateFormat: "Y-m-d H:i",

                             
                          
                        }}
                          
                        id="date-duration"
                        />
                    </Col>
                    <Col md={2} >
                       <Label
                        htmlFor="entity"
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                         Select Entity 
                      </Label> 
                      {/* Have to add ResourceType Entity and remove Inventory */}
                       <Select  placeholder="Entity Filter"  isClearable={true} options={[
                        
                        // {label:"Inventories" , value:"Inventories" },  
                        {label:"Resource Type" , value:"ResourceTypes" },
                        {label:"Operational Hours" , value:"OperationalHours" },
                        {label:"Locations" , value:"Locations" }


                       ]} 
                      onChange={(entity)=>{ entityHandler(entity?.value)}} 
                      id="entity"
                      ></Select> 
                      
                      
                      </Col>
                    <Col md={2} >
                       <Label
                        htmlFor="created-by"
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                         Created By 
                      </Label> 
                      <Select
                                  isClearable={true}
                                  className="basic-single"
                                  classNamePrefix="select"
                                  isLoading={isLoading}
                                    onChange={(opt) =>{
                                      
                                      createdByHandler(opt?.value)
                                    }
                                    }

                                    options={userDropDownData}
                                    placeholder="Create By"
                                    
                                  />
                      
                      
                      </Col>
                    <Col md={2} >
                       <Label
                        htmlFor="sort"
                        className="logininboxpanels"
                        style={{ pointerEvents: "none" }}
                      >
                         Sort
                      </Label> 
                       <Select  defaultValue={[{label:"DESC" , value:"DESC" }]} 
                       
                       isClearable={true}
                        placeholder="Select order"  
                        options={[{label:"ASC" , value:"ASC" } ,
                        {label:"DESC" , value:"DESC" }
                       ]} 
                      onChange={(entity)=>{ sortHandler(entity)}} 
                      id='sort' ></Select> 
                      
                      
                      </Col>
                      <Col md={1}>

                        <Button
                        
                        color="success"
                        style={{width:"100%" , marginTop:"32px"}}
                        onClick={filterHandler}
                        disabled={!isFilterEnabled}
              > 
              
              Filter
              
              </Button>
                      </Col>
                            
                          </Row>

       {auditTrailLogsLoading? <Spinner/>:

     


          <Row>
              <Col md={12}>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  data={auditTrailLogsData?.data?.data}
                  // data={logsData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={auditTrailLogsData?.data?.data}
                      // data={logsData}
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          

                     <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  hover
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>   
{/* Pagination */}
<Row className="align-items-md-center mt-30 mt-3">
                            <Col className="inner-custom-pagination d-flex">
                              <span className="paginationitmes ">Items</span>
                              <div className="d-inline">
                                <ButtonDropdown
                                  isOpen={isOpen}
                                  toggle={toggle}
                                  onClick={(e) => pagelengthnum(e)}
                                >
                                  <DropdownToggle caret color="secondary">
                                    {pageSize}
                                  </DropdownToggle>
                                  <DropdownMenu>
                                    <DropdownItem value="1">10</DropdownItem>
                                    <DropdownItem value="2">30</DropdownItem>
                                    <DropdownItem />
                                    <DropdownItem value="3">50</DropdownItem>
                                  </DropdownMenu>
                                </ButtonDropdown>
                              </div>
                              <span className="paginationitmes1 ">show</span>
                              <div className="text-md-right ms-auto">
                                <Button
                                  color="secondary"
                                  className="waves-effect "
                                  style={{ marginLeft: 7, marginRight: 7 }}
                                  disabled={currentPage <= 1}
                                  onClick={() =>
                                    currentPage === 1
                                      ? null
                                      : changeCurrentPage((prev) => prev - 1)
                                  }
                                >
                                  
                                  <i className="dripicons-chevron-left"></i>
                                </Button>

                                <Button
                                  style={{ marginLeft: 7, marginRight: 7 }}
                                  color="success"
                                  className="btn-rounded waves-effect waves-light me-1 mr-2 ml-2"
                                >
                                  {currentPage}
                                </Button>
                                <Button
                                  style={{ marginLeft: 7, marginRight: 7 }}
                                  color="secondary"
                                  className="waves-effect"
                                  disabled={!hasNextPage}
                                  onClick={() =>
                                    changeCurrentPage((prev) => prev + 1)
                                  }
                                >
                                  <i className="dripicons-chevron-right"></i>
                                </Button>
                              </div>
                            </Col>
                          </Row>
                          
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </Col>
            </Row>
          }   
</Card>

          {/* Model for log Details */}

          <div>
            <Modal isOpen={modal_static}  centered={true}  size='xl'>
              <Row>
                {/* <Col md={4}></Col> */}
                <Col md={12}>
                  <ModalHeader toggle={() => setModal_static(false)}>
                    Log Details
                  </ModalHeader>
                </Col>
                
              </Row>

         {auditTrailLogLoading? <Spinner/> :     <ModalBody>
                {/* <Row>
                  <Col md={3}>
                    <b>ID</b>
                  </Col>
                  <Col md={9}>{auditTrailLogData?.data?.id}</Col>
                 
                </Row> */}
                

                <Row className='mt-1'>
                  <Col md={3}>
                    <b>LogType:</b>
                  </Col>
                  <Col md={9}>{auditTrailLogData?.data?.logType}</Col>
                  {/* <Col md={2}></Col> */}
                </Row>
                <Row className='mt-1'>
                  <Col md={3}>
                    <b>Controller Name:</b>
                  </Col>
                  <Col md={8}>{auditTrailLogData?.data?.controllertName}</Col>
                  
                </Row>
                <Row className='mt-1'>
                  <Col md={3}>
                    <b>ResourceTypes:</b>
                  </Col>
                  <Col md={8}>{ beforeResourceValues.length>0? beforeResourceValues?.map(RT => RT.Name).join(', '):"N/A" }</Col>
                  
                </Row>
                <Row className='mt-1'>
                  <Col md={3}>
                    <b>Location:</b>
                  </Col>
                  <Col md={8}>{ beforeResourceValues[0]?.businessName ??"N/A"
                  }</Col>
                  
                </Row>
                
                <Link to={`/user_profile_detail/${auditTrailLogData?.data?.userId}`}   className="link">
                <Row className='mt-1'>
                  <Col md={3}>
                   <b>User Email:</b>  
                  </Col>
                  <Col md={9}>{auditTrailLogData?.data?.userEmail}</Col>
                  {/* <Col md={2}></Col> */}
                </Row>
                </Link>
                <Link to={`/user_profile_detail/${auditTrailLogData?.data?.userId}`}   className="link">
                <Row className='mt-1'>
                  <Col md={3}>
                    <b>User Name:</b>
                  </Col>
                  <Col md={8}>{auditTrailLogData?.data?.userName}</Col>
                  
                </Row>
                </Link>
               
               

                <Row className='mt-1'>
                  <Col md={3}>
                    <b>Path:</b>
                  </Col>
                  <Col md={9}>{auditTrailLogData?.data?.path}</Col>
                  {/* <Col md={2}></Col> */}
                </Row>

                <Row >
                  <Col md={3}>
                    <b>Created At:</b>
                  </Col>
                  <Col md={9}>
                    {moment(auditTrailLogData?.data?.createdAt).format(
                      "DD-MM-YYYY   hh:mm"
                    )}
                  </Col>
                  <Col md={2}></Col>
                </Row>

                <Row className='mt-1'>
                  <Col md={3}>
                    <b>Update Message:</b>
                  </Col>
                  <Col md={8}>{auditTrailLogData?.data?.requestBody?.revokeMessage??"N/A"}</Col>
                  
                </Row>

                <Row className='mt-4'>
                  <Col md={6}>
                 <b className='title'>Before Updation:  </b> 

                  </Col>
                  <Col md={6}>
                  <b className='title'>After Updation: </b> 
                 
                  </Col>
                  
                </Row>
                <hr />

                <Row className='mt-1'>
                  <Col md={6}>
                  {/* <ReactJson src={beforeOperationsHourValues} /> */}

                  {/* <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={beforeColumns}
                  data={jsonObject}
                  // data={logsData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={beforeColumns}
                      data={[jsonObject]}
                      // data={logsData}
                      search
                    >
                      {(toolkitProps) => (
                        <React.Fragment>
                          

                     <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable
                                  hover
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  classes={"table align-middle table-nowrap"}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                />
                              </div>
                            </Col>
                          </Row>   
                          
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider> */}

{auditTrailLogData?.data?.logType==='ResourceTypes'?" ResourceTypes Log In Progress": auditTrailLogData?.data?.logType==='Locations'?"Locations Log In Progress":  <DynamicView data={beforeOperationsHourValues}  dataTypes={"before"} emptyData={jsonObject}/>}
                  </Col>
                  <Col md={6}>
                  {/* <ReactJson src={jsonObject.operationsHours} /> */}
                  {auditTrailLogData?.data?.logType==='ResourceTypes'?" ResourceTypes Log In Progress": auditTrailLogData?.data?.logType==='Locations'?"Locations Log In Progress":  <DynamicView data={jsonObject} dataTypes={"after"} emptyData={jsonObject} />}
   


                 
                  </Col>
                  
                </Row>

             
                
              </ModalBody>}
            </Modal>
          </div>
    </div>
  )
}






const DynamicView = ({ data, dataType ,emptyData }) => {
  if (!Array.isArray(data)) return <Spinner animation="border" />;

  // Check if data is an empty array or contains only empty objects
  const isEmptyData = emptyData.length === 0 || emptyData.every(obj => Object.keys(obj).length === 0);

  if (isEmptyData) {
    return <div> User Made some Changes and then revert back.. </div>;
  }
 

return (
    <div>
      {data.map((obj, index) => {
        const { Name, ...rest } = obj; // Extract the Name key
      
        return (
          <div key={index} style={{ marginBottom: '10px'}}>
            <div style={{ marginBottom:"10px" ,height: dataType === "after" ? '90px':"20px"   }}>
              <Row>
                {/* <Col md={6}>
                  <b className='title'>{Name?"ResourceType Name:":""}</b>
                </Col> */}
                <Col md={6} className='title'>
                  {Name}
                </Col>
              </Row>
            </div>
            <div style={{ border: '1px solid', padding: '10px' ,borderRadius:'10px'}}>
              {Object.keys(rest).map((key) => (
                <div key={key}>
                  <Row className='mt-1'>
                    <Col md={6}>
                      <b>{key}</b>
                    </Col>
                    <Col md={6}>
                      {rest[key].toString()}
                    </Col>
                  </Row>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};





export default AuditTrailLogs
