import React from "react";
import UseTeams from "./useTeams";
import { CSVLink } from "react-csv";
import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
  Input,
} from "reactstrap";

import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import "react-toastify/dist/ReactToastify.css";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import "./../Tables/datatables.scss";
import "./css/styles.css";
import UserPermissionFetcher from "../../helpers/getUserPerimissions";
import NoAccess from "../../components/Common/NoAccess";
import Spinner from "../../components/Common/Loader";

const defaultSorted = [
  {
    dataField: "id",
    order: "asc",
  },
];

const Teams = () => {
  const userPermissions = UserPermissionFetcher("teams");

  const {
    AllTeamsSvg,
    isOpen,
    searchedData,
    headers,
    pageSize,
    AllTeamsData,
    currentPage,
    pageOptions,
    hasNextPage,
    isLoading,
    buttonFunc,
    setCurrentPage,
    handleIncrementBtn,
    setSearchedData,
    pagelengthnum,
    toggle,
  } = UseTeams();
  const columns = [
    {
      dataField: "name",
      text: "Team name ",
      sort: true,
      formatter: (cell, row) => (
        <Link to={`/view_team/${row?.id}`} className="link">
          {cell}
        </Link>
      ),

      style: { width: "12%" },
    },
    {
      dataField: "createdAt",
      text: "Created on",
      sort: true,
      style: { width: "12%" },
    },
    {
      dataField: "teamMembersCount",
      text: "Members",
      sort: true,
      style: { width: "8%" },
    },
    {
      dataField: "totalAmountSpent",
      text: "Total Amount Spent (SAR)",
      sort: true,
      style: { width: "21%" },
    },
    {
      dataField: "DiscountCharges",
      text: "Discount Applied %",
      sort: true,
      // style: { width: "40%" },
    },
    {
      dataField: "recentTransactionTime",
      text: "Last Transaction Date",
      sort: true,
      // style: { width: "40%" },
    },
    {
      dataField: "",
      text: "Actions",
      sort: true,
      // style: { width: "40%" },
      formatter: (cell, row) => (
        <div className="d-flex align-content-center flex-wrap pt-3">
          <Link to={`/edit_team/${row?.id}`} className="link">
            {<p className="">Edit</p>}
          </Link>
        </div>
      ),
    },
  ];

  return (
    <>
      {userPermissions === null ? (
        <div className="page-content">
          <Spinner />
        </div>
      ) : userPermissions.read ? (
        <div className="page-content">
          <Row className="mb-3">
            <Col md={4}>
              <span className="locationbrandtitle  ">All Teams</span>
            </Col>
            <Col md={6}></Col>
            <Col md={2}>
              {userPermissions.create && (
                <Link to="/create_team" className="list">
                  <Button
                    // disabled={!userPermissions.create}
                    color="success"
                    className="waves-effect waves-light mr-1  w-100"
                    block
                  >
                    <span className="printbutton">Create</span>
                  </Button>
                </Link>
              )}
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <Row className="mb-2">
                    <Col md="4">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <Input
                            type="search"
                            className="mt-1 mb-1 search-input-team"
                            placeholder="Search Team"
                            value={searchedData}
                            onChange={(ele) => {
                              setCurrentPage(1);
                              setSearchedData(ele?.target?.value);
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {isLoading ? (
                    <Spinner />
                  ) : (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="id"
                      columns={columns}
                      data={AllTeamsData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={AllTeamsData}
                          search
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      hover
                                      keyField={"id"}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      classes={"table align-middle  "}
                                      headerWrapperClasses={"thead-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <Row className="align-items-md-center mt-3">
                <Col className="inner-custom-pagination d-flex">
                  <span className="paginationitmes ">Items</span>
                  <div className="d-inline">
                    <ButtonDropdown
                      isOpen={isOpen}
                      toggle={toggle}
                      onClick={(e) => pagelengthnum(e)}
                    >
                      <DropdownToggle caret color="secondary">
                        {pageSize}
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem value="1">10</DropdownItem>
                        <DropdownItem value="2">30</DropdownItem>
                        <DropdownItem />
                        <DropdownItem value="3">50</DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </div>
                  <span className="paginationitmes1 ">show</span>
                  <span>
                    <CSVLink
                      className="csvButton"
                      data={AllTeamsSvg ? AllTeamsSvg : []}
                      headers={headers}
                      filename={" teamsData.csv"}
                    >
                      Export
                    </CSVLink>
                  </span>
                  <div className="text-md-right ms-auto">
                    <Button
                      color="none"
                      className="waves-effect "
                      style={{
                        marginLeft: 7,
                        marginRight: 7,
                      }}
                      disabled={currentPage <= 1}
                      onClick={() => handleIncrementBtn(false)}
                    >
                      <i className="dripicons-chevron-left"></i>
                    </Button>

                    {buttonFunc()}
                    <Button
                      style={{
                        marginLeft: 7,
                        marginRight: 7,
                      }}
                      color="none"
                      className="waves-effect"
                      disabled={!hasNextPage}
                      onClick={() => handleIncrementBtn(true)}
                    >
                      <i className="dripicons-chevron-right"></i>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default Teams;
