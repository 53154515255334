import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getDiscountLogs,
  getDiscountLogsDrops,
  getDiscountLogsDropsCode,
  getDiscountLogsDropsName,
} from "../../../../APIS/discountAmount";

const UseDiscountLog = () => {
  let history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const token = localStorage.getItem("Token");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [activeTabJustify, setActiveTabJustify] = useState("1");
  const changeCurrentPage = (pageNum) => setCurrentPage(pageNum);
  const [filter, useFilter] = useState(false);
  const [count, setCount] = useState(1);
  const [discountLogFilter, setDiscountLogFilter] = useState();
  const queryClient = useQueryClient();
  const QueryClient = useQueryClient();
  // const total=50;
  const {
    data: DiscountLogs,
    isLoading,
    total,
    hasNextPage,
    hasPreviousPage,
  } = useQuery(["getDiscountLogs", filter, count], () =>
    getDiscountLogs(
      pageSize,
      currentPage,
      token,
      history,
      filter,
      discountLogFilter
    )
  );
  const DiscountLogsData = DiscountLogs?.data;

  const { data: DiscountLogsDropsName } = useQuery(
    ["getDiscountLogsdropsName"],
    () =>
      getDiscountLogsDropsName(
        pageSize,
        currentPage,
        token,
        history,
        filter,
        discountLogFilter
      )
  );
  const DiscountLogsNames = DiscountLogsDropsName?.map((e) => {
    return e;
  });

  const { data: DiscountLogsDropsCode } = useQuery(
    ["getDiscountLogsdropsCode"],
    () =>
      getDiscountLogsDropsCode(
        pageSize,
        currentPage,
        token,
        history,
        filter,
        discountLogFilter
      )
  );

  const DiscountLogsCode = DiscountLogsDropsCode?.map((e) => {
    return e;
  });

  const DiscountLogsNamesFunc = (e) => {
    setDiscountLogFilter({ ...discountLogFilter, logName: e?.value });
  };
  const DiscountLogsCodeFunc = (e) => {
    setDiscountLogFilter({ ...discountLogFilter, logValue: e?.value });
  };
  const SeacrhFunc = (e) => {
    if (
      discountLogFilter?.logName === undefined ||
      discountLogFilter?.logValue === undefined
    ) {
      toast.error("Please select both filters");
    } else {
      setCount(count + 1);
      // useFilter(true);
      QueryClient.invalidateQueries("getDiscountLogs");
    }
    // Call useFilter unconditionally, outside of the if-else block
    useFilter(true);
  };

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  const pagelengthnum = (e) => {
    if (e.target.value === "1") {
      setPageSize(10);
    } else if (e.target.value === "2") {
      setPageSize(30);
    } else if (e.target.value === "3") {
      setPageSize(50);
    }
  };
  const pageOptions = {
    sizePerPage: pageSize,
    totalSize: total ?? 0,
    custom: true,
  };

  const toggleCustomJustified = (tab) => {
    if (activeTabJustify !== tab) {
      setActiveTabJustify(tab);
    }
  };

  useEffect(() => {
    QueryClient.invalidateQueries("getDiscountLogs");
  }, [filter, count]);

  const defaultSorted = [
    {
      dataField: "code",
      order: "asc",
    },
  ];

  const data = [
    {
      discountCode: "No Data",
      userName: "",
      discountAmount: "No Data",
      timesUsed: "",
      lastUsed: "",
    },
  ];

  return {
    defaultSorted,
    data,
    currentPage,
    pageOptions,
    // userdetailsData,
    toggleCustomJustified,
    activeTabJustify,
    setActiveTabJustify,
    // userActivebookingsData,
    // userPastBookingsData,
    // userScheduledBookingsData,
    // hasNextPage,
    // hasPreviousPage,
    total,
    // pastHasNextPage,scheduledHasNextPage,
    // isLoading,
    pagelengthnum,
    pageSize,
    toggle,
    isOpen,
    DiscountLogsData,
    changeCurrentPage,
    DiscountLogsNames,
    DiscountLogsCode,
    DiscountLogsNamesFunc,
    DiscountLogsCodeFunc,
    SeacrhFunc,
  };
};

export default UseDiscountLog;
