import React, { useEffect, useMemo, useState } from "react";
import { getSingleTeamApi } from "../../../APIS/allTeams";
import { useHistory, useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { toast } from "react-toastify";
import { DIOM_BASED_URLS } from "../../../config/url";

const UseEditTeam = () => {
  const QueryClient = useQueryClient();
  const { id } = useParams();
  const history = useHistory();
  const [errMsg, setErrMsg] = useState("");
  const [canDeleteTeam, setCanDeleteTeam] = useState(false);
  const [modal_static2, setModal_static2] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [teamExist, setTeamExist] = useState(false);
  const token = localStorage.getItem("Token");
  const [discountNumber, setDiscountNumber] = useState(null);
  const [modal_static, setModal_static] = useState(false);
  const [selectedDiscountRadio, setSelectedDiscountRadio] = useState("");
  const [teamName, setTeamName] = useState("");
  const [teamAdminName, setTeamAdminName] = useState("");
  const [teamAdminEmail, setTeamAdminEmail] = useState("");
  const [teamArr, setTeamArr] = useState([]);
  const [modal_static1, setModal_static1] = useState(false);
  const [delCheck1, setDelCheck1] = useState(false);
  const [delCheck2, setDelCheck2] = useState(false);
  const [delCheck3, setDelCheck3] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const [newMemberEmail, setNewMemberEmail] = useState("");

  const removeMmeberFunc = (ele) => {
    const newTeamArr = [...teamArr];

    const index = newTeamArr.findIndex(
      (member) => member?.email === ele?.email
    );

    if (index !== -1) {
      newTeamArr.splice(index, 1);
    }

    setTeamArr(newTeamArr);
  };

  // unique email function

  function checkUniqueEmails(array) {
    const emailSet = new Set();

    for (let i = 0; i < array.length; i++) {
      const emailObj = array[i];
      const { email } = emailObj;

      if (emailSet.has(email)) {
        // Email is repeated
        return false;
      }

      emailSet.add(email);
    }

    // All emails are unique
    return true;
  }

  const addMemFunc = () => {
    if (!checkUniqueEmails([...teamArr, { email: teamAdminEmail }])) {
      return toast.error("This email is already exist", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setTeamArr([
        ...teamArr,
        {
          name: teamAdminName,
          email: teamAdminEmail,
          role: "teamMember",
        },
      ]);

      setTeamAdminEmail("");
      setTeamAdminName("");
    }
  };

  const handleOptionChange = (event) => {
    setSelectedDiscountRadio(event.target.value);
  };

  const tog_static = () => {
    setModal_static(!modal_static);

    // removeBodyCss();
  };
  const tog_static1 = () => {
    setModal_static1(!modal_static1);

    // removeBodyCss();
  };
  const tog_static2 = () => {
    setModal_static2(!modal_static2);

    // removeBodyCss();
  };

  function Offsymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  function OnSymbol(text) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {text}
      </div>
    );
  }

  // all team without pagination

  const { data: TeamData } = useQuery(["singleTeamsData"], () =>
    getSingleTeamApi(token, history, id)
  );

  const singleTeamsData = TeamData?.data;

  useMemo(() => {
    setTeamArr(singleTeamsData?.teamMembers);
    setTeamName(singleTeamsData?.name);
  }, [singleTeamsData]);

  const changeAdmin = (e, item, index) => {
    const newTeam = teamArr.map((team) => {
      return { ...team, role: "teamMember" };
    });

    const object = newTeam.find((e) => e.email === item?.email);
    const newArr = [...newTeam];
    const newObject = {
      ...object,
      role: e === true ? "teamAdmin" : "teamMember",
    };
    newArr[index] = newObject;
    setTeamArr(newArr);
  };

  const createTeamFunc = () => {
    setCreateLoading(true);
    fetch(`${DIOM_BASED_URLS}/admin-teams/${id}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        name: teamName,
        discountOnBookings: singleTeamsData?.discountOnBookings,
      }),
    })
      .then((result3) => {
        setCreateLoading(false);
        if (result3.status === 200) {
          QueryClient.invalidateQueries("singleTeamsData");
          toast.success("Successfully Updated");
        } else if (result3.status === 204) {
          QueryClient.invalidateQueries("singleTeamsData");
          toast.success("Successfully Updated");
        } else {
          toast.error(" Something went wrong");
        }
        setModal_static1(false);
      })
      .catch((error) => {
        setCreateLoading(false);
        toast.error(" Something went wrong");
      });
  };

  // This Fn Call API to Check This team exist if not disable Save btn
  const handleSearch = () => {
    if (teamName !== singleTeamsData?.name) {
      fetch(`${DIOM_BASED_URLS}/teams/run-commands`, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain",
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          command: "CHECK_TEAM_EXISTANCE",
          key: "",
          value: [teamName],
        }),
      })
        .then((result3) => result3?.json())
        .then((result3) => {
          if (result3?.message === "No Team With this name exists") {
            setTeamExist(false);
          } else if (
            result3?.error?.message ===
            "Team name already exists. Please try another"
          ) {
            setTeamExist(true);
            toast.error(result3?.error?.message);
          }
          setModal_static1(false);
        })
        .catch((error) => toast.error(" Something went wrong"));
    }
  };

  const deleteTeamFunc = () => {
    setDelLoading(true);
    fetch(`${DIOM_BASED_URLS}/admin-teams/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({}),
    })
      .then((result) => result?.json())
      .then((result3) => {
        setDelLoading(false);
        if (result3.message === "Team Deleted Successfully") {
          QueryClient.invalidateQueries("AllTeamsDataFilters");
          QueryClient.invalidateQueries("AllTeamsData");
          history.push("/teams");
          toast.success(result3.message);
        } else {
          setDelLoading(false);
          toast.error(result3?.error?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(" Something went wrong");
      });
  };

  const updateTeamMmeber = () => {
    fetch(`${DIOM_BASED_URLS}/teams/${id}/run-commands`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        command: "TEAM_ADD_MEMBER",
        key: "",
        value: [newMemberEmail],
      }),
    })
      .then((result) => result?.json())
      .then((result3) => {
        setDelLoading(false);
        if (result3.statusCode === 200) {
          QueryClient.invalidateQueries("singleTeamsData");
          setNewMemberEmail("");
          toast.success(result3.message);
        } else {
          toast.error(result3?.error?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(" Something went wrong");
      });
  };

  const updateAdminFunc = () => {
    const coworkerId = teamArr?.find((item) => {
      if (item?.role === "teamAdmin") {
        return item;
      }
    });

    fetch(`${DIOM_BASED_URLS}/teams/${id}/run-commands`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        command: "CHANGE_ADMIN",

        key: "",

        value: [coworkerId?.id],
      }),
    })
      .then((result) => result?.json())
      .then((result3) => {
        setModal_static(false);
        setDelLoading(false);
        if (result3.statusCode === 200) {
          QueryClient.invalidateQueries("singleTeamsData");
          setNewMemberEmail("");
          toast.success(result3.message);
        } else {
          toast.error(result3?.error?.message);
        }
      })
      .catch((error) => {
        setModal_static(false);
        console.log("error", error);
        toast.error(" Something went wrong");
      });
  };

  const delMmemberFunc = (member) => {
    fetch(`${DIOM_BASED_URLS}/teams/${id}/run-commands`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        command: "TEAM_REMOVE_MEMBER",

        key: "add team admin",

        value: [member?.id],
      }),
    })
      .then((result) => result?.json())
      .then((result3) => {
        setModal_static(false);
        setDelLoading(false);
        if (result3.statusCode === 200) {
          QueryClient.invalidateQueries("singleTeamsData");
          setNewMemberEmail("");
          toast.success(result3.message);
        } else {
          toast.error(result3?.error?.message);
        }
      })
      .catch((error) => {
        setModal_static(false);
        console.log("error", error);
        toast.error(" Something went wrong");
      });
  };

  const delButtonClicked = () => {
    setModal_static2(true);

    fetch(`${DIOM_BASED_URLS}/teams/${id}/run-commands`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain",
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        command: "CAN_TEAM_BE_DELETED",

        key: "",

        value: [id],
      }),
    })
      .then((result) => result?.json())
      .then((result3) => {
        if (result3?.data?.canDelete === true) {
          setCanDeleteTeam(true);
          // toast.success(result3.message);
        } else {
          setCanDeleteTeam(false);
          setErrMsg(result3.message);
          // toast.error(result3?.error?.message);
        }
      })
      .catch((error) => {
        console.log("error", error);
        toast.error(" Something went wrong");
      });
  };

  // Define a state variable to hold the initial teamName
  const [initialTeamName, setInitialTeamName] = useState("");

  // Use useEffect to set initialTeamName when the component mounts
  useEffect(() => {
    setInitialTeamName(teamName);
  }, [teamArr]);
  return {
    errMsg,
    updateAdminFunc,
    newMemberEmail,
    setNewMemberEmail,
    updateTeamMmeber,
    setDelCheck1,
    setDelCheck2,
    setDelCheck3,
    delCheck1,
    delCheck2,
    delCheck3,
    delLoading,
    deleteTeamFunc,
    tog_static2,
    modal_static2,
    setModal_static2,
    tog_static2,
    teamName,
    createLoading,
    teamExist,
    handleSearch,
    discountNumber,
    setDiscountNumber,
    createTeamFunc,
    tog_static1,
    modal_static1,
    setModal_static1,
    Offsymbol,
    OnSymbol,
    tog_static,
    modal_static,
    setModal_static,
    handleOptionChange,
    selectedDiscountRadio,
    setSelectedDiscountRadio,
    setTeamName,
    setTeamExist,
    teamName,
    teamAdminName,
    setTeamAdminName,
    teamAdminEmail,
    setTeamAdminEmail,
    teamArr,
    setTeamArr,
    removeMmeberFunc,
    addMemFunc,
    changeAdmin,
    delMmemberFunc,
    delButtonClicked,
    canDeleteTeam,
    initialTeamName,
  };
};

export default UseEditTeam;
