import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import AuthLockScreen from "../pages/Authentication/AuthLockScreen";

// Dashboard

// Pages Calendar
import Calendar from "../pages/Calendar/Calendar";

//Utility
import StarterPage from "../pages/Utility/StarterPage";
import Maintenance from "../pages/Utility/Maintenance";
import CommingSoon from "../pages/Utility/CommingSoon";
import Timeline from "../pages/Utility/Timeline";
import FAQs from "../pages/Utility/FAQs";
import Pricing from "../pages/Utility/Pricing";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Inner Authentication
// import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
// import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";

import MyDashboard from "../pages/MyDashboard/mydashboard";

import Finance from "../pages/Finance/index";
import Reports from "../pages/Reports/index";
import ReportRequest from "../pages/Reports/reportRequest";
import AddAPackage from "../pages/AddAPackage/index";
import LocationDetailed from "../pages/Locations/LocationDetailed/index";
import Locations from "../pages/Locations/index";

import LocationBrands from "../pages/Locations/LocationBrands/index";
import Inventories from "../pages/Inventories/Index";
import InventoryDetail from "../pages/Inventories/InventoryDetail/index";
import ReactBigCalendar from "../pages/Calendar/ReactBigCalendar";
import ResourceType from "../pages/Inventories/ResourceType/index";
import ResourceDetailed from "../pages/Inventories/ResourceType/ResourceDetailed/index";
import FloorPlan from "../pages/Inventories/FloorPlan/index";
import FloorPlanDetail from "../pages/Inventories/FloorPlan/FloorPlanDetail/index";
import CheckedinCustomer from "../pages/MyDashboard/CheckedinCustomer/index";
import CheckedinCustomerDetail from "../pages/MyDashboard/CheckedinCustomer/CheckedinCustomerDetail/index";
import InvoicesMainPage from "../pages/Finance/InvoicesMainPage/index";
import InvoicesDetailPage from "../pages/Finance/InvoicesMainPage/InvoicesDetailPage/index";
import LocationBrandDetail from "../pages/Locations/LocationBrands/LocationBrandDetail/index";
import CreateLocationBrand from "../pages/Locations/LocationBrands/CreateLocationBrand/index.js";
import Taxation from "../pages/Finance/Taxation/index";
import BookingDetail from "../pages/MyDashboard/RequestBookings/BookingDetail/index";
import Categories from "../pages/Inventories/Categories";
import UserProfile from "../pages/UserProfile/index.js";
import CategoriesDetail from "../pages/Inventories/Categories/CategoriesDetail";
import RequestBookings from "../pages/MyDashboard/RequestBookings/index.js";
import UserProfileDetail from "../pages/UserProfile/UserProfileDetail/index.js";
import CreateCategory from "../pages/Inventories/Categories/createCategory";
import NotificationListingPage from "../pages/Notifications/notificationListingPage";
import DataLogs from "../pages/DataLogs";
import AuditTrailLogs from "../pages/DataLogs/auditTrailLogs.js";
import UserDel from "../pages/UserProfile/UserDel";
import OperationalHours from "../pages/Locations/OperationalHours";
import AnnountmentListing from "../pages/Announcement/announcmentListing";
import ExceptionListing from "../pages/Locations/OperationalHours/ExceptionListing/index.js";
import AddException from "../pages/Locations/OperationalHours/ExceptionListing/ExceptionAdd/index.js";
import EditException from "../pages/Locations/OperationalHours/ExceptionListing/EditExceptions";
import Discount from "../pages/pricing/discount";
import DiscountLog from "../pages/pricing/discount/DiscoutLog";
import EditDiscount from "../pages/pricing/discount/editDiscount";
import Packages from "../pages/pricing/packages";
import Faqs from "../pages/pricing/packages/faqs";
import CreatePackages from "../pages/pricing/packages/createPackage";
import PackageCategories from "../pages/pricing/packages/packageCategories";
import EditPackage from "../pages/pricing/packages/editPackage";
import DetailedPackage from "../pages/pricing/packages/detailedPackage";
import PackageInvoiceDetail from "../pages/Finance/InvoicesMainPage/packageInvoiceDetail";
import InstantCheckIn from "../pages/pricing/instantCheckIn/index.js";
import EditInstantCheckin from "../pages/pricing/instantCheckIn/editInstantCheckin";
import Teams from "../pages/teams";
import CreateTeam from "../pages/teams/createTeam";
import ViewTeam from "../pages/teams/viewTeam";
import EditTeam from "../pages/teams/editTeam";
import TeamInvoiceDetailsPage from "../pages/Finance/InvoicesMainPage/teamInvoiceDetailsPage";
import {UserRoles} from "../pages/userRoles";
import UserDetailRole from "../pages/userRoles/UserDetailPage/index";
import CreateUser from "../pages/UserProfile/Create User";
import RoleUsers from "../pages/userRoles/roleUsers/index.js";
import PlanDiscounts from "../pages/Discounts/PlanDiscount/index.js";
import Index2 from "../pages/Discounts/PlanDiscount/CreatePlanDiscount/index2.js";
import NextStep from "../pages/Discounts/PlanDiscount/CreatePlanDiscount/nextStep.js";
import ChangePassword from "../pages/Authentication/ChangePassword.js";
import CreateAdminBooking from "../pages/MyDashboard/RequestBookings/AdminBooking/CreateAdminBooking.js";
import AdminGuestBookingDetails from "../pages/MyDashboard/RequestBookings/AdminBooking/Components/AdminGuestBookingDetails.js";
import UserGuestBookingDetails from "../pages/MyDashboard/RequestBookings/AdminBooking/Components/UserGuestBookingDetails.js";
import HayperPayCard from "../pages/Payment/card.js";

const token = localStorage.getItem("Token");

// Custom Fn

const authProtectedRoutes = [
  {
    path: "/dashboard",
    component: MyDashboard,
  },
  { path: "/calendar_view", component: ReactBigCalendar },
  { path: "/admin-booking-details/:id", component: AdminGuestBookingDetails },
  {
    path: "/user_roles",
    component: UserRoles,
  },
  {
    path: "/plan-discounts",
    component: PlanDiscounts,
  },
  {
    path: "/plan_discount",
    component: Index2,
  },
  {
    path: "/create-admin-booking",
    component: CreateAdminBooking,
  },
  {
    path: "/plan_discounts/:id",
    component: Index2,
  },
  {
    path: "/next_step/:id",
    component: NextStep,
  },
  // {
  //   path: "/plan_discount_detail/:id",
  //   component: DiscountDetailPage,
  // },
  {
    path: "/role_users/:role",
    component: RoleUsers,
  },
  {
    path: "/user_role_detail/:id",
    component: UserDetailRole,
  },
  { path: "/category/:id", component: CategoriesDetail },
  { path: "/finance", component: Finance },
  { path: "/reports", component: Reports },
  { path: "/report_request", component: ReportRequest },
  { path: "/add_package", component: AddAPackage },
  { path: "/location/:id", component: LocationDetailed },
  { path: "/locations", component: Locations },
  { path: "/data_logs", component: DataLogs },
  { path: "/audit-trail-logs", component: AuditTrailLogs },
  { path: "/booking_detail/:id/:t_ID", component: BookingDetail },
  { path: "/booking_detail/:id", component: BookingDetail },
  { path: "/location_brands", component: LocationBrands },
  { path: "/inventories", component: Inventories },
  { path: "/inventory/:id", component: InventoryDetail },
  { path: "/resourcetypes", component: ResourceType },
  { path: "/categories", component: Categories },
  { path: "/create_category", component: CreateCategory },
  { path: "/change-password", component: ChangePassword },
  {
    path: "/notification_listingPage//:PreviousUrl",
    component: NotificationListingPage,
  },
  { path: "/user_delete", component: UserDel },
  {
    path: "/user_create",
    component: CreateUser,
  },
  { path: "/announcment_listing", component: AnnountmentListing },
  { path: "/operational_hours", component: OperationalHours },
  { path: "/exception_listing", component: ExceptionListing },
  { path: "/add_exception", component: AddException },
  { path: "/edit_exception/:id", component: EditException },
  { path: "/discount", component: Discount },
  { path: "/discount_log", component: DiscountLog },
  { path: "/package_categories", component: PackageCategories },
  // { path: "/forgot-password", component: ForgetPassword },

  {
    path: "/edit_Package/:id",
    component: EditPackage,
  },
  {
    path: "/detailed_Package/:id",
    component: DetailedPackage,
  },
  { path: "/package_invoice_detail/:id", component: PackageInvoiceDetail },
  {
    path: "/resourceType/:id/:resourceTypeKey",
    component: ResourceDetailed,
  },
  { path: "/floorplans", component: FloorPlan },
  { path: "/floorplan/:floorid", component: FloorPlanDetail },
  { path: "/checkedin_customer", component: CheckedinCustomer },
  {
    path: "/checkedin_customer_detail/:id",
    component: CheckedinCustomerDetail,
  },
  { path: "/invoices", component: InvoicesMainPage },
  { path: "/invoice/:id", component: InvoicesDetailPage },
  { path: "/team_invoice_detail_page/:id", component: TeamInvoiceDetailsPage },

  { path: "/location_brand/:id", component: LocationBrandDetail },
  { path: "/create_location_brand", component: CreateLocationBrand },
  { path: "/taxation", component: Taxation },
  { path: "/request_bookings/:t_ID", component: RequestBookings },
  { path: "/user_profile", component: UserProfile },
  { path: "/teams", component: Teams },
  {
    path: "/create_team",
    component: CreateTeam,
  },
  { path: "/view_team/:id", component: ViewTeam },
  { path: "/edit_team/:id", component: EditTeam },

  { path: "/user_profile_detail/:id", component: UserProfileDetail },
  { path: "/edit_discount/:id", component: EditDiscount },
  {
    path: "/packages",
    component: Packages,
  },
  { path: "/faqs", component: Faqs },
  {
    path: "/create_packages",
    component: CreatePackages,
  },
  { path: "/instant_checkin", component: InstantCheckIn },
  { path: "/edit_instant_checkin", component: EditInstantCheckin },

  // Tables
  { path: "/basic-tables", component: BasicTables },
  { path: "/datatable-table", component: DatatableTables },
  { path: "/responsive-table", component: ResponsiveTables },
  { path: "/editable-table", component: EditableTables },

  //Utility
  { path: "/starter", component: StarterPage },
  { path: "/timeline", component: Timeline },
  // { path: "/faqs", component: FAQs },
  { path: "/pricing", component: Pricing },

  //calendar
  { path: "/calendar", component: Calendar },

  // this route should be at the end of all other routes
  // { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> }

  {
    path: "/",
    exact: true,
    component: () =>
      token !== "" ? <Redirect to="/dashboard" /> : <Redirect to="/login" />,
  },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/user-booking-details/:id", component: UserGuestBookingDetails },
  { path: "/hayperpay-card-view", component: HayperPayCard },
  // { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/lock-screen", component: AuthLockScreen },

  // Authentication Inner
  // { path: "/auth-login", component: Login1 },
  { path: "/auth-register", component: Register1 },
  // { path: "/auth-recoverpw", component: ForgetPwd1 },

  { path: "/maintenance", component: Maintenance },
  { path: "/comingsoon", component: CommingSoon },
  { path: "/404", component: Error404 },
  { path: "/500", component: Error500 },
];

export { authProtectedRoutes, publicRoutes };
