import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import UsePackageCategory from "./usePackageCategory";
import {
  Col,
  Input,
  Modal,
  ModalBody,
  Row,
  Button,
  Card,
  Container,
} from "reactstrap";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Spinner } from "reactstrap";
import "./css/packageCategory.css";
import { height, width } from "@mui/system";
import UserPromissionFetcher from "../../../../helpers/getUserPerimissions";
import NoAccess from "../../../../components/Common/NoAccess";
// import uuid from "uuid/v4";

const { v4: uuid, stringify } = require("uuid");

const PackageCategories = () => {
  const userPermissions = UserPromissionFetcher("packages");
  const {
    categoryLoading,
    modal_static1,
    modal_static2,
    resourceTypePackagesData,
    allCategoriesData,
    packageCategoryName,
    tog_static2,
    setModal_static2,
    tog_static1,
    setModal_static1,
    PackageCategoryNameFunc,
    addResourceTypesCategoryFunc,
    setPackageCategoryName,
    setidForDelete,
    deleteCategoryFunc,
    saveCategoryData,
    removeResourceTypesCategoryFunc,
  } = UsePackageCategory();
  // const [bgclr]=useState(resourceTypePackagesData?.length % 2 === 0 ?  "red" : "blue")

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    const data = allCategoriesData ? allCategoriesData : [];

    setColumns([
      {
        name: "Uncategorized",
        id: 43266,
        items: resourceTypePackagesData?.map((element) => {
          return {
            id: element?.id?.toString(),
            content: element?.Name,
            businessName: element?.businessName,
          };
        }),
      },
      ...data?.map((category) => {
        return {
          id: category?.id,
          name: category.name,
          items: category?.resourceTypes?.map((element) => {
            return {
              id: element?.id?.toString(),
              content: element?.name,
              businessName: element?.businessName,
            };
          }),
        };
      }),
    ]);
  }, [resourceTypePackagesData, allCategoriesData]);

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;
    if (source.droppableId !== destination?.droppableId) {
      const sourceColumn = columns[source?.droppableId];
      const destColumn = columns[destination?.droppableId];
      const sourceItems = [...sourceColumn?.items];
      const destItems = [...destColumn?.items];
      const [removed] = sourceItems?.splice(source.index, 1);

      destItems.splice(destination.index, 0, removed);

      removeResourceTypesCategoryFunc(sourceColumn?.id, removed?.id);
      addResourceTypesCategoryFunc(destColumn?.id, removed?.id);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
    }
  };

  const createCategoryColumnFunc = () => {
    saveCategoryData();
    setModal_static1(false);
  };

  return (
    <>
      {userPermissions === null ? (
        <Spinner />
      ) : userPermissions.read ? (
        <div className="page-content">
          <Row className="mb-4">
            <Col md={4}>
              <Link to="/packages" className="link">
                <span className="fas fa-angle-left arrowheightwidth"></span>
              </Link>
              <span className="bookingtitle ">Categories</span>
            </Col>
            <Col md={6}></Col>
            <Col md={2}>
              {userPermissions.create && (
                <Button
                  color="success"
                  className="waves-effect waves-light mr-1 w-100 "
                  block
                  onClick={tog_static1}
                >
                  <span className=" printbutton ">Create Category</span>
                </Button>
              )}
            </Col>
          </Row>

          <Row>
            <Col md={3}>
              <span style={{ display: "flex" }}>
                {" "}
                <p className="drgdrptitle">Uncategorized</p>
                <span hidden={categoryLoading}>
                  {" "}
                  <Spinner
                    className="cat_loading"
                    animation="border"
                    role="status"
                  ></Spinner>
                </span>
              </span>
              {/* <hr className="drgdrptitleLine" style={{width:"100%"}} /> */}
            </Col>
            <Col md={9}>
              <span style={{ display: "flex" }}>
                {" "}
                <p className="drgdrptitle" style={{ marginLeft: "4%" }}>
                  Categorized
                </p>
                <span hidden={categoryLoading}>
                  {" "}
                  <Spinner
                    className="cat_loading"
                    animation="border"
                    role="status"
                  ></Spinner>
                </span>
              </span>
            </Col>
          </Row>

          <Row>
            <Col md={12} xl={12}>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: "100%",

                  overflowY: "auto",

                  overflowX: "auto",
                }}
              >
                <DragDropContext
                  onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
                >
                  {Object?.entries(columns)?.map(
                    ([columnId, column], index) => {
                      return (
                        <span
                          className={`${
                            index === 0 ? "first_box position-static" : ""
                          }`}
                        >
                          <Row>
                            <Col md={12}>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  // backgroundColor:
                                  //   index % 2 === 0 ? "#03B2A5" : "#e0e0e0",
                                  backgroundColor:
                                    index === 0 ? "#03B2A5" : "#e0e0e0",

                                  borderRadius: "5px",
                                  marginRight: "20px",
                                  boxShadow:
                                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                }}
                                key={columnId}
                              >
                                <Row style={{ marginTop: 25 }}>
                                  <Col
                                    md={12}
                                    style={{ display: "flex", width: "100%" }}
                                  >
                                    <span
                                      style={{
                                        overflow: "auto",
                                        maxHeight: "25px",
                                      }}
                                    >
                                      <h2
                                        style={{
                                          fontWeight: 700,
                                          fontSize: "19px",
                                          //  alignItems: "left",

                                          paddingLeft: "10px",
                                        }}
                                        className="mb-0 "
                                      >
                                        {column?.name}

                                        <span
                                          style={{ marginLeft: "5px" }}
                                          className={"colLength"}
                                        >
                                          ({column?.items?.length})
                                        </span>
                                      </h2>
                                    </span>

                                    <span className="deleteIconPackage">
                                      {/* {index === 0 ? undefined : ( */}
                                      {index === 0 ||
                                      column?.items?.length !==
                                        0 ? undefined : (
                                        <i
                                          onClick={() => {
                                            setModal_static2(true);
                                            setidForDelete(column?.id);
                                          }}
                                          className="delIcon mdi mdi-delete  deliconsize ml-5"
                                        ></i>
                                      )}
                                    </span>
                                  </Col>

                                  {/* <Col md={1}>
                      
                       </Col> */}

                                  {/* <Col md={1}>
                               {
                                ((index ===0)  || (column?.items?.length !==0)) ?
                                undefined:<i
                                onClick={() => {
                                  setModal_static2(true);
                                  setidForDelete(column?.id);
                                }}
                                className="delIcon mdi mdi-delete  deliconsize ml-5"
                              ></i>
                               }
                              </Col> */}
                                </Row>

                                <div
                                  style={{
                                    margin: 8,
                                    fontWeight: 700,
                                    fontSize: "11px",
                                  }}
                                >
                                  <hr />
                                  <Droppable
                                    droppableId={columnId}
                                    key={columnId}
                                  >
                                    {(provided, snapshot) => {
                                      return (
                                        <div
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                          style={{
                                            //  background: index % 2 === 0 ?"#03B2A5":"#e0e0e0",
                                            background: "#e0e0e0",
                                            padding: 4,
                                            width: 250,
                                            minHeight: 500,
                                            // minHeight:100,
                                          }}
                                          className="div2"
                                        >
                                          {column?.items?.map((item, index) => {
                                            return (
                                              <Draggable
                                                key={item.id}
                                                draggableId={item?.id}
                                                index={index}
                                              >
                                                {(provided, snapshot) => {
                                                  return (
                                                    <div
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      style={{
                                                        userSelect: "none",
                                                        // padding: "16px 16px 0px 0px",
                                                        paddingLeft: "10px",
                                                        paddingTop: "7px",
                                                        paddingBottom: "0.10px",
                                                        margin: "0 0 8px 23px",
                                                        fontWeight: 400,
                                                        fontSize: "12px",
                                                        width: "80%",
                                                        boxShadow: "10px",
                                                        // height: "30px",
                                                        borderRadius: "5px",
                                                        boxShadow:
                                                          "0 4px 8px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                                        backgroundColor:
                                                          snapshot.isDragging
                                                            ? "#343a40"
                                                            : "white",
                                                        color:
                                                          snapshot.isDragging
                                                            ? "white"
                                                            : "black",
                                                        ...provided
                                                          .draggableProps.style,
                                                      }}
                                                    >
                                                      {item.content}

                                                      <p>
                                                        <small>
                                                          {" ( "}
                                                          {item?.businessName}
                                                          {" ) "}
                                                        </small>
                                                      </p>
                                                    </div>
                                                  );
                                                }}
                                              </Draggable>
                                            );
                                          })}
                                          {provided.placeholder}
                                        </div>
                                      );
                                    }}
                                  </Droppable>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </span>
                      );
                    }
                  )}
                </DragDropContext>
              </div>
            </Col>
          </Row>

          <div>
            <Modal
              size="md"
              isOpen={modal_static1}
              toggle={tog_static1}
              centered={true}
            >
              <ModalBody>
                <Row className="">
                  <Col md={8}>
                    {" "}
                    <p className="f_faq_model  mt-3">Create Category</p>
                  </Col>
                  <Col md={2}></Col>
                  <Col md={1}>
                    <i className=""></i>
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col md={12}>
                    <p className="f_faq_model_title1 mt-2">
                      Please enter the name for the category.
                    </p>
                    <Input
                      type="text"
                      placeholder="Category Name"
                      onChange={(e) => PackageCategoryNameFunc(e)}
                    />
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col md={3}></Col>
                  <Col md={3}></Col>

                  <Col md={3}>
                    <Button
                      color="success"
                      outline
                      className="waves-effect waves-light w-100"
                      onClick={() => setModal_static1(false)}
                    >
                      No
                    </Button>
                  </Col>

                  <Col md={3}>
                    <Button
                      color="success"
                      className="waves-effect waves-light w-100"
                      onClick={createCategoryColumnFunc}
                    >
                      Create
                    </Button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>

          <div>
            <Modal
              size="md"
              isOpen={modal_static2}
              toggle={tog_static2}
              centered={true}
            >
              <ModalBody>
                <Row className="">
                  <Col md={3}></Col>
                  <Col md={7}>
                    {" "}
                    <p className="f_faq_model  mt-3">Delete Category</p>
                  </Col>

                  <Col md={1}>
                    <i className=""></i>
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col md={2}></Col>
                  <Col md={10}>
                    <p className=" mt-2">
                      <b> Are you sure you want delete this category? </b>
                    </p>
                  </Col>
                </Row>

                <hr />
                <Row>
                  <Col md={3}></Col>

                  <Col md={3}>
                    <Button
                      color="success"
                      outline
                      className="waves-effect waves-light w-100"
                      onClick={() => setModal_static2(false)}
                    >
                      No
                    </Button>
                  </Col>

                  <Col md={3}>
                    <Button
                      color="success"
                      className="waves-effect waves-light w-100"
                      onClick={deleteCategoryFunc}
                    >
                      Delete
                    </Button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
};

export default PackageCategories;
